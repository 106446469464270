define("livarava-web/components/event/event-register-form", ["exports", "ember-delay/delay", "jquery"], function (_exports, _delay, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Attributes
    event: null,
    ticket: null,
    ticketFound: null,
    error: null,
    locale: 'en',
    // Flags
    isComplete: false,
    isFoundTicket: false,
    // Computed
    currentUrl: Ember.computed(function () {
      return window && window.location ? window.location.href : '/';
    }),
    // Find Ticket
    findTicket: function findTicket() {
      var _this = this;
      var store = this.store;
      var eventId = Ember.get(this, 'event.id');
      var email = Ember.get(this, 'ticket.email');
      store.query('ticket', {
        'filter[event]': eventId,
        'filter[email]': email,
        per_page: 1,
        page: 1
      }).then(function (items) {
        if (items.content.length > 0) {
          var ticketFound = items.objectAt(0);

          // set(this, 'error', null);
          Ember.set(_this, 'isFoundTicket', true);
          Ember.set(_this, 'ticketFound', ticketFound);
          (0, _delay.default)(100).then(function () {
            (0, _jquery.default)('#message-success').fadeIn();
          });
        }
      });
    },
    // Complete
    complete: function complete() {
      var order = this.order;
      if (order.type === 'paid') {
        var paymentUrl = Ember.get(this, 'ticket.payment_url');
        window.open(paymentUrl, '_blank');
      }
      Ember.set(this, 'isComplete', true);
      (0, _delay.default)(100).then(function () {
        (0, _jquery.default)('#message-success').fadeIn();
      });
    },
    // Actions
    actions: {
      submit: function submit() {
        var _this2 = this;
        var event = this.event;
        var ticket = this.ticket;
        Ember.set(this, 'error', null);
        Ember.set(ticket, 'event', event);
        ticket.save().then(function (item) {
          _this2.complete();
        }, function (data) {
          Ember.set(_this2, 'error', 'Error');
          var errorData = JSON.parse(data.errors[0].detail);
          if (!errorData) return;
          var error = errorData.error;
          Ember.set(_this2, 'error', error);
          if (error.code === 409) {
            _this2.findTicket();
          }
        });
      }
    }
  });
  _exports.default = _default;
});