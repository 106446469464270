define("livarava-web/components/neuron/neuron-overview", ["exports", "livarava-web/mixins/neuron-type-determinator", "livarava-web/utils/parse-video-url"], function (_exports, _neuronTypeDeterminator, _parseVideoUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Properties
    item: null,
    // Computed
    showLink: Ember.computed('item.type', function () {
      return ['video', 'image', 'email', 'phone', 'event', 'link'].includes(this.get('item.type'));
    }),
    videoURL: Ember.computed('item.type', 'item.url', function () {
      return this.get('item.type') === 'video' ? (0, _parseVideoUrl.default)(this.get('item.url')) : '';
    }),
    gotoURL: Ember.computed('item.title', function () {
      var url = '';
      if (this.isLink) {
        url = this.get('item.title');
      } else {
        url = this.get('item.url');
      }
      return url;
    })
  });
  _exports.default = _default;
});