define("livarava-web/components/neuron/neuron-basic-info", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Defaults
    axon: null,
    views: true,
    display: null,
    displayOptions: _environment.default.displayOptions,
    // Flags
    canManageAxon: Ember.computed('me.id', 'axon.author.id', function () {
      var meId = parseInt(Ember.get(this, 'me.id'));
      var authorId = parseInt(Ember.get(this, 'axon.author.id'));
      return meId && authorId && meId === authorId;
    }),
    // Actions
    actions: {
      onAxonDisplayChange: function onAxonDisplayChange() {
        var axon = this.axon;
        axon.save();
      }
    }
  });
  _exports.default = _default;
});