define("livarava-web/components/neuroner/filter-form-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      addRule: function addRule() {
        this.get('group.rules').pushObject(Ember.Object.create({}));
      },
      deleteRule: function deleteRule(object) {
        object.remove();
        this.get('group.rules').removeObject(object);
      },
      setAll: function setAll() {
        var all = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : false;
        this.set('group.all', all);
      }
    }
  });
  _exports.default = _default;
});