define("livarava-web/templates/components/input-display-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ZlHXptlF",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dropdown \",[30,[36,3],[[35,5],\"opened\",\"closed\"],null]]]],[12],[2,\"\\n\"],[6,[37,7],null,[[\"action\"],[[30,[36,0],[[32,0],[30,[36,6],[[35,5]],null],false],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-light\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],[30,[36,4],[\"showDisplayDropdown\",[32,0]],null]],null],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[35,1,[\"display\"]],\"private\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-lock\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[30,[36,2],[[35,1,[\"display\"]],\"public\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-globe\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-chain\"],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-content dropdown-right\"],[12],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[30,[36,9],[[35,8]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"a\"],[24,0,\"dropdown-item\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"setDisplay\",[32,1,[\"value\"]]],null],[12],[2,\"\\n        \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"item\",\"eq\",\"if\",\"toggle\",\"showDisplayDropdown\",\"mut\",\"click-outside\",\"displayOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-display-button.hbs"
    }
  });
  _exports.default = _default;
});