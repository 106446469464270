define("livarava-web/templates/components/neuronet/neuronet-app-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "eiX+zjxY",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"neuronet-app-item \",[30,[36,6],[[35,4],\"neuronet-app-unavailable\"],null],\"  text-center\"]]],[12],[2,\"\\n\"],[6,[37,1],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],null,[[\"route\"],[[35,4]]],[[\"default\"],[{\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"img\"],[15,\"src\",[31,[[34,2]]]],[14,0,\"img img-fluid\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[15,0,[31,[[34,0]]]],[12],[13],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[34,3]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[15,\"src\",[31,[[34,2]]]],[14,0,\"img img-fluid\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[15,0,[31,[[34,0]]]],[12],[13],[2,\"\\n    \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"    \"],[10,\"div\"],[14,0,\"title\"],[12],[1,[34,3]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-muted\"],[12],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"comming soon\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"if\",\"img\",\"title\",\"route\",\"link-to\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuronet/neuronet-app-item.hbs"
    }
  });
  _exports.default = _default;
});