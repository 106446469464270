define("livarava-web/templates/components/navigation/navigation-item-inside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lMhSFvRV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"action\",\"dragOverAction\",\"dragOutAction\"],[\"onDragDrop\",\"onDragOver\",\"onDragOut\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"navigation-draggable-target navigation-inside\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"navigation-inside navigation-inside-dragover navigation-level navigation-level\",[34,0,[\"nextLevel\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"draggable-object-target\",\"isDragOver\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/navigation/navigation-item-inside.hbs"
    }
  });
  _exports.default = _default;
});