define("livarava-web/templates/components/profile-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mSk617Of",
    "block": "{\"symbols\":[\"sectionRoute\",\"&default\"],\"statements\":[[10,\"ul\"],[14,0,\"nav nav-pills nav-stacked nav-sections\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"link-to\",\"sectionRoutes\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/profile-sections.hbs"
    }
  });
  _exports.default = _default;
});