define("livarava-web/templates/components/funnel/funnel-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0CI9apwh",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"alert alert-info text-center mb-2\"],[24,\"role\",\"button\"],[4,[38,7],[[32,0],[30,[36,6],[\"isCollapsed\",[32,0]],null]],null],[12],[2,\"\\n  \"],[10,\"b\"],[12],[2,\"\\n    \"],[1,[35,8,[\"label\"]]],[2,\":\\n  \"],[13],[2,\"\\n  \"],[1,[35,1,[\"length\"]]],[2,\"\\n\"],[6,[37,10],[[30,[36,9],[[35,0],\"order\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"    заявок\\n    на суму \"],[1,[30,[36,5],[[35,4]],[[\"format\"],[\"0\"]]]],[2,\" UAH\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[8,\"bs-collapse\",[],[[\"@collapsed\"],[[32,0,[\"isCollapsed\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n\"],[6,[37,10],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,3],[[30,[36,2],[[35,0],\"/\",[35,0],\"-list\"],null]],[[\"items\",\"sort\",\"onDelete\"],[[35,1],null,null]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      No \"],[1,[34,0]],[2,\" items.\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"modelType\",\"items\",\"concat\",\"component\",\"amount\",\"number-format\",\"toggle\",\"action\",\"funnel\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/funnel/funnel-list.hbs"
    }
  });
  _exports.default = _default;
});