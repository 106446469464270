define("livarava-web/templates/components/input-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fuwVX2Kb",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[24,0,\"btn-group btn-switch\"],[24,\"role\",\"group\"],[24,\"aria-label\",\"\"],[4,[38,0],[[32,0],\"toggleValue\"],null],[12],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"btn \",[30,[36,2],[[35,1],\"btn-secondary\",\"btn-light\"],null]]]],[12],[2,\"Off\"],[13],[2,\"\\n  \"],[10,\"div\"],[15,0,[31,[\"btn \",[30,[36,4],[[30,[36,3],[[35,1],true],null],\"btn-success\",\"btn-light\"],null]]]],[12],[2,\"On\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"unless\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-switch.hbs"
    }
  });
  _exports.default = _default;
});