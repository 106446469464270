define("livarava-web/components/neuroner/filter-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    actions: {
      addRule: function addRule() {
        this.get('model.rules').pushObject(Ember.Object.create({}));
      },
      addNotification: function addNotification() {
        this.get('model.notifications').pushObject(Ember.Object.create({
          kind: "email",
          destination: ""
        }));
      },
      deleteRule: function deleteRule(object) {
        this.get('model.rules').removeObject(object);
      },
      deleteNotification: function deleteNotification(object) {
        this.get('model.notifications').removeObject(object);
      },
      setAll: function setAll(kind) {
        this.set('model.kind', kind);
      },
      addGroup: function addGroup() {
        this.get('model.group').pushObject(Ember.Object.create({
          all: true,
          rules: Ember.A([Ember.Object.create({
            text: "",
            kind: ""
          })])
        }));
      },
      save: function save() {
        this.set('json', JSON.stringify(this.model));
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      searchFeeds: function searchFeeds(term) {
        if (Ember.isBlank(term)) {
          return [];
        }
        return this.store.query('neuroner.feed', {
          filter: {
            url: term
          }
        });
      }
    }
  });
  _exports.default = _default;
});