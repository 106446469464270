define("livarava-web/components/details-popover", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    store: Ember.inject.service(),
    tagName: '',
    modelName: null,
    modelId: null,
    jsonData: null,
    updateJSON: function updateJSON(model) {
      var json = {};
      if (!model) {
        return;
      }
      model.eachAttribute(function (name, meta) {
        var value = model.get(name);
        if (!value) return;
        if (meta.type == 'datetime') {
          value = (0, _moment.default)(value).format('DD-MM-YYYY HH:mm');
        }
        json[name.replace(/_/g, ' ')] = String(value);
      });
      this.set('jsonData', json);
      return json;
    },
    // Actions
    actions: {
      fetchModel: function fetchModel() {
        var _this = this;
        if (this.jsonData) {
          return;
        }
        var modelName = this.modelName,
          modelId = this.modelId;
        return this.store.findRecord(modelName, modelId).then(function (model) {
          return _this.updateJSON(model);
        });
      }
    }
  });
  _exports.default = _default;
});