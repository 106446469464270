define("livarava-web/templates/components/service/service-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pSAGY134",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[35,0,[\"title\"]]],[2,\" \"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"serviceType\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/service/service-type.hbs"
    }
  });
  _exports.default = _default;
});