define("livarava-web/components/common/common-page", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // States
    header: true,
    footer: true
  });
  _exports.default = _default;
});