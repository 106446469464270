define("livarava-web/templates/projects/item/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YGkk0w9c",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-3\"],[12],[2,\"\\n    \"],[1,[30,[36,1],null,[[\"orientation\",\"class\",\"item\"],[\"vertical\",\"mt-0 mb-0\",[35,0]]]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-9\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"project/project-settings-sections\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/item/settings.hbs"
    }
  });
  _exports.default = _default;
});