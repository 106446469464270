define("livarava-web/templates/components/form-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PMVpkVM5",
    "block": "{\"symbols\":[\"message\"],\"statements\":[[6,[37,3],[[35,0,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n    Saving, please wait...\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"isError\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"alert-heading\"],[12],[2,\"\\n      Error!\\n    \"],[13],[2,\"\\n    \"],[10,\"p\"],[14,0,\"mb-0\"],[12],[2,\"\\n      \"],[1,[35,0,[\"adapterError\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,0,[\"errors\",\"messages\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[32,1]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,0,[\"isNew\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-warning\"],[12],[2,\"\\n    New \"],[1,[34,2]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"hasDirtyAttributes\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-success\"],[12],[2,\"\\n      Saved!\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-warning\"],[12],[2,\"\\n      Changes not saved\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"unless\",\"type\",\"if\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/form-messages.hbs"
    }
  });
  _exports.default = _default;
});