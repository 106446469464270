define("livarava-web/helpers/number-to-human-size", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.numberToHumanSize = numberToHumanSize;
  function numberToHumanSize(params /*, hash*/) {
    var bytes = params[0];
    var si = !params[1];
    var roundAmount = params[2] || 0;
    var thresh = si ? 1000 : 1024;
    var u = -1;
    var units = si ? ['kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'] : ['KiB', 'MiB', 'GiB', 'TiB', 'PiB', 'EiB', 'ZiB', 'YiB'];
    if (Math.abs(bytes) < thresh) {
      return "".concat(bytes, " B");
    }
    do {
      bytes /= thresh;
      ++u;
    } while (Math.abs(bytes) >= thresh && u < units.length - 1);
    return "".concat(bytes.toFixed(roundAmount), " ").concat(units[u]);
  }
  var _default = Ember.Helper.helper(numberToHumanSize);
  _exports.default = _default;
});