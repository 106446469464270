define("livarava-web/templates/components/project/project-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fY7iDe4b",
    "block": "{\"symbols\":[\"section\",\"section\"],\"statements\":[[6,[37,6],[[30,[36,5],[[35,4],\"horizontal\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-sections nav-horizontal\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",[32,2,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,2,[\"icon\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n          \"],[1,[32,2,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,6],[[30,[36,5],[[35,4],\"vertical\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-sections nav-vertical flex-column\"],[12],[2,\"\\n\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-link\",[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[32,1,[\"icon\"]]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n          \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"sectionsRoutes\",\"-track-array\",\"each\",\"orientation\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/project/project-sections.hbs"
    }
  });
  _exports.default = _default;
});