define("livarava-web/routes/messages", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    messages: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Title
    titleToken: 'Messages',
    // Model
    model: function model() {
      return this.store.query('thread', {});
    },
    // After Model
    afterModel: function afterModel(model, transition) {
      if (transition.targetName === 'messages.thread') {
        return;
      }
      if (Ember.get(model, 'length') > 0) {
        var id = Ember.get(model, 'firstObject').get('id');
        this.transitionTo('messages.thread', id);
      }
    },
    actions: {
      updateThreads: function updateThreads() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});