define("livarava-web/templates/components/input-autocomplete", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5/l/C6sZ",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[1,[30,[36,9],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"input\",\"key-down\",\"focusIn\",\"focusOut\",\"autofocus\"],[\"text\",[35,8],[35,7],[35,6],[30,[36,0],[[32,0],\"onInput\"],null],[30,[36,0],[[32,0],\"onKeyDown\"],null],[30,[36,0],[[32,0],\"onFocusIn\"],null],[30,[36,0],[[32,0],\"onFocusOut\"],null],[35,5]]]]],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\\n\"],[6,[37,11],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"i\"],[24,0,\"fa fa-remove fa-btn-remove\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"removeValue\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,11],[[35,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"item\",\"selectItem\"],[[32,1],[30,[36,0],[[32,0],\"selectItem\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"input-autocomplete-item\",\"items\",\"-track-array\",\"each\",\"autofocus\",\"value\",\"placeholder\",\"inputClass\",\"input\",\"showRemoveButton\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-autocomplete.hbs"
    }
  });
  _exports.default = _default;
});