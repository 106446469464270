define("livarava-web/components/review/rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'span',
    rating: 0,
    value: Ember.computed('rating', function () {
      return Math.round((this.get('rating') || 0) / 2 * 10) / 10;
    }),
    halfStar: Ember.computed('value', function () {
      var value = this.get('value');
      var ceil = Math.ceil(value);
      var floor = Math.floor(value);
      if (ceil == floor) {
        return null;
      }
      return floor;
    })
  });
  _exports.default = _default;
});