define("livarava-web/components/project/project-settings-investments-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Attributes
    currencyOptions: _environment.default.currencyOptions,
    // Actions
    actions: {
      save: function save() {
        this.save(this.model);
      },
      setImage: function setImage(item) {
        var _this = this;
        if (item === null) {
          Ember.set(this, 'model.image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          Ember.set(_this, 'model.image', image);
        });
      },
      setStatus: function setStatus(value) {
        Ember.set(this, 'model.status', value);
        if (value === 'archived') {
          Ember.set(this, 'model.featured', false);
        }
        this.save(this.model);
      },
      setFeatured: function setFeatured(value) {
        Ember.set(this, 'model.featured', value);
        this.save(this.model);
      }
    }
  });
  _exports.default = _default;
});