define("livarava-web/components/neuron-general-info", ["exports", "livarava-web/mixins/neuron-type-determinator", "livarava-web/utils/parse-video-url", "ember-delay/delay", "jquery"], function (_exports, _neuronTypeDeterminator, _parseVideoUrl, _delay, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Service
    store: Ember.inject.service(),
    // Attributes
    item: null,
    // Flags
    showHeaderEditInput: false,
    // Events
    onSaveNeuron: null,
    // Computed
    showLink: Ember.computed('item.type', function () {
      return ['video', 'image', 'email', 'phone', 'event', 'link'].includes(Ember.get(this, 'item.type'));
    }),
    videoURL: Ember.computed('item.{type,url}', function () {
      return Ember.get(this, 'item.type') === 'video' ? (0, _parseVideoUrl.default)(Ember.get(this, 'item.url')) : '';
    }),
    gotoURL: Ember.computed('item.title', function () {
      var url = '';
      if (this.isLink) {
        url = Ember.get(this, 'item.title');
      } else {
        url = Ember.get(this, 'item.url');
      }
      return url;
    }),
    // Attributes
    actions: {
      save: function save() {
        var neuron = this.item;
        if (!neuron.id) {
          return;
        }
        neuron.save().then().catch(function () {
          alert('Error');
        });
      },
      saveNeuron: function saveNeuron() {
        this.onSaveNeuron();
      },
      editHeader: function editHeader(value) {
        var item = this.item;
        Ember.set(this, 'showHeaderEditInput', value);
        if (value) {
          (0, _delay.default)(5).then(function () {
            (0, _jquery.default)("#neuronHeader").focus();
          });
        } else {
          if (item.hasDirtyAttributes) {
            item.save();
          }
        }
      }
    }
  });
  _exports.default = _default;
});