define("livarava-web/controllers/services/tree", ["exports", "@babel/runtime/helpers/esm/toConsumableArray"], function (_exports, _toConsumableArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    q: null,
    parent: null,
    children: [],
    response: null,
    filteredServices: Ember.computed('model.services.@each.{title,id}', 'q', function () {
      var services = this.model.services,
        q = this.q;
      var filtered = services;
      if (q) {
        filtered = services.filter(function (_ref) {
          var id = _ref.id,
            title = _ref.title;
          return id == q || title.toLowerCase().includes(q.toLowerCase());
        });
      }
      return filtered;
    }),
    chartHeight: Ember.computed('model.services.[]', function () {
      return this.get('model.services.length') * 17 + 'px';
    }),
    chartOptions: Ember.computed('model.services.@each.{id,parent}', function () {
      var services = this.model.services;
      var top = services.filter(function (s) {
        return !s.get('parent.id');
      });
      var data = {
        name: 'Послуги',
        children: (0, _toConsumableArray2.default)(top.map(function (_ref2) {
          var title = _ref2.title,
            id = _ref2.id;
          var children = services.filter(function (s) {
            return s.get('parent.id') == id;
          }).map(function (_ref3) {
            var title = _ref3.title,
              id = _ref3.id;
            return {
              name: "".concat(id, " ").concat(title)
              // value: id,
            };
          });

          return {
            name: "".concat(id, " ").concat(title, " (").concat(children.length, ")"),
            children: children
          };
        }))
      };
      return {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [{
          type: 'tree',
          data: [data],
          edgeShape: 'polyline',
          edgeForkPosition: '90%',
          roam: false,
          top: '1%',
          left: '1%',
          bottom: '1%',
          right: '35%',
          symbolSize: 10,
          label: {
            position: 'left',
            verticalAlign: 'middle',
            align: 'right',
            fontSize: 13
          },
          leaves: {
            label: {
              position: 'right',
              verticalAlign: 'middle',
              align: 'left'
            }
          },
          emphasis: {
            focus: 'ancestor'
          },
          expandAndCollapse: true,
          animationDuration: 550,
          animationDurationUpdate: 750
        }]
      };
    }),
    actions: {
      onChartClick: function onChartClick(props) {
        console.log(props);
      },
      toggleChild: function toggleChild(service) {
        var children = this.children;
        if (children.includes(service)) {
          children.removeObject(service);
        } else {
          children.pushObject(service);
        }
      },
      save: function save() {
        var _this = this;
        var parent = this.parent,
          children = this.children;
        return Promise.all(children.map(function (service) {
          service.set('parent', parent);
          return service.save();
        })).then(function () {
          return _this.set('response', {
            success: true
          });
        }).catch(function (error) {
          return _this.set('response', {
            success: false,
            error: error
          });
        });
      }
    }
  });
  _exports.default = _default;
});