define("livarava-web/routes/funnels/item", ["exports", "livarava-web/mixins/authenticated-route-mixin", "livarava-web/config/environment"], function (_exports, _authenticatedRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    me: Ember.inject.service(),
    // Title
    titleToken: 'Properties Funnel',
    // Params
    queryParams: {
      funnel_next_since: {
        refreshModel: true
      },
      funnel_next_until: {
        refreshModel: true
      },
      manager: {
        refreshModel: true,
        default: null
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var modelType = params['model_type'];
      var funnel_next_since = params['funnel_next_since'];
      var funnel_next_until = params['funnel_next_until'];
      var manager = params['manager'];
      var funnels = _environment.default.options[modelType].funnel;
      var out = {
        funnels: funnels
      };
      funnels.forEach(function (f) {
        var funnel = f.value;
        var params = {};
        if (funnel_next_since) {
          params['filter[funnel_next][since]'] = funnel_next_since;
        }
        if (funnel_next_until) {
          params['filter[funnel_next][until]'] = funnel_next_until;
        }
        params['filter[funnel]'] = funnel;
        if (manager) {
          params['filter[manager]'] = manager;
        }
        if (modelType == 'order') {
          params['filter[status]'] = 'draft,opened,accepted';
        }
        out[funnel] = store.query(modelType, params);
      });
      if (modelType == 'order') {
        var _params = {
          'filter[status]': 'canceled',
          per_page: 1
        };
        if (funnel_next_since) {
          _params['filter[funnel_next][since]'] = funnel_next_since;
        }
        if (funnel_next_until) {
          _params['filter[funnel_next][until]'] = funnel_next_until;
        }
        if (manager) {
          _params['filter[manager]'] = manager;
        }
        out['canceled'] = store.query(modelType, _params);
      }
      out.modelType = Promise.resolve(modelType);
      return Ember.RSVP.hash(out);
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});