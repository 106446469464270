define("livarava-web/components/action/funnel-dropdown", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var allStatuses = {
    property: Array.from(_environment.default.options.property.funnel),
    entity: Array.from(_environment.default.options.entity.funnel),
    order: Array.from(_environment.default.options.order.funnel)
  };
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    funnel: null,
    model: null,
    newFunnel: null,
    funnelOptions: Ember.computed('model.type', function () {
      var options = allStatuses[this.get('model.type')];
      return (options || []).map(function (option, index) {
        return Object.assign({
          index: index + 1
        }, option);
      });
    }),
    funnelObject: Ember.computed('funnel', 'funnelOptions.[]', function () {
      var funnel = this.funnel;
      if (!funnel) {
        return null;
      }
      return this.funnelOptions.find(function (option) {
        return option.value == funnel;
      });
    }),
    actions: {
      handleFunnelChange: function handleFunnelChange(newFunnelObject) {
        var _this = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        var model = this.model;
        model.setProperties({
          funnel: newFunnelObject.value
        });
        return model.save().then(function () {
          if (typeof _this.onChange == 'function') {
            return _this.onChange(newFunnelObject.value);
          }
        });
      }
    }
  });
  _exports.default = _default;
});