define("livarava-web/routes/payments/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      return this.store.findRecord('payment', params.id);
    },
    // Actions
    actions: {
      process: function process() {
        if (confirm("Are you sure?")) {
          var model = this.controller.model;
          Ember.set(model, 'status', 'process');
          model.save();
        }
      },
      delete: function _delete() {
        var _this = this;
        if (confirm("Are you sure?")) {
          var model = this.controller.model;
          model.destroyRecord().then(function () {
            return _this.transitionTo('payments');
          });
        }
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});