define("livarava-web/templates/projects/item/preorders/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5+i83yGK",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[11,\"form\"],[24,0,\"m-b-3\"],[4,[38,1],[[32,0],\"save\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"h4\"],[12],[2,\"Settings\"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"m-b-2\"],[12],[13],[2,\"\\n\\n  \"],[10,\"h5\"],[12],[2,\"Notifications\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"preorders_notifications\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,0],null,[[\"deleteNotification\",\"notifications\"],[\"deleteNotification\",[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-success\"],[4,[38,1],[[32,0],\"addNotification\"],null],[12],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\" Add\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"m-b-2\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"class\",\"route\",\"model\"],[\"btn btn-info\",\"projects.item.preorders\",[35,2,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      Cancel\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"button\"],[15,0,[31,[\"btn btn-primary \",[30,[36,6],[[35,2,[\"isSaving\"]],\"disabled\"],null]]]],[14,4,\"submit\"],[12],[2,\"\\n\"],[6,[37,6],[[35,2,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\" Please, wait...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-save\"],[12],[13],[2,\" Save\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,8],[[30,[36,7],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"project-preorders-settings-notification\",\"action\",\"model\",\"-track-array\",\"each\",\"link-to\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/item/preorders/settings.hbs"
    }
  });
  _exports.default = _default;
});