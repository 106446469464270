define("livarava-web/templates/sites/item/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "luIEo5x5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-0\"],[12],[2,\"About\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-success\",\"sites.item.general\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Edit\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mb-3\"],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0,[\"description\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,[35,0,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info font-italic\"],[12],[2,\"Here can be a description for your site.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,2],[[35,0,[\"url\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"http://\",[34,0,[\"url\"]]]]],[14,\"target\",\"_blank\"],[12],[1,[35,0,[\"url\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/about.hbs"
    }
  });
  _exports.default = _default;
});