define("livarava-web/components/motivation/items-without-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    filteredItems: Ember.computed.filter('items.@each.nmanager', function (item) {
      return !item.get('manager.id');
    })
  });
  _exports.default = _default;
});