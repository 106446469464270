define("livarava-web/controllers/messages/new", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    messages: Ember.inject.service(),
    // Recipients
    recipients: null,
    recipient: Ember.computed('recipients.[]', function () {
      var recipients = this.recipients;
      return recipients.length > 0 ? recipients.objectAt(0) : null;
    }),
    recipientInputPlaceholer: Ember.computed('recipients.[]', function () {
      var length = Ember.get(this, 'recipients.length');
      return length > 0 ? "" : "Type the name of a person or group";
    }),
    recipientIdsArray: Ember.computed('recipients.[]', function () {
      var recipients = this.recipients.toArray();
      return recipients.length > 0 ? recipients.map(function (recipient) {
        return parseInt(recipient.id);
      }) : [];
    }),
    recipientIds: Ember.computed('recipientIdsArray', function () {
      var recipients = this.recipientIdsArray;
      return recipients.length > 0 ? getRecipientsIds(recipients) : null;
    }),
    recipientChanged: Ember.observer('recipients.[]', function () {
      var _this = this;
      var store = this.store;
      var threadIds = this.threadIds;
      store.query('thread', {
        'filter[recipients]': threadIds,
        per_page: 1,
        page: 1
      }).then(function (items) {
        if (items.meta.total_results === 0) {
          Ember.set(_this, 'thread', null);
          return;
        }
        var thread = items.get('firstObject');
        var threadId = parseInt(thread.id);
        Ember.set(_this, 'thread', thread);
        store.query('message', {
          'filter[thread]': threadId,
          per_page: 20,
          page: 1
        }).then(function (items) {
          _this.set('threadMessages', items);
        });
      }).catch(function () {
        Ember.set(_this, 'thread', null);
      });
    }),
    // Thread
    thread: null,
    threadIds: Ember.computed('recipients.[]', 'me.id', function () {
      var ids = this.recipientIdsArray;
      var meId = parseInt(Ember.get(this, 'me.id'));
      ids.push(meId);
      return getRecipientsIds(ids);
    }),
    threadMessages: null,
    page: 1,
    // Message
    message: null,
    init: function init() {
      this._super();
      Ember.set(this, 'recipients', Ember.A());
      Ember.set(this, 'thread', null);
      Ember.set(this, 'message', null);
      this.messages.newThreadInit();
      Ember.run.schedule("afterRender", this, function () {
        (0, _jquery.default)(".rside-input-zone input").focus();
      });
    },
    newLine: function newLine() {
      var el = document.getElementById('messageTextarea'),
        elj = (0, _jquery.default)("#messageTextarea"),
        allText = elj.val(),
        currentPos = getCaret(el),
        beforeText = allText.substr(0, currentPos),
        afterText = allText.substr(currentPos);
      elj.val(beforeText + '\n' + afterText);
      setCaretPosition(el, currentPos);
    },
    enterPressed: function enterPressed() {
      var _this2 = this;
      var store = this.store;
      var recipients = this.recipients;
      var recipientsIds = this.threadIds;
      var message = this.message;
      var author = Ember.get(this, 'me.user');
      var thread = this.thread;
      if (recipients.length === 0) {
        return;
      }
      if (recipients.length > 1) {
        alert("'Group chats isn't fully supported yet.");
        return;
      }
      if (!message) {
        return;
      }
      Ember.set(this, 'message', null);
      if (!thread) {
        // Create Thread for new Message
        var _thread = store.createRecord('thread', {
          recipients: recipientsIds
        });
        _thread.save().then(function (thread) {
          // Create new Message
          var item = store.createRecord('message', {
            message: message,
            author: author,
            thread: thread
          });
          item.save().then(function () {
            _this2.send('updateThreads');
            _this2.init();
            _this2.transitionTo('messages.thread', thread.id);
          });
        });
      } else {
        // Create new Message
        var item = store.createRecord('message', {
          message: message,
          author: author,
          thread: thread
        });
        item.save().then(function () {
          _this2.send('updateThreads');
          _this2.init();
          _this2.transitionTo('messages.thread', thread.id);
        });
      }
    },
    // Actions
    actions: {
      addRecipient: function addRecipient(value) {
        var _this3 = this;
        var store = this.store;
        if (value > -1) {
          store.findRecord('user', value).then(function (user) {
            _this3.recipients.pushObject(user);
          });
        }
      },
      onBackspace: function onBackspace(value) {
        var recipients = this.recipients;
        if (recipients.length > 0 && (value === null || value === "")) {
          recipients.popObject();
        }
      },
      onKeyDown: function onKeyDown(value, event) {
        if (event.key === 'Enter' || event.keyCode === 13 || event.keyCode === 10) {
          if (event.ctrlKey) {
            event.preventDefault();
            this.newLine();
          } else if (event.shiftKey) {
            event.preventDefault();
            this.newLine();
          } else {
            event.preventDefault();
            this.enterPressed();
          }
        } else if (event.key === 'Escape') {
          Ember.set(this, 'message', null);
        }
      }
    }
  });
  _exports.default = _default;
  function getRecipientsIds(arr) {
    return arr.sort(function (a, b) {
      return a > b;
    }).join(',');
  }
  function getCaret(el) {
    if (el.selectionStart) {
      return el.selectionStart;
    }

    // ^ Above doesn't work in IE - Fix below
    else if (document.selection) {
      el.focus();
      var r = document.selection.createRange();
      if (r == null) {
        return 0;
      }
      var re = el.createTextRange(),
        rc = re.duplicate();
      re.moveToBookmark(r.getBookmark());
      rc.setEndPoint('EndToStart', re);
      return rc.text.length;
    }
    return 0;
  }
  function setCaretPosition(el, caretPos) {
    if (el != null) {
      if (el.createTextRange) {
        var range = el.createTextRange();
        range.move('character', caretPos);
        range.select();
      } else {
        if (el.selectionStart) {
          el.focus();
          el.setSelectionRange(caretPos, caretPos);
        } else el.focus();
      }
    }
  }
});