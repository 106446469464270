define("livarava-web/components/site/site-new-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Actions
    actions: {
      save: function save() {
        var router = this.router;
        var title = Ember.get(this, 'model.title');
        if (!title) return;
        this.save(this.model);
      }
    }
  });
  _exports.default = _default;
});