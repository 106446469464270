define("livarava-web/templates/components/neuroner/customer-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6kwF/gbC",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"route\",\"model\"],[\"neuroner.settings.customers.item\",[35,4,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"item\"],[[35,4]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-6\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      Package: \"],[1,[35,7,[\"neuroner_label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Due:\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[15,0,[31,[[30,[36,1],[[35,0],\"text-danger\",\"text-success\"],null]]]],[12],[2,\"\\n          \"],[1,[30,[36,3],[[35,2],\"YYYY-MM-DD\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        None\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Feeds: \"],[1,[35,7,[\"neuroner_feeds_current\"]]],[2,\"/\"],[1,[35,7,[\"neuroner_feeds_limit\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Filters: \"],[1,[35,7,[\"neuroner_filters_current\"]]],[2,\"/\"],[1,[35,7,[\"neuroner_filters_limit\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"isOverdue\",\"if\",\"due\",\"moment-format\",\"item\",\"user/user-small\",\"link-to\",\"data\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/customer-list-item.hbs"
    }
  });
  _exports.default = _default;
});