define("livarava-web/templates/components/review/rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7C2Q7nKY",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[1,[30,[36,2],[[35,0],[35,0]],null]],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[30,[36,5],[1,5,true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[30,[36,2],[[30,[36,4],[[32,1],[35,3]],null],\"star-half-o\",[30,[36,2],[[30,[36,1],[[32,1],[35,0]],null],\"star\",\"star-o\"],null]],null],\" text-success\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"lte\",\"if\",\"halfStar\",\"eq\",\"range\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/review/rate.hbs"
    }
  });
  _exports.default = _default;
});