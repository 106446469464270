define("livarava-web/locales/uk/config", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // Ember-I18n includes configuration for common locales. Most users
  // can safely delete this file. Use it if you need to override behavior
  // for a locale or define behavior for a locale that Ember-I18n
  // doesn't know about.
  var _default = {
    rtl: false,
    pluralForm: function pluralForm(count) {
      if (count === 0) {
        return 'zero';
      }
      if (count === 1) {
        return 'one';
      }
      if (count === 2) {
        return 'two';
      }
      if (count < 5) {
        return 'few';
      }
      if (count >= 5) {
        return 'many';
      }
      return 'other';
    }
  };
  _exports.default = _default;
});