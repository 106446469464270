define("livarava-web/templates/feedbacks/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xzII3Xs6",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"feedbacks\"]],[[\"default\"],[{\"statements\":[[2,\"Feedbacks\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[30,[36,2],[[35,1,[\"subject\"]],[35,1,[\"subject\"]],\"New\"],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"feedback\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[2,\"New feedback\"],[13],[2,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,4],null,[[\"model\",\"save\"],[[35,1],[30,[36,3],[[32,0],\"save\"],null]]]]],[2,\"\\n\\n    \"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\\n  \"],[13],[3,\"body\"],[2,\"\\n\"],[13],[3,\"feedback\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"if\",\"action\",\"feedback/feedback-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/feedbacks/create.hbs"
    }
  });
  _exports.default = _default;
});