define("livarava-web/templates/components/project-preorders-settings-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "KP2tKioc",
    "block": "{\"symbols\":[\"notifications\",\"kind\",\"&default\"],\"statements\":[[18,3,null],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"form-group col-xs-12 col-sm-4 col-md-2\"],[12],[2,\"\\n  \"],[10,\"button\"],[14,0,\"btn btn-secondary dropdown-toggle\"],[14,\"data-toggle\",\"dropdown\"],[14,\"aria-haspopup\",\"true\"],[14,\"aria-expanded\",\"false\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[1,[34,3]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"dropdown-menu\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[35,4]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[24,0,\"dropdown-item\"],[4,[38,2],[[32,0],\"change\",\"kind\",[32,2]],null],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group col-xs-12 col-sm-6 col-md-8\"],[12],[2,\"\\n\"],[6,[37,8],[[30,[36,7],[[35,0,[\"kind\"]],\"slack\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\",\"required\",\"placeholder\"],[\"url\",\"form-control\",[35,0,[\"destination\"]],true,\"Enter Slack hook URL...\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"type\",\"class\",\"value\",\"required\",\"placeholder\"],[\"email\",\"form-control\",[35,0,[\"destination\"]],true,\"Enter email...\"]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group col-xs-12 col-sm-2 text-xs-right\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,2],[[32,0],\"remove\",[35,0]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-times\"],[14,\"aria-hidden\",\"true\"],[12],[13],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"notifications\",\"input\",\"action\",\"typeLabel\",\"types\",\"-each-in\",\"each\",\"eq\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/project-preorders-settings-notification.hbs"
    }
  });
  _exports.default = _default;
});