define("livarava-web/components/project-preorders-settings-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESTINATIONS_TYPES = void 0;
  var DESTINATIONS_TYPES = {
    'email': {
      title: 'Email'
    },
    'slack': {
      title: 'Slack'
    }
  };
  _exports.DESTINATIONS_TYPES = DESTINATIONS_TYPES;
  var _default = Ember.Component.extend({
    tagName: 'fieldset',
    classNames: "form-group row",
    types: DESTINATIONS_TYPES,
    typeLabel: Ember.computed('notifications.kind', function () {
      var TYPE = DESTINATIONS_TYPES[this.get('notifications.kind')];
      return TYPE ? TYPE.title : 'Select...';
    }),
    actions: {
      change: function change(property, value) {
        this.notifications.set(property, value);
      },
      remove: function remove(item) {
        this.sendAction('deleteNotification', item);
      }
    }
  });
  _exports.default = _default;
});