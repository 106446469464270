define("livarava-web/components/property/propertyservice-table", ["exports", "livarava-web/config/environment", "livarava-web/validations/propertyservice-validations"], function (_exports, _environment, _propertyserviceValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    propertyserviceValidations: _propertyserviceValidations.default,
    allServiceTypes: _environment.default.service.types,
    allServiceStatuses: _environment.default.service.status,
    property: null,
    propertyservices: null,
    openedPropertyservice: null,
    initPropertyservice: function initPropertyservice() {
      if (this.property) {
        var store = this.store;
        var openedPropertyservice = store.createRecord('propertyservice', {
          property: this.property
        });
        this.set('openedPropertyservice', openedPropertyservice);
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.initPropertyservice();
      },
      savePropertyservice: function savePropertyservice(changeset) {
        var _this = this;
        if (this.get('openedPropertyservice.isSaving')) {
          // skip save if in progress
          return;
        }
        return changeset.save().then(function (propertyservice) {
          _this.setProperties({
            openedPropertyservice: null
          });
          if (typeof _this.onAdd == 'function') {
            return _this.onAdd(propertyservice);
          }
        });
      },
      removeService: function removeService(propertyservice) {
        var _this2 = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        return propertyservice.destroyRecord().then(function () {
          if (typeof _this2.onAdd == 'function') {
            return _this2.onAdd(propertyservice);
          }
        });
      }
    }
  });
  _exports.default = _default;
});