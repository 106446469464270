define("livarava-web/templates/components/site/site-api", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6qCaumWl",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"form\"],[24,0,\"site-form\"],[4,[38,0],[[32,0],\"submit\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"form-group mb-3\"],[12],[2,\"\\n    \"],[10,\"label\"],[14,\"for\",\"apikey\"],[14,0,\"mb-1\"],[12],[2,\"API Key\"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"input-group mb-2\"],[12],[2,\"\\n      \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"id\",\"class\",\"placeholder\",\"readonly\",\"click\"],[\"text\",[35,1,[\"api_key\"]],\"apikey\",\"form-control\",\"No api key yet\",\"readonly\",[30,[36,0],[[32,0],\"onApiKeyClick\"],null]]]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"input-group-append\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-outline-secondary\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"generateApiKey\"],null],[12],[2,\"\\n          \"],[1,[30,[36,3],[[35,1,[\"api_key\"]],\"Regenerate\",\"Generate\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-muted small\"],[12],[2,\"\\n      Use this API Key for importing/exporting data with LivaRava API.\\n      \"],[10,\"a\"],[14,6,\"/docs\"],[12],[2,\"Read more...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"input\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-api.hbs"
    }
  });
  _exports.default = _default;
});