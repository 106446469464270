define("livarava-web/components/motivation/actions-distribution-by-managers", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // attributes
    dates: null,
    managers: null,
    fetchData: function fetchData() {
      var _this = this;
      var _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store,
        managers = this.managers;
      var promiseHash = (managers || []).filter(function (m) {
        return m;
      }).reduce(function (result, manager) {
        result[manager.get('fullname')] = store.query('action', {
          'filter[author]': manager.get('id'),
          date_start: (0, _momentTimezone.default)(dates[0]).format('YYYY-MM-DD'),
          date_end: (0, _momentTimezone.default)(dates[1]).format('YYYY-MM-DD'),
          per_page: 1,
          page: 1
        }).then(function (actionItems) {
          return actionItems.get('meta.total_results');
        });
        return result;
      }, {});
      return Ember.RSVP.hash(promiseHash).then(function (distribution) {
        return _this.set('distribution', distribution);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchData();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.fetchData();
    }
  });
  _exports.default = _default;
});