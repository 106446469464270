define("livarava-web/controllers/invoices/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm('Are you sure?')) {
          item.destroyRecord().then(function () {
            return _this.transitionToRoute('invoices');
          });
        }
      },
      save: function save(item) {
        var _this2 = this;
        item.save().then(function () {
          return _this2.transitionToRoute('invoices');
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('invoices.item', this.get('model.id'));
      }
    }
  });
  _exports.default = _default;
});