define("livarava-web/routes/support/posts/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var id = parseInt(params.id);
      params['neuron'] = id;
      params['per_page'] = 1000;
      return Ember.RSVP.hash({
        item: store.findRecord('post', id),
        comments: store.query('comment', params)
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});