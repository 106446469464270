define("livarava-web/mixins/anonymous-route-mixin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Services
    moment: Ember.inject.service(),
    // Before Model
    beforeModel: function beforeModel() {
      var moment = this.moment;
      moment.updateLocale('en', {
        week: {
          dow: 1 // Monday is the first day of the week
        }
      });
    }
  });
  _exports.default = _default;
});