define("livarava-web/templates/projects/item/preorders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "a/NsN1Oq",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  \"],[1,[35,0,[\"title\"]]],[2,\" ·\\n  Preorders\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"route\",\"model\"],[\"btn btn-info btn\",\"projects.item.preorders.settings\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-cog\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"m-b-2\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/item/preorders.hbs"
    }
  });
  _exports.default = _default;
});