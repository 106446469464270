define("livarava-web/templates/products/item/customers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XfaFDpQP",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"h2\"],[12],[2,\"Customers\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"customers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"customers\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"item\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"user/user-small\",\"link-to\",\"model\",\"-track-array\",\"each\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/products/item/customers/index.hbs"
    }
  });
  _exports.default = _default;
});