define("livarava-web/controllers/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    messages: Ember.inject.service(),
    // Actions
    actions: {
      newThread: function newThread() {
        this.messages.newThreadInit();
        this.transitionToRoute('messages.new');
      }
    }
  });
  _exports.default = _default;
});