define("livarava-web/templates/components/service/input-service-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hxez/ZF0",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[6,[37,6],null,[[\"tagName\",\"triggerClass\",\"dropdownClass\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"allowClear\"],[[35,5],[35,4],[35,3],false,[35,2],[30,[36,1],[[32,0],\"handleChange\"],null],[35,0],true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"selected\",\"action\",\"options\",\"dropdownClass\",\"triggerClass\",\"tagName\",\"power-select\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/service/input-service-type.hbs"
    }
  });
  _exports.default = _default;
});