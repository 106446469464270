define("livarava-web/components/contact/contact-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Actions
    actions: {
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      reset: function reset() {
        this.model.rollbackAttributes();
      }
    }
  });
  _exports.default = _default;
});