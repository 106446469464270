define("livarava-web/templates/components/neuron/neuron-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ofhXLYe0",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"media neuron-list-item\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"media-left\"],[12],[2,\"\\n\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"img\"],[14,0,\"media-object feed-item-image\"],[15,\"src\",[31,[[34,0,[\"icon_url\"]]]]],[15,\"alt\",[31,[[34,0,[\"header\"]]]]],[14,\"width\",\"60\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"media-body\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n\\n\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"h5\"],[14,0,\"media-heading\"],[15,\"title\",[31,[[34,0,[\"header\"]]]]],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[35,0,[\"header\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,0,[\"text\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[12],[1,[30,[36,1],[[35,0,[\"text\"]]],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,4],null,[[\"item\"],[[35,0]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"neuron\",\"truncate\",\"link-to\",\"if\",\"neuron/neuron-basic-info\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-list-item.hbs"
    }
  });
  _exports.default = _default;
});