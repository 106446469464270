define("livarava-web/models/neuroner/filter", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    label: _emberData.default.attr('string'),
    kind: _emberData.default.attr('string', {
      defaultValue: 'all'
    }),
    rules: _emberData.default.attr('array-of-objects'),
    notifications: _emberData.default.attr('array-of-objects'),
    active: _emberData.default.attr('boolean'),
    feeds: _emberData.default.hasMany('neuroner.feed')
  });
  _exports.default = _default;
});