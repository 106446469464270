define("livarava-web/routes/services/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    title: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var serviceId = parseInt(params.id);
      return store.findRecord('service', serviceId).then(function (item) {
        var title = Ember.get(item, 'title');
        Ember.set(_this, 'title', title);
        return item;
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this2 = this;
        item.save().then(function (item) {
          return _this2.transitionTo('services.index');
        });
      }
    }
  });
  _exports.default = _default;
});