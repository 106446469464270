define("livarava-web/controllers/leads/index", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'sort', 'status', 'author', 'city'],
    page: 1,
    per_page: 10,
    q: null,
    sort: null,
    status: null,
    author: null,
    city: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'city',
      query: 'city',
      type: 'search',
      placeholder: 'City...'
    }, {
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Type',
      options: Array.from(_environment.default.options.order.status)
    }, {
      name: 'author',
      query: 'author',
      type: 'user',
      inputComponent: 'input-user',
      placeholder: 'Автор'
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }, {
      query: 'per_page',
      type: 'select',
      placeholder: 'Per Page',
      options: [{
        value: 5,
        label: '5'
      }, {
        value: 10,
        label: '10'
      }, {
        value: 25,
        label: '25'
      }, {
        value: 50,
        label: '50'
      }, {
        value: 100,
        label: '100'
      }, {
        value: 250,
        label: '250'
      }]
    }])
  });
  _exports.default = _default;
});