define("livarava-web/templates/payments/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Hu3iG5xJ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"payouts\"]],[[\"default\"],[{\"statements\":[[2,\"Payments\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"New\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"payout\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n    \"],[1,[30,[36,3],null,[[\"model\",\"onSave\"],[[35,2],[30,[36,1],[\"onSave\"],null]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\",\"route-action\",\"model\",\"payment/payment-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/payments/create.hbs"
    }
  });
  _exports.default = _default;
});