define("livarava-web/controllers/neuron/item/subscribers", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attributes
    neuron: null,
    // States
    isLoading: false,
    // Query Params
    queryParams: ['page', 'per_page', 'q', 'type', 'display', 'author'],
    // Params
    r: null,
    page: 1,
    per_page: 9,
    q: null,
    // Filters
    filters: Ember.computed('type', function () {
      return Ember.A([{
        name: 'q',
        query: 'q',
        type: 'search',
        placeholder: 'Search...'
      }]);
    }),
    // Actions
    actions: {
      setPage: function setPage(page) {
        var _this = this;
        var neuronId = this.get('model.neuron.id');
        this.set('isLoading', true);
        this.transitionToRoute('neuron.item.subscribers', neuronId, {
          queryParams: {
            page: page
          }
        }).then(function () {
          return _this.set('isLoading', false);
        });
      },
      setFilter: function setFilter(name) {
        var _this2 = this;
        var value = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
        var neuronId = this.get('model.neuron.id');
        this.set('isLoading', true);
        this.transitionToRoute('neuron.item.subscribers', neuronId, {
          queryParams: (0, _defineProperty2.default)({
            page: 1
          }, "filter[".concat(name, "]"), value)
        }).then(function () {
          return _this2.set('isLoading', false);
        });
      },
      reset: function reset() {
        var _this3 = this;
        var neuronId = this.get('model.neuron.id');
        this.set('isLoading', true);
        this.transitionToRoute('neuron.item.subscribers', neuronId, {
          queryParams: {
            page: 1,
            title: null,
            type: null,
            display: null
          }
        }).then(function () {
          return _this3.set('isLoading', false);
        });
      }
    }
  });
  _exports.default = _default;
});