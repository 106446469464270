define("livarava-web/templates/components/site-members-role-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ATZYRPPt",
    "block": "{\"symbols\":[\"roleChoice\"],\"statements\":[[10,\"select\"],[15,0,[31,[\"form-control site-member-role-picker \",[34,2]]]],[15,\"onchange\",[30,[36,4],[[32,0],[30,[36,3],[[35,0]],null]],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,1],[[35,0],[32,1,[\"value\"]]],null]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"role\",\"eq\",\"class\",\"mut\",\"action\",\"roles\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site-members-role-picker.hbs"
    }
  });
  _exports.default = _default;
});