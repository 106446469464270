define("livarava-web/templates/components/neuroner/feed-available-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iRw4mgXv",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"approved\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-check-square-o fa-mr-5 text-success\"],[12],[13],[2,\"approved\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-clock-o fa-mr-5 text-warning\"],[12],[13],[2,\"in revision\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/feed-available-status.hbs"
    }
  });
  _exports.default = _default;
});