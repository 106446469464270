define("livarava-web/templates/neuroner/settings/customers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4U+MhuHR",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"h2\"],[12],[2,\"Customers\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,1,[\"customers\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"customers\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,1,[\"customers\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"item\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,5],null,[[\"count\",\"current\",\"pager\",\"class\"],[[35,1,[\"customers\",\"meta\",\"total_pages\"]],[35,0],true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"      Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"customers\",\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"No customers was found\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[1,[30,[36,8],[[30,[36,7],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page\",\"model\",\"neuroner/customer-list-item\",\"-track-array\",\"each\",\"pagination-pager\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuroner/settings/customers/index.hbs"
    }
  });
  _exports.default = _default;
});