define("livarava-web/components/speech/speech-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    speech: Ember.inject.service(),
    // Actions
    actions: {
      micStart: function micStart() {
        var speech = this.speech;
        speech.start();
      },
      micFinish: function micFinish() {
        var speech = this.speech;
        speech.stop();
      }
    }
  });
  _exports.default = _default;
});