define("livarava-web/components/profile/profile-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sectionsRoutes: Ember.computed('model.rights', function () {
      var model = this.model;
      var rights = Ember.get(this, 'model.rights');
      var sections = [{
        name: 'about',
        title: 'About'
      }, {
        name: 'settings',
        title: 'Settings'
      }];
      return sections.filter(function (obj) {
        return rights[obj['name']] && rights[obj['name']] === true;
      }).map(function (obj) {
        return new Object({
          name: obj['name'],
          title: obj['title'],
          route: "profile.".concat(obj['name'])
        });
      });
    })
  });
  _exports.default = _default;
});