define("livarava-web/templates/settings/payments/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BibnEDa+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Settings\"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"settings.billing\"]],[[\"default\"],[{\"statements\":[[2,\"Billing\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"settings.payments\"]],[[\"default\"],[{\"statements\":[[2,\"Payments\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Payment #\"],[1,[35,1,[\"id\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"billing\"],[12],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"item\"],[[35,1]]]]],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"payment/payment-item\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/settings/payments/item.hbs"
    }
  });
  _exports.default = _default;
});