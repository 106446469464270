define("livarava-web/validations/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    accepted: '{description} must be accepted',
    after: '{description} must be after {after}',
    before: '{description} must be before {before}',
    blank: "{description} can't be blank",
    collection: '{description} must be a collection',
    confirmation: "{description} doesn't match {on}",
    date: '{description} must be a valid date',
    email: 'Неправильна e-mail адреса',
    empty: "{description} can't be empty",
    equalTo: '{description} must be equal to {is}',
    even: '{description} must be even',
    exclusion: '{description} is reserved',
    greaterThan: 'Має бути більше {gt}',
    greaterThanOrEqualTo: 'Значення не може бути менше {gte}',
    inclusion: '{description} is not included in the list',
    invalid: '{description} is invalid',
    lessThan: 'Має бути менше {lt}',
    lessThanOrEqualTo: 'Значення не може бути більше {lte}',
    notAnInteger: 'Має бути цілим числом',
    notANumber: 'Значення має бути числовим',
    odd: '{description} must be odd',
    onOrAfter: '{description} must be on or after {onOrAfter}',
    onOrBefore: '{description} must be on or before {onOrBefore}',
    otherThan: '{description} must be other than {value}',
    phone: 'Неправильний номер телефону',
    positive: 'Має бути додатнім числом',
    multipleOf: '{description} must be a multiple of {multipleOf}',
    present: "Поле обов'язкове",
    singular: "{description} can't be a collection",
    tooLong: '{description} is too long (maximum is {max} characters)',
    tooShort: 'Має містити мінімум {min} символів',
    between: 'Довжина має бути від {min} до {max} символів',
    url: 'Неправильна URL-адреса',
    wrongDateFormat: '{description} must be in the format of {format}',
    wrongLength: '{description} is the wrong length (should be {is} characters)'
  };
  _exports.default = _default;
});