define("livarava-web/mixins/authenticated-route-mixin", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Services
    moment: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Observers
    sessionSignedChanged: Ember.observer('session.signed', function () {
      var isSigned = Ember.get(this, 'session.signed');
      var next = window.location.href;
      if (!isSigned) {
        this.transitionTo('signin', {
          queryParams: {
            next: next
          }
        });
      }
    }),
    // Before Model
    beforeModel: function beforeModel() {
      var isSigned = Ember.get(this, 'session.signed');
      var next = window.location.href;
      var moment = this.moment;
      moment.updateLocale('en', {
        week: {
          dow: 1 // Monday is the first day of the week
        }
      });

      if (!isSigned) {
        this.transitionTo('signin', {
          queryParams: {
            next: next
          }
        });
      }
    }
  });
  _exports.default = _default;
});