define("livarava-web/components/neuron-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    // Image Style
    imageStyle: Ember.computed('item.icon_url', function () {
      var url = Ember.get(this, 'item.icon_url');
      return Ember.String.htmlSafe("background-image: url(".concat(url, ")"));
    })
  });
  _exports.default = _default;
});