define("livarava-web/routes/sites/item/users/index", ["exports", "lodash/omitBy", "lodash/isNil", "livarava-web/utils/get-filters-from-params"], function (_exports, _omitBy2, _isNil2, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Users',
    // Params
    queryParams: {
      q: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var site_id = this.paramsFor('sites.item').site_id;
      params['site_user'] = site_id;
      return Ember.RSVP.hash({
        site_id: site_id,
        users: this.store.query('user', (0, _omitBy2.default)((0, _getFiltersFromParams.default)(params), _isNil2.default))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});