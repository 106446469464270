define("livarava-web/templates/components/project/project-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T+K0+yFU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"mb-2\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"imageUrl\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[15,\"src\",[31,[[34,0,[\"imageUrl\"]]]]],[14,0,\"icon-100\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"icon-100\"],[12],[1,[35,0,[\"titleFirstLetter\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[10,\"div\"],[12],[1,[35,0,[\"title\"]]],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/project/project-item.hbs"
    }
  });
  _exports.default = _default;
});