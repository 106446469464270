define("livarava-web/components/property/property-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    typeOptions: _environment.default.options.property.type,
    // Actions
    actions: {
      setAuthor: function setAuthor(user) {
        var model = this.model;
        if (!user) {
          model.set("author", null);
          return;
        }
        model.set("author", user);
        model.set("firstname", user.get("firstname"));
        model.set("lastname", user.get("lastname"));
        model.set("email", user.get("email"));
        model.set("phone", user.get("phone"));
      },
      setPropertyType: function setPropertyType(property_type) {
        this.model.set("property_type", property_type);
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this["delete"](this.model);
      }
    }
  });
  _exports.default = _default;
});