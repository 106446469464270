define("livarava-web/components/order/order-link-for-entity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    order: null,
    entity: null,
    copied: false,
    // https://www.hospodari.com/my/business/146766/orders/163713
    url: Ember.computed('order.id', 'entity.id', function () {
      var order = this.order,
        entity = this.entity;
      if (order && order.id && entity && entity.id) {
        return "https://www.hospodari.com/my/business/".concat(entity.id, "/orders/").concat(order.id);
      }
      return '';
    }),
    actions: {
      copy: function copy() {
        var textArea = document.createElement('textarea');
        textArea.value = this.get('url');

        // Avoid scrolling to bottom
        textArea.style.top = '0';
        textArea.style.left = '0';
        textArea.style.position = 'fixed';
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        try {
          var successful = document.execCommand('copy');
          this.set('copied', successful);
        } catch (err) {
          console.error('Async: Could not copy text: ', err);
        }
        document.body.removeChild(textArea);
      }
    }
  });
  _exports.default = _default;
});