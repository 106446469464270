define("livarava-web/routes/notifications", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    notifications: Ember.inject.service(),
    // Model
    model: function model() {
      var store = this.store;
      return store.query('notification', {
        per_page: 10,
        page: 1
      });
    },
    // Actions
    actions: {
      refreshNotifications: function refreshNotifications() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});