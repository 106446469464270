define("livarava-web/models/payout", ["exports", "ember-data", "livarava-web/utils/get-currency-sign"], function (_exports, _emberData, _getCurrencySign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Amount
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    currencySign: Ember.computed('currency', function () {
      return (0, _getCurrencySign.default)(this.currency);
    }),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      var status = this.status;
      return !status ? null : status.capitalize();
    }),
    // Relationships
    project: _emberData.default.belongsTo('neuron'),
    product: _emberData.default.belongsTo('neuron'),
    user: _emberData.default.belongsTo('user')
  });
  _exports.default = _default;
});