define("livarava-web/components/common/filters/filter-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    controller: null,
    filter: null,
    value: null,
    timer: null,
    setValue: function setValue(value) {
      var component = this;
      var controller = this.controller;
      var name = this.get('filter.query');
      var currentValue = controller.get(name);
      if (currentValue === value) {
        if (!this.value) {
          this.set('value', value);
        }
        return;
      }
      component.set('value', value);
      // console.log(name, value);
      var queryParams = {};
      queryParams[name] = value;
      queryParams['page'] = 1;
      controller.setProperties(queryParams);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var value = this.controller.get(this.get('filter.query'));
      this.setValue.call(this, value);
    },
    // Actions
    actions: {
      onSearchKeyUp: function onSearchKeyUp(value) {
        Ember.run.debounce(this, this.setValue, value, 500);
      }
    }
  });
  _exports.default = _default;
});