define("livarava-web/components/rssitems-infinite-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actions: {
      infinityLoad: function infinityLoad() {
        this.sendAction();
      }
    }
  });
  _exports.default = _default;
});