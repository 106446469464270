define("livarava-web/templates/lang/blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6ouBF4F2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,[[\"class\"],[\"blog\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Blog\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h2\"],[12],[2,\"\\n    Blog\\n\\n    \"],[10,\"div\"],[14,0,\"btn-group pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,2],[[32,0],[30,[36,1],[\"isFiltersExpanded\",[32,0]],null]],null],[12],[10,\"i\"],[14,0,\"fa fa-bars\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,5],null,[[\"model\",\"page\",\"postLink\"],[[35,4],[35,3],\"lang.blog.item\"]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"toggle\",\"action\",\"page\",\"model\",\"blog/blog-view-v2\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/blog/index.hbs"
    }
  });
  _exports.default = _default;
});