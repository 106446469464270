define("livarava-web/templates/axons/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wF+I0AJc",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\",\"footer\"],[\"common-dark\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"axons\"],[12],[2,\"\\n\\n    \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n      \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n      \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"axons.index\"]],[[\"default\"],[{\"statements\":[[2,\"Axons\"]],\"parameters\":[]}]]],[13],[2,\"\\n      \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"liva\",\"header\"]]],[2,\" - \"],[1,[35,1,[\"rava\",\"header\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n      \"],[1,[30,[36,2],null,[[\"model\"],[[35,1]]]]],[2,\"\\n\\n    \"],[13],[3,\"body\"],[2,\"\\n  \"],[13],[3,\"axons\"],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"axon/axon-item\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/axons/item.hbs"
    }
  });
  _exports.default = _default;
});