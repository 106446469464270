define("livarava-web/templates/components/neuron/neuron-subscription-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+N/x/2Eb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,7,[\"signed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"button\"],[14,0,\"btn btn-default disabled\"],[12],[2,\"\\n      Loading...\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,3],[[32,0],\"unsubscribe\"],null],[12],[2,\"\\n      Unsubscribe\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[2,\"\\n              \"],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[2,\"\\n          \"],[1,[34,2]],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"btn btn-warning\"],[4,[38,3],[[32,0],\"subscribe\"],null],[12],[2,\"\\n      Subscribe\\n\"],[6,[37,4],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[1,[34,2]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],null,[[\"class\",\"tagName\",\"route\"],[\"btn btn-warning\",\"button\",\"signup\"]],[[\"default\"],[{\"statements\":[[2,\"    Subscribe\\n    \"],[10,\"span\"],[14,0,\"badge badge-secondary\"],[12],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[1,[35,0,[\"subscriber_count\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"link-to\",\"subscriptionsCount\",\"action\",\"if\",\"subscribed\",\"isLoading\",\"me\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-subscription-button.hbs"
    }
  });
  _exports.default = _default;
});