define("livarava-web/components/odc/odc-table", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    addressComponents: null,
    serviceId: null,
    page: 1,
    limit: 10,
    loading: false,
    items: null,
    kved: null,
    address: null,
    fetchItems: function fetchItems() {
      var _this = this;
      this.set('loading', true);
      var page = this.page,
        limit = this.limit,
        address = this.address,
        kved = this.kved;
      var url = "https://odc.livarava.com/entities/?page=".concat(page, "&limit=").concat(limit);
      if (address) {
        url += "&address=" + encodeURIComponent("%%".concat(address, "%%"));
      }
      if (kved) {
        url += "&kved_primary=" + encodeURIComponent("".concat(kved));
      }
      (0, _fetch.default)(url).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (_ref) {
        var resources = _ref.resources;
        _this.set('items', resources);
        _this.set('loading', false);
      }).catch(function () {
        _this.set('loading', false);
      });
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      if (this.get('addressComponents.city')) {
        this.set('address', (this.get('addressComponents.address_area_level_1') ? '' : 'місто ') + this.get('addressComponents.city'));
      }
      this.fetchItems();
    },
    actions: {
      handleSearch: function handleSearch() {
        this.setProperties({
          page: 1
        });
        Ember.run.debounce(this, this.fetchItems, null, 300);
      },
      next: function next() {
        this.set('page', 1 + this.page);
        Ember.run.debounce(this, this.fetchItems, null, 300);
      }
    }
  });
  _exports.default = _default;
});