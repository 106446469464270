define("livarava-web/components/user/user-sms-modal", ["exports", "livarava-web/utils/get-api-url"], function (_exports, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    recipients: null,
    message: null,
    smsError: null,
    // Flags
    isSmsSending: false,
    showSmsForm: false,
    showSmsSuccess: false,
    showSmsError: false,
    // Actions
    actions: {
      sendSms: function sendSms() {
        var _this = this;
        var recipients = this.recipients;
        var message = this.message;
        var params = {
          recipients: recipients,
          message: message
        };
        var url = (0, _getApiUrl.default)('sms', params, 'POST');
        var token = Ember.get(this, 'me.token');
        var headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        };
        Ember.set(this, 'isSmsSending', true);
        Ember.set(this, 'smsError', null);
        fetch(url, headers).then(function () {
          Ember.set(_this, 'isSmsSending', false);
          Ember.set(_this, 'smsError', null);
          Ember.set(_this, 'showSmsSuccess', true);
        }).catch(function (response) {
          Ember.set(_this, 'isSmsSending', false);
          Ember.set(_this, 'smsError', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this, 'showSmsError', true);
        });
      }
    }
  });
  _exports.default = _default;
});