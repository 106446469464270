define("livarava-web/templates/sites/item/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9eRazqp5",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"onDelete\",\"onSave\"],[[35,1],[30,[36,0],[\"delete\"],null],[30,[36,0],[\"save\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"site/site-general\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/general.hbs"
    }
  });
  _exports.default = _default;
});