define("livarava-web/templates/neuron/item/connections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tXeKuZMQ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-8 mb-5\"],[12],[2,\"\\n    \"],[1,[30,[36,7],null,[[\"model\",\"controller\",\"axons\",\"neuron\",\"page\",\"q\",\"type\",\"display\",\"author\",\"isLoading\",\"onPageChanged\",\"onDeleteAxon\"],[[35,1],[32,0],[35,1,[\"axons\"]],[35,1,[\"neuron\"]],[35,6],[35,5],[35,4],[35,3],[35,2],[35,1,[\"isLoading\"]],[30,[36,0],[[32,0],\"setPage\"],null],[30,[36,0],[[32,0],\"deleteAxon\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-4 mb-5\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Promoted\"],[13],[2,\"\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sticky-top neuron-adsense\"],[12],[2,\"\\n        \"],[1,[30,[36,8],null,[[\"name\"],[\"xxxxxx\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Learn more\"],[13],[2,\"\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,9],null,[[\"neuron\"],[[35,1,[\"neuron\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"author\",\"display\",\"type\",\"q\",\"page\",\"axon-list\",\"adsense/neuron-aside\",\"neuron/neuron-axons-random\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuron/item/connections.hbs"
    }
  });
  _exports.default = _default;
});