define("livarava-web/templates/docs/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6s3EgQ6F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"docs-page\"],[12],[2,\"\\n            \"],[10,\"h1\"],[14,0,\"title\"],[12],[2,\"\\n              \"],[1,[35,0,[\"title\"]]],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"date mb-4\"],[12],[2,\"\\n                \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[2,\"\\n                  \"],[1,[30,[36,3],[[35,0,[\"created\"]],\"DD MMMM, YYYY\"],null]],[2,\" ·\\n                  \"],[6,[37,4],[[35,0,[\"author\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[35,0,[\"author\",\"fullname\"]]]],\"parameters\":[]},{\"statements\":[[2,\"LivaRava\"]],\"parameters\":[]}]]],[2,\"\\n                \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"description\"],[12],[2,\"\\n\"],[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"                \"],[2,[35,0,[\"description\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,6,[\"rights\",\"pages\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"form-buttons-fixed text-right\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"models\"],[\"sites.item.pages.edit\",[30,[36,1],[[35,0,[\"site\",\"id\"]],[35,0,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"btn btn-lg btn-warning\"],[12],[2,\"\\n              \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\" Edit\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"array\",\"link-to\",\"moment-format\",\"if\",\"froala-content\",\"me\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/docs/index.hbs"
    }
  });
  _exports.default = _default;
});