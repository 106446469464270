define("livarava-web/templates/components/comment/comment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "muoZiJX5",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,9],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center text-primary\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spin fa-spinner fa-3x fa-fw\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Loading...\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"comments\"],[12],[2,\"\\n\"],[6,[37,9],[[35,1,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"item\",\"onDeleteComment\",\"onSaveComment\"],[[32,1],[35,4],[35,3]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,9],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"count\",\"current\",\"change\",\"pager\",\"class\"],[[35,1,[\"meta\",\"total_pages\"]],[35,0],\"pageChanged\",true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"          Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"No comments\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"page\",\"items\",\"pagination-pager\",\"onSaveComment\",\"onDeleteComment\",\"comment/comment-list-item\",\"-track-array\",\"each\",\"pages\",\"if\",\"loading\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/comment/comment-list.hbs"
    }
  });
  _exports.default = _default;
});