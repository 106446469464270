define("livarava-web/routes/lang/blog/index", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/config/environment"], function (_exports, _getFiltersFromParams, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Title
    titleToken: 'Blog',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      },
      author: {
        refreshModel: true
      },
      language: {
        refreshModel: true
      },
      site: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var locale = this.get('intl.primaryLocale');
      params.per_page = 12;
      params.lira = _environment.default.neuronet.blog;
      params.site = _environment.default.neuronet.site;
      params.language = params.language || locale;
      return this.store.query('post', (0, _getFiltersFromParams.default)(params));
    }
  });
  _exports.default = _default;
});