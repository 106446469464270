define("livarava-web/components/motivation/field-totals", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // filters
    dates: null,
    manager: null,
    // attributes
    readonlyManager: false,
    modelType: 'order',
    // other
    managers: null,
    items: null,
    totals: Ember.computed('items.@each.id', function () {
      if (!this.get('items.length')) {
        return;
      }
      return this.items.reduce(function (result, item) {
        return {
          amount: result.amount + item.get('amount'),
          cac: result.cac + item.get('cac'),
          cashback: result.cashback + item.get('cashback')
        };
      }, {
        amount: 0,
        cac: 0,
        cashback: 0
      });
    }),
    fetchData: function fetchData() {
      var _this = this;
      var _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store;
      var requestParams = {
        date_start: (0, _momentTimezone.default)(dates[0]).format('YYYY-MM-DD'),
        date_end: (0, _momentTimezone.default)(dates[1]).format('YYYY-MM-DD'),
        per_page: 1000,
        page: 1
      };
      return store.query('order', requestParams).then(function (items) {
        return _this.set('items', items);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('dates', [(0, _momentTimezone.default)().startOf('week').toDate(), (0, _momentTimezone.default)().endOf('week').toDate()
      // moment('2021-03-20').toDate(),
      // moment('2021-03-27').toDate(),
      ]);

      Ember.run.next(this, function () {
        var _this2 = this;
        this.loading.run(function () {
          return _this2.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDateRange: function setDateRange(dates) {
        var _this3 = this;
        this.set('dates', dates);
        if (dates && dates.length == 2) {
          this.loading.run(function () {
            return _this3.fetchData();
          });
        }
      }
    }
  });
  _exports.default = _default;
});