define("livarava-web/components/common/common-save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'span',
    klass: 'btn-success',
    // Computed
    saveClass: Ember.computed('model.isNew', 'model.isSaving', 'model.hasDirtyAttributes', function () {
      var isSaving = Ember.get(this, 'model.isSaving');
      var isNew = Ember.get(this, 'model.isNew');
      var hasDirtyAttributes = Ember.get(this, 'model.hasDirtyAttributes');
      var klass = this.klass;
      if (isSaving) {
        return 'disabled';
      } else if (isNew || hasDirtyAttributes) {
        return 'btn-warning';
      } else {
        return klass;
      }
    })
  });
  _exports.default = _default;
});