define("livarava-web/components/input-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Service
    router: Ember.inject.service(),
    // Attributes
    classDefault: null,
    classImage: 'input-image',
    // Computed
    imageStyle: Ember.computed('value.image_url', function () {
      var url = Ember.get(this, 'value.image_url');
      if (url === undefined) return '';
      return Ember.String.htmlSafe("background-image: url('".concat(url, "');"));
    }),
    // Flags
    showModal: false,
    showButton: false,
    // Events
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'showButton', true);
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'showButton', false);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var element = this.element;
      var width = this.width;
      var height = this.height;
      element.style.width = width ? width + 'px' : '100%';
      element.style.height = height ? height + 'px' : element.offsetWidth + "px";
    },
    // Actions
    actions: {
      showModal: function showModal() {
        Ember.set(this, 'item', this.value);
        Ember.set(this, 'showModal', true);
      },
      onSelect: function onSelect(item) {
        var model = this.model;
        var onSelect = this.onSelect;
        if (item === null) {
          Ember.set(this, 'value', null);
        } else {
          Ember.set(this, 'value', item);
        }
        if (!model) {
          if (onSelect === undefined) return;
          onSelect(item);
          return;
        }
        model.save().then(function (item) {
          if (onSelect === undefined) return;
          onSelect(item);
        });
      }
    }
  });
  _exports.default = _default;
});