define("livarava-web/models/lead", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Base
    type: 'lead',
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    status: _emberData.default.attr('string', {
      defaultValue: 'new'
    }),
    // Address
    city: _emberData.default.attr('string'),
    // місто
    address: _emberData.default.attr('string'),
    // deprecated - старе поле адреси, більше не використовується
    address_area_level_1: _emberData.default.attr('string'),
    // область
    address_area_level_2: _emberData.default.attr('string'),
    // район
    address_postal_code: _emberData.default.attr('string'),
    // поштовий індекс
    address_type: _emberData.default.attr('string'),
    // тип вулиці
    address_name: _emberData.default.attr('string'),
    // назва вулиці
    address_building: _emberData.default.attr('string'),
    // номер будинку
    address_apartment: _emberData.default.attr('string'),
    // номер квартири

    // Marketing
    utm_source: _emberData.default.attr('string'),
    utm_medium: _emberData.default.attr('string'),
    utm_campaign: _emberData.default.attr('string'),
    utm_term: _emberData.default.attr('string'),
    utm_content: _emberData.default.attr('string'),
    utm_referral: _emberData.default.attr('string'),
    // Finance
    cac: _emberData.default.attr('number'),
    cashback: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    final_deadline: _emberData.default.attr('datetime'),
    // Apply
    apply_deadline: _emberData.default.attr('datetime'),
    apply_amount: _emberData.default.attr('number'),
    apply_competitors: _emberData.default.attr('number'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    manager: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product')
  });
  _exports.default = _default;
});