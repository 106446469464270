define("livarava-web/templates/sites/item/billing-settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NvVesdwt",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[14,0,\"mb-3\"],[12],[2,\"Billing Settings\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mt-0 mb-3\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"model\",\"save\"],[[35,1],[30,[36,0],[\"save\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"site/site-billing-settings-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/billing-settings.hbs"
    }
  });
  _exports.default = _default;
});