define("livarava-web/routes/application", ["exports", "lodash/isNumber", "ember-keyboard-shortcuts", "livarava-web/config/environment"], function (_exports, _isNumber2, _emberKeyboardShortcuts, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function matches(pattern, routeName) {
    var escaped = pattern.replace(/\./g, '.').replace(/\*/g, '[^.]+');
    var regex = new RegExp(escaped);
    return routeName.match(regex);
  }
  var _default = Ember.Route.extend({
    // Services
    speech: Ember.inject.service(),
    // sockets: service(),
    notifications: Ember.inject.service(),
    me: Ember.inject.service(),
    api: Ember.inject.service(),
    intl: Ember.inject.service(),
    metrics: Ember.inject.service(),
    router: Ember.inject.service(),
    // Title
    title: function title(tokens) {
      tokens = Ember.makeArray(tokens);
      tokens.unshift('LivaRava');
      return tokens.reverse().join(' - ');
    },
    // Keyboard Shortcuts
    keyboardShortcuts: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.set('keyboardShortcuts', {
        'ctrl+m': 'speechStart'
      });
      this.me.load();
      this.notifications;
      // this.sockets;

      var url = "".concat(_environment.default.api.host).concat(_environment.default.api.path, "/");
      fetch(url, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json'
        }
      }).then(function (response) {
        // let message = response.json();
        response.json().then(function (object) {
          // console.log(object.version);
          Ember.set(_this, 'api.version', object.version);
        });
      });
      var router = this.router;
      router.on('routeDidChange', function () {
        Ember.run.scheduleOnce('afterRender', _this, _this._trackPage);
      });
    },
    _trackPage: function _trackPage() {
      var router = this.router;
      var page = router.currentURL;
      var title = router.currentRouteName || 'unknown';
      this.metrics.trackPage({
        page: page,
        title: title
      });
    },
    activate: function activate() {
      (0, _emberKeyboardShortcuts.bindKeyboardShortcuts)(this);
    },
    deactivate: function deactivate() {
      (0, _emberKeyboardShortcuts.unbindKeyboardShortcuts)(this);
    },
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      var intl = this.intl;
      intl.setLocale(['en', 'uk', 'ru']);
    },
    // Actions
    actions: {
      openNeuron: function openNeuron(id) {
        var _this2 = this;
        var neuronId = parseInt(id);
        if (!(0, _isNumber2.default)(neuronId)) {
          return;
        }
        this.transitionTo('neuron.item.overview', neuronId).then(function () {
          _this2.controllerFor('application').clearSearch();
        });
      },
      createNeuron: function createNeuron(value) {
        var _this3 = this;
        this.transitionTo('neuron.create', value).then(function () {
          return _this3.controllerFor('application').clearSearch();
        });
      },
      speechStart: function speechStart() {
        var speech = this.speech;
        speech.start();
      },
      willTransition: function willTransition(transition) {
        // reset scroll
        this._super();
        var resetScroll = this.resetScroll;

        // default is opt-in since it's a mixin, set to false to explicitly opt-out
        if (resetScroll === false) {
          return;

          // scroll to a specific position
        } else if (Ember.typeOf(resetScroll) === 'number') {
          window.scrollTo({
            top: resetScroll
          });

          // scroll to a specific position based on the route name
        } else if (Ember.typeOf(resetScroll) === 'object') {
          resetScroll.find(function (position, routePattern) {
            if (matches(routePattern, transition.targetName)) {
              window.scrollTo({
                top: position
              });
              return true;
            }
          });

          // scroll to top
        } else {
          window.scrollTo({
            top: 0
          });
        }
      }
    }
  });
  _exports.default = _default;
});