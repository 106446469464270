define("livarava-web/components/modal/modal-sms", ["exports", "livarava-web/utils/get-api-url"], function (_exports, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    items: null,
    message: null,
    smsError: null,
    // Flags
    isSmsSending: false,
    showSmsForm: false,
    showSmsSuccess: false,
    showSmsError: false,
    // Computed
    recipients: Ember.computed('items.[]', function () {
      var valid = [];
      var invalid = [];
      var items = this.items;
      items.forEach(function (item) {
        var id = parseInt(Ember.get(item, 'id'));
        var email = Ember.get(item, 'email');
        var phone = Ember.get(item, 'phone');
        var firstname = Ember.get(item, 'firstname');
        var lastname = Ember.get(item, 'lastname');
        var fullname = Ember.get(item, 'fullname') || Ember.get(item, 'title');
        if (phone !== undefined && phone.length > 1) {
          if (phone && phone.indexOf(',') > 0) {
            var phones = phone.split(",");
            phones.forEach(function (p) {
              var number = parsePhone(p);
              if (validatePhone(number)) {
                valid.push({
                  id: id,
                  firstname: firstname,
                  lastname: lastname,
                  fullname: fullname,
                  email: email,
                  phone: number
                });
              } else {
                invalid.push({
                  id: id,
                  firstname: firstname,
                  lastname: lastname,
                  fullname: fullname,
                  email: email,
                  phone: number
                });
              }
            });
          } else {
            var number = parsePhone(phone);
            if (validatePhone(number)) {
              valid.push({
                id: id,
                firstname: firstname,
                lastname: lastname,
                fullname: fullname,
                email: email,
                phone: number
              });
            } else {
              invalid.push({
                id: id,
                firstname: firstname,
                lastname: lastname,
                fullname: fullname,
                email: email,
                phone: number
              });
            }
          }
        }
      });
      Ember.set(this, 'recipientInvalid', invalid);
      return valid;
    }),
    recipientInvalid: null,
    recipientCount: Ember.computed.reads('recipients.length'),
    recipientTitle: Ember.computed('items.[]', function () {
      var phones = [];
      var items = this.items;
      items.forEach(function (item) {
        phones.push(Ember.get(item, 'phone'));
      });
      return phones.join(', ');
    }),
    messageLength: Ember.computed('message', function () {
      var message = this.message;
      return message ? message.length : 0;
    }),
    smsPerMessage: Ember.computed('message', 'messageLength', function () {
      var message = this.message;
      var length = this.messageLength;
      var smsLength = /[а-яА-Я]/gi.test(message) ? 70 : 160;
      return Math.ceil(length / smsLength);
    }),
    messagePrice: Ember.computed('recipientCount', 'smsPerMessage', function () {
      var recipientCount = this.recipientCount;
      var smsPerMessage = this.smsPerMessage;
      var smsPrice = 0.35;
      return recipientCount * smsPerMessage * smsPrice;
    }),
    // Actions
    actions: {
      sendSms: function sendSms() {
        var _this = this;
        var recipients = this.recipients;
        var message = this.message;
        var params = {
          recipients: recipients,
          message: message
        };
        var url = (0, _getApiUrl.default)('sms', params, 'POST');
        var token = Ember.get(this, 'me.token');
        var headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        };
        Ember.set(this, 'isSmsSending', true);
        Ember.set(this, 'smsError', null);
        fetch(url, headers).then(function () {
          Ember.set(_this, 'isSmsSending', false);
          Ember.set(_this, 'smsError', null);
          Ember.set(_this, 'showSmsSuccess', true);
        }).catch(function (response) {
          Ember.set(_this, 'isSmsSending', false);
          Ember.set(_this, 'smsError', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this, 'showSmsError', true);
        });
      }
    }
  });
  _exports.default = _default;
  function parsePhone(p) {
    return p.replace(/([ \-()])/gi, '');
  }
  function validatePhone(p) {
    return /^\+380(39|50|63|66|67|68|91|92|93|94|95|96|97|98|99)[0-9]{7}$/.test(p);
  }
});