define("livarava-web/templates/components/invoice/invoice-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yKTn/rKK",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[14,0,\"invoice-status dropdown text-nowrap\"],[15,\"title\",[31,[[34,3,[\"statusLabel\"]]]]],[12],[2,\"\\n\"],[6,[37,11],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[[34,2]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,9],null,[[\"action\",\"tagName\"],[[30,[36,1],[[32,0],[30,[36,8],[[35,3,[\"showDropDown\"]]],null],false],null],\"span\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"i\"],[24,\"role\",\"button\"],[16,0,[31,[[34,2]]]],[4,[38,1],[[32,0],[30,[36,4],[\"showDropDown\",[35,3]],null]],null],[12],[13],[2,\"\\n      \"],[10,\"div\"],[15,0,[31,[\"dropdown-menu \",[30,[36,0],[[35,3,[\"showDropDown\"]],\"show\"],null]]]],[12],[2,\"\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"a\"],[24,6,\"\"],[24,0,\"dropdown-item text-primary\"],[4,[38,1],[[32,0],\"setStatus\",[32,1,[\"value\"]]],null],[12],[2,\"\\n            \"],[10,\"small\"],[12],[2,\"\\n\"],[6,[37,0],[[32,1,[\"icon\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"                \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,0],[[32,1,[\"sign\"]]],null,[[\"default\"],[{\"statements\":[[2,\"                \"],[1,[32,1,[\"sign\"]]],[2,\"\\n              \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"              \"],[1,[32,1,[\"label\"]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"icon\",\"item\",\"toggle\",\"options\",\"-track-array\",\"each\",\"mut\",\"click-outside\",\"isEditable\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/invoice/invoice-status.hbs"
    }
  });
  _exports.default = _default;
});