define("livarava-web/utils/get-api-url", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getApiUrl;
  function getApiUrl(functionName, params) {
    var method = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'GET';
    var hostUrl = _environment.default.api.host;
    var apiPath = _environment.default.api.path;
    var token = _environment.default.api.token;
    var time = new Date().getTime();
    var url = "".concat(hostUrl).concat(apiPath, "/functions/").concat(functionName);
    if (method === 'GET') {
      url += "/?__t=".concat(time);
      if (token) {
        url += "&token=".concat(token);
      }
      for (var key in params) {
        if (params.hasOwnProperty(key)) {
          var value = encodeURIComponent(params[key]);
          url = url + "&".concat(key, "=").concat(value);
        }
      }
      return url;
    }
    return url;
  }
});