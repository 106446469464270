define("livarava-web/templates/components/site-categories", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jFVBYRvq",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"Categories\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n      \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,6],[[35,5],\"btn-secondary\"],null]]]],[4,[38,8],[[32,0],[30,[36,7],[\"isFiltersExpanded\",[32,0]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-bars\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,9],null,[[\"class\",\"route\",\"model\"],[\"btn btn-primary\",\"sites.item.categories.create\",[35,1,[\"site_id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n        New Category\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,4],null,[[\"controller\",\"filters\"],[[35,3],[35,3,[\"filters\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"hr\"],[14,0,\"m-b-2\"],[12],[13],[2,\"\\n\\n\"],[6,[37,6],[[35,1,[\"items\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"items\",\"onRemoveCategory\"],[[35,1,[\"items\"]],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n    No categories yet.\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onRemoveCategory\",\"model\",\"site-categories-list\",\"controller\",\"common/common-filters\",\"isFiltersExpanded\",\"if\",\"toggle\",\"action\",\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site-categories.hbs"
    }
  });
  _exports.default = _default;
});