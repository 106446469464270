define("livarava-web/components/service/service-seo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      save: function save(item) {
        Ember.get(this, 'onSave')(item);
      }
    }
  });
  _exports.default = _default;
});