define("livarava-web/controllers/invoices/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Query Params
    queryParams: ['copy', 'customer', 'customer_type'],
    copy: null,
    customer: null,
    customer_type: null,
    // Actions
    actions: {
      save: function save(model) {
        var _this = this;
        model.save().then(function () {
          return _this.transitionToRoute('invoices.index');
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('invoices');
      }
    }
  });
  _exports.default = _default;
});