define("livarava-web/components/neuron/neuron-axons-random", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    items: null,
    neuron: null,
    // Flags
    isLoading: true,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var store = this.store;
      var token = Ember.get(this, 'me.token');
      var neuronId = parseInt(Ember.get(this, 'neuron.id'));
      var url = (0, _getApiUrl.default)('random-axons', {
        neuron: neuronId
      });
      var params = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      var request = (0, _fetch.default)(url, params);
      request.then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        var list = response;
        var out = [];
        for (var i = 0; i < list.length; i++) {
          var item = list[i];
          var xaxon_id = item.axon;
          var xneuron_id = item.neuron;
          var xaxon = store.findRecord('axon', xaxon_id);
          var xneuron = store.findRecord('neuron', xneuron_id);
          out.push({
            xaxon: xaxon,
            xneuron: xneuron
          });
        }
        Ember.set(_this, 'items', out);
        Ember.set(_this, 'isLoading', false);
      }).catch(function () {
        Ember.set(_this, 'isLoading', false);
      });
    }
  });
  _exports.default = _default;
});