define("livarava-web/routes/homepage", ["exports", "livarava-web/mixins/anonymous-route-mixin"], function (_exports, _anonymousRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        posts: store.query('post', {
          'filter[featured]': true,
          'filter[display]': 'public',
          per_page: 8,
          page: 1,
          sort: '-created'
        })
        // events: store.query('event', {
        //     'filter[featured]': true,
        //     'filter[display]': 'public',
        //     per_page: 8,
        //     page: 1,
        //     sort: '-startdate'
        // }),
      });
    }
  });
  _exports.default = _default;
});