define("livarava-web/components/neuron-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    getPrice: Ember.computed('model.price', function () {
      return this.get('model.price') === 0 ? 'Free of charge' : this.get('model.price');
    })
  });
  _exports.default = _default;
});