define("livarava-web/controllers/tasks/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['copy', 'project', 'product'],
    copy: null,
    project: null,
    product: null,
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function (item) {
          return _this.transitionToRoute('tasks.edit', item.id);
        });
      },
      saveAndGo: function saveAndGo(item) {
        var _this2 = this;
        item.save().then(function (item) {
          return _this2.transitionToRoute('tasks.item', item.id);
        });
      }
    }
  });
  _exports.default = _default;
});