define("livarava-web/validations/action-validations", ["exports", "ember-changeset-validations/validators", "livarava-web/validators/date"], function (_exports, _validators, _date) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    text: [(0, _validators.validatePresence)(true)],
    scheduled: [(0, _validators.validatePresence)(true), (0, _date.default)({
      future: true
    })],
    action_type: [(0, _validators.validatePresence)(true)],
    action_direction: [(0, _validators.validatePresence)(true)],
    manager: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});