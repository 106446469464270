define("livarava-web/controllers/store/neuroner", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      install: function install(application) {
        var _this = this;
        var url = (0, _getApiUrl.default)('store-application-install', {
          application: application
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          var status = response.status;
          if (status === 'installed') {
            var me = _this.me;
            me.load().then(function () {
              _this.transitionToRoute('index');
            });
          } else if (status === 'payment') {
            var next = response.next;
            if (window && next) {
              window.open(next, '_self');
            }
          } else {
            alert('Error! Please, contact us.');
          }
        }).catch(function () {
          alert('Error! Please, contact us.');
        });
      }
    }
  });
  _exports.default = _default;
});