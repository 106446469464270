define("livarava-web/components/post/post-comment-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    model: null,
    // Handlers
    onSubmit: null,
    // Actions
    actions: {
      submit: function submit() {
        var onSubmit = this.onSubmit;
        if (onSubmit) onSubmit(this.model);
      }
    }
  });
  _exports.default = _default;
});