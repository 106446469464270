define("livarava-web/templates/projects/item/members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xkUF3q4O",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"onAddUser\",\"onEditUser\",\"onSaveUser\",\"onRemoveUser\"],[[35,1],[30,[36,0],[\"setRole\"],null],[30,[36,0],[\"getRole\"],null],[30,[36,0],[\"setRole\"],null],[30,[36,0],[\"removeRole\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"project/project-members\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/item/members.hbs"
    }
  });
  _exports.default = _default;
});