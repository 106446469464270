define("livarava-web/templates/components/common/common-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "jeW+xanQ",
    "block": "{\"symbols\":[\"filter\"],\"statements\":[[10,\"div\"],[14,0,\"filters m-b-1\"],[12],[2,\"\\n\\n  \"],[11,\"form\"],[24,0,\"form-inline\"],[4,[38,6],[[32,0],\"donothing\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n\\n\"],[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,5],[[30,[36,4],[[32,1,[\"type\"]],\"language\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,3],null,[[\"class\",\"controller\",\"filter\"],[\"form-group my-1\",[35,0],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,2],[[30,[36,1],[\"common/filters/filter-\",[32,1,[\"type\"]]],null]],[[\"class\",\"controller\",\"filter\"],[\"form-group my-1\",[35,0],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-primary filter\"],[4,[38,6],[[32,0],\"search\",[35,10,[\"q\"]]],null],[12],[2,\"\\n        Search\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"controller\",\"concat\",\"component\",\"common/filters/filter-select\",\"eq\",\"if\",\"action\",\"filters\",\"-track-array\",\"each\",\"model\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-filters.hbs"
    }
  });
  _exports.default = _default;
});