define("livarava-web/templates/components/input-datetime", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FMzvEe5Q",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-datetime\"],[12],[2,\"\\n  \"],[1,[30,[36,5],null,[[\"class\",\"mask\",\"value\",\"placeholder\",\"readonly\"],[\"form-control\",[35,4],[35,3],[35,2],[35,1]]]]],[2,\"\\n\\n\"],[6,[37,7],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"text-muted text-right btn-now\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"setNow\"],null],[12],[2,\"\\n      \"],[10,\"small\"],[12],[2,\"now\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"readonly\",\"placeholder\",\"_value\",\"_mask\",\"input-mask\",\"nowButton\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-datetime.hbs"
    }
  });
  _exports.default = _default;
});