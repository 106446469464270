define("livarava-web/templates/sites/item/users/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "QMSI1zLA",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"controller\",\"model\",\"page\"],[[32,0],[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"page\",\"model\",\"site/site-users\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/users/index.hbs"
    }
  });
  _exports.default = _default;
});