define("livarava-web/models/user", ["exports", "ember-data", "livarava-web/utils/is-url"], function (_exports, _emberData, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    about: _emberData.default.attr('string'),
    timezone: _emberData.default.attr('string'),
    sections: _emberData.default.attr(),
    online: _emberData.default.attr('boolean'),
    // Type
    type: 'user',
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Birth Date and Place
    dateofbirth: _emberData.default.attr('datetime'),
    placeofbirth: _emberData.default.attr('string'),
    // Icon
    icon: _emberData.default.attr('string'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed.reads('image_url'),
    imageStyle: Ember.computed('image_url', function () {
      var url = this.image_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Address
    city: _emberData.default.attr('string'),
    // місто
    address: _emberData.default.attr('string'),
    // deprecated - старе поле адреси, більше не використовується
    address_area_level_1: _emberData.default.attr('string'),
    // область
    address_area_level_2: _emberData.default.attr('string'),
    // район
    address_postal_code: _emberData.default.attr('string'),
    // поштовий індекс
    address_type: _emberData.default.attr('string'),
    // тип вулиці
    address_name: _emberData.default.attr('string'),
    // назва вулиці
    address_building: _emberData.default.attr('string'),
    // номер будинку
    address_apartment: _emberData.default.attr('string'),
    // номер квартири

    // Finance
    cac: _emberData.default.attr("number"),
    ltv: _emberData.default.attr("number"),
    // Tax
    tax_residency: _emberData.default.attr("string"),
    tax_id: _emberData.default.attr("number"),
    // Marketing
    utm_source: _emberData.default.attr('string'),
    utm_medium: _emberData.default.attr('string'),
    utm_campaign: _emberData.default.attr('string'),
    utm_term: _emberData.default.attr('string'),
    utm_content: _emberData.default.attr('string'),
    utm_referral: _emberData.default.attr('string'),
    // Dynamic
    role: _emberData.default.attr(),
    // Relationships
    projects: _emberData.default.hasMany('project'),
    // Rights
    rights: _emberData.default.attr('plain-object')
  });
  _exports.default = _default;
});