define("livarava-web/components/input-property-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Actions
    actions: {
      optionChanged: function optionChanged(item) {
        var _this = this;
        var propertyId = parseInt(Ember.get(item, "property.id"));
        return this.store.findRecord("property", propertyId).then(function (property) {
          _this.optionChanged(property);
        });
      }
    }
  });
  _exports.default = _default;
});