define("livarava-web/templates/components/order/order-see-more", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E2gKHz/f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"minheight \",[30,[36,3],[[35,0],\"fullheight\"],null]]]],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"a\"],[24,6,\"\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],true],null],[12],[2,\"See more\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"showFull\",\"mut\",\"action\",\"if\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/order/order-see-more.hbs"
    }
  });
  _exports.default = _default;
});