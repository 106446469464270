define("livarava-web/controllers/users/index", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'q', 'sort'],
    page: 1,
    per_page: 10,
    q: null,
    date_start: null,
    date_end: null,
    sort: '-created,fullname',
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }]),
    // Actions
    actions: {
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      setFilter: function setFilter(name, value) {
        this.transitionToRoute('invoices', {
          queryParams: (0, _defineProperty2.default)({
            page: this.page,
            q: this.q
          }, "filter[".concat(name, "]"), value)
        });
      },
      deleteUser: function deleteUser(item) {
        var _this = this;
        if (!item) {
          return;
        }
        item.set('isShowingUserDeleteModal', false);
        item.destroyRecord().then(function () {
          _this.send('reloadUsers');
        });
      }
    }
  });
  _exports.default = _default;
});