define("livarava-web/routes/neuron/item/comments", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var neuronId = parseInt(this.paramsFor('neuron.item').id);
      var neuron = store.peekRecord('neuron', neuronId);
      params['neuron'] = neuronId;
      params['per_page'] = 1000;
      var comments = store.query('comment', params).then(function (data) {
        neuron.reload();
        return data;
      }).catch(function () {
        // Do something
      });
      return Ember.RSVP.hash({
        neuron: neuron,
        comments: comments
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});