define("livarava-web/routes/properties/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    title: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var id = parseInt(params.id);
      return Ember.RSVP.hash({
        item: store.findRecord('property', id).then(function (item) {
          var title = Ember.get(item, 'title');
          Ember.set(_this, 'title', title);
          return item;
        }),
        offers: store.query('offer', {
          'filter[property]': id,
          per_page: 100,
          page: 1
        }),
        propertyusers: store.query('propertyuser', {
          'filter[property]': id,
          per_page: 100,
          page: 1
        }),
        comments: store.query('comment', {
          neuron: id,
          per_page: 1000
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});