define("livarava-web/components/neuron/neuron-subscription-button", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    subscriptions: null,
    subscriptionsCount: Ember.computed.reads('subscriptions.meta.total_results'),
    subscribed: Ember.computed.reads('subscriptions.meta.subscribed'),
    // States
    isLoading: true,
    reload: function reload() {
      var _this = this;
      this.store.query('subscription', {
        neuron: Ember.get(this, 'item.id'),
        per_page: 1
      }).then(function (data) {
        Ember.set(_this, 'isLoading', false);
        Ember.set(_this, 'subscriptions', data);
      }).catch(function () {
        // Handle error
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.reload();
    },
    // Actions
    actions: {
      subscribe: function subscribe() {
        var _this2 = this;
        var neuron_id = Ember.get(this, 'item.id');
        var url = (0, _getApiUrl.default)('subscription-subscribe', {
          neuron: neuron_id
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          _this2.reload();
        });
      },
      unsubscribe: function unsubscribe() {
        var _this3 = this;
        var neuron_id = Ember.get(this, 'item.id');
        var url = (0, _getApiUrl.default)('subscription-unsubscribe', {
          neuron: neuron_id
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          _this3.reload();
        });
      }
    }
  });
  _exports.default = _default;
});