define("livarava-web/controllers/contacts/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['subject'],
    subject: null,
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function (item) {
          return _this.transitionToRoute('contacts');
        });
      }
    }
  });
  _exports.default = _default;
});