define("livarava-web/components/notification/notification-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Properties
    items: null,
    itemsToShow: null,
    page: 1,
    pagesTotal: Ember.computed.reads('items.meta.total_pages'),
    hasMore: Ember.computed('page', 'pagesTotal', function () {
      var pagesTotal = this.pagesTotal;
      var page = this.page;
      return page < pagesTotal;
    }),
    markAllAsReadChanged: Ember.observer('markAllAsRead', function () {
      var markAllAsRead = this.markAllAsRead;
      var items = this.itemsToShow;
      for (var i = 0; i < items.length; i++) {
        Ember.set(items.objectAt(i), 'read', true);
      }
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, 'itemsToShow', this.items.toArray());
    },
    // Actions
    actions: {
      loadMore: function loadMore() {
        var _this = this;
        var store = this.store;
        if (this.hasMore) {
          Ember.set(this, 'page', this.page + 1);
          return store.query('notification', {
            per_page: 10,
            page: this.page
          }).then(function (items) {
            _this.itemsToShow.pushObjects(items.toArray());
          });
        }
      }
    }
  });
  _exports.default = _default;
});