define("livarava-web/components/debug/debug-form", ["exports", "livarava-web/utils/get-api-url"], function (_exports, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    me: Ember.inject.service(),
    actions: {
      debug: function debug() {
        var _this = this;
        var value = this.value;
        var params = {
          title: value
        };
        var url = (0, _getApiUrl.default)('debug', params, 'POST');
        var token = Ember.get(this, 'me.token');
        fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        }).then(function (response) {
          return response.json();
        }).then(function (data) {
          console.log(data);
          Ember.set(_this, 'data', data);
        }).catch(function (response) {});
      }
    }
  });
  _exports.default = _default;
});