define("livarava-web/templates/application", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4ElCtP0i",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[8,\"loading-line-slider\",[],[[],[]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],null,[[\"class\"],[\"main-container\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[34,4]],[2,\"\\n\\n\"],[1,[34,5]],[2,\"\\n\\n\"],[1,[34,6]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"while-loading\",\"main-content\",\"ember-load-remover\",\"speech/speech-modal\",\"common/common-kayako\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/application.hbs"
    }
  });
  _exports.default = _default;
});