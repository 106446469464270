define("livarava-web/utils/get-currency-label", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getCurrencyLabel;
  function getCurrencyLabel(currency) {
    var out = null;
    _environment.default.currencyOptions.forEach(function (currency_) {
      if (currency_.value === currency) {
        out = currency_.label;
      }
    });
    return out;
  }
});