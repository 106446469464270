define("livarava-web/components/neuron-autocomplete-v1", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    value: null,
    type: 'neuron',
    storedValue: null,
    selectedValue: null,
    selectedItem: null,
    selectedIndex: -1,
    items: null,
    min: 3,
    limit: 4,
    searchOnly: false,
    // Events
    onInput: null,
    onChange: null,
    onSelect: null,
    onEscape: null,
    // Reset
    reset: function reset() {
      Ember.set(this, 'selectedIndex', -1);
      Ember.set(this, 'selectedValue', null);
      Ember.set(this, 'selectedItem', null);
      Ember.set(this, 'items', []);
    },
    // Collapse
    collapse: function collapse() {
      Ember.set(this, 'items', null);
    },
    // Search
    search: function search(value) {
      var _this = this;
      var limit = this.limit;
      var min = this.min;
      var type = this.type;
      this.reset();
      if (value === null) {
        return;
      }
      if (value === '') {
        return;
      }
      if (value && value.length < min) {
        return;
      }
      var baseUrl = "".concat(_environment.default.api.host, "/api/v2/autocomplete");
      var url = "".concat(baseUrl, "?q=").concat(value, "&type=").concat(type, "&limit=").concat(limit);
      var token = Ember.get(this, 'me.token');
      var params = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      (0, _fetch.default)(url, params).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        return Ember.set(_this, 'items', response.data);
      }).catch(function () {
        return Ember.set(_this, 'items', []);
      });
    },
    // Next Item Select
    nextItem: function nextItem() {
      var index = this.selectedIndex;
      var items = this.items;
      var value = this.value;
      if (items.length === 0) {
        return;
      }
      if (index === -1) {
        Ember.set(this, 'storedValue', value);
      }
      index = index + 1;
      if (index >= items.length) {
        index = -1;
      }
      items.forEach(function (item) {
        Ember.set(item, 'selected', false);
      });
      Ember.set(this, 'selectedIndex', index);
      if (index >= 0 && index < items.length) {
        Ember.set(items[index], 'selected', true);
        if (!this.searchOnly) {
          Ember.set(this, 'value', items[index].header);
        }
        Ember.set(this, 'selectedValue', items[index].id);
        Ember.set(this, 'selectedItem', items[index]);
      } else if (index === -1) {
        if (!this.searchOnly) {
          Ember.set(this, 'value', this.storedValue);
        }
        Ember.set(this, 'selectedValue', null);
        Ember.set(this, 'selectedItem', null);
      }
    },
    // Previous Item Select
    previousItem: function previousItem() {
      var index = this.selectedIndex;
      var items = this.items;
      var value = this.value;
      if (items.length === 0) {
        return;
      }
      if (index === -1) {
        Ember.set(this, 'storedValue', value);
      }
      index = index - 1;
      if (index <= -2) {
        index = items.length - 1;
      }
      items.forEach(function (item) {
        Ember.set(item, 'selected', false);
      });
      Ember.set(this, 'selectedIndex', index);
      if (index >= 0 && index < items.length) {
        Ember.set(items[index], 'selected', true);
        if (!this.searchOnly) {
          Ember.set(this, 'value', items[index].header);
        }
        Ember.set(this, 'selectedValue', items[index].id);
        Ember.set(this, 'selectedItem', items[index]);
      } else if (index === -1) {
        if (!this.searchOnly) {
          Ember.set(this, 'value', this.storedValue);
        }
        Ember.set(this, 'selectedValue', null);
        Ember.set(this, 'selectedItem', null);
      }
    },
    // Set Item
    setItem: function setItem() {
      var value = this.selectedValue;
      var item = this.selectedItem;
      if (this.onChange) {
        this.onChange(value, item);
      }
      if (this.onSelect) {
        this.onSelect(value, item);
      }
      Ember.set(this, 'items', []);
      Ember.set(this, 'selectedValue', null);
      Ember.set(this, 'selectedItem', null);
      Ember.set(this, 'value', null);
    },
    // Actions
    actions: {
      onInput: function onInput() {
        var value = this.value;
        Ember.run.debounce(this, this.search, value, 500);
        if (this.onInput) {
          this.onInput(value);
        }
      },
      onFocusIn: function onFocusIn() {
        var value = this.value;
        this.search(value);
      },
      onOutsideClick: function onOutsideClick() {
        var value = this.value;
        var item = this.item;
        this.collapse();
      },
      onKeyUp: function onKeyUp(value_, event) {
        if (event.key === 'ArrowDown') {
          this.nextItem();
        } else if (event.key === 'ArrowUp') {
          this.previousItem();
        } else if (event.key === 'Enter') {
          this.setItem();
        } else if (event.key === 'Escape') {
          if (this.items && this.items.length > 0) {
            this.collapse();
            return;
          }
          if (this.value) {
            Ember.set(this, 'value', null);
            if (this.onChange) {
              this.onChange(null, null);
            }
            return;
          }
          if (this.onEscape) {
            this.onEscape();
          }
        }
      },
      setValue: function setValue() {
        var value = this.selectedValue;
        if (value === null) {
          return;
        }
        this.setItem();
      },
      selectItem: function selectItem(item) {
        var value = item.id;
        var header = item.header;
        if (!this.searchOnly) {
          Ember.set(this, 'value', header);
        }
        Ember.set(this, 'selectedValue', value);
        Ember.set(this, 'selectedItem', item);
        Ember.set(this, 'items', []);
        this.setItem();
      }
    }
  });
  _exports.default = _default;
});