define("livarava-web/templates/components/currency-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bCVrwT5e",
    "block": "{\"symbols\":[\"currencyChoice\"],\"statements\":[[10,\"select\"],[14,0,\"form-control\"],[15,\"onchange\",[30,[36,3],[[32,0],\"selectCurrency\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[31,[[32,1,[\"value\"]]]]],[15,\"selected\",[31,[[30,[36,2],[[30,[36,1],[[32,1,[\"value\"]],[35,0]],null],\"selected\"],null]]]],[12],[2,\"\\n      \"],[1,[32,1,[\"label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"currency\",\"eq\",\"if\",\"action\",\"currencyOptions\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/currency-picker.hbs"
    }
  });
  _exports.default = _default;
});