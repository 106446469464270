define("livarava-web/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dZkARQ3V",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"common/common-loading\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/index.hbs"
    }
  });
  _exports.default = _default;
});