define("livarava-web/components/review/review-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    item: Ember.computed.reads('model.item'),
    // States
    authorModal: false,
    propertyModal: false,
    orderModal: false,
    entityModal: false,
    init: function init() {
      this._super.apply(this, arguments);
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      setRelationship: function setRelationship(relationship, user) {
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!user) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set(relationship, user);
        return item.save();
      }
    }
  });
  _exports.default = _default;
});