define("livarava-web/templates/components/speech/speech-modal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Ec2GX/Pt",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,1,[\"showMic\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"div\"],[24,0,\"speech-modal\"],[4,[38,0],[[32,0],\"micFinish\"],null],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"recording\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[15,0,[31,[\"col-sm-12 text-center \",[30,[36,2],[[35,1,[\"isRecording\"]],\"text-danger\",\"\"],null]]]],[12],[2,\"\\n          \"],[11,\"i\"],[24,\"role\",\"button\"],[24,0,\"fa fa-microphone\"],[4,[38,0],[[32,0],\"micStart\"],null],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-12 text-center\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[[35,1,[\"interimTranscript\"]],[35,1,[\"interimTranscript\"]],[35,1,[\"finalTranscript\"]]],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"speech\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/speech/speech-modal.hbs"
    }
  });
  _exports.default = _default;
});