define("livarava-web/templates/investments/created", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CS0oxmI/",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"investments\"]],[[\"default\"],[{\"statements\":[[2,\"Investments\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Investment #\"],[1,[35,1,[\"id\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"investment\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body text-center\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"https://store.livarava.com/ed8d0bfe-7e90-11ec-919e-5254a2021b2b.gif\"],[14,0,\"img-fluid mb-3\"],[14,\"alt\",\"Responsive image\"],[12],[13],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"\\n          \"],[8,\"link-to\",[[24,0,\"btn btn-lg btn-primary\"]],[[\"@route\",\"@query\"],[\"investments.create\",[30,[36,3],null,[[\"copy\"],[[35,2]]]]]],[[\"default\"],[{\"statements\":[[2,\"\\n            Create one more\\n          \"]],\"parameters\":[]}]]],[2,\"\\n\\n          \"],[8,\"link-to\",[[24,0,\"btn btn-lg btn-success\"]],[[\"@route\"],[\"investments.index\"]],[[\"default\"],[{\"statements\":[[2,\"\\n            To investments\\n          \"]],\"parameters\":[]}]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[3,\"body\"],[2,\"\\n\"],[13],[3,\"payout\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"id\",\"hash\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/investments/created.hbs"
    }
  });
  _exports.default = _default;
});