define("livarava-web/templates/components/neuron-autocomplete-v1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qgX6j+qJ",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,9],null,[[\"action\"],[[30,[36,1],[[32,0],\"onOutsideClick\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuron-autocomplete-v1\"],[12],[2,\"\\n    \"],[1,[30,[36,8],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"input\",\"key-down\",\"focusIn\",\"autofocus\"],[\"text\",\"form-inline form-control\",[35,7],[35,6],[30,[36,1],[[32,0],\"onInput\"],null],[30,[36,1],[[32,0],\"onKeyUp\"],null],[30,[36,1],[[32,0],\"onFocusIn\"],null],[35,5]]]]],[2,\"\\n\\n\"],[6,[37,0],[[35,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"dropdown\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"div\"],[16,0,[31,[\"item \",[30,[36,0],[[32,1,[\"selected\"]],\"selected\"],null]]]],[16,\"title\",[31,[[32,1,[\"header\"]]]]],[4,[38,1],[[32,0],\"selectItem\",[32,1]],null],[12],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"media\"],[12],[2,\"\\n              \"],[10,\"img\"],[14,0,\"image\"],[15,\"src\",[31,[[30,[36,0],[[32,1,[\"image_url\"]],[32,1,[\"image_url\"]],[32,1,[\"icon_url\"]]],null]]]],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"media-body\"],[12],[2,\"\\n              \"],[10,\"span\"],[14,0,\"header\"],[12],[2,\"\\n                \"],[1,[32,1,[\"header\"]]],[2,\"\\n              \"],[13],[2,\"\\n              \"],[13],[2,\"\\n            \"],[13],[2,\"\\n\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"items\",\"-track-array\",\"each\",\"autofocus\",\"value\",\"placeholder\",\"input\",\"click-outside\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-autocomplete-v1.hbs"
    }
  });
  _exports.default = _default;
});