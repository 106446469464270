define("livarava-web/controllers/services/children", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'service_type', 'featured'],
    page: 1,
    per_page: 10,
    q: null,
    service_type: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'featured',
      query: 'featured',
      type: 'select',
      placeholder: 'Featured',
      options: [{
        value: true,
        label: 'Yes'
      }, {
        value: false,
        label: 'No'
      }]
    }, {
      name: 'service_type',
      query: 'service_type',
      type: 'select',
      placeholder: 'Type',
      options: _environment.default.service.types.map(function (item) {
        return {
          value: item.value,
          icon: item.icon,
          label: item.title
        };
      })
    }])
  });
  _exports.default = _default;
});