define("livarava-web/templates/components/payment/payment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nRdtPQHM",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[6,[37,4],[[30,[36,3],[\"value\",[35,2,[\"status\"]],[35,1]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[32,1,[\"icon\"]]],[15,\"title\",[31,[[32,1,[\"label\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"statusOptions\",\"item\",\"find-by\",\"with\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/payment/payment-status.hbs"
    }
  });
  _exports.default = _default;
});