define("livarava-web/components/transaction/transaction-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // handlers
    delete: function _delete() {},
    save: function save() {},
    typeOptions: _environment.default.options.transaction.type,
    customer: null,
    balanceBefore: Ember.computed('model.{id,balance_before}', 'customer.balance', function () {
      return this.get('model.id') ? this.get('model.balance_before') : this.get('customer.balance') || 0;
    }),
    balanceAfter: Ember.computed('balanceBefore', 'model.{amount,transaction_type}', function () {
      var before = Number(this.balanceBefore || 0);
      var delta = Number(this.get('model.amount') || 0);
      var type = this.get('model.transaction_type');
      return type == 'credit' ? before - delta : before + delta;
    }),
    // Actions
    actions: {
      save: function save(model) {
        var _this = this;
        return model.validate().then(function () {
          if (model.get('isValid')) {
            if (typeof _this.save == 'function') {
              return _this.save(model);
            }
          }
        });
      },
      delete: function _delete() {
        this['delete'](this.get('model.data'));
      },
      handleCustomerChange: function handleCustomerChange(neuron) {
        var _this2 = this;
        this.set('model.customer', neuron || null);
        this.set('customer', null);
        this.store.findRecord(neuron.get('type'), neuron.get('id')).then(function (customer) {
          return _this2.set('customer', customer);
        });
      },
      cancel: function cancel() {
        if (typeof this.cancel == 'function') {
          return this.cancel();
        }
      }
    }
  });
  _exports.default = _default;
});