define("livarava-web/controllers/lang/portfolio", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Properties
    languages: _environment.default.intl.localeLabels
  });
  _exports.default = _default;
});