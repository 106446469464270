define("livarava-web/templates/components/thread/thread-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HdkmbcBZ",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[11,\"div\"],[24,0,\"btn-get-messages\"],[24,\"role\",\"button\"],[4,[38,1],[[32,0],\"refreshThreads\"],null],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"fa fa-refresh \",[30,[36,3],[[35,2],\"fa-spin-2x\"],null]]]],[12],[13],[2,\"\\n  Refresh Messages \"],[1,[34,4]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\\n\"],[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"item\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"thread/thread-list-item\",\"action\",\"isRefreshing\",\"if\",\"threadsUpdatedChanged\",\"sortedItems\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/thread/thread-list.hbs"
    }
  });
  _exports.default = _default;
});