define("livarava-web/templates/lang/blog/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "YzTk4II2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,11],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,10],null,[[\"model\",\"page\"],[[35,4,[\"item\"]],[35,8]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,7],[[35,6,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,5],null,[[\"item\",\"onNewComment\"],[[35,4,[\"item\"]],[30,[36,3],[[32,0],\"onComment\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n        Please, \"],[6,[37,2],null,[[\"query\",\"route\"],[[30,[36,1],null,[[\"next\"],[[35,0]]]],\"signin\"]],[[\"default\"],[{\"statements\":[[2,\"Sign In\"]],\"parameters\":[]}]]],[2,\" to live a comment.\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[1,[30,[36,9],null,[[\"items\",\"neuron\",\"page\",\"onSaveComment\",\"onDeleteComment\"],[[35,4,[\"comments\"]],[35,4,[\"item\"]],[35,8],[30,[36,3],[[32,0],\"onSaveComment\"],null],[30,[36,3],[[32,0],\"onDeleteComment\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"currentUrl\",\"-hash\",\"link-to\",\"action\",\"model\",\"comment/comment-form\",\"me\",\"if\",\"page\",\"comment/comment-list\",\"blog/blog-post\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/blog/item.hbs"
    }
  });
  _exports.default = _default;
});