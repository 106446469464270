define("livarava-web/controllers/projects/item/preorders/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    actions: {
      addNotification: function addNotification() {
        this.get('model.preorders_notifications').pushObject(Ember.Object.create({
          kind: "email",
          destination: ""
        }));
      },
      deleteNotification: function deleteNotification(object) {
        if (confirm("Are you sure?")) {
          this.get('model.preorders_notifications').removeObject(object);
        }
      },
      save: function save() {
        this.model.save();
      },
      delete: function _delete() {
        if (confirm("Are you sure?")) {
          this['delete'](this.model);
        }
      }
    }
  });
  _exports.default = _default;
});