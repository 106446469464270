define("livarava-web/components/site/site-event-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Proxy for event/event-form.js
  });
  _exports.default = _default;
});