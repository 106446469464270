define("livarava-web/routes/sites/item/pages/index", ["exports", "lodash/omitBy", "lodash/isNil", "livarava-web/utils/get-filters-from-params"], function (_exports, _omitBy2, _isNil2, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      lira: {
        refreshModel: true
      },
      language: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var site_id = this.paramsFor('sites.item').site_id;
      params['site'] = site_id;
      var site = Ember.get(this, 'store').peekRecord('site', site_id);
      var items = Ember.get(this, 'store').query('page', (0, _omitBy2.default)((0, _getFiltersFromParams.default)(params), _isNil2.default));
      return Ember.RSVP.hash({
        site_id: site_id,
        site: site,
        items: items
      });
    },
    // Actions
    actions: {
      refresh: function refresh() {
        this.reload();
      },
      delete: function _delete(item) {
        var _this = this;
        if (confirm('Are you sure?')) {
          var id = Ember.get(item, 'site.id');
          item.destroyRecord().then(function () {
            return _this.refresh();
          });
        }
      }
    }
  });
  _exports.default = _default;
});