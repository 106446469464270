define("livarava-web/templates/components/site/site-users-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qm0tIKIF",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,7],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"No users was found\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,3],null,[[\"site_id\",\"item\"],[[35,2],[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"count\",\"current\",\"pager\",\"class\"],[[35,1,[\"meta\",\"total_pages\"]],[35,0],true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"      Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"page\",\"items\",\"site_id\",\"site/site-users-item\",\"-track-array\",\"each\",\"pagination-pager\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-users-list.hbs"
    }
  });
  _exports.default = _default;
});