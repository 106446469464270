define("livarava-web/components/neuron/neuron-axon-button", ["exports", "fetch", "ember-delay/delay", "livarava-web/utils/get-api-url", "livarava-web/config/environment", "jquery"], function (_exports, _fetch, _delay, _getApiUrl, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Service
    router: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    // Attributes
    liva: null,
    rava: null,
    display: null,
    displayOptions: _environment.default.displayOptions,
    // Flags
    showAxonModal: false,
    showSuccess: false,
    showTextTab: true,
    showFileTab: false,
    isCreating: false,
    isDisplayExpanded: false,
    isFileUploading: false,
    // Rights
    canCreateAxon: Ember.computed('liva', 'rava', 'display', function () {
      var liva = Ember.get(this, 'liva.id');
      var rava = Ember.get(this, 'rava.id') || Ember.get(this, 'rava.title');
      var display = this.display;
      return liva && rava && display;
    }),
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      var displaySettings = Ember.get(this, 'localSettings.settings.axonDisplay');
      var displayConfig = _environment.default.displayOptions[0].value;
      var displayDefault = displaySettings || displayConfig;
      Ember.set(this, 'display', displayDefault);
    },
    // on Key Down
    keyDown: function keyDown() {
      this.send('hide');
    },
    success: function success() {
      var _this = this;
      Ember.set(this, 'showSuccess', true);
      Ember.set(this, 'rava', null);
      (0, _delay.default)(100).then(function () {
        (0, _jquery.default)('#message-success').fadeIn();
        _this.sendAction('reload');
      });
    },
    refresh: function refresh() {
      var router = this.router;
      var neuronId = Ember.get(this, 'liva.id');
      var r = new Date().getTime();
      Ember.set(this, 'isCreating', false);
      router.transitionTo('neuron.item.connections', neuronId, {
        queryParams: {
          r: r
        }
      });
    },
    actions: {
      show: function show() {
        Ember.set(this, 'showSuccess', false);
        Ember.set(this, 'showAxonModal', true);
        (0, _delay.default)(5).then(function () {
          (0, _jquery.default)('#axonmodalinput').focus();
        });
      },
      hide: function hide() {
        Ember.set(this, 'showAxonModal', false);
        Ember.set(this, 'rava', null);
      },
      setAxonTab: function setAxonTab(tabName) {
        if (tabName === 'text') {
          Ember.set(this, 'rava', null);
          Ember.set(this, 'showTextTab', true);
          Ember.set(this, 'showFileTab', false);
        } else if (tabName === 'file') {
          Ember.set(this, 'rava', null);
          Ember.set(this, 'showTextTab', false);
          Ember.set(this, 'showFileTab', true);
        }
      },
      onAxonNeuronInput: function onAxonNeuronInput() {
        Ember.set(this, 'rava', null);
      },
      setRava: function setRava(rava) {
        Ember.set(this, 'rava', rava);
      },
      onFileUpload: function onFileUpload(data) {
        var _this2 = this;
        var title = data.link;
        var name = data.name;
        var display = this.display;
        var url = (0, _getApiUrl.default)('parse-neuron', {
          title: title
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        Ember.set(this, 'isFileUploading', true);
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (data) {
          if (data && data.hasOwnProperty('neuron_id')) {
            // Load neuron
            _this2.store.findRecord('neuron', data.neuron_id).then(function (neuron) {
              // Store
              Ember.set(_this2, 'rava', neuron);
            });
          } else {
            // Nullify
            Ember.set(_this2, 'rava', null);

            // Update data
            data.header = name;
            data.display = display;

            // Init Neuron
            var neuron = _this2.store.createRecord('neuron', data);
            Ember.set(neuron, 'type', data.type);

            // Store
            Ember.set(_this2, 'rava', neuron);
          }

          // Update Flags
          Ember.set(_this2, 'isFileUploading', false);
        }).catch(function () {
          // Update Flags
          Ember.set(_this2, 'isFileUploading', false);
        });
      },
      setDisplay: function setDisplay(option) {
        if (!option) return;
        Ember.set(this, 'display', option.value);

        // Save Project to Local Settings
        Ember.set(this, 'localSettings.axonDisplay', option.value);
      },
      create: function create() {
        var _this3 = this;
        var liva_id = parseInt(Ember.get(this, 'liva.id'));
        var rava_id = parseInt(Ember.get(this, 'rava.id'));
        var display = this.display;
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        Ember.set(this, 'isCreating', true);
        if (isNaN(rava_id)) {
          var newNeuron = this.rava;
          newNeuron.save().then(function () {
            var rava_id = parseInt(newNeuron.id);
            var url = (0, _getApiUrl.default)('create-axon', {
              liva: liva_id,
              rava: rava_id,
              display: display
            });
            (0, _fetch.default)(url, params).then(function (response) {
              if (response.ok) {
                return response.json();
              } else {
                throw new Error();
              }
            }).then(function () {
              _this3.success();
              _this3.refresh();
            }).catch(function () {
              // Do something
            });
          });
        } else {
          var url = (0, _getApiUrl.default)('create-axon', {
            liva: liva_id,
            rava: rava_id,
            display: display
          });
          (0, _fetch.default)(url, params).then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error();
            }
          }).then(function () {
            _this3.success();
            _this3.refresh();
          }).catch(function () {
            // Do something
          });
        }
      }
    }
  });
  _exports.default = _default;
});