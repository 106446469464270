define("livarava-web/routes/reviews/create", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: 'New Review',
    // Query Params
    queryParams: {
      copy: {
        reloadModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var copyId = params.copy;
      var created = (0, _moment.default)();
      if (copyId) {
        return store.findRecord('review', copyId).then(function (data) {
          return store.createRecord('review', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.serialize().data.attributes), {}, {
            created: created
          }));
        });
      } else {
        return store.createRecord('review', {});
      }
    },
    // Actions
    actions: {}
  });
  _exports.default = _default;
});