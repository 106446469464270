define("livarava-web/components/contact/contact-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Computed
    isGravatar: Ember.computed('contact.image_url', function () {
      var imageUrl = this.get('contact.image_url');
      return imageUrl.indexOf('//www.gravatar.com/') > 0;
    })
  });
  _exports.default = _default;
});