define("livarava-web/components/files/logo-uploader", ["exports", "fetch", "livarava-web/utils/get-api-url", "livarava-web/config/environment"], function (_exports, _fetch, _getApiUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // args
    display: 'public',
    liva: null,
    accept: 'image/*',
    errors: null,
    onChange: function onChange() {},
    init: function init() {
      this._super.apply(this, arguments);
    },
    actions: {
      upload: function upload(file) {
        var _this = this;
        this.set('errors', []);
        var _config$api = _environment.default.api,
          host = _config$api.host,
          path = _config$api.path;
        var name = file.name;
        var display = this.display,
          liva = this.liva;
        var params = {
          headers: {
            'X-LivaRava-Token': this.get('me.token')
          }
        };
        var uploadURL = "".concat(host).concat(path, "/upload");
        return file.upload(uploadURL).then(function (data) {
          var fileURL = data.body.link;
          var url = (0, _getApiUrl.default)('parse-neuron', {
            title: fileURL
          });
          return (0, _fetch.default)(url, params).then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error();
            }
          });
        }).then(function (data) {
          if (data && data.hasOwnProperty('neuron_id')) {
            // Load neuron
            return _this.store.findRecord('neuron', data.neuron_id);
          } else {
            // Update data
            data.header = "Logo of #" + _this.get('liva.id');
            data.display = display;

            // Init Neuron
            var neuron = _this.store.createRecord('neuron', data);
            neuron.set('type', data.type);
            return neuron.save();
          }
        }).then(function (newNeuron) {
          liva.setProperties({
            logo_url: newNeuron.get('image_url'),
            logo: newNeuron
          });
          return liva.save();
        }).then(function () {
          return _this.onChange();
        }).catch(function () {
          _this.errors.pushObject(file.name);
          file.queue.remove(file);
        });
      }
    }
  });
  _exports.default = _default;
});