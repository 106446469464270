define("livarava-web/templates/navigations/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w2rJ82Rf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"navigations\"]],[[\"default\"],[{\"statements\":[[2,\"Navigations\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"h2\"],[12],[2,\"\\n  Navigations\\n\\n  \"],[10,\"div\"],[14,0,\"pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\",\"model\"],[\"btn btn-primary\",\"navigations.create\",[35,2]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n      New Navigation\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,4],null,[[\"model\",\"showSite\",\"save\"],[[35,1],true,[30,[36,3],[[32,0],\"save\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"site_id\",\"action\",\"navigation/navigation-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/navigations/create.hbs"
    }
  });
  _exports.default = _default;
});