define("livarava-web/models/service", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Base
    title: _emberData.default.attr('string'),
    type: 'service',
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    service_type: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    order: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon_class: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string'),
    // Open Graph
    og_title: _emberData.default.attr('string'),
    og_type: _emberData.default.attr('string'),
    og_image: _emberData.default.belongsTo('neuron'),
    og_image_url: _emberData.default.attr('string'),
    og_url: _emberData.default.attr('string'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    children: _emberData.default.hasMany('service', {
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('service', {
      inverse: 'children'
    })
  });
  _exports.default = _default;
});