define("livarava-web/components/input-relationship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isEdit: false
  });
  _exports.default = _default;
});