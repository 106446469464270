define("livarava-web/components/site/site-user-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    recipients: Ember.computed('item', function () {
      var item = this.item;
      var firstname = Ember.get(item, 'firstname');
      var lastname = Ember.get(item, 'lastname');
      var email = Ember.get(item, 'email');
      var phone = Ember.get(item, 'phone');
      return [{
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone
      }];
    })
  });
  _exports.default = _default;
});