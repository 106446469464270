define("livarava-web/models/project", ["exports", "ember-data", "livarava-web/utils/is-url"], function (_exports, _emberData, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    titleFirstLetter: Ember.computed('title', function () {
      var title = this.title;
      return title.charAt(0).capitalize();
    }),
    type: 'project',
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    preorders_notifications: _emberData.default.attr(),
    sections: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    // Visibility
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    // Stats
    views: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon', 'icon_url', function () {
      var url = this.icon_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return url;
    }),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed('image_url', function () {
      var url = this.image_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return url;
    }),
    imageStyle: Ember.computed('imageUrl', function () {
      var url = this.imageUrl;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Investments
    investment_amount: _emberData.default.attr('number'),
    investment_goal: _emberData.default.attr('number'),
    investment_progress: _emberData.default.attr('number'),
    investment_currency: _emberData.default.attr('string'),
    investment_card: _emberData.default.attr('number'),
    investment_iban: _emberData.default.attr('string')
  });
  _exports.default = _default;
});