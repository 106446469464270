define("livarava-web/components/neuron/neuron-buttons", ["exports", "livarava-web/mixins/neuron-type-determinator"], function (_exports, _neuronTypeDeterminator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Services
    router: Ember.inject.service(),
    // Properties
    item: null,
    // Actions
    actions: {
      create: function create() {
        var _this = this;
        this.item.save().then(function (data) {
          var router = _this.router;
          var neuronId = data.id;
          router.transitionTo('neuron.item.overview', neuronId);
        });
      },
      save: function save() {
        this.item.save();
      }
    }
  });
  _exports.default = _default;
});