define("livarava-web/templates/components/neuron-normal", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "lbbh9opZ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"clearfix neuron-normal\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"neuron-image\"],[15,5,[34,0]],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"neuron-data\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"neuron-header\"],[12],[2,\"\\n        \"],[1,[35,1,[\"header\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"neuron-info\"],[12],[2,\"\\n        \"],[1,[30,[36,2],null,[[\"item\"],[[35,1]]]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"neuron-summary\"],[12],[2,\"\\n        \"],[1,[35,1,[\"summary\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"imageStyle\",\"item\",\"neuron-short-info\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-normal.hbs"
    }
  });
  _exports.default = _default;
});