define("livarava-web/templates/components/review/review-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8p3gPvrK",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped mt-3\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"created\",\"Created\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"rating\",\"Rating/Comment\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center d-none d-md-table-cell\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"order\",\"Order\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center d-none d-lg-table-cell\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"entity\",\"Entity\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center d-none d-lg-table-cell\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"property\",\"Property\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,0,\"text-center\"],[12],[2,\"\\n        \"],[1,[30,[36,3],null,[[\"field\",\"label\",\"sort\"],[\"author\",\"Author\",[35,2]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"th\"],[14,5,\"width: 15%;\"],[14,0,\"text-right\"],[12],[2,\"\\n        Actions\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,1],null,[[\"item\",\"onDelete\"],[[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"onDelete\",\"review/review-list-item\",\"sort\",\"sort/sort-link\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/review/review-list.hbs"
    }
  });
  _exports.default = _default;
});