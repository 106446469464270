define("livarava-web/templates/sites/item/categories/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qobLzvlE",
    "block": "{\"symbols\":[],\"statements\":[[3,\"TODO: Refactor to site/site-category-form or remove \"],[2,\"\\n\"],[1,[30,[36,2],null,[[\"site\",\"defaultLanguage\",\"onAddCategory\"],[[35,1],[35,1,[\"language\"]],[30,[36,0],[[32,0],\"add\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"category-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/categories/create.hbs"
    }
  });
  _exports.default = _default;
});