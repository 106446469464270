define("livarava-web/helpers/number-format", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function numberFormat(params /*, hash*/) {
    var _params = (0, _slicedToArray2.default)(params, 2),
      number = _params[0],
      format = _params[1];
    if (number === undefined) return;
    return Number(number).toFixed(2);
  });
  _exports.default = _default;
});