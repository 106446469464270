define("livarava-web/components/comment/comment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    pages: true,
    page: 1
  });
  _exports.default = _default;
});