define("livarava-web/components/adsense/neuron-aside", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    name: null,
    // Render
    didRender: function didRender() {
      if (window) {
        var name = this.name;
        // Google appears to want these on the window.
        window.google_ad_client = "ca-pub-7682847466908926";
        window.google_ad_slot = "4166996178";
        window.google_ad_format = "auto";
        // There's probably a better way to do this.
        var html = '<script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>\n' + '<!-- LivaRava Neuron Overview -->\n' + '<ins class="adsbygoogle"\n' + '     style="display:block"\n' + '     data-ad-client="ca-pub-7682847466908926"\n' + '     data-ad-slot="7062009405"\n' + '     data-ad-format="auto"></ins>\n' + '<script>\n' + '(adsbygoogle = window.adsbygoogle || []).push({});\n' + '</script>';
        this.$("#".concat(name)).html(html);
      }
    }
  });
  _exports.default = _default;
});