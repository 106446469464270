define("livarava-web/templates/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sL5ulFY8",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"class\"],[\"common-dark\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"settings\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"menu col-sm-3\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n            Settings\\n          \"],[13],[2,\"\\n\\n          \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\\n            \"],[10,\"div\"],[14,0,\"nav nav-pills nav-stacked flex-column\"],[12],[2,\"\\n\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-item nav-link\",\"settings.profile\"]],[[\"default\"],[{\"statements\":[[2,\"                Profile\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-item nav-link\",\"settings.identity\"]],[[\"default\"],[{\"statements\":[[2,\"                Identity\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-item nav-link\",\"settings.billing\"]],[[\"default\"],[{\"statements\":[[2,\"                Billing\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"nav-item nav-link\",\"settings.invitations\"]],[[\"default\"],[{\"statements\":[[2,\"                Invitations\\n\"]],\"parameters\":[]}]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"col-sm-9\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n          \"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n        \"],[13],[3,\"body\"],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n    \"],[13],[3,\"row\"],[2,\"\\n  \"],[13],[3,\"settings\"],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"-outlet\",\"component\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/settings.hbs"
    }
  });
  _exports.default = _default;
});