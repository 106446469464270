define("livarava-web/components/input-autocomplete", ["exports", "livarava-web/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    class: null,
    inputClass: 'form-control',
    value: null,
    type: 'neuron',
    storedValue: null,
    selectedValue: null,
    selectedItem: null,
    selectedIndex: -1,
    items: [],
    sourceURL: "".concat(_environment.default.api.host, "/api/v2/autocomplete"),
    min: 3,
    limit: 4,
    exclude: null,
    searchOnly: false,
    lira: null,
    timer: null,
    // Events
    onChange: null,
    onSelect: null,
    onInput: null,
    onFocus: null,
    onFocusOut: null,
    // Computed
    showRemoveButton: Ember.computed('value', 'item', function () {
      var disableRemoveButton = this.disableRemoveButton;
      if (disableRemoveButton) {
        return false;
      }
      var value = this.value;
      var item = this.item;
      return value || item;
    }),
    didInsertElement: function didInsertElement() {
      if (this.autofocus) {
        this.$('input').focus();
      }
    },
    // Reset
    reset: function reset() {
      Ember.set(this, 'selectedIndex', -1);
      Ember.set(this, 'selectedValue', null);
      Ember.set(this, 'selectedItem', null);
      Ember.set(this, 'items', []);
    },
    // Search
    search: function search() {
      var _this = this;
      var value = this.value;
      var limit = this.limit;
      var timer = this.timer;
      var type = this.type;
      var exclude = this.exclude;
      clearTimeout(timer);
      timer = setTimeout(function () {
        _this.reset();
        if (value === null) {
          return;
        }
        if (value === '') {
          return;
        }
        if (value && value.length < _this.min) {
          return;
        }

        // console.log(value);

        _jquery.default.get(_this.sourceURL, {
          q: value,
          type: type,
          limit: limit,
          exclude: exclude
        }).done(function (data) {
          return Ember.set(_this, 'items', data.data);
        }).fail(function () {
          return Ember.set(_this, 'items', []);
        });
      }, 500);
      Ember.set(this, 'timer', timer);
    },
    // Next Item Select
    nextItem: function nextItem() {
      var index = this.selectedIndex;
      var items = this.items;
      var value = this.value;
      if (items.length === 0) {
        return;
      }
      if (index === -1) {
        Ember.set(this, 'storedValue', value);
      }
      index = index + 1;
      if (index >= items.length) {
        index = -1;
      }
      items.forEach(function (item) {
        Ember.set(item, 'selected', false);
      });
      Ember.set(this, 'selectedIndex', index);
      if (index >= 0 && index < items.length) {
        Ember.set(items[index], 'selected', true);
        if (!this.searchOnly) {
          Ember.set(this, 'value', items[index].header);
        }
        Ember.set(this, 'selectedValue', items[index].id);
        Ember.set(this, 'selectedItem', items[index]);
      } else if (index === -1) {
        if (!this.searchOnly) {
          Ember.set(this, 'value', this.storedValue);
        }
        Ember.set(this, 'selectedValue', null);
        Ember.set(this, 'selectedItem', null);
      }
    },
    // Previous Item Select
    previousItem: function previousItem() {
      var index = this.selectedIndex;
      var items = this.items;
      var value = this.value;
      if (items.length === 0) {
        return;
      }
      if (index === -1) {
        Ember.set(this, 'storedValue', value);
      }
      index = index - 1;
      if (index <= -2) {
        index = items.length - 1;
      }
      items.forEach(function (item) {
        Ember.set(item, 'selected', false);
      });
      Ember.set(this, 'selectedIndex', index);
      if (index >= 0 && index < items.length) {
        Ember.set(items[index], 'selected', true);
        if (!this.searchOnly) {
          Ember.set(this, 'value', items[index].header);
        }
        Ember.set(this, 'selectedValue', items[index].id);
        Ember.set(this, 'selectedItem', items[index]);
      } else if (index === -1) {
        if (!this.searchOnly) {
          Ember.set(this, 'value', this.storedValue);
        }
        Ember.set(this, 'selectedValue', null);
        Ember.set(this, 'selectedItem', null);
      }
    },
    // Set Item
    setItem: function setItem() {
      var value = this.selectedValue;
      var item = this.selectedItem;
      var onChange = this.onChange;
      if (onChange) onChange(value, item);
      var onSelect = this.onSelect;
      if (onSelect) onSelect(value, item);
      Ember.set(this, 'items', []);
      Ember.set(this, 'selectedValue', null);
      Ember.set(this, 'selectedItem', null);
      Ember.set(this, 'value', null);
    },
    // On backspace
    backspace: function backspace() {
      var onBackspace = this.onBackspace;
      if (onBackspace) {
        onBackspace(this.value);
      }
    },
    // Actions
    actions: {
      onInput: function onInput() {
        this.search();
        var onInput = this.onInput;
        if (onInput) onInput();
      },
      onFocusIn: function onFocusIn() {
        this.search();
        var onFocus = this.onFocus;
        if (onFocus) onFocus();
      },
      onFocusOut: function onFocusOut() {
        var onFocusOut = this.onFocusOut;
        if (onFocusOut) onFocusOut();
      },
      onKeyDown: function onKeyDown(value, event) {
        if (event.key === 'ArrowDown') {
          this.nextItem();
        } else if (event.key === 'ArrowUp') {
          this.previousItem();
        } else if (event.key === 'Enter') {
          this.setItem();
        } else if (event.key === 'Escape') {
          this.reset();
        } else if (event.key === 'Backspace') {
          this.backspace();
        }
      },
      setValue: function setValue() {
        var value = this.selectedValue;
        if (value === null) {
          return;
        }
        this.setItem();
      },
      removeValue: function removeValue() {
        this.reset();
        Ember.set(this, 'value', null);
        var onChange = this.onChange;
        if (onChange) onChange(null, null);
      },
      selectItem: function selectItem(item) {
        var value = item.id;
        var header = item.header;
        if (!this.searchOnly) {
          Ember.set(this, 'value', header);
        }
        Ember.set(this, 'selectedValue', value);
        Ember.set(this, 'selectedItem', item);
        Ember.set(this, 'items', []);
        this.setItem();
        this.$('input').focus();
      }
    }
  });
  _exports.default = _default;
});