define("livarava-web/routes/lang/careers/index", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    // Title
    title: null,
    // Model
    model: function model() {
      var _this = this;
      var store = this.store;
      var siteId = _environment.default.neuronet.site;
      var careersId = _environment.default.neuronet.careers;
      var language = Ember.get(this, 'intl.primaryLocale');
      var path = "/".concat(language, "/careers/");
      var pageFilter = {
        'filter[site]': siteId,
        'filter[path]': path,
        per_page: 1,
        page: 1
      };
      var jobsFilter = {
        'filter[site]': siteId,
        lira: careersId,
        per_page: 10,
        page: 1
      };
      return store.query('page', pageFilter).then(function (pages) {
        var page = Ember.get(pages, 'firstObject');
        if (!page) _this.transitionTo('lang.notfound');
        return Ember.RSVP.hash({
          page: store.findRecord('page', page.id),
          jobs: store.query('post', jobsFilter)
        });
      });
    },
    // After Model
    afterModel: function afterModel(model) {
      Ember.set(this, 'title', Ember.get(model, 'title'));
    }
  });
  _exports.default = _default;
});