define("livarava-web/templates/sites/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vvn7CHE6",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"class\"],[\"common-dark\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"sites\"]],[[\"default\"],[{\"statements\":[[2,\"Sites\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"model\",\"save\"],[[35,1],[30,[36,2],[\"save\"],null]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"route-action\",\"site/site-settings-form\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/edit.hbs"
    }
  });
  _exports.default = _default;
});