define("livarava-web/templates/components/input-city", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0E0inY1l",
    "block": "{\"symbols\":[\"prediction\"],\"statements\":[[6,[37,9],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"tagName\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"search\",\"allowClear\",\"matchTriggerWidth\",\"placeholder\",\"searchPlaceholder\",\"searchMessage\",\"loadingMessage\",\"noMatchesMessage\",\"dropdownClass\"],[[35,8],[35,7],[35,6],[35,5],true,[35,4],[30,[36,2],[[32,0],\"selectCity\"],null],[35,3],[30,[36,2],[[32,0],\"handleSearch\"],null],true,false,\"Виберіть місто\",\"Або введіть назву іншого...\",\"Виберіть або введіть своє\",\"Завантажуємо міста...\",\"Такого міста немає\",\"input-city-dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"name\"]]],[2,\"\\n\"],[6,[37,1],[[32,1,[\"description\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[10,\"br\"],[12],[13],[2,\"\\n\\t  \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[1,[32,1,[\"description\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[6,[37,1],[[35,10]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"small\"],[14,0,\"form-text text-black-50 px-2\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"details\",\"if\",\"action\",\"selectedPlace\",\"predictions\",\"tagName\",\"dropdownClass\",\"triggerClass\",\"inputClass\",\"power-select\",\"showDetails\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-city.hbs"
    }
  });
  _exports.default = _default;
});