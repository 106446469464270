define("livarava-web/templates/components/axon/axon-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yGeNQI4L",
    "block": "{\"symbols\":[\"axon\",\"&default\"],\"statements\":[[6,[37,5],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center text-primary\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spin fa-spinner fa-3x fa-fw\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Loading...\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,0],null,[[\"axon\",\"neuron\",\"display\"],[[32,1],[32,1,[\"lira\"]],[32,1,[\"display\"]]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"Axons was not found\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"axon/axon-list-item\",\"items\",\"-track-array\",\"each\",\"isLoading\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/axon/axon-list.hbs"
    }
  });
  _exports.default = _default;
});