define("livarava-web/helpers/truncate", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "lodash/truncate"], function (_exports, _slicedToArray2, _truncate2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.truncate = truncate;
  /**
   * Truncates text using lodash function 'truncate'.
   * Optionally provides additional transformation.
   * @param text (string): string to truncate.
   * @param length (number): maximum string length
   * @param omission (string): string to indicate text is omitted
   * @param separator (RegExp|string): separator pattern to truncate to
   * @param striptags (boolean): flag to remove all html tags
   * @returns {string}
   */
  function truncate(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
      _ref3$ = _ref3[0],
      text = _ref3$ === void 0 ? '' : _ref3$;
    var _ref2$length = _ref2.length,
      length = _ref2$length === void 0 ? 100 : _ref2$length,
      _ref2$omission = _ref2.omission,
      omission = _ref2$omission === void 0 ? '...' : _ref2$omission,
      _ref2$separator = _ref2.separator,
      separator = _ref2$separator === void 0 ? ' ' : _ref2$separator,
      _ref2$striptags = _ref2.striptags,
      striptags = _ref2$striptags === void 0 ? false : _ref2$striptags;
    var result = text;
    if (striptags) {
      result = result.replace(/(<([^>]+)>)/ig, "");
    }
    return (0, _truncate2.default)(result, {
      length: length,
      omission: omission,
      separator: separator
    });
  }
  var _default = Ember.Helper.helper(truncate);
  _exports.default = _default;
});