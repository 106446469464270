define("livarava-web/templates/events/print", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9U2J/EQR",
    "block": "{\"symbols\":[\"ticket\",\"index\"],\"statements\":[[6,[37,5],null,[[\"printSelector\",\"autoPrint\"],[\".print\",true]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"print\"],[12],[2,\"\\n    \"],[10,\"h1\"],[14,0,\"text-center mb-1\"],[12],[1,[35,1,[\"event\",\"title\"]]],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center mb-3\"],[12],[1,[30,[36,2],[[35,1,[\"event\",\"startdate\"]],\"YYYY-MM-DD HH:mm\"],null]],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"p-3\"],[12],[2,\"\\n      \"],[10,\"table\"],[14,0,\"table table-bordered\"],[12],[2,\"\\n        \"],[10,\"thead\"],[12],[2,\"\\n        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"th\"],[14,0,\"text-center\"],[12],[2,\"#\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Firstname\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Lastname\"],[13],[2,\"\\n          \"],[10,\"th\"],[12],[2,\"Signature\"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,1,[\"tickets\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"tr\"],[12],[2,\"\\n            \"],[10,\"td\"],[14,0,\"text-center\"],[12],[1,[30,[36,0],[[32,2]],null]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"firstname\"]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[1,[32,1,[\"lastname\"]]],[13],[2,\"\\n            \"],[10,\"td\"],[12],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[1,2]}]]],[2,\"        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"inc\",\"model\",\"moment-format\",\"-track-array\",\"each\",\"print-this\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/print.hbs"
    }
  });
  _exports.default = _default;
});