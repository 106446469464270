define("livarava-web/components/neuroner/filter-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.RULE_TYPES = void 0;
  var RULE_TYPES = {
    'contains': {
      title: 'Contains'
    },
    'doesnt_contain': {
      title: 'Doesn’t contain'
    },
    'is': {
      title: 'Is'
    },
    'is_not': {
      title: 'Is not'
    },
    'begins_with': {
      title: 'Begins with'
    },
    'ends_with': {
      title: 'Ends with'
    }
  };
  _exports.RULE_TYPES = RULE_TYPES;
  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: "form-row mb-2",
    text: '',
    types: RULE_TYPES,
    typeLabel: Ember.computed('rule.kind', function () {
      var TYPE = RULE_TYPES[this.get('rule.kind')];
      return TYPE ? TYPE.title : 'Select...';
    }),
    // Actions
    actions: {
      closeDropdownMenu: function closeDropdownMenu() {
        this.set('showDropdownMenu', false);
      },
      remove: function remove(item) {
        this.sendAction('deleteRule', item);
      },
      change: function change(property, value) {
        this.rule.set(property, value);
        this.set('showDropdownMenu', false);
      }
    }
  });
  _exports.default = _default;
});