define("livarava-web/models/category", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    icon_url: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('site'),
    parent: _emberData.default.belongsTo('neuron'),
    // Computed
    icon_url_safe: Ember.computed('icon_url', function () {
      return Ember.String.htmlSafe(this.icon_url);
    })
  });
  _exports.default = _default;
});