define("livarava-web/templates/components/input-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xevGtrFU",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dropdown \",[30,[36,2],[[35,8],\"opened\",\"closed\"],null]]]],[12],[2,\"\\n\"],[6,[37,9],null,[[\"action\"],[[30,[36,3],[[32,0],\"closeDropdown\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[24,0,\"form-control\"],[24,4,\"button\"],[4,[38,3],[[32,0],[30,[36,5],[\"showDropdown\",[32,0]],null]],null],[12],[2,\"\\n      \"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[[34,4]]]],[12],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[1,[30,[36,2],[[35,7],[35,7],[30,[36,2],[[35,6],[35,6],\"Select...\"],null]],null]],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-caret-down\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[15,0,[31,[\"dropdown-content \",[30,[36,2],[[35,10],\"dropdown-right\"],null]]]],[12],[2,\"\\n\"],[6,[37,13],[[30,[36,12],[[30,[36,12],[[35,11]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"button\"],[16,0,[31,[\"dropdown-item \",[30,[36,2],[[30,[36,1],[[32,1,[\"value\"]],[35,0]],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"change\",[32,1]],null],[12],[2,\"\\n        \"],[6,[37,2],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13]],\"parameters\":[]}]]],[2,\"\\n        \"],[1,[32,1,[\"label\"]]],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"value\",\"eq\",\"if\",\"action\",\"icon\",\"toggle\",\"placeholder\",\"label\",\"showDropdown\",\"click-outside\",\"dropdownRight\",\"options\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-select.hbs"
    }
  });
  _exports.default = _default;
});