define("livarava-web/routes/products/index", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _getFiltersFromParams, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Products',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      params['sort'] = '-views';
      return this.store.query('product', (0, _getFiltersFromParams.default)(params));
    },
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});