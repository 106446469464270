define("livarava-web/controllers/neuron/item/debug", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      save: function save() {
        var model = this.model;
        model.save();
      }
    }
  });
  _exports.default = _default;
});