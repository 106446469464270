define("livarava-web/components/right-panel", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    visible: false && _environment.default.environment === 'development',
    actions: {
      toggle: function toggle() {
        this.set('visible', !this.visible);
      }
    }
  });
  _exports.default = _default;
});