define("livarava-web/templates/components/neuron/neuron-info", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "PgDdeoID",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row neuron-info\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-7\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"item\",\"views\"],[[35,1],[35,0]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,1,null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-5\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"item\"],[[35,1]]]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"views\",\"item\",\"neuron/neuron-basic-info\",\"neuron/neuron-buttons\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-info.hbs"
    }
  });
  _exports.default = _default;
});