define("livarava-web/components/neuron-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Attributes
    comments: null,
    neuron: null,
    message: null,
    // Actions
    actions: {
      addComment: function addComment() {
        if (!this.message) {
          return;
        }
        var comment = {
          created: new Date(),
          text: this.message,
          root: this.get('neuron.id'),
          parent: null
        };
        this.onAddComment(comment);
        this.set('message', null);
      }
    }
  });
  _exports.default = _default;
});