define("livarava-web/templates/error500", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nqqVPn4k",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"error\"],[12],[2,\"\\n\\n\"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"img\"],[14,\"src\",\"/img/logo/logo-livarava-500x200-white_n_glow.png\"],[14,0,\"logo\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"h1\"],[12],[2,\"500\"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"text\"],[12],[2,\"\\n    Something is wrong. Please, contact us!\\n  \"],[13],[2,\"\\n\\n  \"],[1,[34,1]],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"common/common-links\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/error500.hbs"
    }
  });
  _exports.default = _default;
});