define("livarava-web/components/property/propertyservice-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    serviceTypes: _environment.default.service.types,
    selectableServiceTypes: _environment.default.service.types.filter(function (st) {
      return ['emergency', 'subscription'].includes(st.value);
    }),
    allServiceStatuses: _environment.default.service.status,
    services: null,
    selectedServiceType: null,
    loadServices: function loadServices(serviceType) {
      var _this = this;
      this.set('services', []);
      return this.store.query('service', {
        page: 1,
        per_page: 100,
        'filter[display]': 'public',
        'filter[service_type]': serviceType,
        sort: '-title'
      }).then(function (services) {
        return _this.set('services', services);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      // init selectedServiceType on open
      this.set('selectedServiceType', this.get('item.service.service_type'));
      this.loadServices(this.get('item.service.service_type'));
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this2 = this;
        return item.validate().then(function () {
          if (item.get('isValid')) {
            return _this2.onSubmit(item);
          }
        });
      },
      handleServiceTypeChange: function handleServiceTypeChange(serviceType) {
        this.set('selectedServiceType', serviceType);
        this.set('item.service', null);
        this.loadServices(serviceType);
      },
      cancel: function cancel() {
        if (typeof this.cancel == 'function') {
          return this.cancel();
        }
      }
    }
  });
  _exports.default = _default;
});