define("livarava-web/routes/products/item/members", ["exports", "fetch", "livarava-web/utils/get-api-url", "jquery"], function (_exports, _fetch, _getApiUrl, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Members',
    // Properties
    product_id: null,
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var product_id = parseInt(this.paramsFor('products.item').product_id);
      Ember.set(this, 'product_id', product_id);
      var adminParams = _jquery.default.extend({}, params);
      adminParams['filter[product_admin]'] = product_id;
      var managerParams = _jquery.default.extend({}, params);
      managerParams['filter[product_manager]'] = product_id;
      var analyticParams = _jquery.default.extend({}, params);
      analyticParams['filter[product_analytic]'] = product_id;
      var memberParams = _jquery.default.extend({}, params);
      memberParams['filter[product_member]'] = product_id;
      return Ember.RSVP.hash({
        product_id: product_id,
        admins: store.query('user', adminParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'admin');
          });
          return items;
        }),
        managers: store.query('user', managerParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'manager');
          });
          return items;
        }),
        analytics: store.query('user', analyticParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'analytic');
          });
          return items;
        }),
        members: store.query('user', memberParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'member');
          });
          return items;
        }),
        roles: [{
          value: 'admin',
          label: 'Admin'
        }, {
          value: 'manager',
          label: 'Manager'
        }, {
          value: 'analytic',
          label: 'Analytic'
        }, {
          value: 'member',
          label: 'Member'
        }]
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      },
      getRole: function getRole(user) {
        var product_id = this.product_id;
        var user_id = Ember.get(user, 'id');
        var role = Ember.get(user, 'role').toLowerCase();
        var url = (0, _getApiUrl.default)('get-product-role', {
          product_id: product_id,
          user_id: user_id,
          role: role
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        return (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        });
      },
      setRole: function setRole(user, role) {
        var _this = this;
        var product_id = this.product_id;
        var user_id = Ember.get(user, 'id');
        role = role.toLowerCase();
        var params = {
          product_id: product_id,
          user_id: user_id,
          role: role
        };
        if (user.part !== null && user.part !== undefined) {
          params['part'] = user.part;
        }
        var url = (0, _getApiUrl.default)('set-product-role', params);
        var token = Ember.get(this, 'me.token');
        var headers = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, headers).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          return _this.refresh();
        });
      },
      removeRole: function removeRole(user, role) {
        var _this2 = this;
        role = role.toLowerCase();
        var product_id = this.product_id;
        var user_id = Ember.get(user, 'id');
        var url = (0, _getApiUrl.default)('remove-product-role', {
          product_id: product_id,
          user_id: user_id,
          role: role
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          return _this2.refresh();
        });
      }
    }
  });
  _exports.default = _default;
});