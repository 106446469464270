define("livarava-web/utils/get-filters-from-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getFiltersFromParams;
  var meta = ['page', 'per_page', 'q', 'neuron', 'lira', 'sort'];
  function getFiltersFromParams(params) {
    for (var key in params) {
      if (!params.hasOwnProperty(key)) {
        continue;
      }
      var value = params[key];
      if (value === null || value === 'null' || value === '') {
        delete params[key];
        continue;
      }
      if (meta.includes(key)) {
        continue;
      }
      params["filter[".concat(key, "]")] = value;
    }
    return params;
  }
});