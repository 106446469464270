define("livarava-web/routes/users/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      return store.findRecord('user', params.id);
    }
  });
  _exports.default = _default;
});