define("livarava-web/templates/components/payment/payment-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1Tbvhqqo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"\\n  Payment #\"],[1,[35,0,[\"id\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"payment\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        Payment ID: \"],[1,[35,0,[\"id\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        Datetime: \"],[1,[30,[36,1],[[35,0,[\"created\"]],\"YYYY-MM-DD HH:mm:ss\"],null]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        Type: \"],[1,[35,0,[\"type\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        Description: \"],[1,[35,0,[\"description\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        Status: \"],[1,[30,[36,2],null,[[\"item\"],[[35,0]]]]],[2,\" \"],[10,\"span\"],[15,0,[31,[[34,3]]]],[12],[1,[35,0,[\"statusLabel\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"pull-right payment-info-block\"],[12],[2,\"\\n\\n        \"],[10,\"div\"],[12],[2,\"Amount\"],[13],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"payment-info-big\"],[12],[2,\"\\n          \"],[1,[30,[36,4],[[35,0,[\"amount\"]]],[[\"format\"],[\"0.00\"]]]],[2,\"\\n          \"],[1,[35,0,[\"currency\"]]],[2,\"\\n        \"],[13],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,6],[[30,[36,5],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"moment-format\",\"payment/payment-status\",\"statusClass\",\"number-format\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/payment/payment-item.hbs"
    }
  });
  _exports.default = _default;
});