define("livarava-web/templates/products/item/settings", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Okf+atsN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Settings\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"model\",\"onSave\"],[[35,1],[30,[36,0],[\"save\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"product-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/products/item/settings.hbs"
    }
  });
  _exports.default = _default;
});