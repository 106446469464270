define("livarava-web/templates/components/review/item-rate", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oOEjwkq5",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text-muted\"],[12],[2,\"\\n  \"],[10,\"small\"],[14,0,\"mr-2\"],[12],[2,\"\\n    \"],[8,\"review/rate\",[],[[\"@rating\"],[[34,0,[\"rating\"]]]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,1],[[35,0,[\"rating_count\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"small\"],[12],[2,\"\\n      \"],[1,[35,0,[\"rating_count\"]]],[2,\"\\n      відгуків\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/review/item-rate.hbs"
    }
  });
  _exports.default = _default;
});