define("livarava-web/controllers/contact", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attributes
    name: Ember.computed.reads('me.data.fullname'),
    email: Ember.computed.reads('me.data.email'),
    subject: null,
    text: null,
    error: null,
    // States
    isSending: false,
    isShowingSuccessModal: false,
    isShowingErrorModal: false,
    isShowingAdditionalInput: false,
    // Actions
    actions: {
      updateSubject: function updateSubject(value) {
        if (value === 'Other...') {
          Ember.set(this, 'subject', null);
          Ember.set(this, 'isShowingAdditionalInput', true);
        } else {
          Ember.set(this, 'subject', value);
        }
      },
      onSubmit: function onSubmit() {
        var _this = this;
        var name = this.name;
        var email = this.email;
        var subject = this.subject;
        var text = this.text;
        var params = {
          name: name,
          email: email,
          subject: subject,
          text: text
        };
        var url = (0, _getApiUrl.default)('contact', params, 'POST');
        var token = Ember.get(this, 'me.token');
        Ember.set(this, 'isSending', true);
        Ember.set(this, 'error', null);
        (0, _fetch.default)(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          Ember.set(_this, 'error', null);
          Ember.set(_this, 'isSending', false);
          Ember.set(_this, 'isShowingSuccessModal', true);
        }).catch(function (response) {
          Ember.set(_this, 'isSending', false);
          Ember.set(_this, 'error', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this, 'isShowingErrorModal', true);
        });
      }
    }
  });
  _exports.default = _default;
});