define("livarava-web/components/entity/entity-item", ["exports", "moment", "livarava-web/config/environment"], function (_exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var allServiceTypes = Array.from(_environment.default.service.types);
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    item: Ember.computed.reads('model.item'),
    allServiceTypes: allServiceTypes,
    isBeingActivated: false,
    isActivationModalShown: false,
    activateTillDateValue: (0, _moment.default)(Date.now() + 7 * 24 * 3600 * 1000).format('YYYY-MM-DD HH:mm'),
    services: null,
    selectedService: null,
    memberUsers: Ember.computed('model.entityusers.@each.user', 'item.author', 'actionItems.@each.customer', function () {
      var author = this.get('item.author');
      var actionItems = this.actionItems;
      var entityusers = this.get('model.entityusers');
      var memberUsers = [];
      if (actionItems) {
        memberUsers.pushObjects(actionItems.mapBy('customer'));
      }
      if (author) {
        memberUsers.pushObject(author);
      }
      if (entityusers) {
        memberUsers.pushObjects(entityusers.mapBy('user'));
      }
      return memberUsers.uniqBy('id');
    }),
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.store.query('service', {
        page: 1,
        per_page: 100,
        'filter[display]': 'public',
        sort: '-title'
      }).then(function (services) {
        _this.set('services', services);
      });
      this.fetchActions();
      this.fetchTransactions();
      this.fetchEntityservices();
    },
    fetchActions: function fetchActions() {
      var _this2 = this;
      this.set('loadingActionItems', true);
      return this.store.query('action', {
        'filter[entity]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (actionItems) {
        _this2.set('actionItems', actionItems);
        _this2.set('loadingActionItems', false);
      }).catch(function () {
        _this2.set('loadingActionItems', false);
      });
    },
    fetchTransactions: function fetchTransactions() {
      var _this3 = this;
      this.set('loadingTransactions', true);
      return this.store.query('transaction', {
        'filter[customer]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (transactions) {
        _this3.set('transactions', transactions);
        _this3.set('loadingTransactions', false);
      }).catch(function () {
        _this3.set('loadingTransactions', false);
      });
    },
    fetchEntityservices: function fetchEntityservices() {
      var _this4 = this;
      this.set('loadingEntityservices', true);
      return this.store.query('entityservice', {
        'filter[entity]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (entityservices) {
        return Promise.all(entityservices.mapBy('service')).then(function () {
          return entityservices;
        });
      }).then(function (entityservices) {
        Ember.run.next(function () {
          return _this4.set('entityservices', entityservices);
        });
        _this4.set('loadingEntityservices', false);
      }).catch(function () {
        _this4.set('loadingEntityservices', false);
      });
    },
    activate: function activate(till) {
      var _this5 = this;
      // skip if in progress
      if (this.isBeingActivated) return;
      var item = this.item;
      var neuron_title = Ember.get(this, 'item.title');
      var result = confirm(this.intl.t('entity.activate_confirm', {
        title: neuron_title,
        date: till.format('YYYY-MM-DD')
      }));
      if (!result) return;
      Ember.set(this, 'isBeingActivated', true);
      item.set('activated', till);
      item.save().then(function () {
        Ember.set(_this5, 'isBeingActivated', false);
      }).catch(function (data) {
        Ember.set(_this5, 'isBeingActivated', false);
        alert(_this5.intl.t('error.occured'));
        item.rollbackAttributes();
        if (data.errors) {
          var error = JSON.parse(data.errors[0].detail).error.message;
          console.log(error);
        }
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      setUserRelationship: function setUserRelationship(relationship, user) {
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!user) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set(relationship, user);
        return item.save();
      },
      removeService: function removeService(entityservice) {
        var _this6 = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        return entityservice.destroyRecord().then(function () {
          _this6.onEntityServicesChange();
        });
      },
      addService: function addService(service) {
        var _this7 = this;
        var store = this.store;
        var entity = this.item;
        if (!service) {
          return;
        }
        this.set('serviceModal', false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        var item = store.createRecord('entityservice', {
          entity: entity,
          service: service
        });
        return item.save().then(function () {
          _this7.onChange();
        }).catch(function (data) {
          console.log(data);
          var error = data.errors[0];
          var message = JSON.parse(error.detail).error.message;
          alert(message);
        });
      },
      activateForMonths: function activateForMonths() {
        var months = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        // skip if in progress
        if (this.isBeingActivated) return;
        var till = (0, _moment.default)().add(months, 'months');
        return this.activate(till);
      },
      activateTillDate: function activateTillDate(date) {
        // close modal
        Ember.set(this, 'isActivationModalShown', false);
        // skip if in progress
        if (this.isBeingActivated) return;
        var till = (0, _moment.default)(date);
        return this.activate(till);
      },
      deactivate: function deactivate() {
        if (this.isBeingActivated) return;
        return this.activate((0, _moment.default)().subtract(1, 'days'));
      },
      toggleBlacklist: function toggleBlacklist() {
        var _this8 = this;
        var item = this.item;
        this.set('serviceModal', false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        if (item.get('blacklisted')) {
          item.set('blacklisted', null);
        } else {
          item.set('blacklisted', new _moment.default());
        }
        return item.save().then(function () {
          _this8.onChange();
        }).catch(function (data) {
          console.log(data);
          var error = data.errors[0];
          var message = JSON.parse(error.detail).error.message;
          alert(message);
        });
      },
      onAddEntityUser: function onAddEntityUser(user, role) {
        var _this9 = this;
        var entityuser = this.store.createRecord('entityuser', {
          role: role,
          entity: this.item,
          user: user
        });
        return entityuser.save().then(function () {
          _this9.onChange();
        });
      },
      onSaveEntityUser: function onSaveEntityUser(entityuser, role) {
        entityuser.set('role', role);
        return entityuser.save();
      },
      onRemoveEntityUser: function onRemoveEntityUser(entityuser) {
        return entityuser.destroyRecord();
      },
      handleActionAdded: function handleActionAdded() {
        this.fetchActions();
      },
      handleTransactionAdded: function handleTransactionAdded() {
        this.fetchTransactions();
      },
      handleEntityserviceAdded: function handleEntityserviceAdded() {
        this.fetchEntityservices();
      },
      handleInvoiceAdded: function handleInvoiceAdded() {
        this.onChange();
      }
    }
  });
  _exports.default = _default;
});