define("livarava-web/templates/components/post-categories-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "s1Da/6p2",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"post-category\"],[12],[2,\"\\n  \"],[1,[35,0,[\"title\"]]],[2,\"\\n  \"],[11,\"a\"],[24,6,\"\"],[24,0,\"text-danger\"],[4,[38,1],[[32,0],\"remove\",[35,0]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-remove\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/post-categories-item.hbs"
    }
  });
  _exports.default = _default;
});