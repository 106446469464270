define("livarava-web/models/product", ["exports", "ember-data", "livarava-web/utils/is-url"], function (_exports, _emberData, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    sections: _emberData.default.attr(),
    status: _emberData.default.attr('string'),
    // Stats
    views: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Type
    type: 'product',
    typeLabel: Ember.computed('type', function () {
      var type = this.type;
      return type[0].toUpperCase() + type.slice(1, type.length);
    }),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon', 'icon_url', function () {
      var url = this.icon_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return url;
    }),
    iconStyle: Ember.computed('iconUrl', function () {
      var url = this.iconUrl;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed('image.image_url', 'image_url', function () {
      var image_url = this.image_url;
      var image_image_url = Ember.get(this, 'image.image_url');
      if (image_url && (0, _isUrl.default)(image_url)) {
        return image_url;
      }
      if (image_image_url && (0, _isUrl.default)(image_image_url)) {
        return image_image_url;
      }
      return null;
    }),
    imageStyle: Ember.computed('imageUrl', function () {
      var url = this.imageUrl;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Goal
    goal_amount: _emberData.default.attr('number'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('neuron')
  });
  _exports.default = _default;
});