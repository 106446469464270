define("livarava-web/components/entity/entity-invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    sender: null,
    recipients: Ember.computed('item.fullname', 'item.email', 'item.phone', function () {
      var firstname = Ember.get(this, 'item.fullname');
      var lastname = '';
      var email = Ember.get(this, 'item.email');
      var phone = Ember.get(this, 'item.phone');
      return [{
        firstname: firstname,
        lastname: lastname,
        email: email,
        phone: phone
      }];
    }),
    emailSubject: Ember.computed('item.id', 'item.created', function () {
      var id = Ember.get(this, 'item.id');
      var date = Ember.get(this, 'item.created').format('YYYYMMDD');
      return "Invoice #".concat(date, "-").concat(id);
    }),
    emailText: Ember.computed('item.fullname', 'item.printUrl', function () {
      var fullname = Ember.get(this, 'item.fullname');
      var url = Ember.get(this, 'item.printUrl');
      return "\u0412\u0456\u0442\u0430\u0454\u043C\u043E,\n\n\u041D\u0430\u0434\u0441\u0438\u043B\u0430\u0454\u043C\u043E \u0412\u0430\u043C \u0440\u0430\u0445\u0443\u043D\u043E\u043A-\u0444\u0430\u043A\u0442\u0443\u0440\u0443 \u043D\u0430 \u043E\u043F\u043B\u0430\u0442\u0443.\n\n\u041F\u0435\u0440\u0435\u0433\u043B\u044F\u043D\u0443\u0442\u0438 \u0442\u0430 \u0440\u043E\u0437\u0434\u0440\u0443\u043A\u0443\u0432\u0430\u0442\u0438 \u0412\u0438 \u043C\u043E\u0436\u0435\u0442\u0435 \u0437\u0430 \u0446\u0438\u043C \u043F\u043E\u0441\u0438\u043B\u0430\u043D\u043D\u044F\u043C:\n\n".concat(url);
    })
  });
  _exports.default = _default;
});