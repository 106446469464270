define("livarava-web/components/sort/sort-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    field: null,
    order: Ember.computed('sort', function () {
      var sort = this.sort;
      var field = this.field;
      if (sort === null) return null;
      if (sort.search(field) < 0) return null;
      return sort.indexOf('-') > -1 ? 'desc' : 'asc';
    }),
    icon: Ember.computed('order', function () {
      var order = this.order;
      if (order === null) return null;
      return order === 'desc' ? 'caret-down' : 'caret-up';
    }),
    // Actions
    actions: {
      reorder: function reorder() {
        var field = this.field;
        var order = this.order;
        var sign = order === 'desc' ? '' : '-';
        var sort = "".concat(sign).concat(field);
        Ember.set(this, 'sort', sort);
      }
    }
  });
  _exports.default = _default;
});