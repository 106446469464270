define("livarava-web/utils/clean-params", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = cleanParams;
  function cleanParams(params) {
    var out = [];
    for (var key in params) {
      if (params.hasOwnProperty(key)) {
        if (params[key] === undefined) {
          continue;
        }
        if (params[key] === null) {
          continue;
        }
        out[key] = params[key];
      }
    }
    return out;
  }
});