define("livarava-web/routes/lang/careers/index-test", ["ember-qunit"], function (_emberQunit) {
  "use strict";

  (0, _emberQunit.moduleFor)('route:lang/careers/index', 'Unit | Route | lang/careers/index', {
    // Specify the other units that are required for this test.
    // needs: ['controller:foo']
  });
  (0, _emberQunit.test)('it exists', function (assert) {
    var route = this.subject();
    assert.ok(route);
  });
});