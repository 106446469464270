define("livarava-web/routes/products/item/payments/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      var payment_id = params.payment_id;
      return store.findRecord('payment', payment_id);
    },
    // Actions
    actions: {
      delete: function _delete() {
        var _this = this;
        if (confirm("Are you sure?")) {
          this.controller.model.destroyRecord().then(function () {
            return _this.transitionTo('payments');
          });
        }
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});