define("livarava-web/components/pagination-pager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties:
    current: null,
    count: null,
    prev: Ember.computed('current', function () {
      return Math.max(parseInt(this.current) - 1, 1);
    }),
    next: Ember.computed('current', 'count', function () {
      return parseInt(this.current) + 1;
    }),
    last: Ember.computed('count', function () {
      return parseInt(this.count);
    }),
    // Flags
    showPagination: Ember.computed('count', function () {
      return parseInt(this.count) > 1;
    }),
    isFirstDisabled: Ember.computed('current', function () {
      return parseInt(this.current) === 1;
    }),
    isPreviousDisabled: Ember.computed('current', function () {
      return parseInt(this.current) === 1;
    }),
    isNextDisabled: Ember.computed('current', 'count', function () {
      return parseInt(this.current) >= parseInt(this.count);
    }),
    isLastDisabled: Ember.computed('current', function () {
      return parseInt(this.current) >= parseInt(this.count);
    }),
    // Actions
    actions: {
      setPage: function setPage(page) {
        Ember.set(this, 'current', page);
      }
    }
  });
  _exports.default = _default;
});