define("livarava-web/routes/neuroner/settings/customers/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var product_id = 119036;
      return Ember.RSVP.hash({
        product_id: product_id,
        customer: store.findRecord('user', params.id)
      });
    }
  });
  _exports.default = _default;
});