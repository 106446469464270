define("livarava-web/components/user/user-admin-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    tagName: 'tr'
  });
  _exports.default = _default;
});