define("livarava-web/templates/components/product-breadcrumbs", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E11kuMWh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[15,0,[31,[\"breadcrumb-item \",[30,[36,2],[[35,0],\"active\"],null]]]],[12],[6,[37,1],null,[[\"route\"],[\"products\"]],[[\"default\"],[{\"statements\":[[2,\"Products\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"],[6,[37,3],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/product-breadcrumbs.hbs"
    }
  });
  _exports.default = _default;
});