define("livarava-web/controllers/entities/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'sort', 'is_active', 'author', 'manager'],
    page: 1,
    per_page: 10,
    q: null,
    sort: null,
    // Filters
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'status',
      query: 'is_active',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: true,
        label: 'Active',
        icon: 'fa fa-play text-success'
      }, {
        value: false,
        label: 'Unactive',
        icon: 'fa fa-pause text-info'
      }]
    }, {
      name: 'author',
      query: 'author',
      type: 'user',
      inputComponent: 'input-user',
      placeholder: 'Автор'
    }, {
      name: 'manager',
      query: 'manager',
      type: 'user',
      inputComponent: 'input-user',
      placeholder: 'Менеджер'
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }, {
      query: 'per_page',
      type: 'select',
      placeholder: 'Per Page',
      options: [{
        value: 5,
        label: '5'
      }, {
        value: 10,
        label: '10'
      }, {
        value: 25,
        label: '25'
      }, {
        value: 50,
        label: '50'
      }, {
        value: 100,
        label: '100'
      }, {
        value: 250,
        label: '250'
      }]
    }])
  });
  _exports.default = _default;
});