define("livarava-web/components/review/review-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    item: null,
    onSave: function onSave() {},
    actions: {
      save: function save(model) {
        var _this = this;
        return model.validate().then(function () {
          if (model.get('isValid')) {
            if (typeof _this.onSubmit == 'function') {
              return _this.onSubmit(model);
            }
          }
        });
      },
      cancel: function cancel() {
        if (typeof this.onCancel == 'function') {
          return this.onCancel();
        }
      }
    }
  });
  _exports.default = _default;
});