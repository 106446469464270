define("livarava-web/templates/profile/projects", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UAH7fu60",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[1,[35,0,[\"fullname\"]]],[2,\" · Projects\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"m-b-2\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/profile/projects.hbs"
    }
  });
  _exports.default = _default;
});