define("livarava-web/models/event", ["exports", "ember-data", "livarava-web/utils/get-language-label", "livarava-web/utils/get-currency-sign", "livarava-web/utils/is-url"], function (_exports, _emberData, _getLanguageLabel, _getCurrencySign, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    type: 'event',
    // Attributes
    event_type: _emberData.default.attr('string'),
    title: _emberData.default.attr('string'),
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // Location
    location: _emberData.default.attr('string'),
    location_type: _emberData.default.attr('string'),
    // Links
    online_url: _emberData.default.attr('string'),
    website_url: _emberData.default.attr('string'),
    register_url: _emberData.default.attr('string'),
    // Tickets
    tickets: _emberData.default.attr('array-of-objects'),
    // Stats
    weight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    views: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    subscriber_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Subscribers count
    comment_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Comments count
    axon_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Axons count

    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    startdate: _emberData.default.attr('datetime'),
    enddate: _emberData.default.attr('datetime'),
    timezone: _emberData.default.attr('string'),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon', 'icon_url', function () {
      var icon_url = this.icon_url;
      if (icon_url && (0, _isUrl.default)(icon_url)) {
        return icon_url;
      }
      return null;
    }),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageStyle: Ember.computed('image_url', function () {
      var imageUrl = this.image_url;
      if (imageUrl && (0, _isUrl.default)(imageUrl)) {
        return Ember.String.htmlSafe("background-image: url(".concat(imageUrl, ");"));
      }
      return null;
    }),
    // Price
    price: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    currency: _emberData.default.attr('string'),
    currencySign: Ember.computed('currency', function () {
      return (0, _getCurrencySign.default)(this.currency);
    }),
    // Relationships
    site: _emberData.default.belongsTo('site'),
    author: _emberData.default.belongsTo('user'),
    organizer: _emberData.default.belongsTo('neuron'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string'),
    // Computed
    previewUrl: Ember.computed('site.url', 'language', function () {
      var id = this.id;
      var siteUrl = Ember.get(this, 'site.url');
      var language = this.language;
      return "http://".concat(siteUrl, "/").concat(language, "/post/").concat(id);
    }),
    languageLabel: Ember.computed('language', function () {
      return (0, _getLanguageLabel.default)(this.language);
    }),
    isStartEndSameDay: Ember.computed('startdate', 'enddate', function () {
      var startday = this.startdate.format('YYYY-MM-DD');
      var endday = this.enddate.format('YYYY-MM-DD');
      return startday === endday;
    }),
    isNotStartEndSameMonth: Ember.computed('startdate', 'enddate', function () {
      var startmonth = this.startdate.format('YYYY-MM');
      var endmonth = this.enddate.format('YYYY-MM');
      return startmonth !== endmonth;
    })
  });
  _exports.default = _default;
});