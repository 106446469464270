define("livarava-web/controllers/settings/invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'q', 'status'],
    page: 1,
    q: null,
    status: null
  });
  _exports.default = _default;
});