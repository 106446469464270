define("livarava-web/components/payment/payment-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    tagName: 'tr',
    doubleClick: function doubleClick() {
      Ember.get(this, 'onDoubleClick')();
    }
  });
  _exports.default = _default;
});