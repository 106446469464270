define("livarava-web/controllers/payments/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attributes
    title: Ember.computed('model.id', function () {
      var itemId = this.get('model.id');
      return "Payment #".concat(itemId, " - LivaRava");
    }),
    // Computed
    statusClass: Ember.computed('model.status', function () {
      var status = this.get('model.status');
      switch (status) {
        case 'created':
          return 'text-muted';
        case 'success':
          return 'text-success';
        case 'canceled':
          return 'text-danger';
        case 'failed':
          return 'text-danger';
        default:
          return '';
      }
    })
  });
  _exports.default = _default;
});