define("livarava-web/templates/components/neuron/neuron-get-started", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WPbN7oT0",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3,[\"signed\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"alert \",[34,0]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/img/logo/logo-color-circle-400x400.png\"],[14,\"width\",\"40\"],[14,\"height\",\"40\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n        Join the \"],[1,[35,1,[\"subscriber_count\"]]],[2,\" people in \"],[10,\"strong\"],[12],[1,[35,1,[\"header\"]]],[13],[2,\" community\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\"],[\"btn btn-primary pull-right\",\"signup\"]],[[\"default\"],[{\"statements\":[[2,\"          Get started\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"klass\",\"item\",\"link-to\",\"me\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-get-started.hbs"
    }
  });
  _exports.default = _default;
});