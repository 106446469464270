define("livarava-web/components/action/action-table", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "moment", "lodash/omit", "livarava-web/config/environment", "livarava-web/validations/action-validations"], function (_exports, _toConsumableArray2, _moment, _omit2, _environment, _actionValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actionTypeOptions: _environment.default.options.action.type,
    actionDirectionOptions: _environment.default.options.action.direction,
    actionValidations: _actionValidations.default,
    model: null,
    isSaving: false,
    selectedTypes: null,
    visibleActionItems: Ember.computed('actionItems.@each.{action_type,action_direction}', 'selectedTypes.[]', function () {
      var selectedTypes = this.selectedTypes,
        actionItems = this.actionItems;
      if (!selectedTypes) return actionItems;
      return actionItems.filter(function (item) {
        return selectedTypes.includes(item.get('action_type'));
      });
    }),
    initAction: function initAction() {
      var _this = this;
      var store = this.store;
      var projectId = parseInt(_environment.default.options.action.defaults.project);
      var productId = parseInt(_environment.default.options.action.defaults.product);
      this.set('openedAction', {
        scheduled: (0, _moment.default)(Date.now() + 3 * 24 * 3600 * 1000),
        manager: this.get('model.manager'),
        action_direction: 'out'
      });
      return Ember.RSVP.hash({
        author: store.findRecord('user', this.get('me.id')),
        project: store.findRecord('project', projectId),
        product: store.findRecord('product', productId)
      }).then(function (_ref) {
        var author = _ref.author,
          project = _ref.project,
          product = _ref.product;
        _this.setProperties({
          'openedAction.author': author,
          'openedAction.project': project,
          'openedAction.product': product
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      // this.initAction();
      this.set('selectedTypes', this.actionTypeOptions.reduce(function (arr, item) {
        return [].concat((0, _toConsumableArray2.default)(arr), [item.value]);
      }, []).without('system'));
    },
    actions: {
      toggleActionForm: function toggleActionForm() {
        this.initAction();
      },
      saveAction: function saveAction(actionChangeset) {
        var _this2 = this;
        if (this.isSaving) {
          // skip save if in progress
          return;
        }
        var action = null;
        if (actionChangeset.get('id')) {
          action = actionChangeset;
        } else {
          actionChangeset.save();
          var data = Object.assign({}, actionChangeset.get('data'), this.getProperties('property', 'entity', 'order', 'lead'), {});
          action = this.store.createRecord('action', data);
        }
        this.set('isSaving', true);
        return action.save().then(function () {
          var model = _this2.model;
          if (model) {
            model.setProperties({
              funnel_last: action.get('created'),
              funnel_next: action.get('scheduled')
            });
            return model.save();
          } else return Promise.resolve(model);
        }).then(function (model) {
          _this2.setProperties({
            isSaving: false,
            openedAction: null
          });
          if (typeof _this2.onAdd == 'function') {
            return _this2.onAdd(action, model);
          }
        }).catch(function () {
          _this2.set('isSaving', false);
        });
      }
    }
  });
  _exports.default = _default;
});