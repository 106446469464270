define("livarava-web/components/site-categories", ["exports", "livarava-web/config/environment", "livarava-web/utils/get-language-label"], function (_exports, _environment, _getLanguageLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    model: null,
    languages: _environment.default.intl.localeLabels,
    language: null,
    languageLabel: null,
    // Functions
    onSetFilter: null,
    onAddCategory: null,
    onSaveCategory: null,
    onRemoveCategory: null,
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      var language = this.selectedLanguage;
      var label = (0, _getLanguageLabel.default)(language);
      this.set('selectedLanguageLabel', label);
    },
    // Actons
    actions: {
      setFilter: function setFilter(name, value) {
        this.onSetFilter(name, value);
        if (name === 'language') {
          var label = (0, _getLanguageLabel.default)(value);
          this.set('selectedLanguage', value);
          this.set('selectedLanguageLabel', label);
        }
      },
      selectCategory: function selectCategory(item) {
        this.set('selectedCategory', item);
      },
      removeSelectedCategory: function removeSelectedCategory() {
        this.set('selectedCategory', null);
      },
      addCategory: function addCategory(item) {
        this.onAddCategory(item, this.get('model.site'));
        this.set('selectedCategory', null);
      }
    }
  });
  _exports.default = _default;
});