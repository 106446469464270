define("livarava-web/templates/lang/careers/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FFKHS/iX",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"    \"],[2,[35,0,[\"page\",\"description\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-center mt-2 mb-5\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,0,[\"jobs\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"mb-1\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"models\"],[\"lang.careers.item\",[30,[36,1],[[35,3],[32,1,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,8,[\"rights\",\"pages\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-buttons-fixed text-right\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"models\"],[\"sites.item.pages.edit\",[30,[36,1],[[35,0,[\"page\",\"site\",\"id\"]],[35,0,[\"page\",\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"btn btn-lg btn-warning\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\" Edit\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"array\",\"link-to\",\"locale\",\"froala-content\",\"-track-array\",\"each\",\"common/common-article\",\"me\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/careers/index.hbs"
    }
  });
  _exports.default = _default;
});