define("livarava-web/templates/components/profile/profile-billing-overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "IZ4HjDh1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"billing\"],[12],[2,\"\\n  \"],[10,\"h3\"],[14,0,\"mb-1\"],[12],[2,\"Overview\"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"mt-0 mb-3\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-2 text-muted\"],[12],[2,\"\\n      Plan\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-10\"],[12],[2,\"\\n      \"],[1,[35,4,[\"plan\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"mt-0 mb-3\"],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-2 text-muted\"],[12],[2,\"\\n      Storage\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-10\"],[12],[2,\"\\n\"],[6,[37,6],[[35,4,[\"space_calculating\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n        Calculating...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[1,[30,[36,2],null,[[\"class\",\"progress\",\"options\"],[\"billing-progress-bar mb-1\",[35,1],[35,0]]]]],[2,\"\\n\\n        \"],[10,\"div\"],[14,0,\"billing-progress-text mb-0\"],[15,5,[31,[\"color: \",[34,3],\";\"]]],[12],[2,\"\\n\"],[2,\"          \"],[1,[30,[36,5],[[35,4,[\"space_used\"]],false,1],null]],[2,\" of \"],[1,[30,[36,5],[[35,4,[\"space_total\"]],false,1],null]],[2,\" used\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[14,0,\"mt-0 mb-3\"],[12],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressOptions\",\"progressValue\",\"ember-progress-bar\",\"progressColor\",\"model\",\"number-to-human-size\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/profile/profile-billing-overview.hbs"
    }
  });
  _exports.default = _default;
});