define("livarava-web/routes/payments/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      project: {
        refreshModel: true
      },
      product: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      amount: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var projectId = parseInt(params.project);
      var productId = parseInt(params.product);
      var userId = parseInt(params.user);
      var amount = params.amount ? parseFloat(params.amount) : null;
      var item = store.createRecord('payment', {
        created: (0, _moment.default)(),
        currency: 'USD'
      });
      if (Number.isInteger(projectId)) {
        store.findRecord('neuron', projectId).then(function (project) {
          Ember.set(item, 'project', project);
        });
      }
      if (Number.isInteger(productId)) {
        store.findRecord('neuron', productId).then(function (product) {
          Ember.set(item, 'product', product);
        });
      }
      if (Number.isInteger(userId)) {
        store.findRecord('user', userId).then(function (user) {
          Ember.set(item, 'user', user);
        });
      }
      if (amount) {
        Ember.set(item, 'amount', amount);
      }
      return item;
    },
    // Actions
    actions: {
      onSave: function onSave(item) {
        var _this = this;
        item.save().then(function () {
          return _this.transitionTo('payments');
        });
      }
    }
  });
  _exports.default = _default;
});