define("livarava-web/components/input-display-button", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    displayOptions: _environment.default.displayOptions,
    tagName: 'span',
    // Actions
    actions: {
      setDisplay: function setDisplay(value) {
        var item = this.item;
        Ember.set(item, 'display', value);
        item.save();
      }
    }
  });
  _exports.default = _default;
});