define("livarava-web/templates/components/payment/payment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wlJU+76v",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped table-hover\"],[12],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"td\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"item\",\"linkRoute\"],[[32,1],\"products.item.payments.item\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"payment/payment-list-item\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/payment/payment-list.hbs"
    }
  });
  _exports.default = _default;
});