define("livarava-web/controllers/payouts/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attributes
    title: Ember.computed('model.id', function () {
      var itemId = this.get('model.id');
      return "Payout #".concat(itemId, " - LivaRava");
    }),
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this.transitionToRoute('payouts');
          });
        }
      }
    }
  });
  _exports.default = _default;
});