define("livarava-web/components/manager/change-manager", ["exports", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/toConsumableArray", "moment", "livarava-web/config/environment"], function (_exports, _defineProperty2, _toConsumableArray2, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    onChange: function onChange() {},
    managersIds: _environment.default.options.manager.managers,
    item: null,
    logAction: true,
    fetchManagers: function fetchManagers() {
      var _this = this;
      var store = this.store;
      var ids = [].concat((0, _toConsumableArray2.default)(this.managersIds), [this.get('me.id')]);
      return Promise.all(ids.map(function (id) {
        return store.findRecord('user', id);
      })).then(function (managers) {
        _this.set('managers', managers);
        return managers;
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchManagers();
    },
    // Actions
    actions: {
      setUserRelationship: function setUserRelationship(relationship, user) {
        var _this2 = this;
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!user) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;

        // proceed
        var scheduled = (0, _moment.default)().add('day', 1);
        var store = this.store;
        var projectId = parseInt(_environment.default.options.action.defaults.project);
        var productId = parseInt(_environment.default.options.action.defaults.product);
        return Ember.RSVP.hash({
          author: store.findRecord('user', this.get('me.id')),
          project: store.findRecord('project', projectId),
          product: store.findRecord('product', productId)
        }).then(function (_ref) {
          var author = _ref.author,
            project = _ref.project,
            product = _ref.product;
          if (!_this2.logAction) return;
          var text = item.get('manager.id') ? "\u0417\u043C\u0456\u043D\u0435\u043D\u043E \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u0430: ".concat(item.get('manager.fullname'), " -> ").concat(user.get('fullname')) : "\u041F\u0440\u0438\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u043C\u0435\u043D\u0435\u0434\u0436\u0435\u0440\u0430: ".concat(user.get('fullname'));
          var action = store.createRecord('action', (0, _defineProperty2.default)({
            scheduled: scheduled,
            manager: user,
            action_type: 'system',
            author: author,
            project: project,
            product: product,
            text: text
          }, _this2.get('item.type'), item));
          return action.save();
        }).then(function (action) {
          item.set(relationship, user);
          if (_this2.logAction) {
            item.setProperties({
              funnel_last: action.get('created'),
              funnel_next: action.get('scheduled')
            });
          }
          return item.save();
        }).then(function () {
          return _this2.onChange(user);
        });
      }
    }
  });
  _exports.default = _default;
});