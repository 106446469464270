define("livarava-web/components/transaction/transaction-table", ["exports", "livarava-web/config/environment", "livarava-web/validations/transaction-validations"], function (_exports, _environment, _transactionValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    transactionValidations: _transactionValidations.default,
    typeOptions: _environment.default.options.transaction.type,
    openedTransaction: null,
    initTransaction: function initTransaction() {
      var _this = this;
      if (this.customer) {
        var store = this.store;
        return store.findRecord('neuron', this.get('customer.id')).then(function (customer) {
          var openedTransaction = store.createRecord('transaction', {
            transaction_type: 'debit',
            text: '',
            balance_before: 0,
            balance_after: 0,
            customer: customer
          });
          _this.set('openedTransaction', openedTransaction);
        });
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.initTransaction();
      },
      saveTransaction: function saveTransaction(transactionChangeset) {
        var _this2 = this;
        if (this.isSaving) {
          // skip save if in progress
          return;
        }
        this.set('isSaving', true);
        return transactionChangeset.save().then(function (transaction) {
          _this2.setProperties({
            isSaving: false,
            openedTransaction: null
          });
          if (typeof _this2.onAdd == 'function') {
            return _this2.onAdd(transaction);
          }
        }).catch(function () {
          _this2.set('isSaving', false);
        });
      },
      deleteTransaction: function deleteTransaction(item) {
        var _this3 = this;
        if (confirm(this.intl.t('modal.confirm'))) {
          this.setProperties({
            isDeleting: true,
            openedTransaction: null
          });
          item.destroyRecord().finally(function () {
            _this3.set('isDeleting', false);
          });
        }
      }
    }
  });
  _exports.default = _default;
});