define("livarava-web/templates/profile/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5ZMZqwP7",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row mb-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-6\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-0\"],[12],[2,\"Profile\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-6 text-md-right\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-success\",\"settings.profile\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Edit\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mb-3\"],[12],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,[35,1,[\"about\"]]],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"clearfix\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"model\"],[[35,2,[\"data\"]]]]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"me\",\"profile/profile-billing-overview\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/profile/about.hbs"
    }
  });
  _exports.default = _default;
});