define("livarava-web/components/common/common-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    tagName: 'div',
    width: '100%',
    height: null,
    classImage: 'common-image',
    // Computed
    title: Ember.computed.reads('image.header'),
    style: Ember.computed('image.title', function () {
      var url = Ember.get(this, 'image.title');
      if (url === undefined) return '';
      return Ember.String.htmlSafe("background-image: url('".concat(url, "');"));
    }),
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var element = this.element;
      var width = this.width;
      var height = this.height;
      if (!height) height = element.offsetWidth + 'px';
      element.style.width = width;
      element.style.height = height;
    }
  });
  _exports.default = _default;
});