define("livarava-web/local-settings-interface", ["exports", "ember-local-settings/local-settings-interface"], function (_exports, _localSettingsInterface) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _localSettingsInterface.default;
    }
  });
});