define("livarava-web/templates/lang/careers/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3jiSCaam",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\",\"model\"],[\"lang.careers\",[35,1]]],[[\"default\"],[{\"statements\":[[2,\"Careers\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,2,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,3],null,[[\"model\"],[[35,2]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"locale\",\"model\",\"blog/blog-post\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/careers/item.hbs"
    }
  });
  _exports.default = _default;
});