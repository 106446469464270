define("livarava-web/templates/settings/identity", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p1NOvDYc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"settings/settings-identity\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/settings/identity.hbs"
    }
  });
  _exports.default = _default;
});