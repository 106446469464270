define("livarava-web/templates/navigations/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3whxi6Kr",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"save\",\"delete\"],[[35,1],[30,[36,0],[\"save\"],null],[30,[36,0],[\"delete\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"navigation/navigation-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/navigations/item.hbs"
    }
  });
  _exports.default = _default;
});