define("livarava-web/models/invitation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    email: _emberData.default.attr('string'),
    status: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    invited: _emberData.default.belongsTo('user'),
    // Special
    repeat: _emberData.default.attr()
  });
  _exports.default = _default;
});