define("livarava-web/components/category-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    site: null,
    selectedCategory: null,
    selectedLanguage: _environment.default.intl.defaultLocale,
    defaultLanguage: null,
    // Functions
    onAddCategory: null,
    // Init
    didReceiveAttrs: function didReceiveAttrs() {
      var defaultLanguage = this.defaultLanguage;
      if (defaultLanguage) Ember.set(this, 'selectedLanguage', defaultLanguage);
    },
    // Actions
    actions: {
      setCategory: function setCategory(item) {
        Ember.set(this, 'selectedCategory', item);
      },
      removeCategory: function removeCategory() {
        Ember.set(this, 'selectedCategory', null);
      },
      setLanguage: function setLanguage(value) {
        if (!value) return;
        Ember.set(this, 'selectedLanguage', value);
      },
      addCategory: function addCategory() {
        this.onAddCategory(this.selectedCategory, this.selectedLanguage);
        Ember.set(this, 'selectedCategory', null);
      }
    }
  });
  _exports.default = _default;
});