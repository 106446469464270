define("livarava-web/routes/events/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var event_id = parseInt(params.id);
      var event = store.findRecord('event', event_id);
      var tickets = store.query('ticket', {
        'filter[event]': event_id,
        per_page: 1000,
        page: 1,
        sort: '-created'
      });
      return Ember.RSVP.hash({
        event: event,
        tickets: tickets
      });
    }
  });
  _exports.default = _default;
});