define("livarava-web/components/order/order-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    propertyusers: null,
    hideAdvanced: true,
    userProperty: Ember.computed("model.property.id", "propertyusers", function () {
      var propertyusers = this.propertyusers;
      var propertyId = this.get("model.property.id");
      if (!propertyusers || !propertyId) return null;
      var userProperty = propertyusers.find(function (pu) {
        return pu.get("property.id") == propertyId;
      });
      return userProperty;
    }),
    project: Ember.computed("model.project", {
      get: function get() {
        return this.get("model.project");
      },
      set: function set(key, value) {
        var _this = this;
        if (!value) {
          Ember.set(this, "model.project", null);
        } else {
          this.store.findRecord("project", value.id).then(function (project) {
            return Ember.set(_this, "model.project", project);
          });
        }
      }
    }),
    product: Ember.computed("model.product", {
      get: function get() {
        return this.get("model.product");
      },
      set: function set(key, value) {
        var _this2 = this;
        if (!value) {
          Ember.set(this, "model.product", null);
        } else {
          this.store.findRecord("product", value.id).then(function (product) {
            return Ember.set(_this2, "model.product", product);
          });
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchProperties();
    },
    fetchProperties: function fetchProperties() {
      var _this3 = this;
      var userId = parseInt(this.get("model.author.id") || this.get("me.id"));
      this.store.query("propertyuser", {
        "filter[user]": userId,
        per_page: 100
      }).then(function (propertyusers) {
        _this3.set("propertyusers", propertyusers);
      });
    },
    // Actions
    actions: {
      setProperty: function setProperty(property) {
        var model = this.model;
        model.set("property", property);
        model.set("city", property.get("city"));
        model.set("address", property.get("address"));
        model.set("address_area_level_1", property.get("address_area_level_1"));
        model.set("address_area_level_2", property.get("address_area_level_2"));
        model.set("address_postal_code", property.get("address_postal_code"));
        model.set("address_type", property.get("address_type"));
        model.set("address_name", property.get("address_name"));
        model.set("address_building", property.get("address_building"));
        model.set("address_apartment", property.get("address_apartment"));
        model.set("email", property.get("email"));
        model.set("phone", property.get("phone"));
      },
      setAuthor: function setAuthor(user) {
        var model = this.model;
        if (!user) {
          model.set("author", null);
          return;
        }
        model.set("author", user);
        model.set("firstname", user.get("firstname"));
        model.set("lastname", user.get("lastname"));
        model.set("email", user.get("email"));
        model.set("phone", user.get("phone"));
        return this.fetchProperties();
      },
      // setProject(project) {
      // 	debugger;
      // 	if (project === null) {
      // 		set(this, "model.project", null);
      // 		return;
      // 	}
      // 	get(this, "store")
      // 		.findRecord("neuron", project.id)
      // 		.then((item) => set(this, "model.project", item));
      // },
      setProduct: function setProduct(product) {
        var _this4 = this;
        if (product === null) {
          Ember.set(this, "model.product", null);
          return;
        }
        this.store.findRecord("neuron", product.id).then(function (item) {
          return Ember.set(_this4, "model.product", item);
        });
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this["delete"](this.model);
      }
    }
  });
  _exports.default = _default;
});