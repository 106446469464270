define("livarava-web/templates/components/site/site-event-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "SyVBFgM3",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"model\",\"save\",\"delete\"],[[35,2],[35,1],[35,0]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"delete\",\"save\",\"model\",\"event/event-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-event-form.hbs"
    }
  });
  _exports.default = _default;
});