define("livarava-web/templates/components/neuroner/feed-health", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "spfLZePP",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"span\"],[14,0,\"small\"],[12],[1,[34,0]],[2,\"%\"],[13],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"progress\",\"options\"],[[35,2],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  Loading...\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"health\",\"options\",\"progress\",\"ember-progress-bar\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/feed-health.hbs"
    }
  });
  _exports.default = _default;
});