define("livarava-web/routes/support/tickets/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var me = this.me;
      return me.load().then(function (data) {
        var meId = data.data.id;
        params['site'] = 4964;
        params['author'] = meId;
        return Ember.RSVP.hash({
          items: store.query('feedback', (0, _getFiltersFromParams.default)(params)).catch(function (error) {
            console.log(error);
          })
        });
      }).catch(function (error) {
        console.log(error);
      });
    }
  });
  _exports.default = _default;
});