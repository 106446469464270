define("livarava-web/templates/components/entity/entity-post-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "dsD351Us",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  No posts yet.\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table table-striped table-hover\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"td\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"media\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"posts.item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"image mr-3\"],[15,5,[32,1,[\"imageStyle\"]]],[15,\"title\",[32,1,[\"title\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"          \"],[10,\"div\"],[14,0,\"media-body\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\",\"model\"],[\"posts.item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"              \"],[10,\"h5\"],[14,0,\"mt-0\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"            \"],[1,[32,1,[\"summary\"]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"items\",\"-track-array\",\"each\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/entity/entity-post-list.hbs"
    }
  });
  _exports.default = _default;
});