define("livarava-web/templates/navigations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cUQzRJB8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Navigations\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Navigations \"],[10,\"span\"],[14,0,\"count-info\"],[12],[1,[35,1,[\"items\",\"meta\",\"total_results\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-secondary\"],[24,\"disabled\",\"disabled\"],[4,[38,3],[[32,0],[30,[36,2],[\"isFiltersExpanded\",[32,0]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-bars\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-primary\",\"navigations.create\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\"\\n        New Navigation\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,5],null,[[\"items\",\"page\"],[[35,1,[\"items\"]],[35,4]]]]],[2,\"\\n\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"toggle\",\"action\",\"page\",\"navigation/navigation-list\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/navigations/index.hbs"
    }
  });
  _exports.default = _default;
});