define("livarava-web/components/axon/axon-button", ["exports", "fetch", "ember-delay/delay", "livarava-web/utils/get-api-url", "livarava-web/config/environment", "jquery"], function (_exports, _fetch, _delay, _getApiUrl, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    localSettings: Ember.inject.service('local-settings'),
    // Parameters
    item: null,
    axonId: null,
    // Attributes
    liva: null,
    rava: null,
    display: null,
    displayOptions: _environment.default.displayOptions,
    // Flags
    showAxonModal: false,
    showSuccess: false,
    isCreating: false,
    isDisplayExpanded: false,
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      var displaySettings = Ember.get(this, 'localSettings.settings.axonDisplay');
      var displayConfig = _environment.default.displayOptions[0].value;
      var displayDefault = displaySettings || displayConfig;
      Ember.set(this, 'display', displayDefault);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var item = this.item;
      Ember.set(this, 'liva', Ember.get(item, 'liva'));
      Ember.set(this, 'rava', Ember.get(item, 'rava'));
    },
    success: function success() {
      Ember.set(this, 'showSuccess', true);
      (0, _delay.default)(100).then(function () {
        (0, _jquery.default)('#message-success').fadeIn();
      });
    },
    // Actions
    actions: {
      show: function show() {
        Ember.set(this, 'showAxonModal', true);
      },
      hide: function hide() {
        Ember.set(this, 'showAxonModal', false);
      },
      setDisplay: function setDisplay(option) {
        if (!option) return;
        Ember.set(this, 'display', option.value);

        // Save Project to Local Settings
        Ember.set(this, 'localSettings.axonDisplay', option.value);
      },
      create: function create() {
        var _this = this;
        var me = this.me;
        var liva = parseInt(Ember.get(this, 'liva.id'));
        var rava = parseInt(Ember.get(this, 'rava.id'));
        var display = this.display;
        var token = Ember.get(this, 'me.token');
        var url = (0, _getApiUrl.default)('create-axon', {
          liva: liva,
          rava: rava,
          display: display
        });
        Ember.set(this, 'isCreating', true);
        (0, _fetch.default)(url, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (data) {
          me.load();
          _this.success();
          Ember.set(_this, 'axonId', parseInt(data.item.id));
          Ember.set(_this, 'isCreating', false);
        }).catch(function () {
          Ember.set(_this, 'isCreating', false);
        });
      }
    }
  });
  _exports.default = _default;
});