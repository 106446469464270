define("livarava-web/templates/components/task-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W9Vgnxe1",
    "block": "{\"symbols\":[],\"statements\":[[10,\"i\"],[15,0,[31,[[34,0]]]],[12],[13]],\"hasEval\":false,\"upvars\":[\"icon\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/task-status.hbs"
    }
  });
  _exports.default = _default;
});