define("livarava-web/components/neuron-list-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    comment: null,
    // Functions
    onDeleteComment: null,
    // Actions
    actions: {
      deleteComment: function deleteComment() {
        var comment = this.comment;
        this.onDeleteComment(comment);
      }
    }
  });
  _exports.default = _default;
});