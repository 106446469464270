define("livarava-web/components/finance/fin-stats-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // attributes
    cac: true,
    ltv: true,
    utm_source: true,
    cashback: false,
    // only for order

    // marketing sources,
    marketingSourceOptions: Array.from(_environment.default.options.marketing.sources),
    // action handlers
    onSave: function onSave() {},
    // Actions
    actions: {
      save: function save(model) {
        this.onSave(model);
      }
    }
  });
  _exports.default = _default;
});