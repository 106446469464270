define("livarava-web/components/currency-picker", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    currency: null,
    currencyOptions: _environment.default.currencyOptions,
    actions: {
      selectCurrency: function selectCurrency(currency) {
        this.set('currency', currency);
      }
    }
  });
  _exports.default = _default;
});