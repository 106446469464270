define("livarava-web/routes/sites/item/events/create", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Query Params
    queryParams: {
      copy: {
        reloadModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var me = this.me;
      var site_id = this.paramsFor('sites.item').site_id;
      var copyId = params.copy;
      var event;
      return store.findRecord('site', site_id).then(function (site) {
        var language = Ember.get(site, 'language');
        var title = '';
        var display = 'private';
        var tickets = Ember.A([]);

        // Dates
        var created = (0, _moment.default)();
        var updated = (0, _moment.default)();
        var startdate = (0, _moment.default)();
        var enddate = (0, _moment.default)();
        var timezone = Ember.get(me, 'timezone');

        // Init Start Date
        startdate.hour(19);
        startdate.minute(0);

        // Init End Date
        enddate.hour(21);
        enddate.minute(0);
        if (copyId) {
          event = store.findRecord('event', copyId).then(function (item) {
            var copy = store.createRecord('event', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, item.serialize().data.attributes), {}, {
              created: created
            }));
            title = 'Copy of ' + Ember.get(item, 'title');
            Ember.set(copy, 'title', title);
            Ember.set(copy, 'display', display);
            Ember.set(copy, 'updated', updated);
            Ember.set(copy, 'startdate', startdate);
            Ember.set(copy, 'enddate', enddate);
            Ember.set(copy, 'timezone', timezone);
            Ember.set(copy, 'tickets', tickets);
            Ember.set(copy, 'views', 0);
            Ember.set(copy, 'comments', 0);
            Ember.set(copy, 'axon_count', 0);
            Ember.set(copy, 'subscriber_count', 0);
            return copy;
          });
        } else {
          event = store.createRecord('event', {
            site: site,
            title: title,
            language: language,
            display: display,
            created: created,
            updated: updated,
            startdate: startdate,
            enddate: enddate,
            timezone: timezone,
            tickets: tickets,
            views: 0,
            comments: 0,
            axon_coun: 0,
            subscriber_count: 0
          });
        }
        return event;
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        var site_id = Ember.get(item, 'site.id');
        item.save().then(function (item) {
          _this.transitionTo('sites.item.events', site_id);
        });
      },
      cancel: function cancel(item) {
        var site_id = Ember.get(item, 'site.id');
        this.transitionTo('sites.item.events', site_id);
      }
    }
  });
  _exports.default = _default;
});