define("livarava-web/templates/components/common/common-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xXyy44cI",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"links\"],[12],[2,\"\\n  \"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"Go to homepage\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[6,[37,0],null,[[\"route\"],[\"contact\"]],[[\"default\"],[{\"statements\":[[2,\"Contact LivaRava\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-links.hbs"
    }
  });
  _exports.default = _default;
});