define("livarava-web/templates/components/common/common-footer-min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uMzaRAOG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"footer\"],[14,0,\"footer-min\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-12 copyright\"],[12],[2,\"\\n        Copyright © 2008-2018 LivaRava. All rights reserved.\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-footer-min.hbs"
    }
  });
  _exports.default = _default;
});