define("livarava-web/templates/components/common/common-save-button", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "euKNY/n2",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,6,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"button\"],[15,0,[31,[\"btn \",[30,[36,4],[[35,3],\"btn-lg\"],null],\" btn-light disabled\"]]],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Saving... \"],[10,\"i\"],[14,0,\"fa fa-circle\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,4],[[35,3],\"btn-lg\"],null],\" \",[34,2]]]],[16,\"title\",[34,5]],[24,4,\"submit\"],[4,[38,7],[[32,0],[35,7],[35,6]],null],[12],[2,\"\\n    \"],[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,1]]]],[14,\"aria-hidden\",\"true\"],[12],[13]],\"parameters\":[]},{\"statements\":[[10,\"i\"],[14,0,\"fa fa-save\"],[14,\"aria-hidden\",\"true\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,4],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[34,0]]],\"parameters\":[]},{\"statements\":[[2,\"Save\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[6,[37,4],[[35,6,[\"hasDirtyAttributes\"]]],null,[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-circle-o\"],[14,\"aria-hidden\",\"true\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"text\",\"icon\",\"saveClass\",\"big\",\"if\",\"title\",\"model\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-save-button.hbs"
    }
  });
  _exports.default = _default;
});