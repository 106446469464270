define("livarava-web/templates/events/tickets", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tYbwdXJW",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"events\"]],[[\"default\"],[{\"statements\":[[2,\"Events\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"event\",\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"event\",\"tickets\"],[[35,1,[\"event\"]],[35,1,[\"tickets\"]]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"event/event-tickets\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/tickets.hbs"
    }
  });
  _exports.default = _default;
});