define("livarava-web/routes/neurons/index", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: 'Neurons',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      var per_page = parseInt(params.per_page) || 7;
      var page = parseInt(params.page) || 1;
      return Ember.RSVP.hash({
        items: store.query('neuron', {
          author: meId,
          per_page: per_page,
          page: page,
          sort: '-created'
        })
      });
    }
  });
  _exports.default = _default;
});