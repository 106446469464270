define("livarava-web/templates/components/service/service-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "I826CmXU",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"table\"],[14,0,\"table table-striped mt-3\"],[12],[2,\"\\n  \"],[10,\"thead\"],[12],[2,\"\\n  \"],[10,\"tr\"],[12],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Title\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Type\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Amount\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[2,\"Order\"],[13],[2,\"\\n    \"],[10,\"th\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"item\",\"tagName\",\"onDelete\"],[[32,1],\"tr\",[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"onDelete\",\"service/service-list-item\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/service/service-list.hbs"
    }
  });
  _exports.default = _default;
});