define("livarava-web/templates/sites/item/navigations/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "aWS8Jyk2",
    "block": "{\"symbols\":[],\"statements\":[[3,\"TODO: Refactor to site/site-navigation-form \"],[2,\"\\n\"],[1,[30,[36,2],null,[[\"model\",\"save\"],[[35,1],[30,[36,0],[\"save\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"navigation/navigation-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/navigations/create.hbs"
    }
  });
  _exports.default = _default;
});