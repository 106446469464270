define("livarava-web/components/project/project-settings-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sectionsRoutes: Ember.computed('item.sections', function () {
      var rights = Ember.get(this, 'item.sections');
      var sections = [{
        name: 'index',
        title: 'Options',
        right: 'settings'
      }, {
        name: 'investments',
        title: 'Investments',
        right: 'investments'
      }, {
        name: 'payments',
        title: 'Payments',
        right: 'payments'
      }];
      return sections.filter(function (obj) {
        return rights.indexOf(obj['right']) >= 0;
      }).map(function (obj) {
        return new Object({
          name: obj['name'],
          title: obj['title'],
          route: "projects.item.settings.".concat(obj['name'])
        });
      });
    })
  });
  _exports.default = _default;
});