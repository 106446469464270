define("livarava-web/components/invoice/invoice-form", ["exports", "jquery", "livarava-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.STATUSES = _exports.SELLERS = _exports.CURRENCIES = void 0;
  var SELLERS = [{
    value: 'LivaRava Ukraine - Tether',
    label: 'LivaRava Ukraine - Tether'
  }, {
    value: 'LivaRava Ukraine - PayPal',
    label: 'LivaRava Ukraine - PayPal'
  }, {
    value: 'LivaRava Ukraine - Bilynskyy',
    label: 'LivaRava Ukraine - Bilynskyy'
  }, {
    value: 'LivaRava Ukraine - Wise',
    label: 'LivaRava Ukraine - Wise'
  }, {
    value: 'LivaRava Ukraine - Privatbank',
    label: 'LivaRava Ukraine - Privatbank'
  }, {
    value: 'LivaRava Ukraine - Ukrsibbank',
    label: 'LivaRava Ukraine - Ukrsibbank'
  }, {
    value: 'LivaRava Ukraine',
    label: 'LivaRava Ukraine'
  }, {
    value: 'LivaRava Inc.',
    label: 'LivaRava Inc.'
  }, {
    value: 'LivaRava International',
    label: 'LivaRava International'
  }, {
    value: 'Hospodari Ukraine',
    label: 'Hospodari Ukraine'
  }];
  _exports.SELLERS = SELLERS;
  var CURRENCIES = [{
    value: 'UAH',
    label: 'UAH',
    sign: "\u20B4"
  }, {
    value: 'USD',
    label: 'USD',
    sign: '$'
  }, {
    value: 'EUR',
    label: 'EUR',
    sign: '€'
  }, {
    value: 'RUB',
    label: 'RUB',
    sign: "\u20BD"
  }];
  _exports.CURRENCIES = CURRENCIES;
  var STATUSES = [{
    value: 'opened',
    label: 'Opened',
    icon: 'fa fa-square-o text-primary'
  }, {
    value: 'payed',
    label: 'Payed',
    icon: 'fa fa-check-square-o text-success'
  }];
  _exports.STATUSES = STATUSES;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Properties
    sourceURL: "".concat(_environment.default.api.host, "/api/v2/autocomplete/neurons/"),
    sellerOptions: SELLERS,
    currencyOptions: CURRENCIES,
    currencySign: Ember.computed('model.currency', function () {
      var options = this.currencyOptions;
      var value = Ember.get(this, 'model.currency');
      var sign = null;
      options.forEach(function (option) {
        if (option.value === value) {
          sign = option.sign;
        }
      });
      return sign;
    }),
    statusOptions: STATUSES,
    project: null,
    amount: Ember.observer('model.lines.@each.price', function () {
      var amount = Ember.get(this, 'model.lines').reduce(function (result, item) {
        return result + (Number(item.price) || 0);
      }, 0);
      Ember.set(this, 'model.amount', amount);
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      addLine: function addLine() {
        Ember.get(this, 'model.lines').pushObject(Ember.Object.create({}));
      },
      deleteLine: function deleteLine(object) {
        Ember.get(this, 'model.lines').removeObject(object);
      },
      change: function change(property, value) {
        Ember.set(this, property, value);
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      skipShortSearches: function skipShortSearches(term, select) {
        if (term.length < 3) {
          select.actions.search('');
          return false;
        }
      },
      searchNeurons: function searchNeurons(query) {
        var _this = this;
        return new Ember.RSVP.Promise(function (resolve) {
          _jquery.default.get(_this.sourceURL, {
            q: query,
            type: 'project'
          }).done(function (data) {
            return resolve(data.data);
          }).fail(function () {
            return resolve([]);
          });
        });
      },
      setCustomer: function setCustomer(item) {
        var _this2 = this;
        if (item === null) {
          Ember.set(this, 'model.customer', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          return Ember.set(_this2, 'model.customer', item);
        });
      },
      setProject: function setProject(project) {
        if (project === null) {
          Ember.set(this, 'model.project', null);
          return;
        }
        Ember.set(this, 'model.project', project);
      },
      setProduct: function setProduct(product) {
        if (product === null) {
          Ember.set(this, 'model.product', null);
          return;
        }
        Ember.set(this, 'model.product', product);
      },
      onCurrencyChange: function onCurrencyChange(option) {
        var value = Ember.get(this, 'model.currency');
        if (!option) return;
        if (!option.value) return;
        if (option.value === value) return;
        Ember.set(this, 'model.currency', option.value);
      },
      onStatusChange: function onStatusChange(option) {
        var value = Ember.get(this, 'model.status');
        if (!option) return;
        if (!option.value) return;
        if (option.value === value) return;
        Ember.set(this, 'model.status', option.value);
      },
      cancel: function cancel() {
        if (typeof this.cancel == 'function') {
          return this.cancel();
        }
      }
    }
  });
  _exports.default = _default;
});