define("livarava-web/routes/navigations/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      return Ember.RSVP.hash({
        items: this.store.query('navigation', (0, _getFiltersFromParams.default)(params))
      });
    }
  });
  _exports.default = _default;
});