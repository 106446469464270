define("livarava-web/templates/neuron/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qyCCiMYB",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,6],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuron\"],[12],[2,\"\\n    \"],[1,[30,[36,3],null,[[\"item\",\"isLoading\",\"onSaveNeuron\"],[[35,2],[35,1],[30,[36,0],[\"save\"],null]]]]],[2,\"\\n\\n    \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"isLoading\",\"model\",\"neuron-article\",\"-outlet\",\"component\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuron/create.hbs"
    }
  });
  _exports.default = _default;
});