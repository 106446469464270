define("livarava-web/models/property", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Neuron
    type: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number'),
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    // Subscribers count
    comment_count: _emberData.default.attr('number'),
    // Comments count
    axon_count: _emberData.default.attr('number'),
    // Axons count
    order_count: _emberData.default.attr('number'),
    // Orders count

    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    verified: _emberData.default.attr('datetime'),
    // General
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // Property
    property_number: _emberData.default.attr('number'),
    property_type: _emberData.default.attr('string'),
    business_id: _emberData.default.attr('number'),
    business_type: _emberData.default.attr('string'),
    balance: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    area_total: _emberData.default.attr('number'),
    floor_count: _emberData.default.attr('number'),
    flat_count: _emberData.default.attr('number'),
    entrance_count: _emberData.default.attr('number'),
    funnel: _emberData.default.attr('string', {
      defaultValue: 'new'
    }),
    funnel_last: _emberData.default.attr('datetime'),
    funnel_next: _emberData.default.attr('datetime'),
    // Address
    city: _emberData.default.attr('string'),
    // місто
    address: _emberData.default.attr('string'),
    // deprecated - старе поле адреси, більше не використовується
    address_area_level_1: _emberData.default.attr('string'),
    // область
    address_area_level_2: _emberData.default.attr('string'),
    // район
    address_postal_code: _emberData.default.attr('string'),
    // поштовий індекс
    address_type: _emberData.default.attr('string'),
    // тип вулиці
    address_name: _emberData.default.attr('string'),
    // назва вулиці
    address_building: _emberData.default.attr('string'),
    // номер будинку
    address_apartment: _emberData.default.attr('string'),
    // номер квартири

    // Contacts
    phone: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    website: _emberData.default.attr('string'),
    // Images
    icon_url: _emberData.default.attr('string'),
    image_url: _emberData.default.attr('string'),
    // Finance
    cac: _emberData.default.attr('number'),
    ltv: _emberData.default.attr('number'),
    // Marketing
    utm_source: _emberData.default.attr('string'),
    utm_medium: _emberData.default.attr('string'),
    utm_campaign: _emberData.default.attr('string'),
    utm_term: _emberData.default.attr('string'),
    utm_content: _emberData.default.attr('string'),
    utm_referral: _emberData.default.attr('string'),
    // Flags
    has_permission: _emberData.default.attr('boolean'),
    // Author
    author: _emberData.default.belongsTo('user'),
    manager: _emberData.default.belongsTo('user'),
    children: _emberData.default.hasMany('property', {
      inverse: 'parent'
    }),
    parent: _emberData.default.belongsTo('property', {
      inverse: 'children'
    }),
    lead: _emberData.default.belongsTo('lead'),
    // Rights
    rights: _emberData.default.attr('plain-object'),
    // Computed
    property_icon: Ember.computed('property_type', function () {
      var property_type = this.property_type;
      var property_map = {
        apartment: 'fa fa-hotel',
        private: 'fa fa-home text-success',
        commercial: 'fa fa-hospital-o text-warning',
        condominium: 'fa fa-building text-info'
      };
      return property_map[property_type] || '';
    }),
    property_title: Ember.computed('property_type', function () {
      var property_type = this.property_type;
      var property_map = {
        apartment: 'квартира',
        private: 'приватний будинок',
        commercial: 'комерційна нерухомість',
        condominium: "об'єднання"
      };
      return property_map[property_type] || '';
    })
  });
  _exports.default = _default;
});