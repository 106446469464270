define("livarava-web/components/event/event-form", ["exports", "ember-cli-uuid", "livarava-web/utils/create-axon", "livarava-web/config/environment"], function (_exports, _emberCliUuid, _createAxon, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Functions
    save: null,
    delete: null,
    reload: null,
    // Attributes
    froalaOptions: _environment.default.froalaOptions.max,
    // States
    isEditField: null,
    // Currency
    currencyOptions: _environment.default.currencyOptions,
    currencySign: Ember.computed('model.currency', function () {
      var options = this.currencyOptions;
      var value = Ember.get(this, 'model.currency');
      var sign = null;
      options.forEach(function (option) {
        if (option.value === value) {
          sign = option.sign;
        }
      });
      return sign;
    }),
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'isEditField', {
        'created': false,
        'language': false,
        'display': false
      });
    },
    // Actions
    actions: {
      change: function change(property, value) {
        Ember.set(this, property, value);
      },
      draft: function draft() {
        this.draft(this.model);
      },
      save: function save() {
        this.save(this.model);
      },
      saveAndGo: function saveAndGo() {
        this.saveAndGo(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      reload: function reload() {
        var reload = this.reload;
        if (reload) reload();
      },
      reset: function reset() {
        this.model.rollbackAttributes();
      },
      rollback: function rollback() {
        this.model.rollbackAttributes();
      },
      setSite: function setSite(item) {
        var _this = this;
        if (item === null) {
          Ember.set(this, 'model.site', null);
          return;
        }
        var store = this.store;
        store.findRecord('neuron', item.id).then(function (site) {
          return Ember.set(_this, 'model.site', site);
        });
      },
      setImage: function setImage(item) {
        var _this2 = this;
        if (item === null) {
          Ember.set(this, 'model.image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          Ember.set(_this2, 'model.image', image);
        });
      },
      setMetaImage: function setMetaImage(item) {
        var _this3 = this;
        if (item === null) {
          Ember.set(this, 'model.meta_image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          Ember.set(_this3, 'model.meta_image', image);
        });
      },
      setOrganizer: function setOrganizer(item) {
        var _this4 = this;
        if (item === null) {
          Ember.set(this, 'model.organizer', null);
          return;
        }
        var store = this.store;
        store.findRecord('neuron', item.id).then(function (organizer) {
          return Ember.set(_this4, 'model.organizer', organizer);
        });
      },
      edit: function edit(prop, status) {
        if (status === 'true') {
          Ember.set(this, 'isEditField.' + prop, true);
        } else if (status === 'false') {
          Ember.set(this, 'isEditField.' + prop, false);
        } else if (status === 'cancel') {
          this.send('rollback', 'created');
          Ember.set(this, 'isEditField.' + prop, false);
        }
      },
      fileUploaded: function fileUploaded(content, files) {
        var store = this.store;
        var item = this.model;
        var data = JSON.parse(files);
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var file = store.createRecord('neuron', {
            title: title,
            header: header,
            display: display
          });
          file.save().then(function (savedFile) {
            return (0, _createAxon.default)(item.id, savedFile.id, display, token);
          });
        }
        return true;
      },
      imageUploaded: function imageUploaded(content, images) {
        var store = this.store;
        var item = this.model;
        var data = JSON.parse(images);
        var type = 'image';
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var image = store.createRecord('neuron', {
            type: type,
            title: title,
            header: header,
            display: display
          });
          image.save().then(function (savedImage) {
            return (0, _createAxon.default)(item.id, savedImage.id, display, token);
          });
        }
        return true;
      },
      addTicket: function addTicket(type) {
        var ticket = null;
        if (type === 'free') {
          ticket = Ember.Object.create({
            name: null,
            quantity: null,
            type: 'free',
            uid: (0, _emberCliUuid.uuid)()
          });
        } else if (type === 'paid') {
          ticket = Ember.Object.create({
            name: null,
            quantity: null,
            type: 'paid',
            uid: (0, _emberCliUuid.uuid)()
          });
        }
        Ember.get(this, 'model.tickets').pushObject(ticket);
      },
      deleteTicket: function deleteTicket(ticket) {
        if (confirm("Are you sure?")) {
          Ember.get(this, 'model.tickets').removeObject(ticket);
        }
      }
    }
  });
  _exports.default = _default;
});