define("livarava-web/components/motivation/orders-count-progress", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "moment", "livarava-web/config/environment"], function (_exports, _toConsumableArray2, _objectSpread2, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // attributes
    readonlyManager: false,
    // filters
    date: null,
    manager: null,
    // data
    results: null,
    // charts options
    weeksChartOptions: Ember.computed('results.{prevWeek,thisWeek}', function () {
      return this.getChartOptions([this.get('results.prevWeek'), this.get('results.thisWeek')], 'По тижнях');
    }),
    monthsChartOptions: Ember.computed('results.{prevMonth,thisMonth}', function () {
      return this.getChartOptions([this.get('results.prevMonth'), this.get('results.thisMonth')], 'По місяцях');
    }),
    getChartOptions: function getChartOptions(totals, titleText) {
      return {
        title: {
          text: titleText
        },
        tooltip: {},
        legend: null,
        xAxis: {
          data: ['Попередній', 'Поточний']
        },
        yAxis: {},
        series: [{
          name: 'Зареєстровано заявок',
          type: 'bar',
          data: totals
        }]
      };
    },
    fetchData: function fetchData() {
      var _this = this;
      var managerId = this.get('manager.id');
      var date = this.date,
        store = this.store;
      var mdate = (0, _moment.default)(date);
      var requestParams = {
        per_page: 1,
        page: 1
      };
      if (managerId) {
        requestParams['filter[manager]'] = managerId;
      }
      return Ember.RSVP.hash({
        prevWeek: store.query('order', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          date_start: mdate.clone().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD'),
          date_end: mdate.clone().subtract(1, 'weeks').endOf('week').format('YYYY-MM-DD')
        })),
        thisWeek: store.query('order', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          date_start: mdate.clone().startOf('week').format('YYYY-MM-DD'),
          date_end: mdate.clone().endOf('week').format('YYYY-MM-DD')
        })),
        prevMonth: store.query('order', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          date_start: mdate.clone().subtract(1, 'months').startOf('month').format('YYYY-MM-DD'),
          date_end: mdate.clone().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
        })),
        thisMonth: store.query('order', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          date_start: mdate.clone().startOf('month').format('YYYY-MM-DD'),
          date_end: mdate.clone().endOf('month').format('YYYY-MM-DD')
        }))
      }).then(function (results) {
        return _this.set('results', {
          prevWeek: results.prevWeek.meta.total_results,
          thisWeek: results.thisWeek.meta.total_results,
          prevMonth: results.prevMonth.meta.total_results,
          thisMonth: results.thisMonth.meta.total_results
        });
      });
    },
    fetchManagers: function fetchManagers() {
      var _this2 = this;
      var store = this.store;
      var ids = [].concat((0, _toConsumableArray2.default)(_environment.default.options.manager.managers), [this.get('me.id')]);
      return Promise.all(ids.map(function (id) {
        return store.findRecord('user', id);
      })).then(function (managers) {
        _this2.set('managers', managers);
        return managers;
      });
    },
    init: function init() {
      var _this3 = this;
      this._super.apply(this, arguments);
      this.set('date', (0, _moment.default)().toDate());
      this.fetchManagers().then(function () {
        return _this3.loading.run(function () {
          return _this3.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDate: function setDate(date) {
        var _this4 = this;
        if (date && date[0]) {
          this.set('date', date && date[0]);
          this.loading.run(function () {
            return _this4.fetchData();
          });
        }
      },
      setManager: function setManager(manager) {
        var _this5 = this;
        this.set('manager', manager);
        this.loading.run(function () {
          return _this5.fetchData();
        });
      }
    }
  });
  _exports.default = _default;
});