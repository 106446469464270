define("livarava-web/components/input-autocomplete-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    imageStyle: Ember.computed('item.image_url', 'item.icon_url', function () {
      var imageUrl = Ember.get(this, 'item.image_url');
      var iconUrl = Ember.get(this, 'item.icon_url');
      var finalUrl = imageUrl || iconUrl;
      return Ember.String.htmlSafe("background-image: url(".concat(finalUrl, ");"));
    }),
    // Actions
    actions: {
      selectItem: function selectItem(item) {
        var selectItem = this.selectItem;
        if (selectItem) {
          selectItem(item);
        }
      }
    }
  });
  _exports.default = _default;
});