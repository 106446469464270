define("livarava-web/controllers/sites/index", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q'],
    page: 1,
    per_page: 6,
    q: null,
    sort: '-views',
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }]),
    // States
    isFiltersExpanded: false,
    isFiltersSetted: Ember.computed('q', function () {
      var _this = this;
      var filters = this.filters;
      var filtersSetted = 0;
      filters.forEach(function (filter) {
        var value = Ember.get(_this, filter.query);
        if (value !== null) {
          filtersSetted += 1;
        }
      });
      return filtersSetted > 0;
    }),
    // Actions
    actions: {
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      onDelete: function onDelete(item) {
        var _this2 = this;
        if (!item) {
          return;
        }
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            _this2.send('reload');
          });
        }
      },
      setFilter: function setFilter(name, value) {
        this.transitionToRoute('invoices', {
          queryParams: (0, _defineProperty2.default)({
            page: this.page,
            q: this.q
          }, "filter[".concat(name, "]"), value)
        });
      },
      resetFilters: function resetFilters() {
        var _this3 = this;
        var queryParams = this.queryParams;
        queryParams.forEach(function (param) {
          var paramName;
          if (param.toString() === '[object Object]') {
            paramName = Object.keys(param)[0];
          } else {
            paramName = param;
          }
          var value = paramName === 'page' ? 1 : null;
          Ember.set(_this3, paramName, value);
        });
      }
    }
  });
  _exports.default = _default;
});