define("livarava-web/templates/components/rssitems-infinite-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fkHIGnJk",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,3],[[35,2,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[35,2,[\"meta\",\"total_results\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-success\"],[12],[2,\"Nearby \"],[10,\"strong\"],[12],[1,[35,2,[\"meta\",\"total_results\"]]],[2,\" results\"],[13],[2,\" was found\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,1],null,[[\"item\",\"filter\"],[[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[1,[30,[36,6],null,[[\"infinityModel\",\"loadingText\",\"loadedText\"],[[35,2],\"Loading...\",\"The End\"]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"Nothing was found\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"filter\",\"neuron-rss-item\",\"model\",\"if\",\"-track-array\",\"each\",\"infinity-loader\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/rssitems-infinite-scroll.hbs"
    }
  });
  _exports.default = _default;
});