define("livarava-web/routes/entities/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var title = "Entity #".concat(params.id);
      this.set('titleToken', title);
      return Ember.RSVP.hash({
        item: store.findRecord('entity', params.id)
      });
    }
  });
  _exports.default = _default;
});