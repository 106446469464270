define("livarava-web/routes/sites/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: function titleToken(model) {
      return Ember.get(model, 'title');
    },
    // Model
    model: function model(param) {
      var store = this.store;
      var id = param.site_id;
      return store.findRecord('site', id);
    },
    // Actions
    actions: {
      delete: function _delete() {
        var _this = this;
        if (confirm("Are you sure?")) {
          this.controller.model.destroyRecord().then(function () {
            return _this.transitionTo('sites');
          });
        }
      }
    }
  });
  _exports.default = _default;
});