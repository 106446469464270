define("livarava-web/components/neuron-type-info", ["exports", "livarava-web/mixins/neuron-type-determinator"], function (_exports, _neuronTypeDeterminator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Attributes
    item: null,
    // Computed
    typeLabel: Ember.computed('item.type', function () {
      var type = this.get('item.type');
      return type[0].toUpperCase() + type.slice(1, type.length);
    })
  });
  _exports.default = _default;
});