define("livarava-web/templates/components/site/site-pages-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pxIBg4SB",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"div\"],[14,0,\"page-list\"],[12],[2,\"\\n\"],[6,[37,7],[[35,1,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"table\"],[14,0,\"table table-striped\"],[12],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[30,[36,3],null,[[\"item\",\"onRemove\"],[[32,1],[35,2]]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,6],null,[[\"count\",\"current\",\"pager\",\"class\"],[[35,1,[\"meta\",\"total_pages\"]],[35,0],true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"        Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info font-italic\"],[12],[2,\"No pages was found yet.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"page\",\"items\",\"onRemove\",\"site/site-pages-list-table-item\",\"-track-array\",\"each\",\"pagination-pager\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-pages-list-table.hbs"
    }
  });
  _exports.default = _default;
});