define("livarava-web/routes/projects/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model() {
      return this.store.createRecord('project', {
        title: ''
      });
    },
    // Actions
    actions: {
      save: function save(model) {
        var _this = this;
        model.save().then(function () {
          return _this.transitionTo('projects');
        });
      }
    }
  });
  _exports.default = _default;
});