define("livarava-web/components/site-navigations", ["exports", "livarava-web/config/environment", "livarava-web/utils/get-language-label"], function (_exports, _environment, _getLanguageLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Variables
    languages: _environment.default.intl.localeLabels,
    language: _environment.default.intl.defaultLocale,
    languageLabel: Ember.computed('language', function () {
      var locale = this.language;
      return (0, _getLanguageLabel.default)(locale);
    }),
    // States
    isDirty: false,
    isSaving: false,
    // Actions
    actions: {
      save: function save() {
        this.set('isSaving', true);
        var items = this.get('model.items');
        items.forEach(function (item) {
          item.save();
        });
        this.set('isSaving', false);
        this.set('isDirty', false);
      }
    }
  });
  _exports.default = _default;
});