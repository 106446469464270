define("livarava-web/utils/parse-video-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = parseVideoURL;
  function parseVideoURL() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    var url_video = url;
    if (url.match('(?:(?:http|https)://)?(?:www.)?youtube.com/?')) {
      url_video = url.replace("watch?v=", "embed/");
    } else if (url.match('(?:(?:http|https)://)?(?:www.)?facebook.com/?')) {
      url_video = "/fb-video.html?url=".concat(url);
    } else if (url.match('(?:(?:http|https)://)?(?:www.)?vimeo.com/?')) {
      url_video = url.replace("//vimeo.com/", "//player.vimeo.com/video/");
    }
    return url_video;
  }
});