define("livarava-web/templates/components/chat/chat-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "CuBKZNin",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"chat-message\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"chat-author-image\"],[15,5,[34,0,[\"imageStyle\"]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"chat-username\"],[12],[1,[35,0,[\"fullname\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"span\"],[14,0,\"chat-datetime\"],[12],[1,[30,[36,3],[[35,2,[\"datetime\"]],\"HH:mm\"],null]],[13],[2,\"\\n\"],[6,[37,4],[[35,2,[\"edited\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"Edited\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\\n    \"],[10,\"p\"],[14,0,\"m-b-0\"],[12],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"markdown\",\"strikethrough\",\"literalMidWordUnderscores\",\"simplifiedAutoLink\",\"openLinksInNewWindow\"],[[35,2,[\"message\"]],true,true,true,true]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"user\",\"link-to\",\"item\",\"moment-format\",\"if\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/chat/chat-message.hbs"
    }
  });
  _exports.default = _default;
});