define("livarava-web/templates/components/input-autocomplete-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Og8fJ8p8",
    "block": "{\"symbols\":[],\"statements\":[[11,\"div\"],[16,0,[31,[\"item \",[30,[36,1],[[35,0,[\"selected\"]],\"selected\"],null]]]],[16,\"title\",[31,[[34,0,[\"header\"]]]]],[4,[38,2],[[32,0],\"selectItem\",[35,0]],null],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"image\"],[15,5,[34,3]],[12],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"header\"],[12],[2,\"\\n    \"],[1,[35,0,[\"header\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"if\",\"action\",\"imageStyle\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-autocomplete-item.hbs"
    }
  });
  _exports.default = _default;
});