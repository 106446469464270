define("livarava-web/templates/components/neuron-product", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NUXjiANb",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-clock-o\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,1],[[35,0,[\"created\"]],\"YYYY-MM-DD, HH:mm Z\"],null]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,[35,0,[\"text\"]]],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"moment-format\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-product.hbs"
    }
  });
  _exports.default = _default;
});