define("livarava-web/services/me", ["exports", "fetch", "livarava-web/config/environment", "ember-local-storage", "moment", "md5"], function (_exports, _fetch, _environment, _emberLocalStorage, _moment, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    store: Ember.inject.service(),
    cookies: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Variables
    signed: Ember.computed.reads('session.signed'),
    token: Ember.computed.reads('session.token'),
    data: Ember.computed.reads('session.data'),
    id: Ember.computed.reads('session.data.id'),
    admin: Ember.computed.reads('session.data.admin'),
    email: Ember.computed.reads('session.data.email'),
    phone: Ember.computed.reads('session.data.phone'),
    firstname: Ember.computed.reads('session.data.firstname'),
    lastname: Ember.computed.reads('session.data.lastname'),
    fullname: Ember.computed.reads('session.data.fullname'),
    locale: Ember.computed.reads('session.data.locale'),
    timezone: Ember.computed.reads('session.data.timezone'),
    rights: Ember.computed.reads('session.data.rights'),
    user: Ember.computed.reads('session.user'),
    // Computed Variables
    imageStyle: Ember.computed('data.image_url', function () {
      var url = Ember.get(this, 'data.image_url');
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    write: function write(token, data) {
      var _this = this;
      console.log('Writing session data...');
      console.log('Token: ', token);
      var domain = _environment.default.api.domain;
      // const path = '/';
      var expires = (0, _moment.default)().add(1, 'y').toDate();

      // Write session token to Cookies
      this.cookies.write('_session', token, {
        domain: domain,
        // path: path,
        expires: expires
      });

      // Write token to session
      Ember.set(this, 'session.signed', true);
      Ember.set(this, 'session.token', token);
      Ember.set(this, 'session.data', data);

      // Load User
      this.store.findRecord('user', data.id).then(function (user) {
        return Ember.set(_this, 'session.user', user);
      });
    },
    clear: function clear() {
      // Clear token from Cookies
      this.cookies.clear('_session');

      // Claer token from session
      Ember.set(this, 'session.signed', false);
      Ember.set(this, 'session.token', null);
      Ember.set(this, 'session.data', null);
      Ember.set(this, 'session.user', null);
    },
    // Load
    load: function load(token) {
      var _this2 = this;
      var options;
      var sessionToken = Ember.get(this, 'session.token');
      var cookiesToken = this.cookies.read('_session');
      if (!token) token = sessionToken;
      if (!token) token = cookiesToken;
      if (token) {
        options = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        console.log(token);
      } else {
        options = {};
      }
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var time = new Date().getTime();
      var url = "".concat(host).concat(path, "/me") + "?__t=".concat(time);
      var request = (0, _fetch.default)(url, options);
      request.then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        var data = response.data;

        // Write data from remote server
        _this2.write(data.session, data);
        return data;
      }).catch(function () {
        // Clear data of session
        _this2.clear();
      });
      return request;
    },
    // Sign Up
    signUp: function signUp(firstname, lastname, phone, email, password) {
      var _this3 = this;
      var invitationId = arguments.length > 5 && arguments[5] !== undefined ? arguments[5] : null;
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var time = new Date().getTime();
      var site = _environment.default.neuronet.uid;
      var passwd = (0, _md.default)(password);
      var url = "".concat(host).concat(path, "/signup/") + "?__t=".concat(time) + "&firstname=".concat(firstname) + "&lastname=".concat(lastname) + "&phone=".concat(phone) + "&email=".concat(email) + "&password=".concat(passwd) + "&site=".concat(site);
      url += invitationId ? "&invitation=".concat(invitationId) : '';
      var request = (0, _fetch.default)(url);
      request.then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        var token = response.token;
        var data = response.data;
        _this3.write(token, data);
      }).catch(function () {
        // Do nothing
      });
      return request;
    },
    // Sign In
    signIn: function signIn(login, password) {
      var _this4 = this;
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var time = new Date().getTime();
      var site = _environment.default.neuronet.uid;
      var passwd = (0, _md.default)(password);
      var url = "".concat(host).concat(path, "/signin/") + "?__t=".concat(time) + "&login=".concat(login) + "&password=".concat(passwd) + "&site=".concat(site);
      var request = (0, _fetch.default)(url);
      request.then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        var token = response.token;
        var data = response.data;
        if (response.error) {
          // Do nothing
          return;
        }
        _this4.write(token, data);
      }).catch(function () {
        // Do nothing
      });
      return request;
    },
    // Sign Out
    signOut: function signOut() {
      var _this5 = this;
      var host = _environment.default.api.host;
      var path = _environment.default.api.path;
      var time = new Date().getTime();
      var token = this.token;
      var site = _environment.default.neuronet.uid;
      var url = "".concat(host).concat(path, "/signout/") + "?__t=".concat(time) + "&token=".concat(token) + "&site=".concat(site);
      var request = (0, _fetch.default)(url);
      request.then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function () {
        _this5.clear();
      }).catch(function () {
        // Do something
      }).finally(function () {
        // Do something
      });
      return request;
    }
  });
  _exports.default = _default;
});