define("livarava-web/templates/sites/item/users/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VKtJMnTy",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"item\"],[[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"model\",\"site/site-user-item\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/users/item.hbs"
    }
  });
  _exports.default = _default;
});