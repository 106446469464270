define("livarava-web/templates/lang/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "80F+JrKG",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"page-container\"],[12],[2,\"\\n  \"],[1,[30,[36,2],null,[[\"images\"],[[35,1]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[34,3]]],\"hasEval\":false,\"upvars\":[\"main-navigation\",\"images\",\"main-homepage\",\"common/common-footer\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/index.hbs"
    }
  });
  _exports.default = _default;
});