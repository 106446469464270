define("livarava-web/components/task-type-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    type: null,
    types: [{
      'value': 'research',
      'label': 'Research'
    }, {
      'value': 'development',
      'label': 'Development'
    }],
    actions: {
      selectType: function selectType(type) {
        this.set('type', type);
      }
    }
  });
  _exports.default = _default;
});