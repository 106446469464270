define("livarava-web/templates/components/neuroner/feed-preview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XEUYHgWr",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"alert \",[30,[36,2],[[30,[36,1],[[35,0],\"Error\"],null],\"alert-danger\"],null],\" \",[30,[36,2],[[30,[36,1],[[35,0],\"Loading...\"],null],\"\",\"alert-success\"],null]]]],[12],[2,\"\\n  \"],[10,\"h4\"],[14,0,\"card-title\"],[12],[1,[34,0]],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"card-text\"],[12],[2,[34,3]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"title\",\"eq\",\"if\",\"text\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/feed-preview.hbs"
    }
  });
  _exports.default = _default;
});