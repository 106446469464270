define("livarava-web/models/investment", ["exports", "ember-data", "livarava-web/utils/get-currency-sign"], function (_exports, _emberData, _getCurrencySign) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    timeSpent: _emberData.default.attr('number'),
    // Amount
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    currencySign: Ember.computed('currency', function () {
      return (0, _getCurrencySign.default)(this.currency);
    }),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      var status = this.status;
      return status ? status.capitalize() : null;
    }),
    // Type
    type: _emberData.default.attr('string'),
    // Views
    itemClass: Ember.computed('status', function () {
      var status = this.status;
      if (status === 'pending') {
        return 'alert-warning';
      } else if (status === 'success') {
        return 'alert-success';
      } else {
        return null;
      }
    }),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    user: _emberData.default.belongsTo('user'),
    task: _emberData.default.belongsTo('neuron'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product')
  });
  _exports.default = _default;
});