define("livarava-web/templates/components/site/site-events-list-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ybJaHd0V",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"post-list\"],[12],[2,\"\\n\"],[6,[37,8],[[35,1,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"table\"],[14,0,\"table table-striped\"],[12],[2,\"\\n      \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"tr\"],[12],[2,\"\\n          \"],[10,\"td\"],[12],[2,\"\\n            \"],[1,[30,[36,4],null,[[\"site_id\",\"item\",\"onRemove\"],[[35,3],[32,1],[35,2]]]]],[2,\"\\n          \"],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,7],null,[[\"count\",\"current\",\"pager\",\"class\"],[[35,1,[\"meta\",\"total_pages\"]],[35,0],true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"        Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info font-italic\"],[12],[2,\"No events was found yet.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n  \"],[18,2,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"page\",\"items\",\"onRemove\",\"site_id\",\"site/site-events-list-table-item\",\"-track-array\",\"each\",\"pagination-pager\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-events-list-table.hbs"
    }
  });
  _exports.default = _default;
});