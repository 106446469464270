define("livarava-web/routes/neuron/item/overview", ["exports", "livarava-web/mixins/authenticated-route-mixin", "livarava-web/mixins/anonymous-route-mixin"], function (_exports, _authenticatedRouteMixin, _anonymousRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      var neuronId = parseInt(this.paramsFor('neuron.item').id);
      return Ember.RSVP.hash({
        neuron: store.peekRecord('neuron', neuronId)
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});