define("livarava-web/components/motivation/distribution", ["exports", "lodash/transform", "lodash/values"], function (_exports, _transform2, _values2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // params
    captionLabel: '',
    columnLabel: '',
    tooltipLabel: '',
    distributionChartOptions: Ember.computed('distribution', function () {
      return this.calculateDistributionChartOptions(this.get('distribution'), this.tooltipLabel);
    }),
    distributionTotal: Ember.computed('distribution', function () {
      return (0, _values2.default)(this.distribution).reduce(function (r, v) {
        return r + v;
      }, 0);
    }),
    calculateDistributionChartOptions: function calculateDistributionChartOptions(distribution, name) {
      var data = (0, _transform2.default)(distribution, function (result, value, key) {
        result.push({
          value: value,
          name: key
        });
        return result;
      }, []);
      var result = {
        title: null,
        tooltip: {
          trigger: 'item'
        },
        legend: {
          show: false
        },
        series: [{
          name: name,
          type: 'pie',
          radius: '50%',
          data: data,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }]
      };
      return result;
    }
  });
  _exports.default = _default;
});