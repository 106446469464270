define("livarava-web/routes/docs/page", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    intl: Ember.inject.service(),
    router: Ember.inject.service(),
    // Title
    title: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var siteId = _environment.default.neuronet.site;
      var path = "/docs/".concat(params.uid, "/");
      return this.store.query('page', {
        'filter[site]': siteId,
        'filter[path]': path,
        per_page: 1,
        page: 1
      }).then(function (pages) {
        var page = Ember.get(pages, 'firstObject');
        if (!page) {
          _this.transitionTo('lang.notfound');
        }
        return store.findRecord('page', page.id);
      });
    },
    // After Model
    afterModel: function afterModel(model) {
      Ember.set(this, 'title', Ember.get(model, 'title'));
    }
  });
  _exports.default = _default;
});