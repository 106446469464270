define("livarava-web/templates/components/neuron-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "78mbyaOh",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-eye\"],[14,\"title\",\"Status\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[15,0,[31,[\"label \",[30,[36,1],[[35,0,[\"online\"]],\"label-danger\",\"label-success\"],null]]]],[12],[1,[34,2]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-clock-o\"],[14,\"title\",\"Registrations\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[1,[30,[36,3],[[35,0,[\"created\"]]],null]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-language\"],[14,\"title\",\"Language\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[1,[34,4]],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-link\"],[14,\"title\",\"Neuron count\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[1,[35,0,[\"neuron_count\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"unless\",\"getStatus\",\"moment-from-now\",\"getLanguage\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-person.hbs"
    }
  });
  _exports.default = _default;
});