define("livarava-web/models/invoice", ["exports", "ember-data", "livarava-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var DEFAULT_TITLE = 'no title';
  var _default = _emberData.default.Model.extend({
    uid: _emberData.default.attr('string'),
    seller: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    address: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    lines: _emberData.default.attr('array-of-objects'),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      return this.status.capitalize();
    }),
    // Relationships
    customer: _emberData.default.belongsTo('neuron'),
    project: _emberData.default.belongsTo('project'),
    product: _emberData.default.belongsTo('product'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Rights
    rights: _emberData.default.attr(),
    // Computed title
    title: Ember.computed('lines.@each.description', function () {
      var lines = this.lines;
      if (!(Array.isArray(lines) && lines.length)) {
        return DEFAULT_TITLE;
      }
      var line = lines.find(function (line) {
        return line && line.description;
      });
      return line && line.description || DEFAULT_TITLE;
    }),
    // URLs
    previewUrl: Ember.computed('uid', function () {
      var uid = this.uid;
      return "".concat(_environment.default.api.host, "/invoice/").concat(uid, "/preview/");
    }),
    printUrl: Ember.computed('uid', function () {
      var uid = this.uid;
      return "".concat(_environment.default.api.host, "/invoice/").concat(uid, "/pdf/");
    }),
    paymentUrl: Ember.computed('id', 'amount', 'currency', 'project.id', 'product.id', function () {
      var amount = this.amount;
      var currency = this.currency;
      var invoice_id = this.id;
      var customer_id = Ember.get(this, 'customer.id');
      var project_id = Ember.get(this, 'project.id');
      var product_id = Ember.get(this, 'product.id');
      return "".concat(_environment.default.api.host, "/payment/?amount=").concat(amount, "&currency=").concat(currency, "&invoice=").concat(invoice_id, "&customer=").concat(customer_id, "&project=").concat(project_id, "&product=").concat(product_id);
    })
  });
  _exports.default = _default;
});