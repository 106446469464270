define("livarava-web/components/navigation/navigation-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    item: null,
    // Events
    onChange: null,
    // States
    isCollapsed: false,
    isHover: false,
    isDrag: false,
    isDragOver: false,
    // Computed
    showBorder: Ember.computed('isHover', function () {
      return !this.isHover;
    }),
    // Actions
    actions: {
      collapse: function collapse() {
        this.set('isCollapsed', !this.isCollapsed);
      },
      onDragStart: function onDragStart() {
        this.set("isDrag", true);
        // console.log('onDragStart' + this.get("item.id"));
      },

      onDragEnd: function onDragEnd() {
        this.set("isDrag", false);
        // console.log('onDragEnd' + this.get("item.id"));
      }
    },

    click: function click() {
      this.send('collapse');
    },
    mouseEnter: function mouseEnter() {
      // console.log('mouseEnter', this.get('item.id'));
      this.set('isHover', true);
    },
    mouseLeave: function mouseLeave() {
      // console.log('mouseLeave', this.get('item.id'));
      this.set('isHover', false);
    }
  });
  _exports.default = _default;
});