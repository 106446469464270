define("livarava-web/routes/page/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(param, transition) {
      var _this = this;
      var page_id = this.paramsFor('page').page_id;
      return this.store.query('neuron', {
        'filter[username]': page_id,
        per_page: 1,
        page: 1
      }).then(function (data) {
        var neuron = data.get('firstObject');
        var neuronId = neuron.id;
        return Ember.RSVP.hash({
          page_id: page_id,
          neuronId: neuronId,
          neuron: neuron,
          post: _this.store.findRecord('post', param.post_id)
        });
      }).catch(function () {
        _this.transitionTo('index');
      });
    }
  });
  _exports.default = _default;
});