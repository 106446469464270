define("livarava-web/routes/lang", ["exports", "livarava-web/mixins/anonymous-route-mixin", "livarava-web/config/environment"], function (_exports, _anonymousRouteMixin, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Services
    intl: Ember.inject.service(),
    // Properties
    locale: Ember.computed.reads('intl.primaryLocale'),
    // After Model
    afterModel: function afterModel(params) {
      var allowedLocales = _environment.default.intl.allowedLocales;
      var defaultLocale = _environment.default.intl.defaultLocale;
      var computedLocale = params && params.locale && allowedLocales.indexOf(params.locale) > -1 ? params.locale : defaultLocale;
      // set(this, 'intl.primaryLocale', computedLocale);
      var intl = Ember.get(this, 'intl');
      intl.setLocale(computedLocale);
    }
  });
  _exports.default = _default;
});