define("livarava-web/templates/components/category-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+FVYkq7a",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[11,\"form\"],[4,[38,0],[[32,0],\"addCategory\"],[[\"on\"],[\"submit\"]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"b\"],[12],[2,\"Neuron\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[1,[30,[36,2],null,[[\"type\",\"value\",\"onSelect\",\"onRemove\"],[\"neuron\",[35,1],[30,[36,0],[[32,0],\"setCategory\"],null],[30,[36,0],[[32,0],\"removeCategory\"],null]]]]],[2,\"\\n\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[10,\"b\"],[12],[2,\"Language\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[1,[30,[36,4],null,[[\"value\",\"onSelect\"],[[35,3],[30,[36,0],[[32,0],\"setLanguage\"],null]]]]],[2,\"\\n\\n      \"],[10,\"br\"],[12],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"card\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"card-header\"],[12],[2,\"\\n      Actions\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"card-body\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n        \"],[11,\"a\"],[24,0,\"btn btn-light\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"cancel\"],null],[12],[2,\"Cancel\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n        \"],[10,\"button\"],[14,0,\"btn btn-success\"],[14,4,\"submit\"],[12],[2,\"\\n          Save\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"selectedCategory\",\"input-neuron\",\"selectedLanguage\",\"input-language-picker\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/category-form.hbs"
    }
  });
  _exports.default = _default;
});