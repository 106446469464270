define("livarava-web/templates/components/details-popover", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VijRKKVv",
    "block": "{\"symbols\":[\"value\",\"key\"],\"statements\":[[8,\"bs-popover\",[],[[\"@delay\",\"@triggerEvents\",\"@title\",\"@onShow\"],[\"300\",\"hover\",[30,[36,7],[[35,6],\" #\",[35,5]],null],[30,[36,8],[[32,0],\"fetchModel\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[35,2]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"div\"],[14,0,\"col-12 col-sm-6 py-1\"],[12],[2,\"\\n            \"],[10,\"div\"],[14,0,\"font-weight-bolder text-uppercase small\"],[12],[2,\"\\n              \"],[1,[32,2]],[2,\"\\n            \"],[13],[2,\"\\n            \"],[10,\"div\"],[14,0,\"text-muted\"],[12],[2,\"\\n              \"],[1,[30,[36,0],[[32,1]],[[\"length\",\"striptags\"],[60,true]]]],[2,\"\\n            \"],[13],[2,\"\\n          \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1,2]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"row my-3 justify-content-center\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-2x fa-circle-o-notch fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"truncate\",\"if\",\"jsonData\",\"-each-in\",\"each\",\"modelId\",\"modelName\",\"concat\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/details-popover.hbs"
    }
  });
  _exports.default = _default;
});