define("livarava-web/mixins/neuron-type-determinator", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    // Service
    me: Ember.inject.service(),
    // States
    isPhone: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'phone';
    }),
    isLink: Ember.computed('item.type', function () {
      var linkFlag = Ember.get(this, 'item.type') === 'link';
      var imageFlag = Ember.get(this, 'item.type') === 'image';
      return linkFlag || imageFlag;
    }),
    isEmail: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'email';
    }),
    isRSS: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'rss';
    }),
    isImage: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'image';
    }),
    isVideo: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'video';
    }),
    isYoutube: Ember.computed('item.video_type', function () {
      return Ember.get(this, 'item.video_type') === 'youtube';
    }),
    isPost: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'post';
    }),
    isJob: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'job';
    }),
    isProject: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'project';
    }),
    isProduct: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'product';
    }),
    isEvent: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'event';
    }),
    isAudio: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'audio';
    }),
    isPerson: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'person';
    }),
    isTask: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.type') === 'task';
    }),
    // Has
    hasExternalURL: Ember.computed('item.type', function () {
      return Ember.get(this, 'item.url') || ['image', 'rss', 'video', 'link'].includes(Ember.get(this, 'item.type'));
    }),
    hasSpecificInfo: Ember.computed('item.type', function () {
      return ['rss', 'post', 'project', 'product', 'event', 'job', 'person'].includes(Ember.get(this, 'item.type'));
    }),
    // Security
    canEdit: Ember.computed('me.{admin,id}', 'item.author.id', function () {
      var me = this.me;
      if (!me.data) {
        return false;
      }
      var admin = me.admin || false;
      if (admin) {
        return true;
      }
      var meId = parseInt(me.id) || null;
      if (!meId) {
        return false;
      }
      var authorId = parseInt(Ember.get(this, 'item.author.id'));
      if (!authorId) {
        return false;
      }
      return meId === authorId;
    })
  });
  _exports.default = _default;
});