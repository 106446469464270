define("livarava-web/components/input-switch", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    value: false,
    // Actions
    actions: {
      toggleValue: function toggleValue() {
        var currentValue = this.value;
        var newValue = !currentValue;
        this.set('value', newValue);
      }
    }
  });
  _exports.default = _default;
});