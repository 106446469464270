define("livarava-web/components/profile/profile-space-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Properties
    progressOptions: null,
    progressValue: Ember.computed('model.{space_total,space_used}', function () {
      var spaceTotal = Ember.get(this, 'model.space_total');
      var spaceUsed = Ember.get(this, 'model.space_used');
      return spaceUsed / spaceTotal;
    }),
    progressBarWidth: Ember.computed('progressValue', function () {
      var progressValue = parseFloat(this.progressValue);
      return parseInt(progressValue * 100);
    }),
    progressBarClass: Ember.computed('progressValue', function () {
      var progressValue = parseFloat(this.progressValue);
      var klass = '';
      if (progressValue > 0) {
        klass = 'bg-success';
      }
      if (progressValue > 0.33) {
        klass = 'bg-warning';
      }
      if (progressValue > 0.66) {
        klass = 'bg-danger';
      }
      return klass;
    }),
    progressTextClass: Ember.computed('progressValue', function () {
      var progressValue = parseFloat(this.progressValue);
      var klass = '';
      if (progressValue > 0) {
        klass = 'text-success';
      }
      if (progressValue > 0.33) {
        klass = 'text-warning';
      }
      if (progressValue > 0.66) {
        klass = 'text-danger';
      }
      return klass;
    })
  });
  _exports.default = _default;
});