define("livarava-web/controllers/sites/item/categories/index", ["exports", "fetch", "livarava-web/config/environment", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _environment, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'language'],
    page: 1,
    per_page: 100,
    language: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'language',
      query: 'language',
      type: 'language',
      placeholder: 'Language',
      options: _environment.default.intl.localeLabels
    }]),
    // Actions
    actions: {
      addSiteCategory: function addSiteCategory(category) {
        var _this = this;
        var site_id = parseInt(Ember.get(this, 'model.site.id'));
        var category_id = category.id;
        var url = (0, _getApiUrl.default)('add-site-category', {
          site_id: site_id,
          category_id: category_id
        });
        var token = Ember.get(this, 'me.token');
        (0, _fetch.default)(url, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function () {
          _this.send('reload');
        });
      },
      removeSiteCategory: function removeSiteCategory(category) {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          var site_id = parseInt(Ember.get(this, 'model.site.id'));
          var category_id = category.id;
          var url = (0, _getApiUrl.default)('remove-site-category', {
            site_id: site_id,
            category_id: category_id
          });
          var token = Ember.get(this, 'me.token');
          var params = {
            headers: {
              'X-LivaRava-Token': token
            }
          };
          (0, _fetch.default)(url, params).then(function () {
            _this2.send('reload');
          });
        }
      },
      saveSiteCategory: function saveSiteCategory() {
        // Do nothing
      }
    }
  });
  _exports.default = _default;
});