define("livarava-web/routes/navigations/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    // Model
    model: function model(param) {
      return this.store.findRecord('navigation', param.id);
    },
    // Actions
    actions: {
      save: function save() {
        this.controller.model.save();
      },
      delete: function _delete() {
        var _this = this;
        if (confirm("Are you sure?")) {
          this.controller.model.destroyRecord().then(function () {
            return _this.transitionTo('navigations');
          });
        }
      }
    }
  });
  _exports.default = _default;
});