define("livarava-web/components/axon-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    q: null,
    type: null,
    display: null,
    axons: null,
    page: 1,
    // States
    isLoading: false,
    isFiltersExpanded: false,
    // Events
    onPageChanged: null,
    onSetFilter: null,
    onReset: null,
    // Actions
    actions: {
      setFilter: function setFilter(name, value) {
        this.set(name, value || null);
        this.onSetFilter(name, value);
      },
      pageChanged: function pageChanged(current) {
        this.set('page', current);
        this.onPageChanged(current);
      },
      reset: function reset() {
        this.set('page', 1);
        this.set('q', null);
        this.set('type', null);
        this.set('display', null);
        this.set('author', null);
      },
      search: function search() {
        // Do nothing
      }
    }
  });
  _exports.default = _default;
});