define("livarava-web/templates/components/site-categories-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LmzzYrVY",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[1,[35,0,[\"title\"]]],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"pull-right\"],[12],[2,\"\\n\\n      \"],[11,\"button\"],[24,0,\"btn btn-sm btn-danger\"],[4,[38,1],[[32,0],\"remove\",[35,0]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-remove\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n\"],[6,[37,2],null,[[\"class\",\"route\",\"model\"],[\"btn btn-sm btn-secondary\",\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        Neuron\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"action\",\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site-categories-item.hbs"
    }
  });
  _exports.default = _default;
});