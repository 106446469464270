define("livarava-web/routes/sites/item/categories/create", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Config
    languages: _environment.default.intl.localeLabels,
    // Model
    model: function model(params) {
      //noinspection UnnecessaryLocalVariableJS
      var site_id = parseInt(this.paramsFor('sites.item').site_id);
      params['filter[site]'] = site_id;
      //noinspection JSUnresolvedFunction,JSUnresolvedVariable,JSValidateTypes
      return this.store.peekRecord('site', site_id);
    }
  });
  _exports.default = _default;
});