define("livarava-web/components/signup-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function validatePhone(phone) {
    var re = /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im;
    return re.test(phone);
  }
  function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }
  function capitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    router: Ember.inject.service(),
    // Error
    error: null,
    // Flags
    isLoading: false,
    // Properties
    invitation: null,
    firstname: null,
    lastname: null,
    phone: null,
    email: null,
    password: null,
    passwordConfirm: null,
    // Debug
    // invitationId: null,
    // firstname: 'John',
    // lastname: 'Doe',
    // phone: '+380503214075',
    // email: 'john.doe@livarava.com',
    // password: '123456',
    // passwordConfirm: '123456',

    // Validation
    phoneValidationError: Ember.computed('phone', function () {
      var phone = this.phone;
      if (!phone) return null;
      if (!validatePhone(phone)) return 'Please provide a valid phone number';
      return null;
    }),
    emailValidationError: Ember.computed('email', 'error', function () {
      var email = this.email;
      var error = this.error;
      if (!email) return null;
      if (!validateEmail(email)) return 'Please provide a valid email address';
      if (error === 409) return 'User already exists';
      return null;
    }),
    // Classes
    firstnameClass: Ember.computed('firstname', function () {
      var firstname = this.firstname;
      return !firstname ? 'form-control text-capitalize' : 'form-control text-capitalize is-valid';
    }),
    lastnameClass: Ember.computed('lastname', function () {
      var lastname = this.lastname;
      return !lastname ? 'form-control text-capitalize' : 'form-control text-capitalize is-valid';
    }),
    phoneClass: Ember.computed('phone', function () {
      var phone = this.phone;
      if (!phone) return 'form-control';
      if (!validatePhone(phone)) return 'form-control is-invalid';
      return 'form-control is-valid';
    }),
    emailClass: Ember.computed('email', 'error', function () {
      var email = this.email;
      var error = this.error;
      if (!email) return 'form-control';
      if (!validateEmail(email)) return 'form-control is-invalid';
      if (error === 409) return 'form-control is-invalid';
      return 'form-control is-valid';
    }),
    passwordClass: Ember.computed('password', 'passwordConfirm', function () {
      var password = this.password;
      var passwordConfirm = this.passwordConfirm;
      if (!password || !passwordConfirm) return 'form-control';
      if (password !== passwordConfirm) return 'form-control is-invalid';
      return 'form-control is-valid';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);

      // Read params
      var invitationId = parseInt(Ember.get(this, 'model.invitation.id'));
      var email = Ember.get(this, 'model.invitation.email');
      var siteId = parseInt(Ember.get(this, 'model.site'));
      var nextUrl = Ember.get(this, 'model.next');

      // Set params
      this.set('invitationId', invitationId);
      this.set('email', email);
      this.set('siteId', siteId);
      this.set('nextUrl', nextUrl);
    },
    // Actions
    actions: {
      signup: function signup() {
        var _this = this;
        var me = this.me;
        var router = this.router;
        var firstname = capitalize(this.firstname);
        var lastname = capitalize(this.lastname);
        var phone = this.phone;
        var email = this.email.toLowerCase();
        var password = this.password;
        var invitationId = this.invitationId;
        var nextUrl = this.nextUrl;
        this.set('error', null);
        this.set('isLoading', true);
        var result = me.signUp(firstname, lastname, phone, email, password, invitationId);
        result.then(function (data) {
          _this.set('isLoading', false);
          var error = data.error;
          if (error) {
            _this.set('error', error);
          } else {
            _this.set('isLoading', true);
            _this.set('error', null);
            me.signIn(email, password).then(function (response) {
              _this.set('isLoading', false);
              if (nextUrl) {
                // Redirect to next URL
                var redirectUrl = "".concat(nextUrl, "?token=").concat(me.token);
                window.location.href = redirectUrl;
              } else {
                router.transitionTo('index');
              }
            }).catch(function (response) {
              _this.set('isLoading', false);
              _this.set('error', response.error || 'Login error. Please, report to admin@livarava.com');
            });
          }
        }).catch(function (error) {
          _this.set('isLoading', false);
          _this.set('error', 'Unknown error. Please, contact us via email admin@livarava.com');
        });
      }
    }
  });
  _exports.default = _default;
});