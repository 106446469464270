define("livarava-web/routes/payouts/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      },
      project: {
        refreshModel: true
      },
      product: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      currency: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      return Ember.RSVP.hash({
        items: this.store.query('payout', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});