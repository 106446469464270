define("livarava-web/components/review/review-table", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    item: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchReviews();
    },
    fetchReviews: function fetchReviews() {
      var _this$store$query,
        _this = this;
      this.set('loadingReviews', true);
      return this.store.query('review', (_this$store$query = {}, (0, _defineProperty2.default)(_this$store$query, "filter[".concat(this.get('item.type'), "]"), this.get('item.id')), (0, _defineProperty2.default)(_this$store$query, "per_page", 100), (0, _defineProperty2.default)(_this$store$query, "page", 1), _this$store$query)).then(function (reviews) {
        _this.set('reviews', reviews);
        _this.set('loadingReviews', false);
      }).catch(function () {
        _this.set('loadingReviews', false);
      });
    },
    actions: {}
  });
  _exports.default = _default;
});