define("livarava-web/routes/settings/profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Profile',
    // Model
    model: function model() {
      var store = this.store;
      var id = Ember.get(this, 'me.id');
      return store.findRecord('user', id);
    }
  });
  _exports.default = _default;
});