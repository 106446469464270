define("livarava-web/templates/components/common/common-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LV0hAXjA",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,5],null,[[\"action\"],[[30,[36,1],[[32,0],\"save\"],null]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,4],null,[[\"type\",\"value\",\"focus-out\",\"autofocus\"],[\"text\",[35,0],[30,[36,1],[[32,0],\"save\"],null],\"true\"]]]],[2,\"\\n\\n    \"],[11,\"span\"],[24,0,\"btn-link ml-2\"],[24,\"role\",\"button\"],[24,\"title\",\"Save\"],[4,[38,1],[[32,0],\"save\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-remove\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,3],[[35,2,[\"isSaving\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"span\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[11,\"span\"],[24,0,\"btn-link ml-2\"],[24,\"role\",\"button\"],[24,\"title\",\"Edit\"],[4,[38,1],[[32,0],\"edit\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"value\",\"action\",\"item\",\"if\",\"input\",\"click-outside\",\"showInput\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-editable.hbs"
    }
  });
  _exports.default = _default;
});