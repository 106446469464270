define("livarava-web/components/blog/blog-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Properties
    model: null,
    page: 1,
    adsenseName: Ember.computed('model.id', 'page', function () {
      var id = Ember.get(this, 'model.id');
      var page = this.page;
      return "event-item-".concat(id, "-aside-adsense-page-").concat(page);
    }),
    // Security
    canEdit: Ember.computed('model.author.id', 'me.id', 'me.admin', function () {
      var authorId = parseInt(Ember.get(this, 'model.author.id'));
      var meId = parseInt(Ember.get(this, 'me.id'));
      var adminFlag = Ember.get(this, 'me.admin');
      return authorId > 0 && meId > 0 && authorId === meId || adminFlag;
    })
  });
  _exports.default = _default;
});