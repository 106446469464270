define("livarava-web/routes/neuroner/feeds/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(param) {
      return this.store.findRecord('neuroner.feed', param.id);
    },
    // Actions
    actions: {
      delete: function _delete(model) {
        var _this = this;
        var isDelete = confirm("Are you sure to delete?");
        if (isDelete) {
          model.destroyRecord().then(function () {
            return _this.transitionTo('neuroner.feeds');
          });
        }
      },
      save: function save(model) {
        var _this2 = this;
        model.save().then(function () {
          return _this2.transitionTo('neuroner.feeds');
        });
      }
    }
  });
  _exports.default = _default;
});