define("livarava-web/routes/invoices/create", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment", "livarava-web/components/invoice/invoice-form", "livarava-web/helpers/full-address"], function (_exports, _objectSpread2, _moment, _invoiceForm, _fullAddress) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'New Invoice',
    // Query Params
    queryParams: {
      copy: {
        reloadModel: true
      },
      customer: {
        reloadModel: true
      },
      customer_type: {
        reloadModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var copyId = params.copy;
      var customerId = params.customer;
      var customerType = params.customer_type;
      var created = (0, _moment.default)();
      var item;
      if (copyId) {
        item = store.findRecord('invoice', copyId).then(function (data) {
          return store.createRecord('invoice', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.getProperties('seller', 'fullname', 'address', 'email', 'phone', 'amount', 'currency', 'lines', 'customer', 'project', 'product')), {}, {
            created: created
          }));
        });
      } else if (customerId) {
        return Ember.RSVP.hash({
          customerNeuron: store.findRecord('neuron', customerId).catch(function () {
            return null;
          }),
          customer: customerType ? store.findRecord(customerType, customerId).catch(function () {
            return null;
          }) : null
        }).then(function (_ref) {
          var customerNeuron = _ref.customerNeuron,
            customer = _ref.customer;
          var customerData = {
            fullname: customerNeuron.get('header')
          };
          if (customer) {
            Object.assign(customerData, customer.getProperties('email', 'phone'), {
              address: _fullAddress.default.compute([customer], {
                showCity: true
              })
            });
          }
          return store.createRecord('invoice', Object.assign({
            created: created,
            seller: _invoiceForm.SELLERS[0].value,
            customer: customerNeuron,
            address: '',
            email: '',
            phone: '',
            amount: '',
            currency: 'USD',
            lines: Ember.A([Ember.Object.create({
              description: '',
              price: null
            })]),
            project: null,
            product: null
          }, customerData));
        });
      } else {
        item = store.createRecord('invoice', {
          created: created,
          seller: _invoiceForm.SELLERS[0].value,
          customer: '',
          address: '',
          email: '',
          phone: '',
          amount: '',
          currency: 'USD',
          lines: Ember.A([Ember.Object.create({
            description: '',
            price: null
          })]),
          project: null,
          product: null
        });
      }
      return item;
    },
    // Actions
    actions: {},
    resetController: function resetController(controller, isExiting /*transition*/) {
      if (isExiting) {
        // isExiting would be false if only the route's model was changing
        controller.set('copy', null);
        controller.set('customer', null);
        controller.set('customer_type', null);
      }
    }
  });
  _exports.default = _default;
});