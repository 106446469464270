define("livarava-web/routes/contacts/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      var contactId = params.id;
      return store.findRecord('contact', contactId).then(function (contact) {
        return store.query('user', {
          'filter[email]': contact.get('email'),
          per_page: 1,
          page: 1
        }).then(function (users) {
          var user = null;
          if (users.get('length') > 0) {
            user = users.get('firstObject');
          }
          return Ember.RSVP.hash({
            contact: contact,
            user: user
          });
        });
      });
    }
  });
  _exports.default = _default;
});