define("livarava-web/components/profile-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sectionRoutes: Ember.computed('sections.[]', function () {
      var sections = this.sections;
      if (!sections) {
        return null;
      }
      sections.filter(function (obj) {
        return ["about", "products", "projects"].includes(obj["name"]);
      }).map(function (obj) {
        var title = obj["title"];
        var name = obj["name"];
        var route = "profile.".concat(name);
        return new Object({
          title: title,
          route: route
        });
      });
    })
  });
  _exports.default = _default;
});