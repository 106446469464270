define("livarava-web/templates/neuroner/settings/status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kA2V98YZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row mb-1\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-10\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Status\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-2 text-right\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-sm btn-primary\"],[4,[38,1],[[32,0],\"reload\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-refresh\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,2],[[35,0,[\"error\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"b\"],[12],[2,\"Neuroner\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-circle text-danger\"],[12],[13],[2,\"\\n    \"],[1,[35,0,[\"error\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"b\"],[12],[2,\"Neuroner\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-circle text-success\"],[12],[13],[2,\"\\n    \"],[1,[35,0,[\"message\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"b\"],[12],[2,\"Last workcycle time\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[1,[35,0,[\"time\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[1,[30,[36,4],[[30,[36,3],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"action\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuroner/settings/status.hbs"
    }
  });
  _exports.default = _default;
});