define("livarava-web/templates/components/infinite-scroll", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7YrGWwlW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"crossedTheLine\",\"triggerDistance\",\"scrollContainer\"],[\"performInfinite\",[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"scrollContainer\",\"triggerDistance\",\"ember-ic-you\",\"infiniteScrollAvailable\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/infinite-scroll.hbs"
    }
  });
  _exports.default = _default;
});