define("livarava-web/routes/products/item/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'About',
    // Model
    model: function model(params) {
      var product_id = parseInt(this.paramsFor('products.item').product_id);
      return this.store.peekRecord('product', product_id);
    },
    // Actions
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});