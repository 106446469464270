define("livarava-web/routes/products/item/tasks", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Tasks',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      author: {
        refreshModel: true
      },
      executor: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      task_type: {
        refreshModel: true
      },
      currency: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var id = parseInt(this.paramsFor('products.item').product_id);
      params['product'] = id;
      return Ember.RSVP.hash({
        items: store.query('task', (0, _getFiltersFromParams.default)(params)),
        product: store.findRecord('product', id)
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});