define("livarava-web/routes/users/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'Users',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      return Ember.RSVP.hash({
        items: this.store.query('user', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reloadUsers: function reloadUsers() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});