define("livarava-web/templates/sites/item/navigations/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+4WS4PRc",
    "block": "{\"symbols\":[],\"statements\":[[3,\"TODO: Refactor to site/site-navigations \"],[2,\"\\n\"],[1,[30,[36,3],null,[[\"site_id\",\"model\",\"controller\",\"language\",\"page\"],[[35,2,[\"site_id\"]],[35,2],[32,0],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"page\",\"language\",\"model\",\"site-navigations\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/navigations/index.hbs"
    }
  });
  _exports.default = _default;
});