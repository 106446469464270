define("livarava-web/templates/my-motivation/orders-count-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "XbV8Iajs",
    "block": "{\"symbols\":[],\"statements\":[[8,\"motivation/orders-count-progress\",[],[[\"@manager\",\"@readonlyManager\"],[[34,0,[\"user\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"me\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/my-motivation/orders-count-progress.hbs"
    }
  });
  _exports.default = _default;
});