define("livarava-web/models/site", ["exports", "ember-data", "livarava-web/utils/is-url"], function (_exports, _emberData, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Type
    type: 'site',
    // Attributes
    title: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    username: _emberData.default.attr('string'),
    feedback_email: _emberData.default.attr('string'),
    api_key: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Header
    header: Ember.computed('title', function () {
      return this.title;
    }),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon', 'icon_url', function () {
      var url = this.icon_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe(url);
    }),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed('image.image_url', 'image_url', function () {
      var image_url = this.image_url;
      var image_image_url = Ember.get(this, 'image.image_url');
      if (image_url && (0, _isUrl.default)(image_url)) {
        return image_url;
      }
      if (image_image_url && (0, _isUrl.default)(image_image_url)) {
        return image_image_url;
      }
      return null;
    }),
    imageStyle: Ember.computed('imageUrl', function () {
      var url = this.imageUrl;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string'),
    // Open Graph
    og_title: _emberData.default.attr('string'),
    og_type: _emberData.default.attr('string'),
    og_image: _emberData.default.belongsTo('neuron'),
    og_image_url: _emberData.default.attr('string'),
    og_url: _emberData.default.attr('string'),
    // Other
    sections: _emberData.default.attr(),
    // Billing
    plan: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    billing_fullname: _emberData.default.attr('string'),
    billing_address: _emberData.default.attr('string'),
    billing_email: _emberData.default.attr('string'),
    billing_phone: _emberData.default.attr('string'),
    balance: _emberData.default.attr('plain-object'),
    space_total: _emberData.default.attr('string'),
    space_used: _emberData.default.attr('string'),
    space_left: _emberData.default.attr('string'),
    space_calculating: _emberData.default.attr('boolean')
  });
  _exports.default = _default;
});