define("livarava-web/routes/index", ["exports", "livarava-web/mixins/anonymous-route-mixin", "ember-local-storage"], function (_exports, _anonymousRouteMixin, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Service
    intl: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Computed
    locale: Ember.computed.reads('intl.primaryLocale'),
    // Actions
    actions: {
      didTransition: function didTransition() {
        var isSigned = Ember.get(this, 'session.signed');
        var locale = this.locale;
        if (isSigned) {
          this.transitionTo('dashboard');
        } else {
          // this.transitionTo('homepage');
          this.transitionTo('lang.page', locale, 'homepage');
        }
      }
    }
  });
  _exports.default = _default;
});