define("livarava-web/routes/settings/payments/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var me = this.me;
      return me.load().then(function (data) {
        var meId = parseInt(data.data.id);
        params['author'] = meId;
        return Ember.RSVP.hash({
          payments: _this.store.query('payment', (0, _getFiltersFromParams.default)(params))
        });
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});