define("livarava-web/components/navigation/navigation-form", ["exports", "lodash/pick", "lodash/omit", "lodash/assign"], function (_exports, _pick2, _omit2, _assign2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {},
    // Actions
    actions: {
      change: function change(property, value) {
        this.set(property, value);
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      setSite: function setSite(item) {
        var _this = this;
        if (item === null) {
          this.set('model.site', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          _this.set('model.site', item);
        });
      },
      setParent: function setParent(item) {
        this.store.pushPayload({
          data: (0, _assign2.default)((0, _pick2.default)(item, 'id'), {
            type: 'navigations',
            attributes: (0, _omit2.default)(item, 'id')
          })
        });
        this.model.set('parent', this.store.peekRecord('navigation', item.id));
      },
      removeParent: function removeParent() {
        this.set('model.parent', null);
        this.set('model.level', 0);
        this.set('model.order', -10);
      }
    }
  });
  _exports.default = _default;
});