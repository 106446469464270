define("livarava-web/components/task-widget", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Properties
    userId: null,
    items: null,
    loading: false,
    error: null,
    tab: 1,
    // Functions
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.fetchItems();
    },
    getParams: function getParams() {
      var params = {};
      switch (this.tab) {
        case 0:
          params = {
            filter: {
              author: this.userId,
              status: 'opened,in_progress'
            }
          };
          break;
        case 1:
          params = {
            filter: {
              executant: this.userId,
              status: 'in_progress'
            }
          };
          break;
        case 2:
          params = {
            filter: {
              status: 'opened'
            }
          };
          break;
      }
      return params;
    },
    fetchItems: function fetchItems() {
      var _this = this;
      if (!this.store) {
        return;
      }
      var params = this.getParams();
      this.set('error', null);
      this.set('loading', true);
      this.store.query('task', params).then(function (tasks) {
        return _this.set('items', tasks);
      }).catch(function (error) {
        return _this.set('error', error);
      }).finally(function () {
        return _this.set('loading', false);
      });
    },
    // Actions
    actions: {
      setTab: function setTab() {
        var tab = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
        this.set('tab', tab);
        this.fetchItems();
      },
      refresh: function refresh() {
        this.fetchItems();
      }
    }
  });
  _exports.default = _default;
});