define("livarava-web/controllers/sites/item/members", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      setSiteRole: function setSiteRole(user, role) {
        var _this = this;
        var site_id = Ember.get(this, 'model.site_id');
        var user_id = user.id;
        role = role.toLowerCase();
        var url = (0, _getApiUrl.default)('set-site-role', {
          site_id: site_id,
          user_id: user_id,
          role: role
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          _this.send('reload');
        });
      },
      removeSiteRole: function removeSiteRole(user, role) {
        var _this2 = this;
        if (confirm('Are you sure?')) {
          role = role.toLowerCase();
          var site_id = Ember.get(this, 'model.site_id');
          var user_id = user.id;
          var url = (0, _getApiUrl.default)('remove-site-role', {
            site_id: site_id,
            user_id: user_id,
            role: role
          });
          var token = Ember.get(this, 'me.token');
          var params = {
            headers: {
              'X-LivaRava-Token': token
            }
          };
          (0, _fetch.default)(url, params).then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error();
            }
          }).then(function () {
            _this2.send('reload');
          });
        }
      }
    }
  });
  _exports.default = _default;
});