define("livarava-web/helpers/format-number", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.formatNumber = formatNumber;
  function formatNumber(params, hash) {
    var _ref = hash || {},
      format = _ref.format;
    var number = params;
    if (Ember.isArray(params)) {
      number = params[0];
    }
    if (typeof number === 'undefined') {
      number = null;
    }
    if (isNaN(number)) {
      number = null;
    }

    /*global numeral*/
    return numeral(number).format(format);
  }
  var _default = Ember.Helper.helper(formatNumber);
  _exports.default = _default;
});