define("livarava-web/templates/framework", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "HCLgXuuN",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"class\"],[\"common-dark\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Framework\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"neuroner\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center mb-4\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/img/logo/logo-framework-400x170-hor.jpg\"],[14,0,\"img-fluid\"],[14,\"alt\",\"LivaRava Framework\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-10\"],[12],[2,\"\\n\\n          \"],[2,[35,1,[\"description\"]]],[2,\"\\n\\n          \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n          \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"Виникли питання? \"],[6,[37,0],null,[[\"route\"],[\"contact\"]],[[\"default\"],[{\"statements\":[[2,\"Пишіть нам\"]],\"parameters\":[]}]]],[2,\".\"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/framework.hbs"
    }
  });
  _exports.default = _default;
});