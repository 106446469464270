define("livarava-web/components/axon-list-item", ["exports", "livarava-web/mixins/neuron-type-determinator"], function (_exports, _neuronTypeDeterminator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Services
    me: Ember.inject.service(),
    // Attributes
    axon: null,
    // Share Modal
    shareAxonRecipients: null,
    shareAxonMessage: null,
    showShareAxonModal: false,
    showShareAxonMessageInput: false,
    // Events
    onDeleteAxon: null,
    // Flags
    hasId: Ember.computed('neuron.id', function () {
      return !isNaN(Ember.get(this, 'neuron.id'));
    }),
    canManageAxon: Ember.computed('me.id', 'axon.author.id', function () {
      var meId = parseInt(Ember.get(this, 'me.id'));
      var authorId = parseInt(Ember.get(this, 'axon.author.id'));
      return meId && authorId && meId === authorId;
    }),
    // Actions
    actions: {
      closeDropdownMenu: function closeDropdownMenu() {
        Ember.set(this, 'showDropdownMenu', false);
      },
      deleteAxon: function deleteAxon() {
        var axon = this.axon;
        this.onDeleteAxon(axon);
      },
      shareAxon: function shareAxon() {
        Ember.set(this, 'showDropdownMenu', false);
        Ember.set(this, 'showShareAxonModal', true);
        Ember.set(this, 'showShareAxonMessageInput', true);
        Ember.set(this, 'shareAxonRecipients', Ember.A());
        Ember.set(this, 'shareAxonMessage', null);
      }
    }
  });
  _exports.default = _default;
});