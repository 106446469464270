define("livarava-web/components/user/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    size: 40,
    // Computed
    style: Ember.computed('item', 'item.image_url', function () {
      var item = this.item;
      if (item === null) {
        return Ember.String.htmlSafe("background-image: none;");
      }
      var imageUrl = Ember.get(this, 'item.image_url');
      var size = this.size;
      var radius = parseInt(size / 2);
      if (imageUrl) {
        return Ember.String.htmlSafe("background-image: url(".concat(imageUrl, "); width: ").concat(size, "px; height: ").concat(size, "px; border-radius: ").concat(radius, "px;"));
      } else {
        return Ember.String.htmlSafe("background-image: none;");
      }
    })
  });
  _exports.default = _default;
});