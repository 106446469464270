define("livarava-web/components/neuron-rss", ["exports", "lodash/without", "livarava-web/mixins/show-more-list"], function (_exports, _without2, _showMoreList) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_showMoreList.default, {
    filter: '',
    actions: {
      onNeuronIt: function onNeuronIt(item) {
        this.set('model.feed', (0, _without2.default)(this.get('model.feed'), item));
      }
    },
    items: Ember.computed('filter', function () {
      var _this = this;
      return this.get('model.feed').filter(function (item) {
        return item.get('title').toLowerCase().includes(_this.filter.toLowerCase()) || item.get('text').toLowerCase().includes(_this.filter.toLowerCase());
      });
    })
  });
  _exports.default = _default;
});