define("livarava-web/templates/components/input-display-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "VOFvlwri",
    "block": "{\"symbols\":[\"option\"],\"statements\":[[10,\"div\"],[15,0,[31,[\"dropdown \",[30,[36,5],[[35,4],\"opened\",\"closed\"],null]]]],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-light\"],[24,4,\"button\"],[4,[38,0],[[32,0],[30,[36,6],[\"showDropdown\",[32,0]],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[15,0,[31,[[34,7]]]],[12],[13],[2,\"\\n    \"],[1,[34,8]],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-caret-down\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"dropdown-content\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"a\"],[24,0,\"dropdown-item\"],[24,6,\"#\"],[4,[38,0],[[32,0],\"setValue\",[32,1]],null],[12],[2,\"\\n          \"],[10,\"i\"],[15,0,[31,[[32,1,[\"icon\"]]]]],[12],[13],[2,\"\\n          \"],[1,[32,1,[\"label\"]]],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"options\",\"-track-array\",\"each\",\"showDropdown\",\"if\",\"toggle\",\"icon\",\"label\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-display-picker.hbs"
    }
  });
  _exports.default = _default;
});