define("livarava-web/components/neuron-rss-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    expanded: false,
    filter: '',
    classNames: ['media'],
    title: Ember.computed('filter', function () {
      return this.getComputedString('title');
    }),
    text: Ember.computed('filter', function () {
      return this.getComputedString('text');
    }),
    externalURL: Ember.computed('item.url', function () {
      var url = this.get('item.url');
      return url;
    }),
    getComputedString: function getComputedString() {
      var property = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'title';
      var regexp = new RegExp(this.filter, 'i');
      return this.get("item.".concat(property)).replace(regexp, "<span class=\"bg-warning\">$&</span>");
    }
  });
  _exports.default = _default;
});