define("livarava-web/components/site/site-seo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      save: function save() {
        this.save(this.model);
      }
    }
  });
  _exports.default = _default;
});