define("livarava-web/routes/page", ["exports", "livarava-web/mixins/anonymous-route-mixin"], function (_exports, _anonymousRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Title
    titleToken: Ember.computed.reads('model.neuron.title'),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var page_id = parseInt(params.page_id);
      return store.query('neuron', {
        'filter[username]': page_id,
        per_page: 1,
        page: 1
      }).then(function (data) {
        var neuron = Ember.get(data, 'firstObject');
        var neuronId = parseInt(Ember.get(neuron, 'id'));
        var type = Ember.get(neuron, 'type');
        console.log(type);
        switch (type) {
          case 'site':
            return store.query('post', {
              lira: neuronId,
              per_page: 6,
              page: params.page,
              'filter[site]': neuronId,
              'filter[display]': 'public',
              'filter[featured]': true
            }).then(function (posts) {
              return Ember.RSVP.hash({
                page_id: page_id,
                neuron: neuron,
                posts: posts
              });
            });
          case 'person':
            return store.query('post', {
              lira: neuronId,
              author: neuronId,
              per_page: 6,
              page: params.page,
              'filter[display]': 'public',
              'filter[featured]': true
            }).then(function (posts) {
              return Ember.RSVP.hash({
                page_id: page_id,
                neuron: neuron,
                posts: posts
              });
            });
          default:
            _this.transitionTo('index');
        }
      }).catch(function () {
        _this.transitionTo('index');
      });
    }
  });
  _exports.default = _default;
});