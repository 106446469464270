define("livarava-web/utils/get-task-type-icon-class", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTaskTypeIconClass;
  function getTaskTypeIconClass(type) {
    var out = null;
    _environment.default.options.taskType.forEach(function (option) {
      if (option.value === type) {
        out = option.icon;
      }
    });
    return out;
  }
});