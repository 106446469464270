define("livarava-web/templates/events/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9o9wPElR",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,7],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,5],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,5],null,[[\"route\"],[\"events\"]],[[\"default\"],[{\"statements\":[[2,\"Events\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[6,[37,6],null,[[\"item\",\"tickets\"],[[35,1],[35,1,[\"tickets\"]]]],[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"comments mb-3\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[10,\"b\"],[12],[2,\"Comments\"],[13],[13],[2,\"\\n\\n      \"],[1,[30,[36,2],null,[[\"item\",\"autofocus\",\"onNewComment\"],[[35,1],false,[30,[36,0],[[32,0],\"addComment\"],null]]]]],[2,\"\\n\\n      \"],[1,[30,[36,4],null,[[\"item\",\"items\",\"onSaveComment\",\"onDeleteComment\"],[[35,1],[35,3],[30,[36,0],[[32,0],\"saveComment\"],null],[30,[36,0],[[32,0],\"deleteComment\"],null]]]]],[2,\"\\n\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"item\",\"comment/comment-form\",\"comments\",\"comment/comment-list\",\"link-to\",\"event/event-item\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/item.hbs"
    }
  });
  _exports.default = _default;
});