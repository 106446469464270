define("livarava-web/templates/components/site/site-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/KwfA+wn",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-0\"],[12],[2,\"\\n      Posts \"],[10,\"span\"],[14,0,\"count-info\"],[12],[1,[35,2,[\"items\",\"meta\",\"total_results\"]]],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-9\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,\"role\",\"button\"],[16,0,[31,[\"btn \",[30,[36,4],[[35,3],\"btn-secondary\",\"btn-light\"],null]]]],[4,[38,6],[[32,0],[30,[36,5],[\"isFiltersExpanded\",[32,0]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-search\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\"],[6,[37,7],null,[[\"class\",\"route\",\"model\"],[\"btn btn-primary\",\"sites.item.posts.create\",[35,2,[\"site_id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-plus\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" New Post\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"controller\",\"filters\"],[[35,0],[35,0,[\"filters\"]]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"hr\"],[14,0,\"mb-3\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,10],null,[[\"site_id\",\"items\",\"page\",\"onRemove\"],[[35,2,[\"site_id\"]],[35,2,[\"items\"]],[35,9],[35,8]]]]]],\"hasEval\":false,\"upvars\":[\"controller\",\"common/common-filters\",\"model\",\"isFiltersExpanded\",\"if\",\"toggle\",\"action\",\"link-to\",\"onRemove\",\"page\",\"site/site-posts-list-table\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-posts.hbs"
    }
  });
  _exports.default = _default;
});