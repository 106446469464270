define("livarava-web/components/task/task-status", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    icon: Ember.computed('item.status', function () {
      var out;
      var status = Ember.get(this, 'item.status');
      var options = _environment.default.options.task.status;
      options.forEach(function (option) {
        if (option.value === status) {
          out = option.icon;
        }
      });
      return out;
    }),
    label: Ember.computed.reads('item.statusLabel')
  });
  _exports.default = _default;
});