define("livarava-web/components/members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    defaultRole: "Employee",
    actions: {
      onNewUserChange: function onNewUserChange(user) {
        var result = confirm(this.intl.t("modal.confirm"));
        if (!result) return;
        this.onAddUser(user, this.defaultRole);
        this.set("showNewMemberForm", false);
      }
    }
  });
  _exports.default = _default;
});