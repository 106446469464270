define("livarava-web/components/navigation/navigation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    items: null,
    site_id: null,
    isDirty: false,
    isSaving: false,
    count: 0,
    init: function init() {
      this._super.apply(this, arguments);
      var order = 10;
      var items = this.items;
      items.forEach(function (item) {
        item.set('order', order);
        order = order + 10;
      });
      var itemsSorted = items.sortBy('order');
      this.set('items', itemsSorted);
    },
    actions: {
      reorder: function reorder() {
        var items = this.items;
        var itemsSorted = items.sortBy('order');
        var order = 10;
        itemsSorted.forEach(function (item) {
          item.set('order', order);
          order = order + 10;
        });
        this.set('items', itemsSorted);
        this.set('isDirty', true);
      },
      save: function save() {
        this.set('count', 0);
        this.set('isSaving', true);
        var items = this.items;
        items.forEach(function (item) {
          item.save();
        });
        this.set('isSaving', false);
        this.set('isDirty', false);
      }
    }
  });
  _exports.default = _default;
});