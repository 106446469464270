define("livarava-web/storages/session", ["exports", "ember-local-storage/local/object"], function (_exports, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Storage = _object.default.extend();

  // Uncomment if you would like to set initialState
  Storage.reopenClass({
    initialState: function initialState() {
      return {
        signed: false,
        token: null,
        data: null,
        user: null
      };
    }
  });
  var _default = Storage;
  _exports.default = _default;
});