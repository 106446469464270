define("livarava-web/routes/dashboard", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Dashboard',
    // Model
    model: function model() {
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      return Ember.RSVP.hash({
        user: store.findRecord('user', meId)
      });
    }
  });
  _exports.default = _default;
});