define("livarava-web/templates/components/neuron/neuron-axons-random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "byeDoZJ2",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,4],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[1,[34,5]],[2,\"\\n  \"],[1,[34,5]],[2,\"\\n  \"],[1,[34,5]],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[6,[37,4],[[35,1]],null,[[\"default\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"xaxon\",\"xneuron\",\"class\"],[[32,1,[\"xaxon\"]],[32,1,[\"xneuron\"]],\"mb-3\"]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"neuron/neuron-axon-random\",\"items\",\"-track-array\",\"each\",\"if\",\"neuron/neuron-axon-placeholder\",\"isLoading\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-axons-random.hbs"
    }
  });
  _exports.default = _default;
});