define("livarava-web/templates/sites/item/seo", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "te5rzpFk",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[14,0,\"mb-3\"],[12],[2,\"Search Optimization\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mt-0 mb-3\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"model\",\"save\",\"refresh\"],[[35,1],[30,[36,0],[\"save\"],null],[30,[36,0],[\"refresh\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"site/site-seo\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/seo.hbs"
    }
  });
  _exports.default = _default;
});