define("livarava-web/templates/components/neuroner/overdue-alert", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qv7GXK9M",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"alert alert-danger text-center\"],[12],[2,\"\\n  Your subscription for LivaRava Neuroner is \"],[10,\"b\"],[12],[2,\"finished\"],[13],[2,\".\"],[10,\"br\"],[12],[13],[2,\"\\n  Please, \"],[6,[37,0],null,[[\"route\"],[\"neuroner.settings.general\"]],[[\"default\"],[{\"statements\":[[2,\"continue\"]],\"parameters\":[]}]]],[2,\" your subscription or\\n  \"],[6,[37,0],null,[[\"route\"],[\"neuroner.settings.general\"]],[[\"default\"],[{\"statements\":[[2,\"downgrade\"]],\"parameters\":[]}]]],[2,\" account after removing unused feeds and filters.\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/overdue-alert.hbs"
    }
  });
  _exports.default = _default;
});