define("livarava-web/mixins/show-more-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Mixin.create({
    itemsToShow: 5,
    actions: {
      showMore: function showMore() {
        this.set('itemsToShow', this.itemsToShow + 5);
      }
    },
    visibleItems: Ember.computed('items', 'itemsToShow', function () {
      if (Ember.isArray(this.items)) {
        return this.items.slice(0, this.itemsToShow);
      }
      return null;
    }),
    hasMore: Ember.computed('items', 'itemsToShow', function () {
      if (Ember.isArray(this.items)) {
        return this.items.length > this.itemsToShow;
      }
      return null;
    })
  });
  _exports.default = _default;
});