define("livarava-web/components/input-date", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Special
    value: null,
    date: null,
    didReceiveAttrs: function didReceiveAttrs() {
      var value = this.value;
      if (!value) return;
      var date = value.toDate();
      this.set('date', date);
    },
    // Actions
    actions: {
      setDate: function setDate(date) {
        if (date && date[0]) {
          var value = date[0];
          var _moment = _momentTimezone.default.tz(value, 'UTC');
          this.set('date', date && date[0]);
          this.set('value', _moment);
        }
      }
    }
  });
  _exports.default = _default;
});