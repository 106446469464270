define("livarava-web/controllers/funnels/orders", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['manager', 'funnel_start', 'funnel_end'],
    manager: null,
    funnel_start: null,
    funnel_end: null,
    actions: {
      setManager: function setManager(value) {
        Ember.set(this, 'manager', value);
      },
      setDateRange: function setDateRange(funnel_start, funnel_end) {
        this.setProperties({
          funnel_start: funnel_start,
          funnel_end: funnel_end
        });
      }
    }
  });
  _exports.default = _default;
});