define("livarava-web/components/payout/payout-status", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusOptions = Array.from(_environment.default.options.payout.status);
  var _default = Ember.Component.extend({
    statusOptions: statusOptions
  });
  _exports.default = _default;
});