define("livarava-web/templates/components/billing/billing-invoice-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W/kGSJeK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"invoice row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-2 text-muted small\"],[12],[2,\"\\n    \"],[1,[30,[36,1],[[35,0,[\"created\"]],\"YYYY-MM-DD HH:mm\"],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[[35,2],[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      Invoice #\"],[1,[35,0,[\"id\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"br\"],[12],[13],[2,\"\\n    \"],[10,\"span\"],[14,0,\"small\"],[12],[1,[35,0,[\"statusLabel\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-2 text-right\"],[12],[2,\"\\n    -\"],[1,[30,[36,4],[[35,0,[\"amount\"]]],[[\"format\"],[\"0.00\"]]]],[2,\"\\n    \"],[1,[35,0,[\"currency\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"moment-format\",\"linkRoute\",\"link-to\",\"format-number\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/billing/billing-invoice-list-item.hbs"
    }
  });
  _exports.default = _default;
});