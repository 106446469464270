define("livarava-web/templates/components/common/filters/filter-daterange", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "LSiVGsND",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[8,\"ember-flatpickr\",[[24,0,\"form-control form-control-sm\"]],[[\"@locale\",\"@date\",\"@mode\",\"@enableTime\",\"@onChange\"],[\"uk\",[30,[36,1],[[35,0,[\"date_start\"]],[35,0,[\"date_end\"]]],null],\"range\",false,[30,[36,2],[[32,0],\"setDateRange\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"controller\",\"array\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/filters/filter-daterange.hbs"
    }
  });
  _exports.default = _default;
});