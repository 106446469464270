define("livarava-web/controllers/new-neuron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    store: Ember.inject.service(),
    // Actions
    actions: {
      create: function create() {
        var neuron = this.store.createRecord('neuron', {
          title: this.title
        });
        neuron.save();
      }
    }
  });
  _exports.default = _default;
});