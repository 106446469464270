define("livarava-web/components/payment/payment-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    currencyOptions: _environment.default.currencyOptions,
    paymentTypeOptions: [{
      value: 'System',
      label: 'System'
    }, {
      value: 'Bank',
      label: 'Bank'
    }, {
      value: 'Card',
      label: 'Card'
    }, {
      value: 'LiqPay',
      label: 'LiqPay'
    }, {
      value: 'PayPal',
      label: 'PayPal'
    }, {
      value: 'WebMoney',
      label: 'WebMoney'
    }, {
      value: 'Paymentwall',
      label: 'Paymentwall'
    }, {
      value: 'Western Union',
      label: 'Western Union'
    }, {
      value: '2Checkout',
      label: '2Checkout'
    }],
    // Actions
    actions: {
      save: function save() {
        this.onSave(this.model);
      },
      delete: function _delete() {
        this.onDelete(this.model);
      },
      setNeuron: function setNeuron(field, item) {
        var model = this.model;
        if (item === null) {
          Ember.set(model, field, null);
          return;
        }
        Ember.set(this, field, item);
      },
      setUser: function setUser(field, item) {
        var model = this.model;
        if (item === null) {
          Ember.set(model, field, null);
          return;
        }
        Ember.set(this, field, item);
      }
    }
  });
  _exports.default = _default;
});