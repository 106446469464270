define("livarava-web/templates/sites/item/categories/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q6BZavYK",
    "block": "{\"symbols\":[],\"statements\":[[3,\"TODO: Refactor to site/site-categories or remove \"],[2,\"\\n\"],[1,[30,[36,3],null,[[\"model\",\"controller\",\"selectedLanguage\",\"onAddCategory\",\"onSaveCategory\",\"onRemoveCategory\"],[[35,2],[32,0],[30,[36,1],[[32,0],\"filter[language]\"],null],[30,[36,0],[[32,0],\"addSiteCategory\"],null],[30,[36,0],[[32,0],\"saveSiteCategory\"],null],[30,[36,0],[[32,0],\"removeSiteCategory\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"get\",\"model\",\"site-categories\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/categories/index.hbs"
    }
  });
  _exports.default = _default;
});