define("livarava-web/components/notification/notification-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Attributes
    item: null,
    // notification model

    // Computed
    text: Ember.computed('item.type', function () {
      var type = Ember.get(this, 'item.type');
      var textMap = {
        "axon_accept": "accepted axon",
        "axon_create": "created axon",
        "axon_reject": "rejected axon",
        "axon_share": "shared axon",
        "axon_shared": "shared axon",
        "event_invited": "",
        // TODO later
        "neuron_comment": "commented on neuron",
        "neuron_update": "updated neuron",
        "page_comment": "commented on page",
        // TODO later
        "post_comment": "commented on post",
        // TODO later
        "product_investment": "invested into project",
        // TODO later
        "project_comment": "commented on project",
        // TODO later
        "project_invite": "invited to project",
        // TODO later
        "project_invite_accepted": "accepted invite to project",
        // TODO later
        "task_comment": "commented on task" // TODO later
      };

      return type in textMap ? textMap[type] : type;
    }),
    // Computed
    route: Ember.computed('item.type', function () {
      var type = Ember.get(this, 'item.type');
      var targetId = parseInt(Ember.get(this, 'item.target_id'));
      var targetNeuron = parseInt(Ember.get(this, 'item.target_neuron'));
      var routeMap = {
        axon_accept: {
          name: 'neuron.item.overview',
          value: targetId
        },
        axon_create: {
          name: 'axons.item',
          value: targetId
        },
        axon_reject: {
          name: 'neuron.item.overview',
          value: targetId
        },
        axon_share: {
          name: 'axons.item',
          value: targetId
        },
        axon_shared: {
          name: 'axons.item',
          value: targetId
        },
        neuron_comment: {
          name: 'neuron.item.comments',
          value: targetNeuron
        },
        neuron_update: {
          name: 'neuron.item.overview',
          value: targetNeuron
        }
      };
      return type in routeMap ? routeMap[type] : null;
    }),
    // Actions
    actions: {
      onClick: function onClick() {
        var router = this.router;
        var route = this.route;
        var item = this.item;
        Ember.set(item, 'read', true);
        item.save();
        if (route) {
          router.transitionTo(route.name, route.value, {
            queryParams: {}
          });
        }
      }
    }
  });
  _exports.default = _default;
});