define("livarava-web/templates/components/input-froala-editor", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "82ec0+iP",
    "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[8,\"froala-editor\",[],[[\"@content\",\"@update\",\"@options\",\"@toolbarButtons\",\"@on-file-uploaded\",\"@on-image-uploaded\"],[[30,[36,1],[[35,0,[\"description\"]]],null],[30,[36,4],[[30,[36,3],[[30,[36,2],[[35,0,[\"description\"]]],null]],null]],null],[34,5],[34,6],[30,[36,7],[[32,0],\"fileUploaded\"],null],[30,[36,7],[[32,0],\"imageUploaded\"],null]]],null]],\"hasEval\":false,\"upvars\":[\"model\",\"html-safe\",\"mut\",\"fn\",\"to-string\",\"froalaOptions\",\"toolbarButtons\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-froala-editor.hbs"
    }
  });
  _exports.default = _default;
});