define("livarava-web/components/neuroner/feed-user-form", ["exports", "livarava-web/utils/is-url", "livarava-web/utils/get-api-url"], function (_exports, _isUrl, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var MIN = 4;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    // Properties
    url: null,
    feed: null,
    timer: null,
    error: null,
    // States
    isLoading: false,
    isSaving: false,
    isDisabled: Ember.computed('feed', function () {
      var feed = this.feed;
      return feed === null;
    }),
    // Computed
    feedHandler: Ember.computed('url', function () {
      var _this = this;
      var store = this.store;
      var url = this.url;
      var timer = this.timer;
      Ember.set(this, 'feed', null);
      Ember.set(this, 'error', null);
      clearTimeout(timer);
      timer = setTimeout(function () {
        if (url === null) {
          return;
        }
        if (url === "") {
          return;
        }
        if (url && url.length < MIN) {
          Ember.set(_this, 'error', 'Too short URL');
          return;
        }
        if (!(0, _isUrl.default)(url)) {
          Ember.set(_this, 'error', 'Invalid URL');
          return;
        }

        // console.log(url);
        Ember.set(_this, 'isLoading', true);
        var params = {
          'filter[url]': url,
          per_page: 1,
          page: 1
        };
        store.query('neuroner.feed', params).then(function (items) {
          var totalResults = items.meta.total_results;
          if (totalResults > 0) {
            var feedId = items.content[0].id;
            store.findRecord('neuroner.feed', feedId).then(function (feed) {
              Ember.set(_this, 'feed', feed);
              Ember.set(_this, 'isLoading', false);
            });
          } else {
            store.createRecord('neuroner.feed', {
              url: url
            }).then(function (feed) {
              Ember.set(_this, 'feed', feed);
              Ember.set(_this, 'isLoading', false);
            });
          }
        }).catch(function (e) {
          var feed = store.createRecord('neuroner.feed', {
            url: url
          });
          Ember.set(_this, 'feed', feed);
          Ember.set(_this, 'isLoading', false);
        });
      }, 500);
      Ember.set(this, 'timer', timer);
    }),
    addFeed: function addFeed(feed_id) {
      var _this2 = this;
      var url = (0, _getApiUrl.default)('neuroner-feed-add', {
        feed_id: feed_id
      });
      var router = this.router;
      var token = Ember.get(this, 'me.token');
      fetch(url, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function () {
        Ember.set(_this2, 'isSaving', false);
        router.transitionTo('neuroner.feeds');
      }).catch(function (e) {
        alert('Error');
        Ember.set(_this2, 'error', 'Error');
        Ember.set(_this2, 'isSaving', false);
      });
    },
    // Actions
    actions: {
      add: function add() {
        var _this3 = this;
        var feed = this.feed;
        Ember.set(this, 'isSaving', true);
        if (!feed.id) {
          feed.save().then(function (feed) {
            _this3.addFeed(parseInt(feed.id));
          });
        } else {
          this.addFeed(parseInt(feed.id));
        }
      }
    }
  });
  _exports.default = _default;
});