define("livarava-web/templates/components/common/common-page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "13pt+M1f",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"page\"],[12],[2,\"\\n  \"],[2,[35,4,[\"description\"]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\\n\"],[6,[37,3],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"common/common-footer\",\"common/common-header\",\"header\",\"if\",\"item\",\"footer\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-page.hbs"
    }
  });
  _exports.default = _default;
});