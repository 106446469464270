define("livarava-web/components/project/project-member-item", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Status
    isEditing: false,
    isShowingPart: Ember.computed('item.role', function () {
      var role = this.get('item.role');
      return _jquery.default.inArray(role, ['admin', 'manager']) > -1;
    }),
    // Functions
    onEditUser: null,
    onSaveUser: null,
    // Actions
    actions: {
      edit: function edit() {
        var _this = this;
        this.onEditUser(this.item).then(function (data) {
          _this.set('item.part', data.part);
          _this.set('isEditing', true);
        });
      },
      cancel: function cancel() {
        this.set('isEditing', false);
      },
      save: function save() {
        this.onSaveUser(this.item, this.get('item.role'));
        this.set('isEditing', false);
      },
      remove: function remove() {
        this.onRemoveUser(this.item, this.get('item.role'));
        this.set('isEditing', false);
      }
    }
  });
  _exports.default = _default;
});