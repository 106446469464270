define("livarava-web/components/action/action-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var actionTypeOptions = Array.from(_environment.default.options.action.type);
  var actionDirectionOptions = Array.from(_environment.default.options.action.direction);
  var _default = Ember.Component.extend({
    actionTypeOptions: actionTypeOptions,
    actionDirectionOptions: actionDirectionOptions,
    actions: {
      save: function save(model) {
        var _this = this;
        return model.validate().then(function () {
          if (model.get('isValid')) {
            if (typeof _this.onSubmit == 'function') {
              return _this.onSubmit(model);
            }
          }
        });
      },
      cancel: function cancel() {
        if (typeof this.onCancel == 'function') {
          return this.onCancel();
        }
      }
    }
  });
  _exports.default = _default;
});