define("livarava-web/routes/navigations/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Model
    model: function model() {
      return this.store.createRecord('navigation', {
        title: '',
        path: '',
        language: this.get('me.locale'),
        author: this.me
      });
    }
  });
  _exports.default = _default;
});