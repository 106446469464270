define("livarava-web/controllers/entities/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    // Query Params
    queryParams: ['copy', 'lead'],
    copy: null,
    lead: null,
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm(this.intl.t('modal.confirm'))) {
          item.destroyRecord().then(function () {
            return _this.transitionToRoute('entities');
          });
        }
      },
      save: function save(item) {
        var _this2 = this;
        item.save().then(function () {
          return _this2.transitionToRoute('entities.item', item.get('id'));
        });
      }
    }
  });
  _exports.default = _default;
});