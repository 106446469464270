define("livarava-web/components/invitation/invitation-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Handlers
    onResend: null,
    onDelete: null,
    // Actions
    actions: {
      resend: function resend(item) {
        this.onResend(item);
      },
      delete: function _delete(item) {
        this.onDelete(item);
      }
    }
  });
  _exports.default = _default;
});