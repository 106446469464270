define("livarava-web/routes/neuron/item/connections", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _getFiltersFromParams, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      r: {
        refreshModel: true
      },
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      display: {
        refreshModel: true
      },
      author: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var neuronId = parseInt(this.paramsFor('neuron.item').id);
      params['neuron'] = neuronId;
      return Ember.RSVP.hash({
        neuron: store.peekRecord('neuron', neuronId),
        axons: store.query('axon', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});