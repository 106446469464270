define("livarava-web/components/image-autocomplete", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    sourceURL: "".concat(_environment.default.api.host, "/api/v2/autocomplete/neurons"),
    imageURL: null,
    min: 3,
    type: 'image',
    value: null,
    items: null,
    init: function init() {
      this._super.apply(this, arguments);
      Ember.set(this, 'items', []);
    },
    // Actions
    actions: {
      skipShortSearches: function skipShortSearches(term, select) {
        if (term.length < this.min) {
          select.actions.search('');
          return false;
        }
      },
      searchNeurons: function searchNeurons(query) {
        var sourceURL = this.sourceURL;
        var q = encodeURIComponent(query);
        var type = encodeURIComponent(this.type);
        var url = "".concat(sourceURL, "?q=").concat(q, "&type=").concat(type);
        var token = Ember.get(this, 'me.token');
        return (0, _fetch.default)(url, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (data) {
          return data.data;
        }).catch(function () {
          return [];
        });
      },
      onImageUpload: function onImageUpload(data) {
        var store = this.store;
        var url = data.link;
        var name = data.name;
        var image = store.createRecord('neuron', {
          type: 'image',
          title: url,
          header: name,
          display: 'public'
        });
        image.save();
        Ember.set(this, 'value', image);
        Ember.set(this, 'imageUrl', url);
      }
    }
  });
  _exports.default = _default;
});