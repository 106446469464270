define("livarava-web/templates/sites/item/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "st10MEFm",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"h2\"],[14,0,\"mb-0\"],[12],[2,\"Billing\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"btn-group pull-right\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"main filters\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"class\",\"route\"],[\"btn btn-success\",\"sites.item.billing-settings\"]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Edit\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mb-3\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"site\",\"payments\",\"invoices\"],[[35,1,[\"site\"]],[35,1,[\"payments\"]],[35,1,[\"invoices\"]]]]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"site/site-billing\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/billing.hbs"
    }
  });
  _exports.default = _default;
});