define("livarava-web/components/common/common-footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    api: Ember.inject.service(),
    // Attributes
    version: Ember.computed.reads('api.version'),
    // Properties
    locale: Ember.computed.reads('intl.primaryLocale'),
    currentRoute: Ember.computed.reads('router.currentRouteName'),
    isMultilanguage: Ember.computed('currentRoute', function () {
      var currentRoute = this.currentRoute;
      return currentRoute.startsWith("lang.");
    }),
    // Actions
    actions: {
      setLocale: function setLocale(locale) {
        if (window && window.location) {
          var currentLocale = this.locale;
          console.log("locale current: ".concat(currentLocale));
          console.log("locale new: ".concat(locale));
          var currentLocation = window.location.href;
          var s1 = "/".concat(currentLocale, "/");
          var s2 = "/".concat(locale, "/");
          window.location.href = currentLocation.replace(s1, s2);
        }
      }
    }
  });
  _exports.default = _default;
});