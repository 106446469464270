define("livarava-web/templates/components/input-user", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "A5QAoEjs",
    "block": "{\"symbols\":[\"prediction\"],\"statements\":[[6,[37,12],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"tagName\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"search\",\"allowClear\",\"matchTriggerWidth\",\"placeholder\",\"dropdownClass\"],[[35,11],[35,10],[35,9],[35,8],true,[35,7],[30,[36,6],[[32,0],\"selectUser\"],null],[35,1],[30,[36,6],[[32,0],\"handleSearch\"],null],[35,5],false,[35,4],\"input-city-dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"fullname\"]]],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[32,1,[\"id\"]],[35,1,[\"id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\t  \"],[10,\"small\"],[14,0,\"text-muted d-block\"],[12],[1,[32,1,[\"phone\"]]],[2,\" \"],[1,[32,1,[\"email\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[6,[37,3],[[35,13]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"small\"],[14,0,\"form-text text-black-50 px-2\"],[12],[2,\"\\n  \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"details\",\"value\",\"not-eq\",\"if\",\"placeholder\",\"allowClear\",\"action\",\"predictions\",\"tagName\",\"dropdownClass\",\"triggerClass\",\"klass\",\"power-select\",\"showDetails\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-user.hbs"
    }
  });
  _exports.default = _default;
});