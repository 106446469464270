define("livarava-web/components/signin-form", ["exports", "ember-local-storage", "livarava-web/config/environment"], function (_exports, _emberLocalStorage, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    router: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Variables
    rootURL: _environment.default.rootURL,
    email: null,
    password: null,
    error: null,
    // Flags
    isEmailError: true,
    isLoading: false,
    // Computed Variables
    emailClass: Ember.computed('error', function () {
      var error = this.error;
      return error === null ? "form-control" : "form-control is-invalid";
    }),
    passwordClass: Ember.computed('error', function () {
      var error = this.error;
      return error === null ? "form-control" : "form-control is-invalid";
    }),
    // Actions
    actions: {
      onKeyDown: function onKeyDown() {
        Ember.set(this, 'error', null);
      },
      signin: function signin() {
        var _this = this;
        var router = this.router;
        var me = this.me;
        var login = this.email;
        var password = this.password;
        Ember.set(this, 'error', null);
        Ember.set(this, 'isLoading', true);
        me.signIn(login, password).then(function () {
          Ember.set(_this, 'isLoading', false);
          var next = Ember.get(_this, 'model.next');
          if (window && next) {
            window.open(next, '_self');
          } else {
            router.transitionTo('dashboard');
          }
        }).catch(function (response) {
          var error = response.error || 'Unknown error. Please, report to admin@livarava.com';
          Ember.set(_this, 'isLoading', false);
          Ember.set(_this, 'error', error);
        });
      },
      signout: function signout() {
        var me = this.me;
        var router = this.router;
        var isSigned = Ember.get(this, 'session.signed');
        if (isSigned) {
          me.signOut().then(function () {
            router.transitionTo('index');
          }).catch(function () {
            router.transitionTo('index');
          });
        } else {
          router.transitionTo('index');
        }
      }
    }
  });
  _exports.default = _default;
});