define("livarava-web/components/order/order-status", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    statusOptions: _environment.default.options.order.status,
    intl: Ember.inject.service(),
    tagName: 'span'
  });
  _exports.default = _default;
});