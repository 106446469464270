define("livarava-web/components/axon/axon-list-item", ["exports", "livarava-web/mixins/neuron-type-determinator"], function (_exports, _neuronTypeDeterminator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend(_neuronTypeDeterminator.default, {
    // Attributes
    axon: null,
    // Flags
    hasId: Ember.computed('neuron.id', function () {
      return !isNaN(Ember.get(this, 'neuron.id'));
    })
  });
  _exports.default = _default;
});