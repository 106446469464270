define("livarava-web/templates/components/investment/investment-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5qKjriJ1",
    "block": "{\"symbols\":[\"status\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"value\",[35,3,[\"status\"]],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],[[32,1,[\"icon\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[32,1,[\"icon\"]]],[15,\"title\",[31,[[32,1,[\"label\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,0],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[1,[32,1,[\"label\"]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"if\",\"label\",\"statusOptions\",\"item\",\"find-by\",\"with\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/investment/investment-status.hbs"
    }
  });
  _exports.default = _default;
});