define("livarava-web/components/product/product-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sectionRoutes: Ember.computed('item.sections', function () {
      var rights = Ember.get(this, 'item.sections');
      var sections = [{
        name: 'about',
        title: 'About',
        icon: 'file-text'
      }, {
        name: 'goals',
        title: 'Goals',
        icon: 'flag'
      },
      // {name: 'payments', title: 'Payments', icon: 'money'},
      {
        name: 'invoices',
        title: 'Invoices',
        icon: 'list-alt'
      }, {
        name: 'customers',
        title: 'Customers',
        icon: 'users'
      }, {
        name: 'tasks',
        title: 'Tasks',
        icon: 'tasks'
      }, {
        name: 'members',
        title: 'Members',
        icon: 'user'
      }, {
        name: 'settings',
        title: 'Settings',
        icon: 'cog'
      }];
      return sections.filter(function (obj) {
        return rights.indexOf(obj['name']) >= 0;
      }).map(function (obj) {
        return new Object({
          name: obj['name'],
          title: obj['title'],
          icon: obj['icon'],
          route: "products.item.".concat(obj['name'])
        });
      });
    })
  });
  _exports.default = _default;
});