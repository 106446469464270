define("livarava-web/components/motivation/popular-services", ["exports", "moment", "lodash/groupBy", "lodash/toPairs", "lodash/sumBy"], function (_exports, _moment, _groupBy2, _toPairs2, _sumBy2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // filters
    dates: null,
    city: null,
    // other
    items: null,
    fetchData: function fetchData() {
      var _this = this;
      var city = this.city,
        _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store;
      var requestParams = {
        date_start: (0, _moment.default)(dates[0]).format('YYYY-MM-DD'),
        date_end: (0, _moment.default)(dates[1]).format('YYYY-MM-DD'),
        per_page: 1000,
        page: 1
      };
      if (city) {
        requestParams['filter[city]'] = city;
      }
      return store.query('order', requestParams).then(function (orders) {
        var grouped = (0, _groupBy2.default)(orders.toArray(), function (order) {
          return order.get('service.id');
        });
        var pairs = (0, _toPairs2.default)(grouped);
        var mapped = pairs.map(function (pair) {
          return {
            service: pair[1][0].get('service'),
            orders: pair[1],
            total: (0, _sumBy2.default)(pair[1], 'amount')
          };
        });
        return mapped;
      }).then(function (items) {
        return _this.set('items', items);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('dates', [(0, _moment.default)().startOf('week').toDate(), (0, _moment.default)().endOf('week').toDate()
      // moment('2021-09-01').toDate(),
      // moment('2021-09-20').toDate(),
      ]);

      Ember.run.next(this, function () {
        var _this2 = this;
        this.loading.run(function () {
          return _this2.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDateRange: function setDateRange(dates) {
        var _this3 = this;
        this.set('dates', dates);
        if (dates && dates.length == 2) {
          this.loading.run(function () {
            return _this3.fetchData();
          });
        }
      },
      setCity: function setCity(city) {
        var _this4 = this;
        this.set('city', city);
        this.loading.run(function () {
          return _this4.fetchData();
        });
      }
    }
  });
  _exports.default = _default;
});