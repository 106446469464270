define("livarava-web/templates/lang/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fvE+3Pxc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"item\"],[[35,0]]]]],[2,\"\\n\\n\"],[6,[37,5],[[35,4,[\"rights\",\"pages\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"form-buttons-fixed text-right\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"route\",\"models\"],[\"sites.item.pages.edit\",[30,[36,1],[[35,0,[\"site\",\"id\"]],[35,0,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,0,\"btn btn-lg btn-warning\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13],[2,\" Edit\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"array\",\"link-to\",\"common/common-page\",\"me\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/lang/page.hbs"
    }
  });
  _exports.default = _default;
});