define("livarava-web/components/service/input-service", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "livarava-web/config/environment"], function (_exports, _objectSpread2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    serviceTypes: _environment.default.service.types,
    services: null,
    loading: false,
    service_type: 'job',
    hidePrependButton: false,
    onChange: function onChange() {},
    init: function init() {
      this._super.apply(this, arguments);
      this.requestServices();
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      this.requestServices();
    },
    requestServices: function requestServices(q) {
      var _this = this;
      this.set('loading', true);
      return this.store.query('service', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, q ? {
        q: q
      } : {
        'filter[parent]': 'null'
      }), {}, {
        page: 1,
        per_page: 20,
        // 'filter[display]': 'public',
        'filter[service_type]': this.service_type,
        sort: '-order'
      })).then(function (services) {
        _this.set('loading', false);
        _this.set('services', services);
      }).catch(function () {
        _this.set('loading', false);
      });
    },
    actions: {
      selectService: function selectService(service) {
        this.onChange(service);
      },
      handleSearch: function handleSearch(q) {
        Ember.run.debounce(this, this.requestServices, q, 300);
      },
      handleServiceChange: function handleServiceChange(service) {
        this.setProperties({
          modalOpened: false
        });
        this.onChange(service);
      }
    }
  });
  _exports.default = _default;
});