define("livarava-web/templates/components/feedback/feedback-comment-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "W35OlqE4",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text mb-2\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-6 text-left\"],[12],[2,\"\\n        \"],[1,[30,[36,0],null,[[\"item\"],[[32,1,[\"author\"]]]]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-sm-6 text-right text-muted small\"],[12],[2,\"\\n        \"],[1,[30,[36,1],[[32,1,[\"created\"]],\"MMMM DD, YYYY HH:mm\"],null]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[2,[32,1,[\"text\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"user/user-medium\",\"moment-format\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/feedback/feedback-comment-list.hbs"
    }
  });
  _exports.default = _default;
});