define("livarava-web/routes/neuroner/filters/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      return this.store.query('neuroner.filter', params);
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});