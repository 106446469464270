define("livarava-web/controllers/events/item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    item: Ember.computed.reads('model.item')
  });
  _exports.default = _default;
});