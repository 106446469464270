define("livarava-web/routes/projects/index", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/mixins/authenticated-route-mixin", "lodash/omitBy", "lodash/isNil"], function (_exports, _getFiltersFromParams, _authenticatedRouteMixin, _omitBy2, _isNil2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      params = (0, _omitBy2.default)(params, _isNil2.default);
      params['sort'] = '-views';
      params['status'] = 'created';
      return Ember.RSVP.hash({
        items: this.store.query('project', (0, _getFiltersFromParams.default)(params))
      });
    }
  });
  _exports.default = _default;
});