define("livarava-web/templates/components/loading-line-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "x1+H90dr",
    "block": "{\"symbols\":[\"pg\"],\"statements\":[[8,\"bs-progress\",[[24,0,\"loading-line-slider\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"bar\"]],[],[[\"@value\",\"@type\"],[[34,0],\"danger\"]],null],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"value\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/loading-line-slider.hbs"
    }
  });
  _exports.default = _default;
});