define("livarava-web/templates/components/review/input-rating", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "3xMnzarq",
    "block": "{\"symbols\":[\"number\"],\"statements\":[[10,\"div\"],[14,0,\"d-flex align-items-center mb-1\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[30,[36,4],[1,10,true],null]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"button\"],[16,0,[31,[\"btn btn-lg btn-outline-primary mr-1 \",[30,[36,2],[[30,[36,1],[[35,0],[32,1]],null],\"active\"],null]]]],[4,[38,3],[[32,0],\"handleChange\",[32,1]],null],[12],[2,\"\\n      \"],[1,[32,1]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"],[10,\"div\"],[14,0,\"small text-muted d-flex align-items-center justify-content-between mb-1\"],[12],[2,\"\\n  \"],[10,\"span\"],[12],[2,\"Погано\"],[13],[10,\"span\"],[12],[2,\"Відмінно!\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"value\",\"eq\",\"if\",\"action\",\"range\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/review/input-rating.hbs"
    }
  });
  _exports.default = _default;
});