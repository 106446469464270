define("livarava-web/components/entity/entityservice-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    serviceTypes: _environment.default.service.types,
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        return item.validate().then(function () {
          if (item.get('isValid')) {
            return _this.onSubmit(item);
          }
        });
      },
      handleCityDetailsChange: function handleCityDetailsChange(details) {
        this.item.setProperties(details ? Ember.getProperties(details, 'address_area_level_1', 'address_area_level_2', 'address_postal_code') : {
          address_area_level_1: null,
          address_area_level_2: null,
          address_postal_code: null
        });
      },
      handleServiceTypeChange: function handleServiceTypeChange(serviceType) {
        this.set('item.service_type', serviceType);
        this.set('item.service', null);
      },
      cancel: function cancel() {
        if (typeof this.cancel == 'function') {
          return this.cancel();
        }
      }
    }
  });
  _exports.default = _default;
});