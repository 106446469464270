define("livarava-web/templates/components/neuron-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Qv8APNqB",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"clearfix neuron-small\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuron-image\"],[15,5,[30,[36,1],[\"background-image: url(\",[35,0,[\"icon_url\"]],\")\"],null]],[12],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuron-header\"],[12],[1,[35,0,[\"header\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"item\",\"html-safe\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-small.hbs"
    }
  });
  _exports.default = _default;
});