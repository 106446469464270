define("livarava-web/routes/payments/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      return this.store.findRecord('payment', params.id);
    },
    // Actions
    actions: {
      onDelete: function onDelete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this.transitionTo('payments');
          });
        }
      },
      onSave: function onSave(item) {
        var _this2 = this;
        item.save().then(function () {
          return _this2.transitionTo('payments');
        });
      }
    }
  });
  _exports.default = _default;
});