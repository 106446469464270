define("livarava-web/templates/components/profile/profile-template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O7S8CjXg",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Profile\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"profile\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n\\n      \"],[1,[30,[36,3],null,[[\"id\",\"class\",\"classDefault\",\"value\",\"model\",\"onSelect\"],[\"profile-image\",\"mb-3\",\"profile-default-image\",[35,2,[\"image\"]],[35,2],[30,[36,1],[[32,0],\"onImageSelect\"],null]]]]],[2,\"\\n\\n      \"],[10,\"h3\"],[14,0,\"mb-0\"],[12],[1,[35,2,[\"fullname\"]]],[13],[2,\"\\n      \"],[10,\"h4\"],[14,0,\"text-muted\"],[12],[1,[35,2,[\"username\"]]],[13],[2,\"\\n\\n      \"],[1,[30,[36,4],null,[[\"item\"],[[35,2]]]]],[2,\"\\n\\n      \"],[10,\"p\"],[14,0,\"text-muted small\"],[12],[2,[35,2,[\"about\"]]],[13],[2,\"\\n    \"],[13],[3,\"col-sm-3\"],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-sm-9\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n        \"],[18,1,null],[2,\"\\n\\n      \"],[13],[3,\"body\"],[2,\"\\n\\n    \"],[13],[3,\"col-sm-9\"],[2,\"\\n\\n  \"],[13],[3,\"row\"],[2,\"\\n\"],[13],[3,\"profile\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"model\",\"input-image\",\"neuron/neuron-basic-info\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/profile/profile-template.hbs"
    }
  });
  _exports.default = _default;
});