define("livarava-web/controllers/products/item/invoices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'project', 'product', 'status', 'currency', 'date_start', 'date_end'],
    page: 1,
    per_page: 10,
    q: null,
    project: null,
    product: null,
    status: null,
    currency: null,
    date_start: null,
    date_end: null
  });
  _exports.default = _default;
});