define("livarava-web/components/neuroner/feed-preview", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    url: null,
    selector: null,
    title: 'Loading...',
    text: 'Loading...',
    // Recieve Attributes
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var url = this.url;
      var selector = this.selector;
      var neuroner_url = _environment.default.api.neuroner;
      var token = Ember.get(this, 'me.token');
      var apiUrl = "".concat(neuroner_url, "/api/get-text/?url=").concat(url);
      if (selector) {
        apiUrl += "&selector=".concat(selector);
      }
      (0, _fetch.default)(apiUrl, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        // console.log(data);
        Ember.set(_this, 'title', data.title);
        Ember.set(_this, 'text', data.text);
      }).catch(function () {
        // console.log(data);
      });
    }
  });
  _exports.default = _default;
});