define("livarava-web/routes/lang/blog/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var id = parseInt(params.id);
      return Ember.RSVP.hash({
        item: store.findRecord('post', id),
        comments: store.query('comment', {
          neuron: id,
          per_page: 1000
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});