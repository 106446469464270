define("livarava-web/helpers/array-join", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function arrayJoin(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
      array = _ref3[0];
    var _ref2$separator = _ref2.separator,
      separator = _ref2$separator === void 0 ? ", " : _ref2$separator;
    return Array.isArray(array) ? array.join(separator) : null;
  });
  _exports.default = _default;
});