define("livarava-web/controllers/sites/item/feedbacks/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      reply: function reply(item) {
        var _this = this;
        item.save().then(function (item) {
          return _this.send('reload');
        });
      },
      close: function close(item) {
        var _this2 = this;
        item.set('status', 'closed');
        item.save().then(function (item) {
          return _this2.send('reload');
        });
      },
      reopen: function reopen(item) {
        var _this3 = this;
        item.set('status', 'open');
        item.save().then(function (item) {
          return _this3.send('reload');
        });
      },
      delete: function _delete(item, site_id) {
        var _this4 = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function (item) {
            return _this4.transitionToRoute('sites.item.feedbacks', site_id);
          });
        }
      }
    }
  });
  _exports.default = _default;
});