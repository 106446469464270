define("livarava-web/templates/neuroner/settings/customers/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+JckhOxf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Customer: \"],[1,[35,0,[\"customer\",\"fullname\"]]],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,1],null,[[\"item\"],[[35,0,[\"customer\"]]]]]],[2,\"\\n\\n\"],[1,[30,[36,3],[[30,[36,2],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"model\",\"neuroner/customer-item\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuroner/settings/customers/item.hbs"
    }
  });
  _exports.default = _default;
});