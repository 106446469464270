define("livarava-web/routes/neuroner/settings/status", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Status',
    // Model
    model: function model() {
      var neuroner_url = _environment.default.api.neuroner;
      var time = new Date().getTime();
      var apiUrl = "".concat(neuroner_url, "/api/test/?__t=").concat(time);
      var token = Ember.get(this, 'me.token');
      var params = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      return (0, _fetch.default)(apiUrl, params).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        // console.log(data);
        return data;
      }).catch(function () {
        // console.log('Error! Please, ask admin to help.');
        return {
          error: 'Down'
        };
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});