define("livarava-web/components/site/site-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    sectionsRoutes: Ember.computed('model.sections', function () {
      var rights = Ember.get(this, 'model.sections');
      var sections = [{
        name: 'about',
        title: 'About'
      }, {
        name: 'navigations',
        title: 'Navigations'
      }, {
        name: 'pages',
        title: 'Pages'
      }, {
        name: 'posts',
        title: 'Posts'
      }, {
        name: 'events',
        title: 'Events'
      }, {
        name: 'sitemap',
        title: 'Sitemap'
      }, {
        name: 'users',
        title: 'Users'
      }, {
        name: 'members',
        title: 'Members'
      }, {
        name: 'general',
        title: 'General Info'
      }, {
        name: 'billing',
        title: 'Billing'
      }, {
        name: 'domains',
        title: 'Domains'
      }, {
        name: 'seo',
        title: 'Search Optimization'
      }, {
        name: 'smm',
        title: 'Social Media'
      }, {
        name: 'api',
        title: 'Import/Export'
      }];
      return sections.filter(function (obj) {
        return rights.indexOf(obj['name']) >= 0;
      }).map(function (obj) {
        return new Object({
          name: obj['name'],
          title: obj['title'],
          route: "sites.item.".concat(obj['name'])
        });
      });
    })
  });
  _exports.default = _default;
});