define("livarava-web/models/notification", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    type: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    link: _emberData.default.attr('string'),
    read: _emberData.default.attr('boolean'),
    // Target
    target_type: _emberData.default.attr('string'),
    target_id: _emberData.default.attr('number'),
    target_neuron: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    recipient: _emberData.default.belongsTo('user')
    // target: DS.belongsTo('neuron'),
  });
  _exports.default = _default;
});