define("livarava-web/components/common/common-filters", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    controller: null,
    filters: null,
    // Actions
    actions: {
      search: function search() {
        var controller = this.controller;
        var filters = this.filters;
        var params = {};
        filters.forEach(function (filter) {
          var value = controller.get(filter.query);
          params[filter.query] = value;
          console.log(filter.query, value);
        });
        controller.setProperties(params);
      },
      onSearchKeyUp: function onSearchKeyUp(value, event) {
        // const controller = this.get('controller');
        var name = event.currentTarget.name;
        // controller.set(name, value);
        console.log(name, value);
      }
    }
  });
  _exports.default = _default;
});