define("livarava-web/templates/components/task-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RajWTGmc",
    "block": "{\"symbols\":[],\"statements\":[[10,\"nobr\"],[12],[2,\"\\n  \"],[10,\"i\"],[15,0,[31,[\"dropdown-item-icon \",[34,0]]]],[12],[13],[2,\"\\n  \"],[1,[34,1]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"icon\",\"type\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/task-type.hbs"
    }
  });
  _exports.default = _default;
});