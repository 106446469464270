define("livarava-web/controllers/neuroner/settings/general", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      install: function install(application) {
        var _this = this;
        var url = (0, _getApiUrl.default)('store-application-install', {
          application: application
        });
        var token = Ember.get(this, 'me.token');
        (0, _fetch.default)(url, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          var status = response.status;
          if (status === 'installed') {
            var me = _this.me;
            me.load().then(function () {
              _this.transitionToRoute('index');
            });
          } else if (status === 'payment') {
            var next = response.next;
            if (window && next) {
              window.open(next, '_self');
            }
          } else {
            alert('Error! Please, contact us.');
          }
        }).catch(function () {
          alert('Error! Please, contact us.');
        });
      },
      uninstall: function uninstall() {
        var _this2 = this;
        if (confirm('Are you sure?')) {
          var application = Ember.get(this, 'me.data.neuroner_package');
          var url = (0, _getApiUrl.default)('store-application-uninstall', {
            application: application
          });
          var token = Ember.get(this, 'me.token');
          (0, _fetch.default)(url, {
            headers: {
              'X-LivaRava-Token': token
            }
          }).then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error();
            }
          }).then(function () {
            var me = _this2.me;
            me.load().then(function () {
              _this2.transitionToRoute('index');
            });
          }).catch(function () {
            alert('Error! Please, contact us.');
          });
        }
      },
      upgrade: function upgrade() {
        if (confirm('Are you sure?')) {
          this.transitionToRoute('store.neuroner');
        }
      }
    }
  });
  _exports.default = _default;
});