define("livarava-web/routes/neuroner/feeds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var me = this.me;
      return me.load().then(function (data) {
        params['filter[user]'] = parseInt(data.data.id);
        return _this.store.query('neuroner.feed', params);
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});