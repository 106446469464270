define("livarava-web/helpers/html-safe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function (params) {
    return Ember.String.htmlSafe(params.join(''));
  });
  _exports.default = _default;
});