define("livarava-web/templates/components/product/product-invoice-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "x8t/XE5b",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,4],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"hr\"],[12],[13],[2,\"\\n  No invoices yet.\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table table-striped table-hover\"],[12],[2,\"\\n    \"],[10,\"thead\"],[12],[2,\"\\n    \"],[10,\"tr\"],[12],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Number\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Description\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[2,\"Amount\"],[13],[2,\"\\n      \"],[10,\"th\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,0],null,[[\"item\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,2,null],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"product/product-invoice-item\",\"items\",\"-track-array\",\"each\",\"unless\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/product/product-invoice-list.hbs"
    }
  });
  _exports.default = _default;
});