define("livarava-web/components/neuroner/filter-form-notification", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.DESTINATIONS_TYPES = void 0;
  var DESTINATIONS_TYPES = {
    "email": {
      title: "Email"
    },
    "slack": {
      title: "Slack"
    }
  };
  _exports.DESTINATIONS_TYPES = DESTINATIONS_TYPES;
  var _default = Ember.Component.extend({
    tagName: "div",
    classNames: "form-row mb-2",
    types: DESTINATIONS_TYPES,
    typeLabel: Ember.computed("notifications.kind", function () {
      var TYPE = DESTINATIONS_TYPES[this.get("notifications.kind")];
      return TYPE ? TYPE.title : "Select...";
    }),
    // Actions
    actions: {
      closeDropdownMenu: function closeDropdownMenu() {
        this.set("showDropdownMenu", false);
      },
      change: function change(property, value) {
        this.notifications.set(property, value);
        this.set("showDropdownMenu", false);
      },
      remove: function remove(item) {
        this.sendAction("deleteNotification", item);
      }
    }
  });
  _exports.default = _default;
});