define("livarava-web/controllers/funnels/item", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['manager', 'funnel_next_since', 'funnel_next_until'],
    manager: null,
    funnel_next_since: null,
    funnel_next_until: null,
    ranges: null,
    init: function init() {
      this._super.apply(this, arguments);
      this.set('ranges', {
        Today: [(0, _moment.default)(), (0, _moment.default)()],
        Tomorrow: [(0, _moment.default)().add(1, 'days'), (0, _moment.default)().add(1, 'days')],
        Yesterday: [(0, _moment.default)().subtract(1, 'days'), (0, _moment.default)().subtract(1, 'days')],
        'This week': [(0, _moment.default)().startOf('week'), (0, _moment.default)().endOf('week')],
        'Next 7 Days': [(0, _moment.default)(), (0, _moment.default)().add(6, 'days')],
        'Last 7 Days': [(0, _moment.default)().subtract(6, 'days'), (0, _moment.default)()],
        'Last 30 Days': [(0, _moment.default)().subtract(29, 'days'), (0, _moment.default)()],
        'This Month': [(0, _moment.default)().startOf('month'), (0, _moment.default)().endOf('month')],
        'This Year': [(0, _moment.default)().startOf('year'), (0, _moment.default)().endOf('year')]
      });
    },
    actions: {
      setManager: function setManager(value) {
        Ember.set(this, 'manager', value);
      },
      setDateRange: function setDateRange(range) {
        if (!range) {
          this.setProperties({
            funnel_next_since: null,
            funnel_next_until: null
          });
        } else {
          var _range = (0, _slicedToArray2.default)(range, 2),
            funnel_next_since = _range[0],
            funnel_next_until = _range[1];
          if (funnel_next_since && funnel_next_until) {
            this.setProperties({
              funnel_next_since: (0, _moment.default)(funnel_next_since).format('YYYY-MM-DD'),
              funnel_next_until: (0, _moment.default)(funnel_next_until).format('YYYY-MM-DD')
            });
          }
        }
      },
      setDateUntil: function setDateUntil(range) {
        var _range2 = (0, _slicedToArray2.default)(range, 1),
          funnel_next_until = _range2[0];
        if (!funnel_next_until) {
          this.setProperties({
            funnel_next_since: null,
            funnel_next_until: null
          });
        } else {
          this.setProperties({
            funnel_next_since: (0, _moment.default)(funnel_next_until).subtract('year', 1).format('YYYY-MM-DD'),
            funnel_next_until: (0, _moment.default)(funnel_next_until).format('YYYY-MM-DD')
          });
        }
      }
    }
  });
  _exports.default = _default;
});