define("livarava-web/validations/transaction-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    customer: [(0, _validators.validatePresence)(true)],
    transaction_type: [(0, _validators.validatePresence)(true)],
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      lt: 10e9
    })],
    description: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});