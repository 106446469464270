define("livarava-web/components/site/site-events-list-table-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    // Functions
    onRemove: null,
    // Actions
    actions: {
      delete: function _delete() {
        this.onRemove(this.item);
      }
    }
  });
  _exports.default = _default;
});