define("livarava-web/templates/components/site/site-users-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5iLrzH5A",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"site-member-item clearfix\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"models\"],[\"sites.item.users.item\",[30,[36,2],[[35,1],[35,0,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"site-member-image\"],[15,5,[34,0,[\"imageStyle\"]]],[12],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[10,\"div\"],[14,0,\"site-member-info\"],[12],[2,\"\\n        \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"models\"],[\"sites.item.users.item\",[30,[36,2],[[35,1],[35,0,[\"id\"]]],null]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[1,[35,0,[\"fullname\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"site-member-role\"],[12],[2,\"\\n          \"],[1,[35,0,[\"role\"]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"site_id\",\"array\",\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-users-item.hbs"
    }
  });
  _exports.default = _default;
});