define("livarava-web/templates/events/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "koqYY2lP",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[2,\"Events\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row events\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2,[\"items\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"col-sm-6 mb-3\"],[12],[2,\"\\n\"],[6,[37,1],null,[[\"route\",\"model\"],[\"events.item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"event\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-image\"],[15,5,[32,1,[\"imageStyle\"]]],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"cell-event-title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-date\"],[12],[1,[30,[36,0],[[32,1,[\"startdate\"]],\"MMMM DD, YYYY\"],null]],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-summary\"],[12],[1,[32,1,[\"summary\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"link-to\",\"model\",\"-track-array\",\"each\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/index.hbs"
    }
  });
  _exports.default = _default;
});