define("livarava-web/utils/get-previews", ["exports", "jquery", "livarava-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getPreviews;
  function getPreviews() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    if (_environment.default.environment === 'development') {
      data.key = 'Q0eQWbEUJRerVFqEURGxz8pO8F2yc2sMO-Z1qUu0'; // Add an access token param.
    }

    return new Ember.RSVP.Promise(function (resolve, reject) {
      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.host, "/api/v2/neuroner.previews"),
        data: data,
        crossDomain: true
      }).done(function (data) {
        return data && Array.isArray(data.data) && resolve(Ember.A(data.data.map(function (item) {
          return Ember.Object.create(item.attributes);
        })));
      }).fail(function (error) {
        return reject(error);
      });
    });
  }
});