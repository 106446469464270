define("livarava-web/templates/services/smm", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fVad2TXL",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],null,[[\"model\"],[[35,1]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"model\",\"onSave\"],[[35,1],[30,[36,0],[\"save\"],null]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"service/service-smm\",\"service/service-template\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/services/smm.hbs"
    }
  });
  _exports.default = _default;
});