define("livarava-web/services/speech", ["exports", "@babel/runtime/helpers/esm/typeof", "ember-delay/delay"], function (_exports, _typeof2, _delay) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    router: Ember.inject.service(),
    // Properties
    recognition: null,
    interimTranscript: null,
    finalTranscript: null,
    // Language
    language: 'ru-RU',
    languageToSay: Ember.computed('language', function () {
      var language = this.language;
      return 'en-US ru-RU'.indexOf(language) > -1 ? language : 'ru-RU';
    }),
    // Flags
    showMic: false,
    isRecording: false,
    // Commands List
    commands: null,
    init: function init() {
      this._super();
      if (!('webkitSpeechRecognition' in window)) {
        // upgrade();
      } else {
        this.initRecognition();
      }
      this.commands = [
      // Transitions
      {
        command: 'homepege',
        text: 'homepage, to homepage, go to homepage, ' + 'главная, на главную, перейти на главную',
        transition: 'index'
      }, {
        command: 'projects',
        text: 'projects, to projects, go to projects, ' + 'проекты, показать проекты, перейти к проектам',
        transition: 'projects',
        params: {
          per_page: 6,
          page: 1
        }
      }, {
        command: 'products',
        text: 'products, to products, go to products, ' + 'продукты, показать продукты, перейти к продуктам',
        transition: 'products',
        params: {
          per_page: 6,
          page: 1
        }
      }, {
        command: 'messages',
        text: 'messages, to messages, go to messages, ' + 'сообщения, показать сообщения, перейти к сообщениям',
        transition: 'messages'
      },
      // Language
      {
        command: 'english',
        text: 'english, англійська, английский',
        language: 'en-US'
      }, {
        command: 'русский',
        text: 'russian, російська, русский',
        language: 'ru-RU'
      }, {
        command: 'українська',
        text: 'ukrainian, українська, украинский',
        language: 'uk-UA'
      },
      // Fun
      {
        command: 'как дела',
        text: 'как дела',
        say: 'Спасибо, хорошо!',
        language: 'ru-RU'
      }];
    },
    reset: function reset() {
      Ember.set(this, 'finalTranscript', '');
      Ember.set(this, 'interimTranscript', '');
    },
    initRecognition: function initRecognition() {
      var _this = this;
      var language = this.language;

      /* global webkitSpeechRecognition */
      var recognition = new webkitSpeechRecognition();
      recognition.continuous = false;
      recognition.interimResults = true;
      recognition.onstart = function () {
        Ember.set(_this, 'isRecording', true);
        Ember.set(_this, 'finalTranscript', '');
        Ember.set(_this, 'interimTranscript', '');
      };
      recognition.onresult = function (event) {
        var interimTranscript = '';
        var finalTranscript = _this.finalTranscript;
        for (var i = event.resultIndex; i < event.results.length; ++i) {
          if (event.results[i].isFinal) {
            finalTranscript += event.results[i][0].transcript;
            Ember.set(_this, 'finalTranscript', finalTranscript);
            _this.execute(finalTranscript);
          } else {
            interimTranscript += event.results[i][0].transcript;
          }
        }
        Ember.set(_this, 'interimTranscript', interimTranscript);
        // set(this, 'search', finalTranscript);
      };

      recognition.onerror = function (event) {
        Ember.set(_this, 'isRecording', false);
      };
      recognition.onend = function () {
        Ember.set(_this, 'isRecording', false);
      };
      recognition.lang = language;
      Ember.set(this, 'recognition', recognition);
    },
    start: function start() {
      Ember.set(this, 'showMic', true);
      var isRecording = this.isRecording;
      if (isRecording) {
        return;
      }
      var recognition = this.recognition;
      recognition.start();
    },
    stop: function stop() {
      Ember.set(this, 'showMic', false);
    },
    execute: function execute(transcript) {
      var _this2 = this;
      if (!transcript) {
        return;
      }
      var commands = this.commands;
      var _loop = function _loop() {
        var c = commands[i];
        if (c['text'].indexOf(transcript.toLowerCase()) > -1) {
          Ember.set(_this2, 'command', c['command']);
          if ('transition' in c) {
            (0, _delay.default)(500).then(function () {
              var params = 'params' in c ? c['params'] : null;
              var route = c['transition'];
              _this2.transition(route, params);
              _this2.reset();
            });
            return {
              v: void 0
            };
          } else if ('say' in c) {
            (0, _delay.default)(500).then(function () {
              var text = c['say'];
              var language = c['language'];
              _this2.say(text, language);
              _this2.reset();
            });
            return {
              v: void 0
            };
          } else if ('language' in c) {
            (0, _delay.default)(500).then(function () {
              var language = c['language'];
              _this2.changeLanguage(language);
              _this2.reset();
            });
            return {
              v: void 0
            };
          }
        }
      };
      for (var i = 0; i < commands.length; i++) {
        var _ret = _loop();
        if ((0, _typeof2.default)(_ret) === "object") return _ret.v;
      }
    },
    changeLanguage: function changeLanguage(language) {
      Ember.set(this, 'language', language);
      this.initRecognition();
      if (language === 'en-US') {
        this.say('Ok. Now I will speak in English.');
      } else if (language === 'ru-RU') {
        this.say('Окей. Теперь я буду говорить по-русски.');
      } else if (language === 'uk-UA') {
        this.say('К сожалению, говорить по-украински я пока не умею, но прекрасно понимаю.');
      }
    },
    transition: function transition(route, params) {
      if (!route) {
        return;
      }
      var router = this.router;
      var responses = this.responses;
      params = params ? {
        queryParams: params
      } : {
        queryParams: {}
      };
      this.say('Ok', 'en-US');
      router.transitionTo(route, params);
      Ember.set(this, 'showMic', false);
    },
    say: function say(text, language) {
      var u = new SpeechSynthesisUtterance();
      language = language ? language : this.languageToSay;
      u.text = text;
      u.lang = language;
      u.rate = 1.2;
      speechSynthesis.speak(u);
    }
  });
  _exports.default = _default;
});