define("livarava-web/components/input-image-modal", ["exports", "fetch", "livarava-web/utils/get-api-url", "livarava-web/config/environment"], function (_exports, _fetch, _getApiUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    value: null,
    type: 'image',
    items: null,
    item: null,
    min: 3,
    limit: 12,
    skip: 0,
    // Flags
    isTyping: false,
    isLoading: false,
    isInFocus: false,
    // Computed
    hasPrev: Ember.computed('items.[]', 'skip', function () {
      var items = this.items;
      var skip = this.skip;
      if (!items) return false;
      return skip > 0;
    }),
    hasNext: Ember.computed('items.[]', function () {
      var items = this.items;
      if (!items) return false;
      return items.length === 12;
    }),
    canSave: Ember.computed('item', function () {
      var item = this.item;
      return item !== null;
    }),
    // Reset
    _reset: function _reset() {
      Ember.set(this, 'item', null);
      Ember.set(this, 'items', null);
    },
    // Search
    search: function search(value) {
      var _this = this;
      var limit = this.limit;
      var skip = this.skip;
      var min = this.min;
      var type = this.type;
      this._reset();
      if (value === undefined) {
        return;
      }
      if (value === null) {
        return;
      }
      if (value === '') {
        return;
      }
      if (value && value.length < min) {
        return;
      }
      Ember.set(this, 'isTyping', false);
      Ember.set(this, 'isLoading', true);
      var baseUrl = "".concat(_environment.default.api.host, "/api/v2/autocomplete");
      var url = "".concat(baseUrl, "?q=").concat(value, "&type=").concat(type, "&limit=").concat(limit, "&skip=").concat(skip);
      var token = Ember.get(this, 'me.token');
      var params = {
        headers: {
          'X-LivaRava-Token': token
        }
      };
      (0, _fetch.default)(url, params).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (response) {
        Ember.set(_this, 'isLoading', false);
        Ember.set(_this, 'items', response.data);
      }).catch(function () {
        Ember.set(_this, 'isLoading', false);
        Ember.set(_this, 'items', []);
      });
    },
    // Actions
    actions: {
      onFileUpload: function onFileUpload(data) {
        var _this2 = this;
        var title = data.link;
        var name = data.name;
        var url = (0, _getApiUrl.default)('parse-neuron', {
          title: title
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        Ember.set(this, 'isFileUploading', true);
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (data) {
          if (data && data.hasOwnProperty('neuron_id')) {
            // Load neuron
            _this2.store.findRecord('neuron', data.neuron_id).then(function (item) {
              Ember.set(_this2, 'item', item);
            });
          } else {
            // Update data
            data.header = name;
            data.display = 'public';

            // Init Neuron
            var item = _this2.store.createRecord('neuron', data);

            // Store
            item.save().then(function (item) {
              Ember.set(_this2, 'item', item);
            });
          }

          // Update Flags
          Ember.set(_this2, 'isFileUploading', false);
        }).catch(function () {
          // Update Flags
          Ember.set(_this2, 'isFileUploading', false);
        });
      },
      onInput: function onInput() {
        var value = this.value;
        Ember.run.debounce(this, this.search, value, 500);
      },
      onFocusIn: function onFocusIn() {
        Ember.set(this, 'isInFocus', true);
        var value = this.value;
        this.search.call(this, value);
      },
      onFocusOut: function onFocusOut() {
        Ember.set(this, 'isInFocus', false);
      },
      onKeyUp: function onKeyUp(value, event) {
        Ember.set(this, 'isInFocus', true);
        Ember.set(this, 'isTyping', true);
      },
      onNext: function onNext() {
        var limit = this.limit;
        var skip = this.skip;
        var value = this.value;
        Ember.set(this, 'skip', skip + limit);
        this.search.call(this, value);
      },
      onPrev: function onPrev() {
        var limit = this.limit;
        var skip = this.skip;
        var value = this.value;
        Ember.set(this, 'skip', skip - limit);
        this.search.call(this, value);
      },
      onSelect: function onSelect(image) {
        var _this3 = this;
        if (image === null) return;
        var id = image.data;
        this.store.findRecord('neuron', id).then(function (item) {
          Ember.set(_this3, 'item', item);
        });
      },
      onRemove: function onRemove() {
        Ember.set(this, 'value', null);
        Ember.set(this, 'item', null);
        Ember.set(this, 'items', null);
        this.onSelect(null);
        Ember.set(this, 'show', false);
      },
      onSave: function onSave() {
        var item = this.item;
        Ember.set(this, 'value', null);
        Ember.set(this, 'items', null);
        this.onSelect(item);
        Ember.set(this, 'show', false);
      }
    }
  });
  _exports.default = _default;
});