define("livarava-web/templates/my-motivation/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/muzhjS8",
    "block": "{\"symbols\":[],\"statements\":[[8,\"motivation/funnel-next-terminal\",[],[[\"@manager\",\"@readonlyManager\"],[[34,0,[\"user\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"me\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/my-motivation/index.hbs"
    }
  });
  _exports.default = _default;
});