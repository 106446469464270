define("livarava-web/components/site/site-settings-form", ["exports", "fetch", "livarava-web/config/environment", "livarava-web/utils/get-api-url", "livarava-web/utils/create-axon", "jquery"], function (_exports, _fetch, _environment, _getApiUrl, _createAxon, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    froalaOptions: _environment.default.froalaOptions.max,
    currencyOptions: _environment.default.currencyOptions,
    // Actions
    actions: {
      change: function change(property, value) {
        Ember.set(this, property, value);
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      setImage: function setImage(item) {
        var _this = this;
        if (item === null) {
          Ember.set(this, 'model.image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          Ember.set(_this, 'model.image', image);
        });
      },
      setMetaImage: function setMetaImage(item) {
        var _this2 = this;
        if (item === null) {
          Ember.set(this, 'model.meta_image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          Ember.set(_this2, 'model.meta_image', image);
        });
      },
      setLanguage: function setLanguage(value) {
        if (!value) return;
        Ember.set(this, 'model.language', value);
      },
      onApiKeyClick: function onApiKeyClick() {
        var $apiKey = (0, _jquery.default)('#apiKey');
        $apiKey.focus();
        $apiKey.select();
      },
      generateApiKey: function generateApiKey() {
        var _this3 = this;
        var site_id = Ember.get(this, 'model.id');
        var url = (0, _getApiUrl.default)('generate_site_api_key', {
          site_id: site_id
        });
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          _this3.refreshRoute(_this3.model);
        });
      },
      fileUploaded: function fileUploaded(content, files) {
        var store = this.store;
        var item = this.model;
        var data = JSON.parse(files);
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var file = store.createRecord('neuron', {
            title: title,
            header: header,
            display: display
          });
          file.save().then(function (savedFile) {
            return (0, _createAxon.default)(item.id, savedFile.id, display, token);
          });
        }
        return true;
      },
      imageUploaded: function imageUploaded(content, images) {
        var store = this.store;
        var item = this.model;
        var data = JSON.parse(images);
        var type = 'image';
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var image = store.createRecord('neuron', {
            type: type,
            title: title,
            header: header,
            display: display
          });
          image.save().then(function (savedImage) {
            return (0, _createAxon.default)(item.id, savedImage.id, display, token);
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});