define("livarava-web/components/transaction/transaction-list-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusOptions = Array.from(_environment.default.options.transaction.status);
  var _default = Ember.Component.extend({
    statusOptions: statusOptions,
    displayOptions: _environment.default.displayOptions,
    typeOptions: _environment.default.options.transaction.type,
    // Actions
    actions: {
      delete: function _delete() {
        this.onDelete(this.item);
      },
      fetchOrder: function fetchOrder() {
        var _this = this;
        if (this.get('order')) {
          return;
        }
        return this.get('store').findRecord('order', this.get('item.order.id')).then(function (order) {
          return _this.set('order', order);
        });
      }
    }
  });
  _exports.default = _default;
});