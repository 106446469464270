define("livarava-web/routes/sites/item/members", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    me: Ember.inject.service(),
    // Title
    titleToken: 'Members',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var site_id = this.paramsFor('sites.item').site_id;
      var adminParams = _jquery.default.extend({}, params);
      adminParams['filter[site_admin]'] = site_id;
      var editorParams = _jquery.default.extend({}, params);
      editorParams['filter[site_editor]'] = site_id;
      var memberParams = _jquery.default.extend({}, params);
      memberParams['filter[site_member]'] = site_id;
      return Ember.RSVP.hash({
        site_id: site_id,
        admins: store.query('user', adminParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'admin');
          });
          return items;
        }),
        editors: store.query('user', editorParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'editor');
          });
          return items;
        }),
        members: store.query('user', memberParams).then(function (items) {
          items.forEach(function (item) {
            Ember.set(item, 'role', 'member');
          });
          return items;
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});