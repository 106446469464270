define("livarava-web/templates/components/task-type-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "tlQWvK+K",
    "block": "{\"symbols\":[\"typeChoice\"],\"statements\":[[10,\"select\"],[14,0,\"form-control\"],[15,\"onchange\",[30,[36,2],[[32,0],\"selectType\"],[[\"value\"],[\"target.value\"]]]],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"option\"],[15,2,[32,1,[\"value\"]]],[15,\"selected\",[30,[36,1],[[35,0],[32,1,[\"value\"]]],null]],[12],[1,[32,1,[\"label\"]]],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"type\",\"eq\",\"action\",\"types\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/task-type-picker.hbs"
    }
  });
  _exports.default = _default;
});