define("livarava-web/routes/invoices/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var invoice_id = parseInt(params.id);
      return store.findRecord('invoice', invoice_id).then(function (item) {
        var id = Ember.get(item, 'id');
        var title = "Invoice #".concat(id);
        Ember.set(_this, 'titleToken', title);
        return item;
      });
    }
  });
  _exports.default = _default;
});