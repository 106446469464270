define("livarava-web/templates/components/input-address-type", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "NkWgF/9t",
    "block": "{\"symbols\":[\"dd\",\"menu\",\"type\",\"&default\"],\"statements\":[[8,\"bs-dropdown\",[[24,0,\"input-group-prepend\"]],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[8,[32,1,[\"button\"]],[],[[\"@type\",\"@outline\"],[\"secondary\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,2],[[35,1]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[1,[34,1]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      Тип\\n      \"],[8,\"bs-tooltip\",[],[[\"@placement\"],[\"top\"]],[[\"default\"],[{\"statements\":[[2,\"\\n        Виберіть тип вулиці: бульвар, проспект, провулок...\\n      \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[10,\"span\"],[14,0,\"caret\"],[12],[13],[2,\"\\n  \"]],\"parameters\":[]}]]],[2,\"\\n  \"],[8,[32,1,[\"menu\"]],[[24,0,\"small\"]],[[\"@align\"],[\"left\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[8,[32,2,[\"item\"]],[],[[],[]],[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[11,\"button\"],[24,0,\"dropdown-item text-capitalize\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"select\",[32,3]],null],[12],[2,\"\\n        \"],[1,[32,3,[\"full\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[3]}]]],[2,\"  \"]],\"parameters\":[2]}]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,4,null]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"if\",\"types\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-address-type.hbs"
    }
  });
  _exports.default = _default;
});