define("livarava-web/controllers/axons/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q'],
    page: 1,
    per_page: 7,
    q: null
  });
  _exports.default = _default;
});