define("livarava-web/templates/sites/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "1B7BOUfH",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,[[\"class\",\"footer\"],[\"common-dark\",true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"sites\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n        \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n        \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"sites\"]],[[\"default\"],[{\"statements\":[[2,\"Sites\"]],\"parameters\":[]}]]],[13],[2,\"\\n        \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"New\"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n        \"],[10,\"h2\"],[12],[2,\"New Site\"],[13],[2,\"\\n\\n        \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n        \"],[1,[30,[36,3],null,[[\"model\",\"save\"],[[35,2],[30,[36,1],[\"save\"],null]]]]],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"route-action\",\"model\",\"site/site-settings-form\",\"common/common-content\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/create.hbs"
    }
  });
  _exports.default = _default;
});