define("livarava-web/routes/sites/item/sitemap", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Sitemap',
    // Model
    model: function model() {
      var site_id = this.paramsFor('sites.item').site_id;
      var url = (0, _getApiUrl.default)('sites/generate-sitemap', {
        site_id: site_id
      });
      return (0, _fetch.default)(url).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      });
    }
  });
  _exports.default = _default;
});