define("livarava-web/adapters/feed", ["exports", "livarava-web/adapters/application"], function (_exports, _application) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _application.default.extend({
    pathForType: function pathForType(type) {
      var camelized = Ember.String.dasherize(type);
      return Ember.String.singularize(camelized);
    }
  });
  _exports.default = _default;
});