define("livarava-web/routes/blog", ["exports", "livarava-web/mixins/anonymous-route-mixin"], function (_exports, _anonymousRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_anonymousRouteMixin.default, {
    // Services
    intl: Ember.inject.service(),
    // Model
    model: function model() {
      var locale = this.get('intl.primaryLocale');
      this.transitionTo('lang.blog', locale);
    }
  });
  _exports.default = _default;
});