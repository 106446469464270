define("livarava-web/templates/components/property/input-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JD1TyTMD",
    "block": "{\"symbols\":[\"prediction\"],\"statements\":[[6,[37,13],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"tagName\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"search\",\"allowClear\",\"matchTriggerWidth\",\"placeholder\",\"dropdownClass\"],[[35,12],[35,11],[35,10],[35,9],true,[35,8],[30,[36,7],[[32,0],\"select\"],null],[35,2],[30,[36,7],[[32,0],\"handleSearch\"],null],[35,6],false,[35,5],\"input-city-dropdown\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[32,1,[\"id\"]],[35,2,[\"id\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"small\"],[14,0,\"text-muted d-block\"],[12],[2,\"\\n      \"],[1,[30,[36,1],[[32,1]],[[\"showCity\"],[true]]]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[6,[37,4],[[35,14]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"small\"],[14,0,\"form-text text-black-50 px-2\"],[12],[2,\"\\n    \"],[1,[34,0]],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"details\",\"full-address\",\"value\",\"not-eq\",\"if\",\"placeholder\",\"allowClear\",\"action\",\"predictions\",\"tagName\",\"dropdownClass\",\"triggerClass\",\"klass\",\"power-select\",\"showDetails\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/property/input-property.hbs"
    }
  });
  _exports.default = _default;
});