define("livarava-web/templates/components/footer-addon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l3E7zsr2",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"footer-addon row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-7\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"http://www.megastock.ru/\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"alt\",\"www.megastock.ru\"],[14,\"border\",\"0\"],[14,\"src\",\"https://www.livarava.com/static/livarava/img/webmoney/acc_blue_on_white_en.png\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n     \\n    \"],[10,\"a\"],[14,6,\"https://passport.webmoney.ru/asp/certview.asp?wmid=113292295478\"],[14,\"target\",\"_blank\"],[12],[2,\"\\n      \"],[10,\"img\"],[14,\"alt\",\"Здесь находится аттестат нашего WM идентификатора 113292295478\"],[14,\"border\",\"0\"],[14,\"src\",\"https://www.livarava.com/static/livarava/img/webmoney/v_blue_on_white_en.png\"],[12],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/footer-addon.hbs"
    }
  });
  _exports.default = _default;
});