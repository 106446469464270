define("livarava-web/components/lead/lead-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    item: Ember.computed.reads('model.item'),
    propertyusers: null,
    entities: null,
    // States
    serviceModal: false,
    authorModal: false,
    propertyModal: false,
    loadingEntites: false,
    activeTab: 'tab-actions',
    statusOptions: _environment.default.options.lead.status,
    // city filter for entities table
    entitiesCity: null,
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.fetchActions().then(function () {
        return _this.fetchOrders();
      }).then(function () {
        return _this.fetchProperties();
      }).then(function () {
        return _this.fetchEntities();
      });
    },
    fetchActions: function fetchActions() {
      var _this2 = this;
      this.set('loadingActionItems', true);
      return this.store.query('action', {
        'filter[lead]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (actionItems) {
        _this2.set('actionItems', actionItems);
        _this2.set('loadingActionItems', false);
      }).catch(function () {
        _this2.set('loadingActionItems', false);
      });
    },
    fetchOrders: function fetchOrders() {
      var _this3 = this;
      this.set('loadingOrders', true);
      return this.store.query('order', {
        'filter[lead]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (orders) {
        _this3.set('orders', orders);
        _this3.set('loadingOrders', false);
      }).catch(function () {
        _this3.set('loadingOrders', false);
      });
    },
    fetchProperties: function fetchProperties() {
      var _this4 = this;
      this.set('loadingProperties', true);
      return this.store.query('property', {
        'filter[lead]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (properties) {
        _this4.set('properties', properties);
        _this4.set('loadingProperties', false);
      }).catch(function () {
        _this4.set('loadingProperties', false);
      });
    },
    fetchEntities: function fetchEntities() {
      var _this5 = this;
      this.set('loadingEntities', true);
      return this.store.query('entity', {
        'filter[lead]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (entities) {
        _this5.set('entities', entities);
        _this5.set('loadingEntities', false);
      }).catch(function () {
        _this5.set('loadingEntities', false);
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      setStatus: function setStatus(status) {
        var item = this.item;
        if (!status || status == item.get('status')) {
          return;
        }
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set('status', status);
        return item.save();
      },
      setUserRelationship: function setUserRelationship(relationship, user) {
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!user) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set(relationship, user);
        return item.save();
      },
      handleActionAdded: function handleActionAdded() {
        this.fetchActions();
      }
    }
  });
  _exports.default = _default;
});