define("livarava-web/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+Db6Swlj",
    "block": "{\"symbols\":[],\"statements\":[[10,\"title\"],[12],[1,[35,0,[\"title\"]]],[13]],\"hasEval\":false,\"upvars\":[\"model\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/head.hbs"
    }
  });
  _exports.default = _default;
});