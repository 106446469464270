define("livarava-web/components/service/service-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      delete: function _delete(item) {
        this.onDelete(item);
      }
    }
  });
  _exports.default = _default;
});