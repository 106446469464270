define("livarava-web/templates/components/site/site-sections", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Q6WLKymo",
    "block": "{\"symbols\":[\"sectionRoute\",\"sectionRoute\",\"&default\"],\"statements\":[[6,[37,1],[[30,[36,0],[[35,6],\"horizontal\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-sections nav-horizontal\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\"],[\"nav-link\",[32,2,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[32,2,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[18,3,null],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[30,[36,0],[[35,6],\"vertical\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ul\"],[14,0,\"nav nav-sections nav-vertical flex-column\"],[12],[2,\"\\n\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,1],[[30,[36,0],[[32,1,[\"name\"]],\"general\"],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"li\"],[14,0,\"mt-0 mb-2\"],[12],[2,\"\\n          \"],[10,\"hr\"],[14,0,\"mt-0 mb-0\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"li\"],[14,0,\"nav-item\"],[12],[2,\"\\n\"],[6,[37,2],null,[[\"class\",\"route\"],[\"nav-link\",[32,1,[\"route\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[32,1,[\"title\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"eq\",\"if\",\"link-to\",\"sectionsRoutes\",\"-track-array\",\"each\",\"orientation\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site/site-sections.hbs"
    }
  });
  _exports.default = _default;
});