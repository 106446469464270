define("livarava-web/components/site/site-api", ["exports", "fetch", "livarava-web/utils/get-api-url", "jquery"], function (_exports, _fetch, _getApiUrl, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      save: function save() {
        this.save(this.model);
      },
      onApiKeyClick: function onApiKeyClick() {
        var $apiKey = (0, _jquery.default)('#apikey');
        $apiKey.focus();
        $apiKey.select();
      },
      generateApiKey: function generateApiKey() {
        var _this = this;
        if (confirm('Are you sure?')) {
          var site_id = Ember.get(this, 'model.id');
          var url = (0, _getApiUrl.default)('generate_site_api_key', {
            site_id: site_id
          });
          var token = Ember.get(this, 'me.token');
          var params = {
            headers: {
              'X-LivaRava-Token': token
            }
          };
          (0, _fetch.default)(url, params).then(function (response) {
            if (response.ok) {
              return response.json();
            } else {
              throw new Error();
            }
          }).then(function () {
            _this.refresh(_this.model);
          });
        }
      }
    }
  });
  _exports.default = _default;
});