define("livarava-web/routes/services/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'New Service',
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      var item;
      item = store.createRecord('service', {
        title: null,
        summary: null
      });
      return item;
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function (item) {
          return _this.transitionTo('services.index');
        });
      }
    }
  });
  _exports.default = _default;
});