define("livarava-web/routes/entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    model: function model() {
      if (!this.get('me.rights.entities')) {
        return this.transitionTo('error403');
      }
    }
  });
  _exports.default = _default;
});