define("livarava-web/components/neuroner/feed-item", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    url: null,
    tab: null,
    rule: null,
    selector: null,
    selectors: null,
    // Flags
    showRule: false,
    // Handlers
    onAddRule: null,
    // Actions
    actions: {
      showMagic: function showMagic(url) {
        var _this = this;
        if (this.tab === 'magic') {
          Ember.set(this, 'tab', null);
          return;
        }
        var neuroner_url = _environment.default.api.neuroner;
        var apiUrl = "".concat(neuroner_url, "/api/get-selector/?url=").concat(url);
        var token = Ember.get(this, 'me.token');
        var rule = {
          url: url
        };
        (0, _fetch.default)(apiUrl, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (data) {
          // console.log(data);
          rule.selector = data.selector;
          Ember.set(_this, 'selectors', data.selectors);
          Ember.set(_this, 'selector', data.selector);
          Ember.set(_this, 'rule', rule);
          _this.toggleProperty('showRule');
        }).catch(function () {
          Ember.set(_this, 'error', 'Error! Please, ask admin to help.');
        });
        Ember.set(this, 'tab', 'magic');
      },
      closeMagic: function closeMagic() {
        Ember.set(this, 'tab', null);
      },
      addRule: function addRule(rule) {
        var onAddRule = this.onAddRule;
        onAddRule(rule);
      },
      showPreview: function showPreview() {
        if (this.tab === 'preview') {
          Ember.set(this, 'tab', null);
          return;
        }
        Ember.set(this, 'tab', 'preview');
      },
      closePreview: function closePreview() {
        Ember.set(this, 'tab', null);
      }
    }
  });
  _exports.default = _default;
});