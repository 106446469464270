define("livarava-web/controllers/sites/item/events/index", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: ['page', 'per_page', 'q', 'lira', 'language'],
    page: 1,
    per_page: 5,
    q: null,
    lira: null,
    language: null,
    date_start: null,
    date_end: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'lira',
      query: 'lira',
      type: 'neuron',
      placeholder: 'Lira'
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }]),
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          var site_id = item.get('site.id');
          item.destroyRecord().then(function () {
            return _this.transitionToRoute('sites.item.events', site_id);
          });
        }
      }
    }
  });
  _exports.default = _default;
});