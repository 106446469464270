define("livarava-web/templates/components/neuroner/customer-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "4nlPUqoV",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      Package: \"],[1,[35,2,[\"neuroner_label\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Due: \"],[6,[37,3],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[1,[30,[36,1],[[35,0],\"YYYY-MM-DD\"],null]]],\"parameters\":[]},{\"statements\":[[2,\"None\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Feeds: \"],[1,[35,2,[\"neuroner_feeds_current\"]]],[2,\"/\"],[1,[35,2,[\"neuroner_feeds_limit\"]]],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[12],[2,\"\\n      Filters: \"],[1,[35,2,[\"neuroner_filters_current\"]]],[2,\"/\"],[1,[35,2,[\"neuroner_filters_limit\"]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"class\",\"item\"],[\"img-fluid\",[35,4]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"due\",\"moment-format\",\"data\",\"if\",\"item\",\"user/user-avatar\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/customer-item.hbs"
    }
  });
  _exports.default = _default;
});