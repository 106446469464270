define("livarava-web/utils/get-task-type-label", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getTaskTypeLabel;
  function getTaskTypeLabel(type) {
    var out = null;
    _environment.default.options.taskType.forEach(function (type_) {
      if (type_.value === type) {
        out = type_.label;
      }
    });
    return out;
  }
});