define("livarava-web/templates/my-motivation/registrations-by-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qGniiAWM",
    "block": "{\"symbols\":[],\"statements\":[[8,\"motivation/analytics-registration-by-manager\",[],[[\"@manager\",\"@readonlyManager\"],[[34,0,[\"user\"]],true]],null]],\"hasEval\":false,\"upvars\":[\"me\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/my-motivation/registrations-by-manager.hbs"
    }
  });
  _exports.default = _default;
});