define("livarava-web/models/neuron", ["exports", "ember-data", "livarava-web/utils/get-display-label", "livarava-web/utils/get-display-icon-class", "livarava-web/utils/is-url"], function (_exports, _emberData, _getDisplayLabel, _getDisplayIconClass, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    header: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    url: _emberData.default.attr('string'),
    location: _emberData.default.attr('string'),
    type_location: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    online: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number'),
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    // Subscribers count
    comment_count: _emberData.default.attr('number'),
    // Comments count
    axon_count: _emberData.default.attr('number'),
    // Axons count

    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    startdate: _emberData.default.attr('date'),
    enddate: _emberData.default.attr('date'),
    // Type
    type: _emberData.default.attr('string'),
    typeLabel: Ember.computed('type', function () {
      var type = this.type;
      return type[0].toUpperCase() + type.slice(1, type.length);
    }),
    // Display
    display: _emberData.default.attr('string'),
    displayLabel: Ember.computed('display', function () {
      return (0, _getDisplayLabel.default)(this.display);
    }),
    displayIconClass: Ember.computed('display', function () {
      return (0, _getDisplayIconClass.default)(this.display);
    }),
    // Icon
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon_url', function () {
      var url = this.icon_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe(url);
    }),
    // Image
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed('image_url', function () {
      var url = this.image_url;
      if (!url) return null;
      if (!(0, _isUrl.default)(url)) return null;
      return Ember.String.htmlSafe(url);
    }),
    imageStyle: Ember.computed('imageUrl', 'iconUrl', function () {
      var imageUrl = this.imageUrl;
      var iconUrl = this.iconUrl;
      var outUrl;
      if (imageUrl && (0, _isUrl.default)(imageUrl)) {
        outUrl = Ember.String.htmlSafe("background-image: url(".concat(imageUrl, ");"));
      }
      if (iconUrl && (0, _isUrl.default)(iconUrl)) {
        outUrl = Ember.String.htmlSafe("background-image: url(".concat(iconUrl, ");"));
      }
      return outUrl;
    }),
    // Video
    video_type: _emberData.default.attr('string'),
    video_url: _emberData.default.attr('string'),
    video_embed_url: _emberData.default.attr('string'),
    // Link
    link_url: Ember.computed('type', 'title', function () {
      var type = this.type;
      var url = this.title;
      return type === 'link' ? url : null;
    }),
    // Author
    author: _emberData.default.belongsTo('user')
  });
  _exports.default = _default;
});