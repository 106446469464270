define("livarava-web/components/payment/payment-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Computed
    statusClass: Ember.computed('item.status', function () {
      var status = this.get('item.status');
      switch (status) {
        case 'success':
          return 'text-success';
        case 'canceled':
          return 'text-danger';
        case 'failed':
          return 'text-danger';
        default:
          return '';
      }
    })
  });
  _exports.default = _default;
});