define("livarava-web/controllers/forgot", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Attributes
    email: null,
    error: null,
    // States
    isSending: false,
    // Actions
    actions: {
      onSubmit: function onSubmit() {
        var _this = this;
        var host = _environment.default.api.host;
        var path = _environment.default.api.path;
        var time = new Date().getTime();
        var email = this.email;
        var site = _environment.default.neuronet.uid;
        var url = "".concat(host).concat(path, "/password-forgot/") + "?__t=".concat(time) + "&email=".concat(email) + "&site=".concat(site);
        this.set('isSending', true);
        this.set('error', null);
        (0, _fetch.default)(url).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', null);
          _this.send('toRestore', email);
        }).catch(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', 'Unexpected error. Please, report to admin@livarava.com');
        });
      }
    }
  });
  _exports.default = _default;
});