define("livarava-web/components/main-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    axonNeuron: null,
    // States
    isOpen: true,
    // Rights
    canConnect: Ember.computed('axonNeuron', function () {
      return !this.axonNeuron;
    }),
    // Actions
    actions: {
      setAxonNeuron: function setAxonNeuron(value, item) {
        this.set('axonNeuron', item);
      },
      connect: function connect() {
        // let liva_id = parseInt(this.get('model.id'));
        // let rava_id = parseInt(this.get('axonNeuron.id'));
        //
        // let liva = this.get('store').peekRecord('neuron', liva_id);
        // let rava = this.get('store').peekRecord('neuron', rava_id);
      }
    }
  });
  _exports.default = _default;
});