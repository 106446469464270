define("livarava-web/routes/tasks/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    me: Ember.inject.service(),
    store: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    // Title
    titleToken: 'New Task',
    // params
    queryParams: {
      copy: {
        refreshModel: true
      },
      project: {
        refreshModel: true
      },
      product: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      // Store
      var store = this.store;
      var localSettings = this.localSettings;

      // Params
      var copyId = parseInt(params.copy);
      var created = (0, _moment.default)();
      var datetime = (0, _moment.default)(Date.now() + 7 * 24 * 3600 * 1000).format('YYYY-MM-DD HH:mm');
      var taskType = 'research';
      var amount = parseFloat(10.0);
      var currency = 'USD';
      var author = this.me;
      var projectId = parseInt(params.project || localSettings.get('settings.project_id'));
      var productId = parseInt(params.product || localSettings.get('settings.product_id'));
      if (Number.isInteger(copyId)) {
        return store.findRecord('task', copyId).then(function (data) {
          var copy = store.createRecord('task', data.serialize().data.attributes);
          var title = Ember.get(data, 'title');
          Ember.set(copy, 'title', "Copy of ".concat(title));
          Ember.set(copy, 'created', created);
          Ember.set(copy, 'updated', created);
          Ember.set(copy, 'due', datetime);
          return copy;
        });
      }

      // Init Task
      var task = store.createRecord('task', {
        // Attributes
        title: '',
        task_type: taskType,
        // Date & Time
        due: datetime,
        hours: 8,
        // Payment
        amount: amount,
        currency: currency,
        // Relationships
        author: author
      });

      // Load Project and Product from Store and update Task properties

      if (Number.isInteger(projectId)) {
        store.findRecord('neuron', projectId).then(function (item) {
          return Ember.set(task, 'project', item);
        });
      }
      if (Number.isInteger(productId)) {
        store.findRecord('neuron', productId).then(function (item) {
          return Ember.set(task, 'product', item);
        });
      }
      return task;
    }
  });
  _exports.default = _default;
});