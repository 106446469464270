define("livarava-web/templates/components/motivation/actions-distribution-by-managers", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5yyEP1su",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,1],null,[[\"distribution\",\"captionLabel\",\"columnLabel\",\"tooltipLabel\"],[[35,0],\"Розподіл виконаних дотиків по менеджерах\",\"Менеджер\",\"Виконано дотиків\"]]]]],\"hasEval\":false,\"upvars\":[\"distribution\",\"motivation/distribution\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/motivation/actions-distribution-by-managers.hbs"
    }
  });
  _exports.default = _default;
});