define("livarava-web/routes/properties/create", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: 'New Invoice',
    // Query Params
    queryParams: {
      copy: {
        reloadModel: true
      },
      lead: {
        reloadModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var copyId = params.copy;
      var leadId = params.lead;
      var created = (0, _moment.default)();
      if (copyId) {
        return store.findRecord('property', copyId).then(function (data) {
          return store.createRecord('property', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.serialize().data.attributes), {}, {
            created: created
          }));
        });
      } else if (leadId) {
        return store.findRecord('lead', leadId).then(function (lead) {
          var property = store.createRecord('property', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, lead.getProperties('title', 'summary', 'description', 'property_number', 'property_type', 'business_id', 'business_type', 'area_total', 'floor_count', 'flat_count', 'entrance_count', 'city', 'address', 'address_area_level_1', 'address_area_level_2', 'address_postal_code', 'address_type', 'address_name', 'address_building', 'address_apartment', 'phone', 'email', 'website', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'manager', 'project')), {}, {
            // final_deadline: created,
            // apply_deadline: created,
            created: created,
            lead: lead
          }));
          return property;
        });
      } else {
        return Ember.RSVP.hash({
          author: store.findRecord('user', this.get('me.id'))
        }).then(function (_ref) {
          var author = _ref.author;
          return store.createRecord('property', {
            created: created,
            author: author,
            title: '',
            address: '',
            email: author.get('email'),
            phone: author.get('phone'),
            property_type: 'condominium'
          });
        });
      }
    },
    // Actions
    actions: {}
  });
  _exports.default = _default;
});