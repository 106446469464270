define("livarava-web/templates/components/infinite-scroll-container", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "e4LfgqtF",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1,[\"imageUrl\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"h3\"],[12],[2,\"\\n      \"],[1,[32,1,[\"title\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[11,\"img\"],[4,[38,0],null,[[\"src\"],[[32,1,[\"imageUrl\"]]]]],[12],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container-body-text\"],[12],[2,\"\\n      \"],[2,[32,1,[\"body\"]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[1,[30,[36,6],null,[[\"scrollContainer\"],[[35,5]]]]]],\"hasEval\":false,\"upvars\":[\"bind-attr\",\"if\",\"model\",\"-track-array\",\"each\",\"scrollContainer\",\"infinite-scroll\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/infinite-scroll-container.hbs"
    }
  });
  _exports.default = _default;
});