define("livarava-web/helpers/expand-line-breaks", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.expandLineBreaks = expandLineBreaks;
  function expandLineBreaks(_ref /*, hash*/) {
    var _ref2 = (0, _slicedToArray2.default)(_ref, 1),
      text = _ref2[0];
    return Ember.String.htmlSafe(text.replace(/\n/g, '<br>'));
  }
  var _default = Ember.Helper.helper(expandLineBreaks);
  _exports.default = _default;
});