define("livarava-web/routes/my-motivation", ["exports", "livarava-web/mixins/authenticated-route-mixin", "jquery"], function (_exports, _authenticatedRouteMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /* global echarts */loadEcharts: function loadEcharts() {
      if (typeof echarts == 'undefined') {
        return new Promise(function (resolve) {
          var url = 'https://cdn.jsdelivr.net/npm/echarts@5.1.1/dist/echarts.min.js';
          _jquery.default.getScript(url, function () {
            return resolve(echarts);
          });
        });
      }
      return Promise.resolve(echarts);
    },
    model: function model() {
      if (!this.get('me.rights.funnels')) {
        return this.transitionTo('error403');
      }
      return this.loadEcharts();
    }
  });
  _exports.default = _default;
});