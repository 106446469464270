define("livarava-web/components/event/event-item", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Service
    me: Ember.inject.service(),
    store: Ember.inject.service(),
    router: Ember.inject.service(),
    // Properties
    tickets: null,
    ticketsTotal: null,
    // Flags
    isLoading: false,
    showTicketsModal: false,
    canCheckout: Ember.computed('ticket', function () {
      var ticket = this.ticket;
      return ticket;
    }),
    canRegister: Ember.computed('item.enddate', function () {
      var enddate = Ember.get(this, 'item.enddate');
      var now = (0, _moment.default)();
      var endDate = enddate.toDate();
      var nowDate = now.toDate();
      return nowDate < endDate;
    }),
    canManage: Ember.computed('me.id', 'me.admin', 'item.author.id', function () {
      var meId = parseInt(Ember.get(this, 'me.id'));
      var meAdmin = Ember.get(this, 'me.admin');
      var authorId = parseInt(Ember.get(this, 'item.author.id'));
      return meId && authorId && meId === authorId || meAdmin;
    }),
    // Functions
    countTicketsTotal: function countTicketsTotal() {
      var tickets = this.tickets;
      var total = [];
      tickets.forEach(function (ticket) {
        total[ticket.uid] = parseInt(ticket.quantity);
      });
      Ember.set(this, 'ticketsTotal', total);
    },
    countTickets: function countTickets(reserved) {
      var tickets = this.tickets;
      var total = this.ticketsTotal;
      tickets.forEach(function (ticket) {
        var quantityTotal = total[ticket.uid];
        var quantityReserved = reserved[ticket.uid];
        var quantity = quantityTotal - quantityReserved;
        Ember.set(ticket, 'quantity', quantity);
      });
      Ember.set(this, 'tickets', tickets);
      Ember.set(this, 'isLoading', false);
    },
    // Actions
    actions: {
      showTicketsModal: function showTicketsModal() {
        var _this = this;
        var store = this.store;
        var eventId = Ember.get(this, 'item.id');
        var tickets = Ember.get(this, 'item.tickets');
        var ticketsTotal = this.ticketsTotal;
        var counter = 0;
        Ember.set(this, 'showTicketsModal', true);
        Ember.set(this, 'isLoading', true);
        if (!ticketsTotal) {
          this.countTicketsTotal();
        }

        // Load Reserved Tickets Quantity
        var reserved = [];
        tickets.forEach(function (ticket) {
          store.query('ticket', {
            'filter[event]': eventId,
            'filter[ticket_uid]': ticket.uid,
            per_page: 1,
            page: 1
          }).then(function (items) {
            reserved[ticket.uid] = parseInt(Ember.get(items, 'meta.total_results'));
            counter += 1;
            if (counter === tickets.length) {
              _this.countTickets(reserved);
            }
          });
        });
      },
      hideTicketsModal: function hideTicketsModal() {
        Ember.set(this, 'showTicketsModal', false);
      },
      selectTicket: function selectTicket(ticket) {
        if (ticket.quantity === 0) {
          return;
        }
        var tickets = Ember.get(this, 'item.tickets');
        tickets.forEach(function (item) {
          Ember.set(item, 'active', false);
        });
        Ember.set(ticket, 'active', true);
        Ember.set(this, 'ticket', ticket);
      },
      checkout: function checkout() {
        var router = this.router;
        var event = this.item;
        var ticket = Ember.get(this, 'ticket.uid');
        router.transitionTo('events.register', event.id, {
          queryParams: {
            ticket: ticket
          }
        });
      }
    }
  });
  _exports.default = _default;
});