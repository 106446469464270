define("livarava-web/components/input-froala-editor", ["exports", "livarava-web/config/environment", "livarava-web/utils/create-axon"], function (_exports, _environment, _createAxon) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    froalaOptions: _environment.default.froalaOptions.max,
    // Actions
    actions: {
      fileUploaded: function fileUploaded(content, files) {
        var store = Ember.get(this, 'store');
        var item = Ember.get(this, 'model');
        var data = JSON.parse(files);
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var file = store.createRecord('neuron', {
            title: title,
            header: header,
            display: display
          });
          file.save().then(function (savedFile) {
            return (0, _createAxon.default)(item.id, savedFile.id, display, token);
          });
        }
        return true;
      },
      imageUploaded: function imageUploaded(content, images) {
        var store = Ember.get(this, 'store');
        var item = Ember.get(this, 'model');
        var data = JSON.parse(images);
        var type = 'image';
        var title = data.link;
        var header = data.name;
        var display = 'public';
        var token = Ember.get(this, 'me.token');
        if (item.get('id')) {
          var image = store.createRecord('neuron', {
            type: type,
            title: title,
            header: header,
            display: display
          });
          image.save().then(function (savedImage) {
            return (0, _createAxon.default)(item.id, savedImage.id, display, token);
          });
        }
        return true;
      }
    }
  });
  _exports.default = _default;
});