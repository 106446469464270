define("livarava-web/utils/create-axon", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createAxon;
  function createAxon(livaId, ravaId) {
    var display = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 'public';
    var token = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    if (livaId === null) {
      // Ember.Logger.log('AxonError: Missing Liva ID');
      return;
    }
    if (ravaId === null) {
      // Ember.Logger.log('AxonError: Missing Rava ID');
      return;
    }
    var url = (0, _getApiUrl.default)('create-axon', {
      liva: livaId,
      rava: ravaId,
      display: display
    });
    return (0, _fetch.default)(url, {
      headers: {
        'X-LivaRava-Token': token
      }
    }).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error();
      }
    });
  }
});