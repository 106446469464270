define("livarava-web/templates/forbidden", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "m0W+Zq9j",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-page text-center\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-5 mb-5\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-lock fa-superbig\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Forbidden\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n    Please, \"],[6,[37,0],null,[[\"route\"],[\"contact\"]],[[\"default\"],[{\"statements\":[[2,\"contact us\"]],\"parameters\":[]}]]],[2,\"\\n    or report to \"],[10,\"a\"],[14,6,\"mailto:admin@livarava.com\"],[12],[2,\"admin@livarava.com\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      Go to homepage\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/forbidden.hbs"
    }
  });
  _exports.default = _default;
});