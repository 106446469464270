define("livarava-web/routes/settings/invitations", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: 'Invitations',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var author = parseInt(Ember.get(this, 'me.id'));
      params['author'] = author;
      return Ember.RSVP.hash({
        sent: store.query('invitation', {
          author: author,
          'filter[status]': 'sent',
          per_page: 1,
          page: 1
        }),
        accepted: store.query('invitation', {
          author: author,
          'filter[status]': 'accepted',
          per_page: 1,
          page: 1
        }),
        canceled: store.query('invitation', {
          author: author,
          'filter[status]': 'canceled',
          per_page: 1,
          page: 1
        }),
        items: store.query('invitation', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});