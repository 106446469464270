define("livarava-web/templates/components/common/common-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "urQturdp",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3,[\"signed\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"nomenu\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[1,[30,[36,1],null,[[\"nomenu\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"nomenu\",\"main-navigation\",\"main-header\",\"me\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-header.hbs"
    }
  });
  _exports.default = _default;
});