define("livarava-web/transforms/array-of-objects", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      if (Array.isArray(serialized)) {
        return Ember.A(serialized.map(function (item) {
          return Ember.Object.create(item);
        }));
      }
      return serialized;
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
  _exports.default = _default;
});