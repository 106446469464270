define("livarava-web/components/motivation/funnel-next-terminal", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/objectSpread2", "moment", "livarava-web/config/environment"], function (_exports, _toConsumableArray2, _objectSpread2, _moment, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // attributes
    readonlyManager: false,
    modelType: 'order',
    // filters
    date: null,
    manager: null,
    // data
    loaded: false,
    overdueItems: null,
    todayItems: null,
    weekItems: null,
    settings: null,
    per_page: 10,
    fetchData: function fetchData() {
      var _this = this;
      var managerId = this.get('manager.id');
      var date = this.date,
        store = this.store,
        modelType = this.modelType,
        per_page = this.per_page;
      var mdate = (0, _moment.default)(date);
      var requestParams = {
        per_page: per_page,
        page: 1,
        'filter[status]': 'draft,opened,accepted'
      };
      if (managerId) {
        requestParams['filter[manager]'] = managerId;
      }
      return Ember.RSVP.hash({
        overdueItems: store.query(modelType, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          'filter[funnel_next][until]': mdate.clone().utc().subtract(1, 'days').endOf('day').toJSON()
        })),
        todayItems: store.query(modelType, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          'filter[funnel_next][since]': mdate.clone().utc().startOf('day').toJSON(),
          'filter[funnel_next][until]': mdate.clone().utc().endOf('day').toJSON()
        })),
        weekItems: store.query(modelType, (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
          'filter[funnel_next][since]': mdate.clone().utc().add(1, 'days').startOf('day').toJSON(),
          'filter[funnel_next][until]': mdate.clone().utc().endOf('week').toJSON()
        }))
      }).then(function (results) {
        return _this.setProperties(results);
      }).finally(function () {
        return _this.set('loaded', true);
      });
    },
    fetchManagers: function fetchManagers() {
      var _this2 = this;
      var store = this.store;
      var ids = [].concat((0, _toConsumableArray2.default)(_environment.default.options.manager.managers), [this.get('me.id')]);
      return Promise.all(ids.map(function (id) {
        return store.findRecord('user', id);
      })).then(function (managers) {
        _this2.set('managers', managers);
        return managers;
      });
    },
    init: function init() {
      var _this3 = this;
      this._super.apply(this, arguments);
      this.set('date', _moment.default.utc().startOf('day').toDate());
      this.set('settings', [{
        key: 'overdueItems',
        title: 'Прострочені',
        doneLabel: 'Прострочених немає',
        icon: 'exclamation-triangle',
        color: 'danger'
      }, {
        key: 'todayItems',
        title: 'На сьогодні',
        doneLabel: 'На сьогодні все виконано',
        icon: 'bell',
        color: 'warning'
      }, {
        key: 'weekItems',
        title: 'На цей тиждень',
        doneLabel: 'На цей тиждень все виконано',
        icon: '',
        color: ''
      }]);
      this.fetchManagers().then(function () {
        return _this3.loading.run(function () {
          return _this3.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDate: function setDate(date) {
        var _this4 = this;
        if (date && date[0]) {
          this.set('date', date && date[0]);
          this.loading.run(function () {
            return _this4.fetchData();
          });
        }
      },
      setManager: function setManager(manager) {
        var _this5 = this;
        this.set('manager', manager);
        this.loading.run(function () {
          return _this5.fetchData();
        });
      }
    }
  });
  _exports.default = _default;
});