define("livarava-web/components/neuron-input-form", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    item: null,
    value: null,
    rava: null,
    // States
    isLoading: false,
    // Events
    onChange: null,
    // Parse Neuron
    parseNeuron: function parseNeuron(value) {
      var _this = this;
      var isLoading = this.isLoading;
      if (isLoading) {
        return;
      }
      if (value === null) {
        return null;
      }
      if (value === '') {
        return null;
      }
      Ember.set(this, 'isLoading', true);
      var store = this.store;
      var url = (0, _getApiUrl.default)('parse-neuron', {
        title: value
      });
      (0, _fetch.default)(url).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        if (data.hasOwnProperty('neuron_id')) {
          // If neuron was found and has ID
          // Load Neuron
          var neuronId = parseInt(data.neuron_id);
          store.findRecord('neuron', neuronId).then(function (item) {
            Ember.set(_this, 'isLoading', false);
            _this.setRava(value, item);
          });
        } else {
          // If neuron was not found but parsed
          // Create Neuron
          var item = store.createRecord('neuron', data);
          Ember.set(_this, 'isLoading', false);
          _this.setRava(value, item);
        }
      }).catch(function () {
        // If neuron was not found and had parse error
        Ember.set(_this, 'isLoading', false);
        _this.setRava(value, null);
      });
    },
    setRava: function setRava(value, item) {
      Ember.set(this, 'rava', item);
      if (this.onChange) {
        this.onChange(item);
      }
    },
    // Actions
    actions: {
      onChange: function onChange(value, item) {
        Ember.set(this, 'isLoading', false);
        Ember.set(this, 'value', null);
        this.setRava(value, item);
      },
      onSelect: function onSelect(value, item) {
        Ember.set(this, 'isLoading', false);
        Ember.set(this, 'value', null);
        this.setRava(value, item);
      },
      onInput: function onInput(value) {
        Ember.run.debounce(this, this.parseNeuron, value, 1000);
      }
    }
  });
  _exports.default = _default;
});