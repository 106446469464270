define("livarava-web/templates/components/user/user-avatar", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "V87GXEfM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"user-avatar \",[34,0]]]],[15,5,[34,1]],[15,\"title\",[31,[[34,2,[\"fullname\"]]]]],[12],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"klass\",\"style\",\"item\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/user/user-avatar.hbs"
    }
  });
  _exports.default = _default;
});