define("livarava-web/adapters/application", ["exports", "ember-data", "livarava-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.JSONAPIAdapter.extend({
    // Services
    me: Ember.inject.service(),
    cookies: Ember.inject.service(),
    // Variables
    host: _environment.default.api.host,
    namespace: 'api/v2',
    // Headers
    headers: Ember.computed('me.token', 'cookies', function () {
      var token = this.get('me.token') || this.cookies.read('token') || _environment.default.api.token;
      return token ? {
        'X-LivaRava-Token': token
      } : {};
    })
  });
  _exports.default = _default;
});