define("livarava-web/routes/sites/item/users/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var user_id = params['user_id'];
      return store.findRecord('user', user_id);
    }
  });
  _exports.default = _default;
});