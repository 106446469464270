define("livarava-web/routes/services/tree", ["exports", "livarava-web/mixins/authenticated-route-mixin", "jquery"], function (_exports, _authenticatedRouteMixin, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Services Admin',
    /* global echarts */loadEcharts: function loadEcharts() {
      if (typeof echarts == 'undefined') {
        return new Promise(function (resolve) {
          var url = 'https://cdn.jsdelivr.net/npm/echarts@5.2.1/dist/echarts.min.js';
          _jquery.default.ajax({
            type: 'GET',
            url: url,
            success: function success() {
              return resolve(echarts);
            },
            dataType: 'script',
            cache: true
          });
        });
      }
      return Promise.resolve(echarts);
    },
    beforeModel: function beforeModel() {
      return this.loadEcharts();
    },
    // Model
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        services: store.query('service', {
          // q: 'водо',
          per_page: 1000,
          'filter[service_type]': 'job',
          order: 'title'
        }).then(function (services) {
          return services.sortBy('title');
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});