define("livarava-web/validators/date", ["exports", "ember-changeset-validations/utils/validation-errors", "ember-changeset-validations/utils/with-defaults", "moment"], function (_exports, _validationErrors, _withDefaults, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = validateDate;
  function validateDate() {
    var options = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    options = (0, _withDefaults.default)(options, {
      allowBlank: false,
      errorFormat: 'YYYY-MM-DD HH:mm'
    });
    return function (key, value) {
      var _options = options,
        allowBlank = _options.allowBlank,
        errorFormat = _options.errorFormat;
      var _options2 = options,
        future = _options2.future,
        before = _options2.before,
        onOrBefore = _options2.onOrBefore,
        after = _options2.after,
        onOrAfter = _options2.onOrAfter,
        message = _options2.message;
      var type = 'date';
      if (allowBlank && (typeof value === 'undefined' || value === null)) {
        return true;
      }
      var date = (0, _moment.default)(value);
      if (!date.isValid()) {
        return (0, _validationErrors.default)(key, {
          type: type,
          value: 'not a date',
          context: {
            value: value,
            message: message
          }
        });
      }
      if (future) {
        message = message || "\u0414\u0430\u0442\u0430 \u043F\u043E\u0432\u0438\u043D\u043D\u0430 \u0431\u0443\u0442\u0438 \u0432 \u043C\u0430\u0439\u0431\u0443\u0442\u043D\u044C\u043E\u043C\u0443";
        type = 'future';
        if (!date.isAfter((0, _moment.default)())) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              future: future,
              message: message
            }
          });
        }
      }
      if (before) {
        before = (0, _moment.default)(before);
        message = message || "\u0414\u0430\u0442\u0430 \u043F\u043E\u0432\u0438\u043D\u043D\u0430 \u0431\u0443\u0442\u0438 \u0434\u043E ".concat(before.format(errorFormat));
        type = 'before';
        if (!date.isBefore(before)) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              before: before,
              message: message
            }
          });
        }
      }
      if (onOrBefore) {
        onOrBefore = (0, _moment.default)(onOrBefore);
        message = message || "\u0414\u0430\u0442\u0430 \u043F\u043E\u0432\u0438\u043D\u043D\u0430 \u0431\u0443\u0442\u0438 \u0434\u043E ".concat(onOrBefore.format(errorFormat), " \u0432\u043A\u043B\u044E\u0447\u043D\u043E");
        type = 'onOrBefore';
        if (!date.isSameOrBefore(onOrBefore)) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              onOrBefore: onOrBefore,
              message: message
            }
          });
        }
      }
      if (after) {
        after = (0, _moment.default)(after);
        message = message || "\u0414\u0430\u0442\u0430 \u043F\u043E\u0432\u0438\u043D\u043D\u0430 \u0431\u0443\u0442\u0438 \u043F\u0456\u0441\u043B\u044F ".concat(after.format(errorFormat));
        type = 'after';
        if (!date.isAfter(after)) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              after: after,
              message: message
            }
          });
        }
      }
      if (onOrAfter) {
        onOrAfter = (0, _moment.default)(onOrAfter);
        message = message || "\u0414\u0430\u0442\u0430 \u043F\u043E\u0432\u0438\u043D\u043D\u0430 \u0431\u0443\u0442\u0438 \u043F\u0456\u0441\u043B\u044F ".concat(onOrAfter.format(errorFormat), " \u0432\u043A\u043B\u044E\u0447\u043D\u043E");
        type = 'onOrAfter';
        if (!date.isSameOrAfter(after)) {
          return (0, _validationErrors.default)(key, {
            type: type,
            value: value,
            context: {
              onOrAfter: onOrAfter,
              message: message
            }
          });
        }
      }
      return true;
    };
  }
});