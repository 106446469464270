define("livarava-web/routes/signout", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    me: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Params
    queryParams: {
      next: {
        refreshModel: true
      }
    },
    goto: function goto(next) {
      if (next && window) {
        window.open(next, '_self');
      } else {
        this.transitionTo('index');
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var me = this.me;
      var isSigned = Ember.get(this, 'session.signed');
      var next = params.next || null;
      if (isSigned) {
        me.signOut().then(function () {
          _this.goto(next);
        }).catch(function () {
          _this.goto(next);
        });
      } else {
        this.goto(next);
      }
    }
  });
  _exports.default = _default;
});