define("livarava-web/components/settings/settings-profile", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      onSave: function onSave() {
        var _this = this;
        var model = this.model;
        model.save().then(function () {
          return _this.me.load();
        });
      },
      onImageSelect: function onImageSelect(item) {
        var _this2 = this;
        var model = this.model;
        if (item === null) {
          Ember.set(model, 'image', null);
          Ember.set(model, 'image_url', null);
        } else {
          Ember.set(model, 'image', item);
          Ember.set(model, 'image_url', item.image_url);
        }
        model.save().then(function () {
          return _this2.me.load();
        });
      }
    }
  });
  _exports.default = _default;
});