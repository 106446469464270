define("livarava-web/routes/products/item/payments/index", ["exports", "lodash/omitBy", "lodash/isNil"], function (_exports, _omitBy2, _isNil2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var product_id = parseInt(this.paramsFor('products.item').product_id);
      params['filter[product]'] = product_id;
      return Ember.RSVP.hash({
        product_id: product_id,
        product: store.peekRecord('product', product_id),
        items: store.query('payment', (0, _omitBy2.default)(params, _isNil2.default)).then(function (results) {
          return results;
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});