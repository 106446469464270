define("livarava-web/routes/contacts/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model() {
      // Init Contact
      return this.store.createRecord('contact', {
        // Attributes
        // Relationships
      });
    }
  });
  _exports.default = _default;
});