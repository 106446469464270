define("livarava-web/routes/sites/item/billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'Billing',
    // Model
    model: function model(params) {
      var store = this.store;
      var site_id = parseInt(this.paramsFor('sites.item').site_id);
      var site = store.findRecord('site', site_id);
      var payments = store.query('payment', {
        'filter[customer]': site_id,
        per_page: 100,
        page: 1,
        sort: '-created'
      });
      var invoices = store.query('invoice', {
        'filter[customer]': site_id,
        per_page: 100,
        page: 1,
        sort: '-created'
      });
      return Ember.RSVP.hash({
        site: site,
        payments: payments,
        invoices: invoices
      });
    }
  });
  _exports.default = _default;
});