define("livarava-web/routes/lang/careers/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      var post_id = parseInt(params.id);
      return store.findRecord('post', post_id);
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'titleToken', Ember.get(model, 'title'));
    }
  });
  _exports.default = _default;
});