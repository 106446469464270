define("livarava-web/models/comment", ["exports", "ember-data", "livarava-web/config/environment"], function (_exports, _emberData, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    text: _emberData.default.attr('string'),
    edited: _emberData.default.attr('boolean'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    root: _emberData.default.belongsTo('neuron'),
    parent: _emberData.default.belongsTo('comment'),
    // Computed
    rootURL: Ember.computed('root', function () {
      var root = Ember.get(this, 'root.id');
      return "".concat(_environment.default.api.host, "/neuron/").concat(root, "/comments");
    })
  });
  _exports.default = _default;
});