define("livarava-web/templates/components/neuron-post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c+AXGK7l",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"m-b-1 clearfix\"],[12],[2,\"\\n    \"],[10,\"a\"],[15,6,[31,[\"/post/edit/\",[34,0,[\"id\"]]]]],[14,0,\"btn btn-success-outline edit-post-link pull-right\"],[12],[2,\"Edit\\n        description\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[2,[35,0,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[2,[30,[36,1],[[35,0,[\"text\"]]],[[\"length\",\"striptags\"],[300,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-t-2 clearfix\"],[12],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,4],[[32,0],\"toggleText\"],null],[12],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Less\"]],\"parameters\":[]},{\"statements\":[[2,\"Read more\"]],\"parameters\":[]}]]],[13],[13],[2,\"\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"truncate\",\"expanded\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-post.hbs"
    }
  });
  _exports.default = _default;
});