define("livarava-web/components/contact/contact-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    isHovered: false,
    mouseEnter: function mouseEnter() {
      this.set('isHovered', true);
    },
    mouseLeave: function mouseLeave() {
      this.set('isHovered', false);
    }
  });
  _exports.default = _default;
});