define("livarava-web/templates/projects/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "iWLQWgfZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"projects\"]],[[\"default\"],[{\"statements\":[[2,\"Projects\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"header\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,3],null,[[\"model\",\"save\",\"delete\"],[[35,1],[30,[36,2],[\"save\"],null],[30,[36,2],[\"delete\"],null]]]]],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"route-action\",\"project/project-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/edit.hbs"
    }
  });
  _exports.default = _default;
});