define("livarava-web/controllers/reviews/create", ["exports", "livarava-web/validations/review-validations"], function (_exports, _reviewValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    reviewValidations: _reviewValidations.default,
    // Query Params
    queryParams: ['copy'],
    copy: null,
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function () {
          return _this.transitionToRoute('reviews.item', item.get('id'));
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('reviews');
      }
    }
  });
  _exports.default = _default;
});