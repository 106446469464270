define("livarava-web/components/site-members-role-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    role: null,
    roles: [{
      value: 'admin',
      label: 'Admin'
    }, {
      value: 'editor',
      label: 'Editor'
    }, {
      value: 'member',
      label: 'Member'
    }]
  });
  _exports.default = _default;
});