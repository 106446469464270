define("livarava-web/templates/components/product/product-invoice-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "M/BgeJE+",
    "block": "{\"symbols\":[],\"statements\":[[10,\"td\"],[14,0,\"text-center text-nowrap\"],[12],[2,\"\\n  \"],[1,[30,[36,1],null,[[\"item\"],[[35,0]]]]],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-nowrap text-muted\"],[12],[2,\"\\n  \"],[10,\"small\"],[12],[2,\"\\n    #\"],[1,[30,[36,2],[[35,0,[\"created\"]],\"YYYYMMDD\"],null]],[2,\"-\"],[1,[35,0,[\"id\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[10,\"small\"],[12],[2,\"\\n    \"],[1,[35,0,[\"title\"]]],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[12],[2,\"\\n    \"],[10,\"small\"],[14,0,\"text-muted\"],[12],[1,[35,0,[\"fullname\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[14,0,\"text-nowrap text-right\"],[12],[2,\"\\n  \"],[10,\"small\"],[12],[2,\"\\n    \"],[1,[30,[36,3],[[35,0,[\"amount\"]]],[[\"format\"],[\"0.00\"]]]],[2,\"\\n    \"],[1,[35,0,[\"currency\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\"],[10,\"td\"],[12],[2,\"\\n  \"],[10,\"a\"],[15,6,[31,[[34,0,[\"previewUrl\"]]]]],[14,\"target\",\"_blank\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-cloud-download\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"invoice/invoice-status\",\"moment-format\",\"format-number\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/product/product-invoice-item.hbs"
    }
  });
  _exports.default = _default;
});