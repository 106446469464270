define("livarava-web/components/post-category-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    selectedCategories: null,
    categories: null,
    post: null,
    // Functions
    onAddCategory: null,
    onRemoveCategory: null,
    actions: {
      add: function add(category) {
        this.onAddCategory(category);
      }
    }
  });
  _exports.default = _default;
});