define("livarava-web/routes/properties/index", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _getFiltersFromParams, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Properties',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      per_page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      property_type: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      },
      phone: {
        refreshModel: true
      },
      email: {
        refreshModel: true
      },
      author: {
        refreshModel: true
      },
      manager: {
        refreshModel: true
      },
      address_name: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        items: store.query('property', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      },
      delete: function _delete(item) {
        var _this = this;
        if (confirm('Are you sure?')) {
          item.destroyRecord().then(function () {
            return _this.send('reload');
          });
        }
      },
      search: function search(q) {
        this.transitionTo('services', {
          queryParams: {
            q: q,
            page: 1
          }
        });
      }
    }
  });
  _exports.default = _default;
});