define("livarava-web/models/ticket", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    type: 'ticket',
    // Ticket
    ticket_uid: _emberData.default.attr('string'),
    price: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      var status = this.status;
      var statuses = {
        reserved: 'Reserved',
        sold: 'Sold',
        used: 'Used'
      };
      return status in statuses ? statuses[status] : 'Error';
    }),
    // Participant
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    company: _emberData.default.attr('string'),
    industry: _emberData.default.attr('string'),
    country: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    event: _emberData.default.belongsTo('event'),
    // Urls
    ticket_url: _emberData.default.attr('string'),
    payment_url: _emberData.default.attr('string'),
    qrcode_url: _emberData.default.attr('string')
  });
  _exports.default = _default;
});