define("livarava-web/templates/support/tickets/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0OR0IhoK",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"support\"]],[[\"default\"],[{\"statements\":[[2,\"Support\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"New Ticket\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"feedbacks\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n    \"],[10,\"h2\"],[12],[2,\"Create a New Support Ticket\"],[13],[2,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,3],null,[[\"model\",\"save\"],[[35,2],[30,[36,1],[[32,0],\"save\"],null]]]]],[2,\"\\n\\n    \"],[1,[30,[36,5],[[30,[36,4],null,null]],null]],[2,\"\\n\\n  \"],[13],[3,\"body\"],[2,\"\\n\"],[13],[3,\"feedbacks\"]],\"hasEval\":false,\"upvars\":[\"link-to\",\"action\",\"model\",\"feedback/feedback-user-form\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/support/tickets/create.hbs"
    }
  });
  _exports.default = _default;
});