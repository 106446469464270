define("livarava-web/components/task/task-form", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    // Actions
    actions: {
      change: function change(property, value) {
        Ember.set(this, property, value);
      },
      save: function save() {
        this.save(this.model);
      },
      saveAndGo: function saveAndGo() {
        this.saveAndGo(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      },
      reset: function reset() {
        this.model.rollbackAttributes();
      },
      setProject: function setProject(item) {
        var _this = this;
        if (item === null) {
          Ember.set(this, 'model.project', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          Ember.set(_this, 'model.project', item);

          // Save Project to Settings
          var localSettings = _this.localSettings;
          localSettings.set('settings.project_id', item.id);
        });
      },
      setProduct: function setProduct(item) {
        var _this2 = this;
        if (item === null) {
          Ember.set(this, 'model.product', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          Ember.set(_this2, 'model.product', item);

          // Save Project to Settings
          var localSettings = _this2.localSettings;
          localSettings.set('settings.product_id', item.id);
        });
      },
      setDue: function setDue(value) {
        var datetime = (0, _moment.default)(value).format();
        Ember.set(this, 'model.due', datetime);
      }
    }
  });
  _exports.default = _default;
});