define("livarava-web/controllers/properties/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'sort', 'phone', 'email', 'author', 'manager', 'address_name'],
    page: 1,
    per_page: 10,
    q: null,
    sort: null,
    phone: '',
    email: '',
    author: null,
    manager: null,
    address_name: '',
    // Filters
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'Type',
      query: 'property_type',
      type: 'select',
      placeholder: 'Type',
      options: [{
        value: 'apartment',
        label: 'квартира',
        icon: 'fa fa-hotel'
      }, {
        value: 'private',
        label: 'приватний будинок',
        icon: 'fa fa-home text-success'
      }, {
        value: 'commercial',
        label: 'комерційна нерухомість',
        icon: 'fa fa-hospital-o text-warning'
      }, {
        value: 'condominium',
        label: "об'єднання",
        icon: 'fa fa-building text-info'
      }]
    }, {
      name: 'author',
      query: 'author',
      type: 'user',
      inputComponent: 'input-user',
      placeholder: 'Author'
    }, {
      name: 'manager',
      query: 'manager',
      type: 'user',
      inputComponent: 'input-user',
      placeholder: 'Менеджер'
    }, {
      name: 'address_name',
      query: 'address_name',
      type: 'search',
      placeholder: 'Вулиця...'
    }, {
      name: 'phone',
      query: 'phone',
      type: 'search',
      placeholder: 'Phone...'
    }, {
      name: 'email',
      query: 'email',
      type: 'search',
      placeholder: 'Email...'
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }, {
      query: 'per_page',
      type: 'select',
      placeholder: 'Per Page',
      options: [{
        value: 5,
        label: '5'
      }, {
        value: 10,
        label: '10'
      }, {
        value: 25,
        label: '25'
      }, {
        value: 50,
        label: '50'
      }, {
        value: 100,
        label: '100'
      }, {
        value: 250,
        label: '250'
      }]
    }])
  });
  _exports.default = _default;
});