define("livarava-web/components/service/input-service-type", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var serviceTypes = Array.from(_environment.default.service.types);
  var _default = Ember.Component.extend({
    options: serviceTypes,
    value: null,
    selected: Ember.computed('value', function () {
      var _this = this;
      if (!this.value) return null;
      return this.options.find(function (option) {
        return option.value == _this.value;
      });
    }),
    actions: {
      handleChange: function handleChange(serviceType) {
        if (typeof this.onChange == 'function') {
          this.onChange(serviceType.value);
        }
      }
    }
  });
  _exports.default = _default;
});