define("livarava-web/controllers/application", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Variables
    search: '',
    // Functions
    clearSearch: function clearSearch() {
      Ember.set(this, 'search', null);
    }
  });
  _exports.default = _default;
});