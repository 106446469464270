define("livarava-web/routes/support/tickets/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'New Ticket',
    // Params
    queryParams: {
      subject: {
        refreshModel: false
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var subject = params.subject;
      return store.findRecord('neuron', 4964).then(function (site) {
        return store.createRecord('feedback', {
          site: site,
          subject: subject
        });
      });
    }
  });
  _exports.default = _default;
});