define("livarava-web/routes/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Params
    queryParams: {
      email: {
        refreshModel: false
      },
      code: {
        refreshModel: false
      }
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      controller.set('password', null);
      controller.set('confirm', null);
    },
    // Actions
    actions: {
      toIndex: function toIndex(params) {
        this.transitionTo('index');
      }
    }
  });
  _exports.default = _default;
});