define("livarava-web/components/neuroner/feed-form", ["exports", "livarava-web/utils/get-previews", "livarava-web/utils/get-article"], function (_exports, _getPreviews, _getArticle) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // States
    preview: false,
    currentTab: 'general',
    // Feed Loader
    loadFeed: function loadFeed() {
      var _this = this;
      if (!this.preview) {
        return;
      }
      this.set('article', null);
      this.set('previews', Ember.Object.create({
        loading: true
      }));
      (0, _getPreviews.default)({
        url: this.get('model.url')
      }).then(function (items) {
        return _this.set('previews', Ember.Object.create({
          success: true,
          items: items
        }));
      }).catch(function (error) {
        return _this.set('previews', Ember.Object.create({
          error: error
        }));
      });
    },
    // Actions
    actions: {
      setTab: function setTab(tabName) {
        this.set('currentTab', tabName);
      },
      loadArticle: function loadArticle(options) {
        var _this2 = this;
        if (!options) {
          this.set('article', null);
          return;
        }
        var url = options.url,
          title = options.title;
        var rule = this.get('model.rules').find(function (rule) {
          return url.startsWith(rule.url);
        }) || {};
        this.set('article', Ember.Object.create({
          loading: true,
          title: title,
          url: url
        }));
        (0, _getArticle.default)({
          url: url
        }, rule.selector, rule.without).then(function (content) {
          return _this2.set('article', Ember.Object.create({
            success: true,
            url: url,
            title: title,
            content: content
          }));
        }).catch(function (error) {
          return _this2.set('article', Ember.Object.create({
            error: error,
            title: title,
            url: url
          }));
        });
      },
      setPreview: function setPreview(preview) {
        this.set('preview', !!preview);
        this.loadFeed();
      },
      addRule: function addRule(rule) {
        if (!Array.isArray(this.get('model.rules'))) {
          this.model.set('rules', Ember.A());
        }
        if (!rule) rule = Ember.Object.create({});
        this.get('model.rules').pushObject(rule);
        this.set('currentTab', 'rules');
      },
      deleteRule: function deleteRule(object) {
        this.get('model.rules').removeObject(object);
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      }
    }
  });
  _exports.default = _default;
});