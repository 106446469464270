define("livarava-web/templates/page/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N3IQMNaG",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"neuron\",\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"h1\"],[12],[1,[35,1,[\"neuron\",\"title\"]]],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"item\",\"views\"],[[35,1,[\"neuron\"]],true]]]],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"model\",\"page\",\"postLink\",\"class\"],[[35,1,[\"posts\"]],[35,3],\"page.post\",\"blog blog-page\"]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"neuron/neuron-info\",\"page\",\"blog/blog-view-v2\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/page/index.hbs"
    }
  });
  _exports.default = _default;
});