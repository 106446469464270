define("livarava-web/components/neuroner/feed-health", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    ajax: Ember.inject.service(),
    // Properties
    health: null,
    progress: null,
    options: {
      easing: 'easeInOut',
      duration: 1000,
      width: 20,
      strokeWidth: 5,
      trailWidth: 2,
      svgStyle: {
        display: 'block',
        width: '100%'
      },
      from: {
        color: '#c11e1d'
      },
      to: {
        color: '#6abd42'
      },
      step: function step(state, circle, attachment) {
        circle.path.setAttribute('stroke', state.color);
      }
    },
    // Init
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var ajax = this.ajax;
      var itemId = this.get('item.id');
      var time = new Date().getTime();
      var url = "".concat(_environment.default.api.neuroner, "/api/feeds/").concat(itemId, "?__t=").concat(time);
      ajax.request(url).then(function (response) {
        var data = response.data;
        var health = data.health;
        _this.set('health', health);
        _this.set('progress', health / 100);
      });
    }
  });
  _exports.default = _default;
});