define("livarava-web/components/input-address-type", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var types = Array.from(_environment.default.address.types);
  var _default = Ember.Component.extend({
    tagName: '',
    types: types,
    value: null,
    actions: {
      select: function select(type) {
        this.trigger("onChange", type.short);
      }
    }
  });
  _exports.default = _default;
});