define("livarava-web/routes/sites/new", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = Ember.get(this, 'store');
      return store.createRecord('site', {
        created: (0, _moment.default)(),
        currency: 'USD'
      });
    }
  });
  _exports.default = _default;
});