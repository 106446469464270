define("livarava-web/models/neuroner/feed", ["exports", "ember-data/model", "ember-data"], function (_exports, _model, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _model.default.extend({
    url: _emberData.default.attr('string'),
    approved: _emberData.default.attr('boolean'),
    rules: _emberData.default.attr('array-of-objects')
  });
  _exports.default = _default;
});