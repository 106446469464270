define("livarava-web/templates/components/contact/contact-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H5JsGUkA",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row contact contact-details\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-4\"],[12],[2,\"\\n    \"],[10,\"span\"],[12],[1,[30,[36,3],null,[[\"item\"],[[35,0]]]]],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"email\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[10,\"a\"],[15,6,[31,[\"mailto:\",[34,0,[\"email\"]]]]],[14,0,\"contact-mailto\"],[12],[1,[35,0,[\"email\"]]],[13],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-3\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"phone\"]]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[12],[1,[30,[36,2],[[35,0,[\"phone\"]],[35,0,[\"phone\"]],\"\"],null]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-2 text-right\"],[12],[2,\"\\n\"],[6,[37,2],[[35,4]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-star\"],[12],[13],[2,\"\\n      \"],[6,[37,1],null,[[\"route\",\"model\"],[\"contacts.edit\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-pencil\"],[12],[13]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-ellipsis-v\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"link-to\",\"if\",\"user/user-medium\",\"isHovered\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/contact/contact-list-item.hbs"
    }
  });
  _exports.default = _default;
});