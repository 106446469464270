define("livarava-web/controllers/services/entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'city', 'sort'],
    page: 1,
    per_page: 10,
    city: null,
    sort: 'entity, -created',
    // Filters
    filters: Ember.A([])
  });
  _exports.default = _default;
});