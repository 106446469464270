define("livarava-web/templates/components/neuron-event", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "w6iMVKC8",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"clearfix\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-clock-o icon-event pull-left icon-event-date\"],[12],[13],[2,\"\\n        \"],[10,\"span\"],[14,0,\"block-event-date text-muted\"],[12],[2,\"\\n          \"],[1,[30,[36,1],[[35,0,[\"startdate\"]],\"YYYY-MM-DD, HH:mm Z\"],null]],[10,\"br\"],[12],[13],[2,\"\\n          \"],[1,[30,[36,1],[[35,0,[\"enddate\"]],\"YYYY-MM-DD, HH:mm Z\"],null]],[2,\"\\n        \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[10,\"i\"],[14,0,\"fa fa-map-marker icon-event\"],[12],[13],[10,\"span\"],[14,0,\"text-muted\"],[12],[1,[35,0,[\"location\"]]],[13],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"m-b-1\"],[12],[10,\"i\"],[14,0,\"fa fa-eye icon-event\"],[12],[13],[10,\"span\"],[14,0,\"badge badge-info\"],[12],[1,[35,0,[\"type_location\"]]],[13],[13],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"m-b-1 \",[30,[36,2],[[35,0,[\"price\"]],\"text-success\",\"text-primary\"],null]]]],[12],[10,\"i\"],[14,0,\"fa fa-money icon-event\"],[12],[13],[10,\"span\"],[12],[1,[34,3]],[13],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"moment-format\",\"unless\",\"getPrice\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-event.hbs"
    }
  });
  _exports.default = _default;
});