define("livarava-web/routes/profile", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: Ember.computed.reads('model.fullname'),
    // Model
    model: function model() {
      var _this = this;
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      return store.findRecord('user', meId).then(function (item) {
        var title = Ember.get(item, 'fullname');
        Ember.set(_this, 'titleToken', title);
        return item;
      });
    }
  });
  _exports.default = _default;
});