define("livarava-web/templates/components/common/common-header-min", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JmyEVriL",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"header-min\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-md-12 text-center\"],[12],[2,\"\\n\\n\"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"img\"],[14,\"src\",\"/img/logo/logo-livarava-20x20-white.png\"],[14,\"width\",\"20\"],[14,\"height\",\"20\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-header-min.hbs"
    }
  });
  _exports.default = _default;
});