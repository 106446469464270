define("livarava-web/components/site/site-pages", ["exports", "livarava-web/config/environment", "livarava-web/utils/get-language-label"], function (_exports, _environment, _getLanguageLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    languages: _environment.default.intl.localeLabels,
    language: _environment.default.intl.defaultLocale,
    languageLabel: Ember.computed('selectedLanguage', function () {
      var locale = this.selectedLanguage;
      return (0, _getLanguageLabel.default)(locale);
    }),
    // Functions
    onSetFilter: null,
    // Actions
    actions: {
      setFilter: function setFilter(name, value) {
        this.onSetFilter(name, value);
      }
    }
  });
  _exports.default = _default;
});