define("livarava-web/components/form-in-iframe", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    didInsertElement: function didInsertElement() {
      this.$('iframe').one('load', function (evt) {
        (0, _jquery.default)(evt.target).contents().find('head').append("<style>\n        header, footer{\n          display: none !important;\n        }\n        </style>");
      });
    }
  });
  _exports.default = _default;
});