define("livarava-web/controllers/reviews/edit", ["exports", "livarava-web/validations/review-validations"], function (_exports, _reviewValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    reviewValidations: _reviewValidations.default,
    intl: Ember.inject.service(),
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm(this.intl.t('modal.confirm'))) {
          item.destroyRecord().then(function () {
            return _this.transitionToRoute('reviews');
          });
        }
      },
      save: function save(item) {
        var _this2 = this;
        item.save().then(function () {
          return _this2.transitionToRoute('reviews.item', item.get('id'));
        });
      },
      cancel: function cancel() {
        this.transitionToRoute('reviews.item', this.get('model.id'));
      }
    }
  });
  _exports.default = _default;
});