define("livarava-web/controllers/lang/blog/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Params
    queryParams: ['page', 'q', 'date_start', 'date_end', 'author', 'language'],
    page: 1,
    q: null,
    date_start: null,
    date_end: null,
    author: null,
    language: null,
    locale: Ember.computed(function () {
      return this.get('intl.primaryLocale');
    })
  });
  _exports.default = _default;
});