define("livarava-web/components/loading-line-slider", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    loading: Ember.inject.service(),
    timer: null,
    value: 10,
    step: 3,
    timeout: 100,
    incrementValue: function incrementValue() {
      this.set('value', this.value + this.step);
      this.timer = Ember.run.later(this, this.incrementValue, this.timeout);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      this.element.setAttribute('value', 10);
      this.incrementValue();
    },
    willDestroyElement: function willDestroyElement() {
      if (this.timer) {
        Ember.run.cancel(this.timer);
      }
      this._super.apply(this, arguments);
    }
  });
  _exports.default = _default;
});