define("livarava-web/templates/components/notification/notification-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "S6HSoak2",
    "block": "{\"symbols\":[\"scroller\",\"item\"],\"statements\":[[6,[37,7],null,[[\"on-load-more\",\"use-document\",\"scroll-debounce\",\"trigger-at\"],[[30,[36,0],[[32,0],\"loadMore\"],null],true,50,\"90%\"]],[[\"default\"],[{\"statements\":[[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,3],null,[[\"item\"],[[32,2]]]]],[2,\"\\n\\n\"]],\"parameters\":[2]}]]],[2,\"\\n  \"],[10,\"div\"],[14,0,\"mt-3 text-center\"],[12],[2,\"\\n\"],[6,[37,2],[[32,1,[\"isLoading\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin fa-3x text-primary\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[11,\"button\"],[24,0,\"btn btn-light\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"loadMore\"],null],[12],[2,\"\\n          Load more\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"hasMore\",\"if\",\"notification/notification-list-item\",\"itemsToShow\",\"-track-array\",\"each\",\"infinite-scroller\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/notification/notification-list.hbs"
    }
  });
  _exports.default = _default;
});