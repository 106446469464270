define("livarava-web/templates/components/chat/chat-main", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "l743fsjB",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[10,\"div\"],[14,0,\"chat-wrapper\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chat-header\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chat-content\"],[14,1,\"chat-messages-wrapper\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"container chat-messages\"],[14,1,\"chat-messages\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,1,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,3],[[30,[36,2],[[30,[36,2],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,0],null,[[\"item\"],[[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"No messages.\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"chat-footer\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"container\"],[12],[2,\"\\n      \"],[1,[30,[36,6],null,[[\"value\",\"class\",\"enter\"],[[35,5],\"form-control\",\"send\"]]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"chat/chat-message\",\"messages\",\"-track-array\",\"each\",\"if\",\"text\",\"chat/chat-textarea\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/chat/chat-main.hbs"
    }
  });
  _exports.default = _default;
});