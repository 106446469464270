define("livarava-web/templates/components/entity/entity-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "9xrDaNcR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[15,\"title\",[30,[36,5],[[35,4,[\"activated\"]],\"YYYY-MM-DD\"],null]],[15,0,[31,[\"d-flex align-items-center \",[30,[36,2],[[35,4,[\"is_active\"]],\"text-success\",\"text-muted\"],null]]]],[12],[2,\"\\n\"],[6,[37,2],[[35,4,[\"is_active\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"fa \",[30,[36,2],[[35,0],[30,[36,1],[\"fa-\",[35,0],\"x\"],null]],null],\" fa-play text-success mr-1\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],[[35,3],\"active\"],null]],[2,\"\\n\"],[6,[37,2],[[35,6]],null,[[\"default\"],[{\"statements\":[[2,\"      till \"],[1,[30,[36,5],[[35,4,[\"activated\"]],\"YYYY-MM-DD\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"i\"],[15,0,[31,[\"fa \",[30,[36,2],[[35,0],[30,[36,1],[\"fa-\",[35,0],\"x\"],null]],null],\" fa-pause text-muted mr-1\"]]],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,2],[[35,3],\"unactive\"],null]],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"size\",\"concat\",\"if\",\"showText\",\"item\",\"moment-format\",\"showDate\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/entity/entity-status.hbs"
    }
  });
  _exports.default = _default;
});