define("livarava-web/routes/tasks/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    title: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var task_id = parseInt(params.id);
      return store.findRecord('task', task_id).then(function (item) {
        var title = Ember.get(item, 'title');
        Ember.set(_this, 'title', title);
        return item;
      });
    }
  });
  _exports.default = _default;
});