define("livarava-web/controllers/products/item/goals", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: ['member', 'date_start', 'date_end'],
    member: Ember.computed.reads('me.data.id'),
    date_start: null,
    date_end: null,
    // Filters
    filters: Ember.computed('me.admin', function () {
      var isAdmin = this.get('me.admin');
      if (isAdmin) {
        return Ember.A([{
          name: 'member',
          query: 'member',
          type: 'user',
          placeholder: 'Member'
        }, {
          name: 'created',
          query: 'created',
          type: 'daterange',
          placeholder: 'Created'
        }]);
      } else {
        return Ember.A([{
          name: 'created',
          query: 'created',
          type: 'daterange',
          placeholder: 'Created'
        }]);
      }
    }),
    // Computed
    showCurrentStatus: Ember.computed('model.stats.amount_month_current', function () {
      var value = this.get('model.stats.amount_month_current');
      return value !== null;
    }),
    chartData: Ember.computed('model.goalStats', function () {
      // console.log('Hi!');
      return {
        labels: ["Payed", "Available", "Potential"],
        datasets: [{
          width: 200,
          height: 200,
          label: '# of USD',
          data: [parseInt(this.get('model.stats.amount_payed')), parseInt(this.get('model.stats.amount_available')), parseInt(this.get('model.stats.amount_potential'))],
          backgroundColor: ['rgba(40, 167, 69, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 0.2)', 'rgba(153, 102, 255, 0.2)', 'rgba(255, 159, 64, 0.2)'],
          borderColor: ['rgba(40, 167, 69, 1)', 'rgba(54, 162, 235, 1)', 'rgba(255, 206, 86, 1)', 'rgba(75, 192, 192, 1)', 'rgba(153, 102, 255, 1)', 'rgba(255, 159, 64, 1)'],
          borderWidth: 1
        }],
        options: {
          legend: {
            position: 'bottom'
          }
        }
      };
    })
  });
  _exports.default = _default;
});