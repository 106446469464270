define("livarava-web/templates/components/profile/profile-space-progress", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "+YDP5LER",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"common-progress\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"progress mb-1\"],[12],[2,\"\\n    \"],[10,\"div\"],[15,0,[31,[\"progress-bar \",[34,0]]]],[14,\"role\",\"progressbar\"],[15,5,[31,[\"width: \",[34,1],\"%\"]]],[15,\"aria-valuenow\",[31,[[34,1]]]],[14,\"aria-valuemin\",\"0\"],[14,\"aria-valuemax\",\"100\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[15,0,[31,[\"common-progress-text \",[34,2],\" mb-0\"]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-cloud\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,4],[[35,3,[\"space_used\"]],false,1],null]],[2,\" of \"],[1,[30,[36,4],[[35,3,[\"space_total\"]],false,1],null]],[2,\" used\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"progressBarClass\",\"progressBarWidth\",\"progressTextClass\",\"model\",\"number-to-human-size\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/profile/profile-space-progress.hbs"
    }
  });
  _exports.default = _default;
});