define("livarava-web/templates/components/product/product-member-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "oIIqalmR",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,7],[[30,[36,6],[[30,[36,6],[[35,5]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,4],null,[[\"item\",\"roles\",\"onEditUser\",\"onSaveUser\",\"onRemoveUser\"],[[32,1],[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onRemoveUser\",\"onSaveUser\",\"onEditUser\",\"roles\",\"product/product-member-item\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/product/product-member-list.hbs"
    }
  });
  _exports.default = _default;
});