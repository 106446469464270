define("livarava-web/controllers/investments/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['copy', 'project', 'product', 'user', 'amount'],
    // Properties
    copy: null,
    project: null,
    product: null,
    user: null,
    amount: null
  });
  _exports.default = _default;
});