define("livarava-web/components/motivation/has-entity-categories", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // attributes
    entityId: 164797,
    readonlyManager: false,
    // filters
    dates: null,
    entity: null,
    // data
    results: null,
    total: Ember.computed('results.@each.orders', function () {
      return (this.get('results') || []).reduce(function (result, item) {
        return result + item.orders.get('meta.total_results');
      }, 0);
    }),
    fetchData: function fetchData() {
      var _this = this;
      var entityId = this.get('entity.id');
      var _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store;
      var requestParams = {
        date_start: (0, _moment.default)(dates[0]).format('YYYY-MM-DD'),
        date_end: (0, _moment.default)(dates[1]).format('YYYY-MM-DD'),
        per_page: 1,
        page: 1
      };
      return store.query('entityservice', {
        per_page: 1000,
        'filter[entity]': entityId
      }).then(function (entityservices) {
        return Promise.all(entityservices.uniqBy('service.id').map(function (es) {
          return Ember.RSVP.hash({
            orders: store.query('order', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, requestParams), {}, {
              'filter[service]': es.get('service.id')
            })),
            service: es.get('service')
          }).then(function (_ref) {
            var orders = _ref.orders,
              service = _ref.service;
            return Promise.resolve({
              orders: orders,
              service: service
            });
          });
        }));
      }).then(function (results) {
        return _this.set('results', results);
      });
    },
    fetchEntity: function fetchEntity() {
      var _this2 = this;
      var store = this.store,
        entityId = this.entityId;
      return store.findRecord('entity', entityId).then(function (entity) {
        return _this2.set('entity', entity);
      });
    },
    init: function init() {
      var _this3 = this;
      this._super.apply(this, arguments);
      this.set('dates', [
      // moment().startOf('week').toDate(),
      // moment().endOf('week').toDate(),
      (0, _moment.default)('2021-07-01').toDate(), (0, _moment.default)('2021-08-31').toDate()]);
      this.fetchEntity().then(function () {
        return _this3.loading.run(function () {
          return _this3.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDateRange: function setDateRange(dates) {
        var _this4 = this;
        this.set('dates', dates);
        if (dates && dates.length == 2) {
          this.loading.run(function () {
            return _this4.fetchData();
          });
        }
      },
      setEntity: function setEntity(entity) {
        var _this5 = this;
        this.set('entity', entity);
        this.loading.run(function () {
          return _this5.fetchData();
        });
      }
    }
  });
  _exports.default = _default;
});