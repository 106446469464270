define("livarava-web/controllers/projects/item/investments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    page: 1,
    q: null,
    date_start: null,
    date_end: null,
    user: null,
    project: null,
    product: null,
    type: null,
    status: null,
    currency: null,
    // Filters
    // filters: ['q', 'project', 'product', 'paymentType', 'status', 'currency'],
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'user',
      query: 'user',
      type: 'user',
      abstract: 'user',
      placeholder: 'User'
    }, {
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: 'created',
        label: 'Created',
        icon: 'fa fa-square-o text-primary'
      }, {
        value: 'success',
        label: 'Success',
        icon: 'fa fa-check-square-o text-success'
      }]
    }, {
      name: 'currency',
      query: 'currency',
      type: 'select',
      placeholder: 'Currency',
      options: [{
        value: 'USD',
        label: 'USD',
        icon: 'fa fa-usd'
      }, {
        value: 'EUR',
        label: 'EUR',
        icon: 'fa fa-eur'
      }, {
        value: 'UAH',
        label: 'UAH',
        sign: '₴'
      }, {
        value: 'RUB',
        label: 'RUB',
        icon: 'fa fa-rub'
      }]
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }]),
    // States
    isFiltersExpanded: false,
    isFiltersSetted: Ember.computed('q', 'project', 'product', 'type', 'status', 'currency', function () {
      var _this = this;
      var filters = this.filters;
      var filtersSetted = 0;
      filters.forEach(function (filter) {
        var value = _this.get(filter);
        if (value !== null) {
          filtersSetted += 1;
        }
      });
      return filtersSetted > 0;
    }),
    isRefreshing: false,
    // Icons
    icons: {
      types: {
        research: 'fa fa-area-chart text-warning',
        development: 'fa fa-wrench text-primary'
      },
      statuses: {
        opened: 'fa fa-square-o text-primary',
        assigned: 'fa fa-user text-success',
        evaluated: 'fa fa-calculator text-warning',
        approved: 'fa fa-check text-success',
        in_progress: 'fa fa-clock-o text-warning',
        finished: 'fa fa-flag-checkered text-primary',
        closed: 'fa fa-check-square-o text-success'
      },
      currencyOptions: {
        USD: '<i class="fa fa-dollar"></i>',
        EUR: '<i class="fa fa-eur"></i>',
        UAH: '&#8372;',
        RUB: '<i class="fa fa-rub"></i>'
      }
    },
    // Actions
    actions: {
      resetFilters: function resetFilters() {
        var _this2 = this;
        var filters = this.filters;
        filters.forEach(function (filter) {
          _this2.set(filter, null);
        });
      },
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      search: function search(q) {
        this.transitionToRoute('payments', {
          queryParams: {
            q: q,
            page: 1
          }
        });
      },
      open: function open(id) {
        if (parseInt(id) < 1) {
          return;
        }
        this.transitionToRoute('payments.item', id);
      },
      refresh: function refresh() {
        this.set('isRefreshing', true);
        this.send('reload');
      }
    }
  });
  _exports.default = _default;
});