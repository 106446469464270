define("livarava-web/routes/investments/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var investmentId = parseInt(params.id);
      return store.findRecord('investment', investmentId).then(function (item) {
        var id = Ember.get(item, 'id');
        var title = "Investment #".concat(id);
        Ember.set(_this, 'titleToken', title);
        return item;
      });
    },
    // Actions
    actions: {
      delete: function _delete() {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          var model = this.controller.model;
          model.destroyRecord().then(function () {
            return _this2.transitionTo('investments');
          });
        }
      }
    }
  });
  _exports.default = _default;
});