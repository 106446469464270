define("livarava-web/templates/components/neuron-article", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "7qCaJ9r+",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[1,[30,[36,4],null,[[\"item\",\"isLoading\",\"onCreateAxon\",\"onSaveNeuron\"],[[35,3],[35,2],[35,1],[35,0]]]]],[2,\"\\n\\n\"],[18,1,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onSaveNeuron\",\"onCreateAxon\",\"isLoading\",\"item\",\"neuron-general-info\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-article.hbs"
    }
  });
  _exports.default = _default;
});