define("livarava-web/templates/components/neuron-rss", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "nulVTMt9",
    "block": "{\"symbols\":[\"rssFeedItem\",\"&default\"],\"statements\":[[10,\"h4\"],[12],[2,\"RSS Feed\"],[13],[2,\"\\n\\n\"],[10,\"form\"],[14,0,\"\"],[12],[2,\"\\n    \"],[10,\"fieldset\"],[14,0,\"form-group\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"type\",\"class\",\"placeholder\",\"value\"],[\"text\",\"form-control input-filter-neuron\",\"Keyword\",[35,1]]]]],[2,\"\\n    \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"item\",\"filter\"],[[32,1],[35,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[6,[37,8],[[35,7]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"m-t-2\"],[12],[2,\"\\n        \"],[11,\"button\"],[24,0,\"btn btn-primary center-block\"],[4,[38,0],[[32,0],\"showMore\"],null],[12],[2,\"More\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"filter\",\"neuron-rss-item\",\"input\",\"visibleItems\",\"-track-array\",\"each\",\"hasMore\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-rss.hbs"
    }
  });
  _exports.default = _default;
});