define("livarava-web/controllers/tasks/item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Model
    item: Ember.computed.reads('model.item'),
    comments: Ember.computed.reads('model.comments'),
    // Attributes
    memberToAssign: null,
    makeInvestment: true,
    statusOptions: _environment.default.options.task.status,
    page: 1,
    // States
    assignModal: false,
    applyModal: false,
    closeModal: false,
    showEditDescription: false,
    // Actions
    actions: {
      setStatus: function setStatus(status) {
        var task = this.item;
        Ember.set(task, 'status', status);
        task.save();
      },
      setAssignedExecutor: function setAssignedExecutor(value) {
        var _this = this;
        var store = this.store;
        store.findRecord('user', value).then(function (user) {
          return Ember.set(_this, 'memberToAssign', user);
        });
      },
      assignExecutor: function assignExecutor() {
        var task = this.item;
        var user = this.memberToAssign;
        if (user === null) return;
        Ember.set(task, 'executor', user);
        Ember.set(task, 'status', 'assigned');
        task.save();
        Ember.set(this, 'assignModal', false);
      },
      removeExecutor: function removeExecutor() {
        var task = this.item;
        Ember.set(task, 'executor', null);
        Ember.set(task, 'status', 'opened');
        task.save();
      },
      applyExecutor: function applyExecutor() {
        var task = this.item;
        var user = Ember.get(this, 'me.user');
        if (user === null) return;
        Ember.set(task, 'executor', user);
        Ember.set(task, 'status', 'assigned');
        task.save();
        Ember.set(this, 'applyModal', false);
      },
      approveTask: function approveTask() {
        var task = this.item;
        Ember.set(task, 'status', 'approved');
        task.save();
      },
      startTask: function startTask() {
        var task = this.item;
        Ember.set(task, 'status', 'in_progress');
        task.save();
      },
      finishTask: function finishTask() {
        var task = this.item;
        Ember.set(task, 'status', 'finished');
        task.save();
      },
      closeTask: function closeTask() {
        var task = this.item;
        Ember.set(task, 'status', 'closed');
        task.save();
      },
      deleteTask: function deleteTask() {
        if (confirm("Are you sure?")) {
          var task = this.item;
          Ember.set(task, 'status', 'deleted');
          task.save();
        }
      },
      archiveTask: function archiveTask() {
        if (confirm("Are you sure?")) {
          var task = this.item;
          Ember.set(task, 'status', 'archived');
          task.save();
        }
      },
      editDescription: function editDescription() {
        Ember.set(this, 'showEditDescription', true);
      },
      save: function save() {
        var item = this.item;
        Ember.set(this, 'showEditDescription', false);
        Ember.set(this, 'showEditTitle', false);
        item.save();
      },
      addComment: function addComment() {
        this.send('reload');
      },
      deleteComment: function deleteComment(comment) {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          comment.destroyRecord().then(function () {
            return _this2.send('reload');
          });
        }
      },
      saveComment: function saveComment(comment) {
        var _this3 = this;
        comment.save().then(function () {
          return _this3.send('reload');
        });
      },
      newComment: function newComment() {
        this.send('reload');
      }
    }
  });
  _exports.default = _default;
});