define("livarava-web/templates/components/notification/notification-axon", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Pq4qGqT5",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],[[35,0,[\"title\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"b\"],[12],[1,[35,0,[\"title\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-spinner fa-spin\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"axon\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/notification/notification-axon.hbs"
    }
  });
  _exports.default = _default;
});