define("livarava-web/controllers/profile/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    editAboutMode: false,
    actions: {
      editAbout: function editAbout() {
        this.set('editAboutMode', true);
      },
      cancelAbout: function cancelAbout() {
        this.set('editAboutMode', false);
      },
      updateAbout: function updateAbout() {
        this.model.save();
        this.set('editAboutMode', false);
      }
    }
  });
  _exports.default = _default;
});