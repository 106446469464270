define("livarava-web/templates/components/sort/sort-link", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Wgqw8DBX",
    "block": "{\"symbols\":[],\"statements\":[[10,\"span\"],[14,0,\"text-nowrap\"],[12],[11,\"a\"],[24,6,\"\"],[16,\"title\",[31,[[34,0]]]],[4,[38,1],[[32,0],\"reorder\"],null],[12],[1,[34,2]],[13],[2,\" \"],[10,\"i\"],[15,0,[31,[\"fa fa-\",[34,3]]]],[14,\"aria-hidden\",\"true\"],[12],[13],[13]],\"hasEval\":false,\"upvars\":[\"field\",\"action\",\"label\",\"icon\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/sort/sort-link.hbs"
    }
  });
  _exports.default = _default;
});