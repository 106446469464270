define("livarava-web/templates/components/members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qBZfZDMS",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,9],[[30,[36,8],[[30,[36,8],[[35,7]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,6],null,[[\"item\",\"onSaveUser\",\"onRemoveUser\"],[[32,1],[35,5],[35,4]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[6,[37,10],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row no-gutters align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n      \"],[1,[30,[36,3],null,[[\"allowClear\",\"onChange\"],[false,[30,[36,2],[[32,0],\"onNewUserChange\"],null]]]]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col col-auto\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-link btn-sm\"],[24,\"title\",\"Edit\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],false],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-close\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\t\"],[11,\"button\"],[24,0,\"btn btn-link btn-sm\"],[4,[38,2],[[32,0],[30,[36,1],[[35,0]],null],true],null],[12],[2,\"+ Add Member\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"showNewMemberForm\",\"mut\",\"action\",\"input-user\",\"onRemoveUser\",\"onSaveUser\",\"members-list-item\",\"items\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/members-list.hbs"
    }
  });
  _exports.default = _default;
});