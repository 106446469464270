define("livarava-web/services/messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Properties
    recipient: null,
    // Flags
    isNew: false,
    isNewThreadCreated: false,
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      this.set('recipient', null);
      this.set('isNew', false);
    },
    newThreadInit: function newThreadInit() {
      this.set('recipient', null);
      this.set('isNew', true);
    },
    newThreadCreated: function newThreadCreated() {
      this.set('recipient', null);
      this.set('isNew', false);
      this.set('isNewThreadCreated', true);
    }
  });
  _exports.default = _default;
});