define("livarava-web/routes/sites/item/pages/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model() {
      var store = this.store;
      var site_id = this.paramsFor('sites.item').site_id;
      return store.findRecord('site', site_id).then(function (site) {
        var language = Ember.get(site, 'language');
        var title = '';
        var display = 'private';
        var created = (0, _moment.default)();
        var updated = (0, _moment.default)();
        return store.createRecord('page', {
          site: site,
          title: title,
          language: language,
          display: display,
          created: created,
          updated: updated
        });
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function (page) {
          var site_id = Ember.get(page, 'site.id');
          var page_id = page.id;
          _this.transitionTo('sites.item.pages.edit', site_id, page_id);
        });
      }
    }
  });
  _exports.default = _default;
});