define("livarava-web/validations/propertyservice-validations", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    amount: [(0, _validators.validatePresence)(true), (0, _validators.validateNumber)({
      positive: true,
      gte: 1,
      lt: 10e9
    })],
    status: [(0, _validators.validatePresence)(true)],
    property: [(0, _validators.validatePresence)(true)],
    service: [(0, _validators.validatePresence)(true)]
  };
  _exports.default = _default;
});