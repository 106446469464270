define("livarava-web/templates/components/neuron-project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5JxUmOeJ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[2,[35,0,[\"text\"]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[2,[30,[36,1],[[35,0,[\"text\"]]],[[\"length\",\"striptags\"],[300,true]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"m-t-2 clearfix\"],[12],[11,\"button\"],[24,0,\"btn btn-secondary\"],[4,[38,4],[[32,0],\"toggleText\"],null],[12],[6,[37,3],[[35,2]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"Less\"]],\"parameters\":[]},{\"statements\":[[2,\"Read more\"]],\"parameters\":[]}]]],[13],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"truncate\",\"expanded\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-project.hbs"
    }
  });
  _exports.default = _default;
});