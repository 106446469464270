define("livarava-web/components/motivation/analytics-registration-summary", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "moment-timezone", "lodash/countBy", "livarava-web/config/environment"], function (_exports, _toConsumableArray2, _defineProperty2, _objectSpread3, _momentTimezone, _countBy2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusOptionsMap = _environment.default.options.order.status.reduce(function (r, i) {
    return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, r), {}, (0, _defineProperty2.default)({}, i.value, i.label));
  }, {});
  var _default = Ember.Component.extend({
    statusOptionsMap: statusOptionsMap,
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // filters
    dates: null,
    // other
    managers: null,
    // charts options
    summaryChartOptions: Ember.computed('orders.@each.created', 'properties.@each.created', 'entities.@each.created', function () {
      var orders = this.get('orders') || [];
      var properties = this.get('properties') || [];
      var entities = this.get('entities') || [];
      var startDate = _momentTimezone.default.utc(this.dates[0]).set({
        hour: 0
      });
      var endDate = _momentTimezone.default.utc(this.dates[1]).set({
        hour: 0
      });
      var dates = [];
      var d = startDate;
      do {
        dates.push(d.add(1, 'days').clone());
      } while (d.isSameOrBefore(endDate));
      var result = {
        title: {
          text: 'Всього реєстрацій'
        },
        tooltip: {},
        legend: null,
        xAxis: {
          data: dates.map(function (m) {
            return m.format('DD-MM');
          })
        },
        yAxis: {},
        series: [{
          name: 'Зареєстровано заявок',
          type: 'bar',
          data: dates.map(function (m) {
            var filteredItems = orders.filter(function (item) {
              return m.isSame(item.get('created'), 'day');
            });
            return filteredItems.length;
          })
        }, {
          name: "Зареєстровано об'єктів",
          type: 'bar',
          data: dates.map(function (m) {
            var filteredItems = properties.filter(function (item) {
              return m.isSame(item.get('created'), 'day');
            });
            return filteredItems.length;
          })
        }, {
          name: 'Зареєстровано підрядників',
          type: 'bar',
          data: dates.map(function (m) {
            var filteredItems = entities.filter(function (item) {
              return m.isSame(item.get('created'), 'day');
            });
            return filteredItems.length;
          })
        }]
      };
      return result;
    }),
    ordersDistribution: Ember.computed('orders.@each.manager', function () {
      var items = this.get('orders');
      if (!items) return null;
      return (0, _countBy2.default)(this.get('orders').toArray(), function (item) {
        return item.get('manager.fullname') || 'Без менеджера';
      });
    }),
    ordersStatusesDistribution: Ember.computed('orders.@each.status', function () {
      var items = this.get('orders');
      if (!items) return null;
      return (0, _countBy2.default)(this.get('orders').toArray(), function (item) {
        return statusOptionsMap[item.get('status')] || '-';
      });
    }),
    propertiesDistribution: Ember.computed('properties.@each.manager', function () {
      var items = this.get('properties');
      if (!items) return null;
      return (0, _countBy2.default)(this.get('properties').toArray(), function (item) {
        return item.get('manager.fullname') || 'Без менеджера';
      });
    }),
    entitiesDistribution: Ember.computed('entities.@each.manager', function () {
      var items = this.get('entities');
      if (!items) return null;
      return (0, _countBy2.default)(this.get('entities').toArray(), function (item) {
        return item.get('manager.fullname') || 'Без менеджера';
      });
    }),
    fetchData: function fetchData() {
      var _this = this;
      var _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store;
      var requestParams = {
        date_start: (0, _momentTimezone.default)(dates[0]).format('YYYY-MM-DD'),
        date_end: (0, _momentTimezone.default)(dates[1]).format('YYYY-MM-DD'),
        per_page: 1000,
        page: 1
      };
      return Ember.RSVP.hash({
        orders: store.query('order', requestParams),
        properties: store.query('property', requestParams),
        entities: store.query('entity', requestParams),
        transactions: store.query('transaction', requestParams),
        invoices: store.query('invoice', (0, _objectSpread3.default)((0, _objectSpread3.default)({}, requestParams), {}, {
          'filter[status]': 'payed',
          'filter[product]': _environment.default.options.order.defaults.product,
          'filter[project]': _environment.default.options.order.defaults.project
        })),
        actionItems: store.query('action', (0, _objectSpread3.default)((0, _objectSpread3.default)({}, requestParams), {}, {
          per_page: 1
        }))
      }).then(function (results) {
        return Promise.all([].concat((0, _toConsumableArray2.default)(results.orders.map(function (o) {
          return o.get('manager');
        })), (0, _toConsumableArray2.default)(results.properties.mapBy('manager')), (0, _toConsumableArray2.default)(results.entities.mapBy('manager')))).then(function (managers) {
          _this.set('managers', managers.uniq());
          return Promise.resolve(results);
        });
      }).then(function (results) {
        return _this.setProperties(results);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.set('dates', [(0, _momentTimezone.default)().startOf('week').toDate(), (0, _momentTimezone.default)().endOf('week').toDate()
      // moment('2021-03-20').toDate(),
      // moment('2021-03-27').toDate(),
      ]);

      Ember.run.next(this, function () {
        var _this2 = this;
        this.loading.run(function () {
          return _this2.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDateRange: function setDateRange(dates) {
        var _this3 = this;
        this.set('dates', dates);
        if (dates && dates.length == 2) {
          this.loading.run(function () {
            return _this3.fetchData();
          });
        }
      }
    }
  });
  _exports.default = _default;
});