define("livarava-web/components/main-navigation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Computed
    locale: Ember.computed.reads('intl.primaryLocale'),
    // Properties
    currentUrl: Ember.computed('xyz', function () {
      return window && window.location ? window.location.href : '/';
    })
  });
  _exports.default = _default;
});