define("livarava-web/locales/uk/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'buttons': {
      'comment': 'Коментувати',
      'delete': 'Видалити',
      'edit': 'Редагувати',
      'subscribe': 'Підписатися'
    },
    'invoice': {
      'status': {
        'opened': 'Відкритий',
        'payed': 'Сплачений'
      }
    },
    'nav': {
      'sites': 'Сайти'
    },
    'portfolio': {
      'buttons': {
        'view': 'View'
      },
      'header': {},
      'content': {}
    },
    'task': {
      'buttons': {
        'apply': 'Apply',
        'assign': 'Assign',
        'approve': 'Approve',
        'finish': 'Finish',
        'start': 'Start',
        'close': 'Close'
      },
      'modals': {
        'apply': {
          'header': 'Apply to task',
          'description': 'Please, input data to apply for task...'
        },
        'assign': {
          'header': 'Assign member',
          'description': 'Please, select the member, that you want to assign for this task...'
        },
        'close': {
          'header': 'Close the task',
          'description': 'Please, input data to close the task...',
          'investment': 'Make investment'
        }
      },
      'status': {
        'opened': 'Opened',
        'assigned': 'Assigned',
        'evaluated': 'Evaluated',
        'approved': 'Approved',
        'in_progress': 'In progress',
        'finished': 'Finished',
        'closed': 'Closed'
      }
    }
  };
  _exports.default = _default;
});