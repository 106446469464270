define("livarava-web/components/motivation/analytics-registration-by-manager", ["exports", "@babel/runtime/helpers/esm/toConsumableArray", "@babel/runtime/helpers/esm/defineProperty", "@babel/runtime/helpers/esm/objectSpread2", "moment", "lodash/countBy", "livarava-web/config/environment"], function (_exports, _toConsumableArray2, _defineProperty2, _objectSpread3, _moment, _countBy2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var statusOptionsMap = _environment.default.options.order.status.reduce(function (r, i) {
    return (0, _objectSpread3.default)((0, _objectSpread3.default)({}, r), {}, (0, _defineProperty2.default)({}, i.value, i.label));
  }, {});
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // attributes
    readonlyManager: false,
    // filters
    dates: null,
    manager: null,
    // charts options
    ordersChartOptions: Ember.computed('orders.@each.created', function () {
      var orders = this.get('orders');
      return this.getChartOptions(orders, 'Реєстрації заявок', 'Зареєстровано заявок', '#5470c6');
    }),
    ordersStatusesDistribution: Ember.computed('orders.@each.status', function () {
      var items = this.get('orders');
      if (!items) return null;
      return (0, _countBy2.default)(this.get('orders').toArray(), function (item) {
        return statusOptionsMap[item.get('status')] || '-';
      });
    }),
    propertiesChartOptions: Ember.computed('properties.@each.created', function () {
      var properties = this.get('properties');
      return this.getChartOptions(properties, "Реєстрації об'єктів", "Зареєстровано об'єктів", '#91cc75');
    }),
    entitiesChartOptions: Ember.computed('entities.@each.created', function () {
      var entities = this.get('entities');
      return this.getChartOptions(entities, 'Реєстрації підрядників', 'Зареєстровано підрядників', '#fac858');
    }),
    actionItemsChartOptions: Ember.computed('actionItems.@each.created', function () {
      var actionItems = this.get('actionItems');
      return this.getChartOptions(actionItems, 'Дотики', 'виконано дотиків', '#ee6666');
    }),
    getChartOptions: function getChartOptions(items, titleText, seriesName, color) {
      var startDate = _moment.default.utc(this.dates[0]).set({
        hour: 0
      });
      var endDate = _moment.default.utc(this.dates[1]).set({
        hour: 0
      });
      var dates = [];
      var d = startDate;
      do {
        dates.push(d.add(1, 'days').clone());
      } while (d.isSameOrBefore(endDate));
      var totals = dates.map(function (m) {
        var filteredOrders = (items || []).filter(function (item) {
          return m.isSame(item.get('created'), 'day');
        });
        return filteredOrders.length;
      });
      return {
        title: {
          text: titleText
        },
        color: color,
        tooltip: {},
        legend: null,
        xAxis: {
          data: dates.map(function (m) {
            return m.format('DD-MM');
          })
        },
        yAxis: {},
        series: [{
          name: seriesName,
          type: 'bar',
          data: totals
        }]
      };
    },
    fetchData: function fetchData() {
      var _this = this;
      var managerId = this.get('manager.id');
      var _this$dates = this.dates,
        dates = _this$dates === void 0 ? [] : _this$dates,
        store = this.store;
      var requestParams = {
        date_start: (0, _moment.default)(dates[0]).format('YYYY-MM-DD'),
        date_end: (0, _moment.default)(dates[1]).format('YYYY-MM-DD'),
        per_page: 1000,
        page: 1
      };
      var requestParamsWithManager = (0, _objectSpread3.default)((0, _objectSpread3.default)({}, requestParams), {}, {
        'filter[manager]': managerId
      });
      return Ember.RSVP.hash({
        orders: store.query('order', requestParamsWithManager),
        properties: store.query('property', requestParamsWithManager),
        entities: store.query('entity', requestParamsWithManager),
        actionItems: store.query('action', (0, _objectSpread3.default)((0, _objectSpread3.default)({}, requestParams), {}, {
          'filter[author]': managerId
        }))
      }).then(function (results) {
        return _this.setProperties(results);
      });
    },
    fetchManagers: function fetchManagers() {
      var _this2 = this;
      var store = this.store;
      var ids = [].concat((0, _toConsumableArray2.default)(_environment.default.options.manager.managers), [this.get('me.id')]);
      return Promise.all(ids.map(function (id) {
        return store.findRecord('user', id);
      })).then(function (managers) {
        _this2.set('managers', managers);
        return managers;
      });
    },
    init: function init() {
      var _this3 = this;
      this._super.apply(this, arguments);
      this.set('dates', [(0, _moment.default)().startOf('week').toDate(), (0, _moment.default)().endOf('week').toDate()
      // moment('2021-03-20').toDate(),
      // moment('2021-03-27').toDate(),
      ]);

      this.fetchManagers().then(function (managers) {
        if (!_this3.get('manager')) {
          _this3.set('manager', managers[0]);
        }
      }).then(function () {
        return _this3.loading.run(function () {
          return _this3.fetchData();
        });
      });
    },
    // Actions
    actions: {
      setDateRange: function setDateRange(dates) {
        var _this4 = this;
        this.set('dates', dates);
        if (dates && dates.length == 2) {
          this.loading.run(function () {
            return _this4.fetchData();
          });
        }
      },
      setManager: function setManager(manager) {
        var _this5 = this;
        this.set('manager', manager);
        this.loading.run(function () {
          return _this5.fetchData();
        });
      }
    }
  });
  _exports.default = _default;
});