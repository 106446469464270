define("livarava-web/routes/settings/billing", ["exports", "fetch", "livarava-web/utils/get-api-url", "livarava-web/utils/get-filters-from-params"], function (_exports, _fetch, _getApiUrl, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Billing',
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      var token = Ember.get(this, 'me.token');
      params['author'] = meId;
      params['per_page'] = 5;
      return Ember.RSVP.hash({
        info: (0, _fetch.default)((0, _getApiUrl.default)('billing-info'), {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }),
        invoices: store.query('invoice', (0, _getFiltersFromParams.default)(params)),
        payments: store.query('payment', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});