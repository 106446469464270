define("livarava-web/utils/is-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = isUrl;
  function isUrl(str) {
    var pattern = new RegExp('^(https?:\\/\\/)?' +
    // protocol
    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
    // domain name
    '((\\d{1,3}\\.){3}\\d{1,3}))' +
    // OR ip (v4) address
    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
    // port and path
    '(\\?[;&a-z\\d%_.~+=-]*)?' +
    // query string
    '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator
    return pattern.test(str);
  }
});