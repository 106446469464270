define("livarava-web/components/site/site-billing", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    progressOptions: null,
    progressValue: null,
    balance: Ember.computed('site.balance', function () {
      var balances = Ember.get(this, 'site.balance');
      var currencyDefault = Ember.get(this, 'site.currency');
      var out = {};
      for (var currency in balances) {
        if (balances.hasOwnProperty(currency)) {
          if (balances[currency] > 0) {
            out[currency] = balances[currency];
          }
        }
      }
      if (!out.hasOwnProperty(currencyDefault)) {
        out[currencyDefault] = balances[currencyDefault];
      }
      return out;
    }),
    // Options
    planOptions: [{
      value: '25GB',
      label: '25GB'
    }, {
      value: '50GB',
      label: '50GB'
    }, {
      value: '100GB',
      label: '100GB'
    }, {
      value: '200GB',
      label: '200GB'
    }, {
      value: '500GB',
      label: '500GB'
    }, {
      value: '1TB',
      label: '1TB'
    }],
    // Flags
    showPlanInput: false,
    showBalance: Ember.computed('site.balance', function () {
      var balances = Ember.get(this, 'site.balance');
      return Object.keys(balances).length > 0;
    }),
    canChangePlan: Ember.computed('site.plan', function () {
      var plan = Ember.get(this, 'site.plan');
      return plan !== 'CONTRACT';
    }),
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var spaceTotal = Ember.get(this, 'site.space_total');
      var spaceUsed = Ember.get(this, 'site.space_used');
      var progressValue = spaceUsed / spaceTotal;
      var progressColor = '#319728';
      if (progressValue > 0.33) {
        progressColor = '#f3ba55';
      }
      if (progressValue > 0.66) {
        progressColor = '#d60f04';
      }
      if (progressValue > 1) {
        progressValue = 1;
      }
      var progressOptions = {
        // Stroke color.
        // Default: '#555'
        color: progressColor,
        // Width of the stroke.
        // Unit is percentage of SVG canvas' size.
        // Default: 1.0
        // NOTE: In Line shape, you should control
        // the stroke width by setting container's height.
        // WARNING: IE doesn't support values over 6, see this bug:
        //          https://github.com/kimmobrunfeldt/progressbar.js/issues/79
        strokeWidth: 5,
        // If trail options are not defined, trail won't be drawn

        // Color for lighter trail stroke
        // underneath the actual progress path.
        // Default: '#eee'
        trailColor: '#ddd',
        // Width of the trail stroke. Trail is always centered relative to
        // actual progress path.
        // Default: same as strokeWidth
        // trailWidth: 0.8,

        // Inline CSS styles for the created SVG element
        // Set null to disable all default styles.
        // You can disable individual defaults by setting them to `null`
        // If you specify anything in this object, none of the default styles
        // apply
        svgStyle: {
          display: 'block',
          // Important: make sure that your container has same
          // aspect ratio as the SVG canvas. See SVG canvas sizes above.
          width: '100%'
        },
        // Text options. Text element is a <p> element appended to container
        // You can add CSS rules for the text element with the className
        // NOTE: When text is set, 'position: relative' will be set to the
        // container for centering. You can also prevent all default inline
        // styles with 'text.style: null'
        // Default: null
        text: {
          // Initial value for text.
          // Default: null
          // value: 'Text',

          // Class name for text element.
          // Default: 'progressbar-text'
          // className: 'progressbar__label',

          // Inline CSS styles for the text element.
          // If you want to modify all CSS your self, set null to disable
          // all default styles.
          // If the style option contains values, container is automatically
          // set to position: relative. You can disable behavior this with
          // autoStyleContainer: false
          // If you specify anything in this object, none of the default styles
          // apply
          // Default: object speficied below
          style: {
            // Text color.
            // Default: same as stroke color (options.color)
            color: '#f00',
            position: 'absolute',
            left: '50%',
            top: '50%',
            padding: 0,
            margin: 0,
            // You can specify styles which will be browser prefixed
            transform: {
              prefix: true,
              value: 'translate(-50%, -50%)'
            }
          },
          // Only effective if the text.style is not null
          // By default position: relative is applied to container if text
          // is set. Setting this to false disables that feature.
          autoStyleContainer: true,
          // Only effective if the shape is SemiCircle.
          // If true, baseline for text is aligned with bottom of
          // the SVG canvas. If false, bottom line of SVG canvas
          // is in the center of text.
          // Default: true
          alignToBottom: true
        },
        // Fill color for the shape. If null, no fill.
        // Default: null
        fill: '#ccc',
        // Duration for animation in milliseconds
        // Default: 800
        // duration: 500,

        // Easing for animation. See #easing section.
        // Default: 'linear'
        easing: 'easeOut',
        // See #custom-animations section
        // Built-in shape passes reference to itself and a custom attachment
        // object to step function
        // from: {color: '#80d151'},
        // to: {color: '#d60f04'},
        // step: function (state, circle, attachment) {
        //   circle.path.setAttribute('stroke', state.color);
        // },

        // If true, some useful console.warn calls will be done if it seems
        // that progressbar is used incorrectly
        // Default: false
        warnings: false
      };
      Ember.set(this, 'progressValue', progressValue);
      Ember.set(this, 'progressColor', progressColor);
      Ember.set(this, 'progressOptions', progressOptions);
    },
    // Actions
    actions: {
      changePlan: function changePlan() {
        if (confirm("Are you sure?")) {
          Ember.set(this, 'showPlanInput', true);
        }
      },
      cancelPlan: function cancelPlan() {
        Ember.set(this, 'showPlanInput', false);
      },
      savePlan: function savePlan() {
        var _this = this;
        var site = this.site;
        site.save().then(function () {
          return Ember.set(_this, 'showPlanInput', false);
        });
      }
    }
  });
  _exports.default = _default;
});