define("livarava-web/templates/components/common/common-content", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "uYwZwIbn",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"nomenu\"],[[35,1]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[12],[2,\"\\n  \"],[18,1,null],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,4],[[35,5]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[34,0]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"common/common-footer\",\"nomenu\",\"common/common-header\",\"header\",\"if\",\"footer\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-content.hbs"
    }
  });
  _exports.default = _default;
});