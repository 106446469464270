define("livarava-web/routes/signin", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Params
    queryParams: {
      next: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var isSigned = Ember.get(this, 'session.signed');
      if (isSigned) {
        this.transitionTo('index');
      }
      var next = params.next;
      return Ember.RSVP.hash({
        next: next
      });
    }
  });
  _exports.default = _default;
});