define("livarava-web/controllers/settings/invitations", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    email: null,
    user: null,
    error: null,
    // Classes
    emailClass: Ember.computed('email', 'error', function () {
      var email = this.email;
      var error = this.error;
      if (!error) return 'form-control';
      return 'form-control is-invalid';
    }),
    // Params
    queryParams: ['page', 'q', 'status'],
    page: 1,
    q: null,
    status: null,
    // States
    isSaving: false,
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            _this.send('reload');
          });
        }
      },
      resend: function resend(item) {
        var _this2 = this;
        item.set('repeat', true);
        item.save().then(function () {
          _this2.send('reload');
        });
      },
      send: function send() {
        var _this3 = this;
        var email = this.email;
        var item = this.store.createRecord('invitation', {
          email: email,
          created: _moment.default.utc()
        });
        this.set('isSaving', true);
        item.save().then(function (item) {
          _this3.set('isSaving', false);
          _this3.set('error', null);
          _this3.set('email', null);
          _this3.send('reload');
        }, function () {
          _this3.set('isSaving', false);
          _this3.set('error', true);
        });
      },
      setUser: function setUser(item) {
        this.set('user', item);
      }
    }
  });
  _exports.default = _default;
});