define("livarava-web/templates/components/homepage/homepage-posts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wA2qefSx",
    "block": "{\"symbols\":[\"post\"],\"statements\":[[10,\"div\"],[14,0,\"homepage-posts\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"row homepage-block-title\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"col-12\"],[12],[2,\"\\n      \"],[10,\"h2\"],[14,0,\"text-center\"],[12],[2,\"\\n        Posts\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"events container\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"col-12 col-md-7 offset-md-1\"],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"            \"],[10,\"div\"],[14,0,\"event mb-3\"],[12],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-image\"],[15,5,[32,1,[\"imageStyle\"]]],[12],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"cell-event-title\"],[12],[1,[32,1,[\"title\"]]],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-date\"],[12],[1,[30,[36,0],[[32,1,[\"created\"]],\"MMMM DD, YYYY\"],null]],[13],[2,\"\\n              \"],[10,\"div\"],[14,0,\"event-summary\"],[12],[1,[32,1,[\"summary\"]]],[13],[2,\"\\n            \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]],[2,\"      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"link-to\",\"posts\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/homepage/homepage-posts.hbs"
    }
  });
  _exports.default = _default;
});