define("livarava-web/components/send-neuron-on-email", ["exports", "jquery", "livarava-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Properties
    neuronId: null,
    // States
    isFormVisible: Ember.computed('expanded', 'model.isNew', function () {
      return this.expanded && this.get('model.isNew');
    }),
    // Functions
    createModel: function createModel() {
      this.destroyModel();
      this.set('model', this.store.createRecord('send', {
        neuronId: this.neuronId,
        filterId: this.filterId
      }));
    },
    destroyModel: function destroyModel() {
      if (this.model) {
        this.store.unloadRecord(this.model);
      }
      this.set('model', null);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      var typeaheads = this.$('input').focus().typeahead({
        minLength: 3,
        source: function source(query, process) {
          _jquery.default.get("".concat(_environment.default.api.host, "/api/v2/autocomplete/neurons/"), {
            q: query,
            type: 'email'
          }).done(function (data) {
            return process(data.data);
          }).fail(function () {
            return process([]);
          });
        },
        displayText: function displayText(item) {
          return item.title;
        },
        delay: 100,
        autoSelect: true
      });
      this.set('typeaheads', typeaheads);
    },
    willDestroyElement: function willDestroyElement() {
      this._super.apply(this, arguments);
      if (this.typeaheads) {
        this.typeaheads.typeahead('destroy');
      }
      this.set('typeaheads', null);
    },
    // Actions
    actions: {
      expand: function expand() {
        this.createModel();
        this.set('expanded', true);
      },
      collapse: function collapse() {
        this.set('expanded', false);
        this.destroyModel();
      },
      new: function _new() {
        this.createModel();
      },
      submit: function submit() {
        this.model.save();
      }
    }
  });
  _exports.default = _default;
});