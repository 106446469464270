define("livarava-web/templates/sites/item/events/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H8vgoFzH",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"save\"],[[35,1],[30,[36,0],[\"save\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"site/site-event-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/events/create.hbs"
    }
  });
  _exports.default = _default;
});