define("livarava-web/components/share/share-axon", ["exports", "ember-delay/delay", "livarava-web/utils/share-axon", "jquery"], function (_exports, _delay, _shareAxon, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    message: null,
    // Flags
    showMessageInput: false,
    isSharing: false,
    canShare: Ember.computed('recipients.[]', 'axon.id', function () {
      var recipients = this.recipients;
      var hasRecipients = recipients.length > 0;
      var axonId = parseInt(Ember.get(this, 'axon.id'));
      return hasRecipients && axonId > -1;
    }),
    // Recipients
    recipients: null,
    recipient: Ember.computed('recipients.[]', function () {
      var recipients = this.recipients;
      return recipients.length > 0 ? recipients.objectAt(0) : null;
    }),
    recipientInputPlaceholer: Ember.computed('recipients.[]', function () {
      var length = Ember.get(this, 'recipients.length');
      return length > 0 ? "" : "Type the name of a person or group";
    }),
    recipientIdsArray: Ember.computed('recipients.[]', function () {
      var recipients = this.recipients.toArray();
      return recipients.length > 0 ? recipients.map(function (recipient) {
        return parseInt(recipient.id);
      }) : [];
    }),
    recipientIds: Ember.computed('recipientIdsArray', function () {
      var recipients = this.recipientIdsArray;
      return recipients.length > 0 ? getRecipientsIds(recipients) : null;
    }),
    init: function init() {
      this._super();
      Ember.set(this, 'recipients', Ember.A());
      Ember.set(this, 'message', null);
    },
    success: function success() {
      Ember.set(this, 'showSuccess', true);
      Ember.set(this, 'recipients', Ember.A());
      Ember.set(this, 'message', null);
      (0, _delay.default)(100).then(function () {
        (0, _jquery.default)('#message-success').fadeIn();
      });
    },
    // Actions
    actions: {
      show: function show() {
        Ember.set(this, 'showSuccess', false);
        Ember.set(this, 'isVisible', true);
      },
      hide: function hide() {
        Ember.set(this, 'isVisible', false);
        Ember.set(this, 'showSuccess', false);
      },
      addRecipient: function addRecipient(value) {
        var _this = this;
        var store = this.store;
        if (value > -1) {
          store.findRecord('user', value).then(function (user) {
            _this.recipients.pushObject(user);
          });
        }
      },
      removeRecipient: function removeRecipient(value) {
        var recipients = this.recipients;
        recipients = recipients.filter(function (item) {
          return parseInt(item.id) !== parseInt(value);
        });
        Ember.set(this, 'recipients', recipients);
      },
      onBackspace: function onBackspace(value) {
        var recipients = this.recipients;
        if (recipients.length > 0 && (value === null || value === "")) {
          recipients.popObject();
        }
      },
      share: function share() {
        var _this2 = this;
        var recipientsIds = this.recipientIds;
        var axonId = Ember.get(this, 'axon.id');
        var message = this.message;
        var token = Ember.get(this, 'me.token');
        Ember.set(this, 'isSharing', true);
        (0, _shareAxon.default)(recipientsIds, axonId, message, token).then(function (data) {
          Ember.set(_this2, 'isSharing', false);
          _this2.success();
        }).catch(function () {
          // Handle Error
          Ember.set(_this2, 'isSharing', true);
        });
      }
    }
  });
  _exports.default = _default;
  function getRecipientsIds(arr) {
    return arr.sort(function (a, b) {
      return a > b;
    }).join(',');
  }
});