define("livarava-web/templates/components/invitation/invitation-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "fOH8EJec",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,2],null,[[\"item\",\"onResend\",\"onDelete\"],[[32,1],[35,1],[35,0]]]]],[2,\"\\n\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onDelete\",\"onResend\",\"invitation/invitation-item\",\"items\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/invitation/invitation-list.hbs"
    }
  });
  _exports.default = _default;
});