define("livarava-web/templates/components/neuron/neuron-axon-placeholder", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "c3C7U+Pd",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"placeholder-glow neuron-random row mb-3\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-4\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"placeholder rounded\"],[14,5,\"width: 100%; height: 80px;\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-8\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"neuron-header\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"placeholder w-100 m-1\"],[12],[2,\" \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"placeholder w-75 m-1\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"placeholder w-50 m-1\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"placeholder w-50 m-1\"],[12],[2,\" \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-axon-placeholder.hbs"
    }
  });
  _exports.default = _default;
});