define("livarava-web/components/site/site-page-form", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Computed
    previewUrl: Ember.computed('model.site.url', 'model.path', function () {
      if (!this.model) return null;
      var currentUrl = window.location.href;
      var siteUrl = Ember.get(this, 'model.site.url');
      var path = Ember.get(this, 'model.path');
      if (siteUrl === undefined) return null;
      var isLivaRavaSite = siteUrl.indexOf('www.livarava.com') > -1;
      var isLocalSite = currentUrl.indexOf('local.livarava.com') > -1;
      if (isLivaRavaSite && isLocalSite) {
        return 'http://local.livarava.com' + path;
      }
      return 'https://' + siteUrl + path;
    }),
    validation: function validation(name, errors) {
      var element = (0, _jquery.default)('#' + name);
      var elementErrors = [];
      errors.forEach(function (item) {
        if (item.attribute === name) {
          elementErrors.push(item.message);
        }
      });
      element.addClass(elementErrors ? 'is-invalid' : 'is-valid');
      Ember.set(this, name + 'Error', elementErrors.join('; '));
    },
    // Actions
    actions: {
      preview: function preview() {
        var url = 'http://' + Ember.get(this, 'model.site.url') + Ember.get(this, 'model.path');
        window.open(url, '_blank');
      },
      save: function save() {
        var model = this.model;

        // if (model.validate()) {
        this.save(model);
        // } else {
        // const errors = get(model, 'errors');
        // this.validation('path', errors);
        // this.validation('title', errors);
        // }
      },
      delete: function _delete() {
        this['delete'](this.model);
      }
    }
  });
  _exports.default = _default;
});