define("livarava-web/router", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('actions', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('axons', function () {
      this.route('item', {
        path: '/:id'
      });
      this.route('new');
    });
    this.route('blog');
    this.route('cloud');
    this.route('contact');
    this.route('contacts', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('dashboard');
    this.route('debug');
    this.route('docs', function () {
      this.route('page', {
        path: '/:uid'
      });
    });
    this.route('entities', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('error401');
    this.route('error403');
    this.route('error404', {
      path: '*:'
    });
    this.route('error500');
    this.route('events', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('register', {
        path: '/:id/register'
      });
      this.route('tickets', {
        path: '/:id/tickets'
      });
      this.route('print', {
        path: '/:id/print'
      });
    });
    this.route('feedbacks', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('framework');
    this.route('forbidden');
    this.route('forgot');
    this.route('funnels', function () {
      this.route('item', {
        path: '/:model_type'
      });
    });
    this.route('homepage');
    this.route('investments', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
      this.route('new');
      this.route('success');
      this.route('created');
    });
    this.route('invoices', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('lang', {
      path: '/:locale'
    }, function () {
      this.route('blog', function () {
        this.route('item', {
          path: '/:id'
        });
      });
      this.route('index');
      this.route('notfound');
      this.route('page', {
        path: '/:uid'
      });
      this.route('careers', function () {
        this.route('item', {
          path: '/:id'
        });
      });
    });
    this.route('leads', function () {
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('motivation', function () {
      this.route('registrations', function () {
        this.route('registrations-by-manager');
        this.route('orders-count-progress');
        this.route('has-entity-categories');
      });
      this.route('properties-overdue');
      this.route('entities-overdue');
      this.route('orders-totals');
      this.route('services-of-activated-entities');
      this.route('popular-services');
      this.route('unit-economy');
    });
    this.route('my-motivation', function () {
      this.route('registrations-by-manager');
      this.route('orders-count-progress');
      this.route('properties-overdue');
      this.route('entities-overdue');
    });
    this.route('navigations', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('neuron', function () {
      this.route('create', {
        path: '/create/:title'
      });
      this.route('item', {
        path: '/:id'
      }, function () {
        this.route('overview');
        this.route('comments');
        this.route('connections');
        this.route('subscribers');
        this.route('debug');
      });
    });
    this.route('neuroner', function () {
      this.route('feeds', function () {
        this.route('create');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
      this.route('filters', function () {
        this.route('create');
        this.route('edit', {
          path: '/:id/edit'
        });
      });
      this.route('settings', function () {
        this.route('general');
        this.route('customers', function () {
          this.route('item', {
            path: '/:id'
          });
        });
        this.route('feeds', function () {
          this.route('create');
          this.route('item', {
            path: '/:id'
          });
          this.route('edit', {
            path: '/:id/edit'
          });
          this.route('preview', {
            path: '/:id/preview'
          });
        });
        this.route('status');
      });
    });
    this.route('neuronet');
    this.route('neurons', function () {});
    this.route('notfound');
    this.route('notifications');
    this.route('orders', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('page', {
      path: '/:page_id'
    }, function () {
      this.route('item');
      this.route('post', {
        path: '/posts/:post_id'
      });
    });
    this.route('pages', function () {
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('password');
    this.route('payments', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('payouts', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      });
      this.route('new');
    });
    this.route('posts', function () {
      this.route('item', {
        path: '/:id'
      });
    });
    this.route('products', function () {
      this.route('create');
      this.route('edit', {
        path: '/:product_id/edit'
      });
      this.route('item', {
        path: '/:product_id'
      }, function () {
        this.route('about');
        this.route('payments', function () {
          this.route('item', {
            path: '/:payment_id'
          });
        });
        this.route('customers', function () {});
        this.route('members');
        this.route('settings');
        this.route('goals');
        this.route('invoices');
        this.route('tasks');
      });
    });
    this.route('profile', function () {
      this.route('about');
      this.route('projects');
      this.route('products', function () {
        this.route('create');
        this.route('edit');
        this.route('item', function () {
          this.route('about');
          this.route('settings');
          this.route('members');
          this.route('payments');
        });
      });
      this.route('settings');
    });
    this.route('projects', function () {
      this.route('create');
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('item', {
        path: '/:id'
      }, function () {
        this.route('members');
        this.route('about');
        this.route('preorders', function () {
          this.route('settings');
        });
        this.route('settings', function () {
          this.route('payments');
          this.route('investments');
        });
        this.route('payments');
        this.route('payouts');
        this.route('investments', function () {
          this.route('settings');
        });
        this.route('stocks');
      });
    });
    this.route('properties', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('restore');
    this.route('reviews', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('search', {
      path: '/search/:neuron_id'
    });
    this.route('search-examples');
    this.route('sites', function () {
      this.route('create');
      this.route('edit', {
        path: '/:site_id/edit'
      });
      this.route('item', {
        path: '/:site_id'
      }, function () {
        this.route('about');
        this.route('feedbacks', function () {
          this.route('create');
          this.route('item', {
            path: '/:feedback_id'
          });
          this.route('edit', {
            path: '/:feedback_id/edit'
          });
        });
        this.route('navigations', function () {
          this.route('create');
          this.route('edit', {
            path: '/:navigation_id/edit'
          });
        });
        this.route('categories', function () {
          this.route('create');
          this.route('edit', {
            path: '/:category_id/edit'
          });
        });
        this.route('pages', function () {
          this.route('create');
          this.route('item', {
            path: '/:page_id'
          });
          this.route('edit', {
            path: '/:page_id/edit'
          });
        });
        this.route('posts', function () {
          this.route('create');
          this.route('item', {
            path: '/:post_id'
          });
          this.route('edit', {
            path: '/:post_id/edit'
          });
        });
        this.route('events', function () {
          this.route('create');
          this.route('item', {
            path: '/:event_id'
          });
          this.route('edit', {
            path: '/:event_id/edit'
          });
        });
        this.route('users', function () {
          this.route('item', {
            path: '/:user_id'
          });
          this.route('edit', {
            path: '/:user_id/edit'
          });
        });
        this.route('members');
        this.route('sitemap');
        this.route('billing');
        this.route('billing-settings');
        this.route('settings');
        this.route('general');
        this.route('domains');
        this.route('seo');
        this.route('smm');
        this.route('api');
      });
      this.route('new');
    });
    this.route('services', function () {
      this.route('create');
      this.route('admin');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
      this.route('seo', {
        path: '/:id/seo'
      });
      this.route('smm', {
        path: '/:id/smm'
      });
      this.route('children', {
        path: '/:id/children'
      });
      this.route('entities', {
        path: '/:id/entities'
      });
      this.route('tree');
    });
    this.route('settings', function () {
      this.route('profile');
      this.route('invitations');
      this.route('billing');
      this.route('invoices', function () {
        this.route('item', {
          path: '/:id'
        });
      });
      this.route('payments', function () {
        this.route('item', {
          path: '/:id'
        });
      });
      this.route('identity');
    });
    this.route('signin');
    this.route('signout');
    this.route('signup');
    this.route('speech');
    this.route('store', function () {
      this.route('neuroner');
      this.route('sites');
    });
    this.route('support', function () {
      this.route('tickets', function () {
        this.route('create');
        this.route('item', {
          path: '/:id'
        });
      });
      this.route('posts', function () {
        this.route('item', {
          path: '/:id'
        });
      });
    });
    this.route('tasks', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('transactions', function () {
      this.route('create');
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
    this.route('users', function () {
      this.route('item', {
        path: '/:id'
      });
      this.route('edit', {
        path: '/:id/edit'
      });
    });
  });
  var _default = Router;
  _exports.default = _default;
});