define("livarava-web/utils/share-axon", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = createAxon;
  function createAxon(recipientsIds, axonId, message) {
    var token = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : null;
    if (recipientsIds === null) {
      // Ember.Logger.log('ShareAxonError: Missing Recipients');
      return;
    }
    if (axonId === null) {
      // Ember.Logger.log('ShareAxonError: Missing Axon');
      return;
    }
    var url = (0, _getApiUrl.default)('share-axon', {
      recipients: recipientsIds,
      axon: axonId,
      message: message
    });
    return (0, _fetch.default)(url, {
      headers: {
        'X-LivaRava-Token': token
      }
    }).then(function (response) {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error();
      }
    });
  }
});