define("livarava-web/components/product/product-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    onArchive: null,
    isMouseOver: false,
    showDropdownButton: Ember.computed('media.isMobile', 'isMouseOver', function () {
      var isMobile = Ember.get(this, 'media.isMobile');
      var isMouseOver = this.isMouseOver;
      if (isMobile) return true;
      if (isMouseOver) return true;
      return false;
    }),
    mouseEnter: function mouseEnter() {
      Ember.set(this, 'isMouseOver', true);
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, 'isMouseOver', false);
      Ember.set(this, 'item.showDropdownMenu', false);
    },
    actions: {
      archive: function archive() {
        var _this = this;
        var item = this.item;
        Ember.set(item, 'status', 'archived');
        item.save().then(function () {
          _this.onArchive();
        });
      }
    }
  });
  _exports.default = _default;
});