define("livarava-web/components/comment/comment-form", ["exports", "livarava-web/utils/upload-file", "livarava-web/utils/create-axon", "moment"], function (_exports, _uploadFile, _createAxon, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Attributes
    item: null,
    buttonLabel: 'Send',
    // Handlers
    onSubmit: null,
    // States
    isUploading: false,
    isSending: false,
    isError: false,
    isSubmitDisabled: Ember.computed('text', 'isSending', function () {
      var text = this.text;
      var isSending = this.isSending;
      return !text || isSending;
    }),
    // Paste
    paste: function paste(e) {
      var _this = this;
      var clipboardData = e.originalEvent.clipboardData;
      var clipboardImage = null;
      for (var i = 0; i < clipboardData.items.length; i++) {
        var item = clipboardData.items[i];
        console.log("Item: " + item.type);
        if (item.type.indexOf("image") > -1) {
          clipboardImage = item.getAsFile();
          break;
        }
      }
      if (!clipboardImage) {
        console.log("Discarding image paste data");
        return;
      }
      console.log("Upload image from paste data...");
      Ember.set(this, 'isUploading', true);
      (0, _uploadFile.default)(clipboardImage).then(function (fileData) {
        console.log(fileData);
        var url = fileData.link;
        var header = 'Pasted Image ' + (0, _moment.default)().format("YYYY-MM-DD hh:mm:ss");
        var neuron_id = parseInt(Ember.get(_this, 'item.id'));
        var image = _this.store.createRecord('neuron', {
          type: 'image',
          title: url,
          header: header,
          display: 'private'
        });
        image.save().then(function (imageData) {
          var livaId = neuron_id;
          var ravaId = parseInt(imageData.get('id'));
          var display = 'private';
          var token = Ember.get(_this, 'me.token');
          (0, _createAxon.default)(livaId, ravaId, display, token).then(function () {
            var text = "![](".concat(url, ")");
            var comment = _this.store.createRecord('comment', {
              text: text,
              root: neuron_id,
              parent: null
            });
            comment.save().then(function () {
              Ember.set(_this, 'isUploading', false);
              _this.onNewComment();
            });
          });
        });
      });
    },
    // Actions
    actions: {
      submit: function submit() {
        var _this2 = this;
        var store = this.store;
        var text = this.text;
        var item = this.item;
        store.findRecord('neuron', item.id).then(function (root) {
          if (!text) return;
          if (!root) return;
          var comment = store.createRecord('comment', {
            text: text,
            root: root,
            parent: null
          });
          Ember.set(_this2, 'text', null);
          Ember.set(_this2, 'isSending', true);
          Ember.set(_this2, 'isError', false);
          comment.save().then(function () {
            Ember.set(_this2, 'isSending', false);
            var onNewComment = _this2.onNewComment;
            if (!onNewComment) return;
            onNewComment();
          }).catch(function () {
            Ember.set(_this2, 'isSending', false);
            Ember.set(_this2, 'isError', true);
          });
        }).catch(function () {
          Ember.set(_this2, 'isSending', false);
          Ember.set(_this2, 'isError', true);
        });
      },
      onKeyUp: function onKeyUp(value, event) {
        if (event.ctrlKey && event.key === 'Enter') {
          this.send('submit');
        }
      }
    }
  });
  _exports.default = _default;
});