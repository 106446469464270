define("livarava-web/components/navigation/navigation-item-before", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Variables
    item: null,
    // Events
    onChange: null,
    // States
    isDragOver: false,
    // Actions
    actions: {
      onDragOver: function onDragOver() {
        this.set('isDragOver', true);
        // console.log('onDragOverBefore' + this.get("item.id"));
      },

      onDragOut: function onDragOut() {
        this.set('isDragOver', false);
        // console.log('onDragOverBefore' + this.get("item.id"));
      },

      onDragDrop: function onDragDrop(obj) {
        var _this = this;
        // console.log('onDragDropBefore' + this.get("item.id"));
        var object = obj;
        var target = this.item;
        var level = parseInt(target.get('level'));
        var order = parseInt(target.get('order')) - 1;
        var parentId = target.get('parent.id');
        this.store.findRecord('neuron', parentId).then(function (item) {
          object.set('level', level);
          object.set('order', order);
          object.set('parent', item);
          _this.onChange();
        });
      }
    }
  });
  _exports.default = _default;
});