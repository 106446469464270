define("livarava-web/utils/get-display-choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getNeuronTypeChoices;
  function getNeuronTypeChoices() {
    return Ember.A([{
      value: 'private',
      label: 'Private'
    }, {
      value: 'public',
      label: 'Public'
    }]);
  }
});