define("livarava-web/components/common/filters/filter-daterange", ["exports", "@babel/runtime/helpers/esm/slicedToArray", "moment"], function (_exports, _slicedToArray2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Actions
    actions: {
      setDateRange: function setDateRange(range) {
        var _range = (0, _slicedToArray2.default)(range, 2),
          dateStart = _range[0],
          dateEnd = _range[1];
        if (dateStart && dateEnd) {
          this.controller.set('page', 1);
          this.controller.set('date_start', (0, _moment.default)(dateStart).format('YYYY-MM-DD'));
          this.controller.set('date_end', (0, _moment.default)(dateEnd).format('YYYY-MM-DD'));
        }
      }
    }
  });
  _exports.default = _default;
});