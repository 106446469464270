define("livarava-web/components/entity/entityservice-table", ["exports", "livarava-web/config/environment", "livarava-web/validations/entityservice-validations"], function (_exports, _environment, _entityserviceValidations) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    entityserviceValidations: _entityserviceValidations.default,
    allServiceTypes: _environment.default.service.types,
    entity: null,
    entityservices: null,
    openedEntityservice: null,
    initEntityservice: function initEntityservice() {
      if (this.entity) {
        var store = this.store;
        var openedEntityservice = store.createRecord('entityservice', {
          entity: this.entity
        });
        this.set('openedEntityservice', openedEntityservice);
      }
    },
    actions: {
      toggleModal: function toggleModal() {
        this.initEntityservice();
      },
      saveEntityservice: function saveEntityservice(changeset) {
        var _this = this;
        if (this.get('openedEntityservice.isSaving')) {
          // skip save if in progress
          return;
        }
        return changeset.save().then(function (entityservice) {
          _this.setProperties({
            openedEntityservice: null
          });
          if (typeof _this.onAdd == 'function') {
            return _this.onAdd(entityservice);
          }
        });
      },
      removeService: function removeService(entityservice) {
        var _this2 = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        return entityservice.destroyRecord().then(function () {
          if (typeof _this2.onAdd == 'function') {
            return _this2.onAdd(entityservice);
          }
        });
      }
    }
  });
  _exports.default = _default;
});