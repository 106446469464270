define("livarava-web/components/modal/modal-email", ["exports", "livarava-web/utils/get-api-url", "livarava-web/config/environment", "fetch"], function (_exports, _getApiUrl, _environment, _fetch) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    sender: 'neuronet@livarava.com',
    items: null,
    subject: null,
    text: null,
    message: null,
    // Computed
    recipients: Ember.computed('items.[]', function () {
      var recipients = [];
      var items = this.items;
      items.forEach(function (item) {
        var email = Ember.get(item, 'email');
        var phone = Ember.get(item, 'phone');
        var firstname = Ember.get(item, 'firstname');
        var lastname = Ember.get(item, 'lastname');
        var fullname = Ember.get(item, 'fullname') || Ember.get(item, 'title');
        recipients.push({
          firstname: firstname,
          lastname: lastname,
          fullname: fullname,
          email: email,
          phone: phone
        });
      });
      return recipients;
    }),
    recipientCount: Ember.computed.reads('recipients.length'),
    // Options
    senderOptions: [{
      value: 'neuronet@livarava.com',
      label: 'neuronet@livarava.com'
    }, {
      value: 'info@livarava.com',
      label: 'info@livarava.com'
    }, {
      value: 'sales@livarava.com',
      label: 'sales@livarava.com'
    }, {
      value: 'info@hospodari.com',
      label: 'info@hospodari.com'
    }, {
      value: 'sales@hospodari.com',
      label: 'sales@hospodari.com'
    }],
    froalaOptions: _environment.default.froalaOptions.max,
    // Flags
    isPlainOnly: false,
    isEmailSending: false,
    showEmailForm: false,
    showEmailSuccess: false,
    showEmailError: false,
    // Actions
    actions: {
      sendEmail: function sendEmail() {
        var _this = this;
        var sender = this.sender;
        var recipients = this.recipients;
        var subject = this.subject;
        var text = this.text;
        var html = this.html;
        var params = {
          sender: sender,
          recipients: recipients,
          subject: subject,
          text: text,
          html: html
        };
        var url = (0, _getApiUrl.default)('email', params, 'POST');
        var token = Ember.get(this, 'me.token');
        var headers = {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        };
        Ember.set(this, 'showEmailSuccess', false);
        Ember.set(this, 'showEmailError', false);
        Ember.set(this, 'isEmailSending', true);
        Ember.set(this, 'emailError', null);
        (0, _fetch.default)(url, headers).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          Ember.set(_this, 'isEmailSending', false);
          Ember.set(_this, 'emailError', null);
          Ember.set(_this, 'showEmailSuccess', true);
        }).catch(function (response) {
          Ember.set(_this, 'isEmailSending', false);
          Ember.set(_this, 'emailError', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this, 'showEmailError', true);
        });
      }
    }
  });
  _exports.default = _default;
});