define("livarava-web/controllers/services/admin", ["exports", "@babel/runtime/helpers/esm/objectSpread2"], function (_exports, _objectSpread2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions

    parentIds: Ember.computed.mapBy('model.parents', 'id'),
    actions: {
      clearAll: function clearAll() {
        var store = this.store;
        return store.query('service', {
          per_page: 1000
        }).then(function (services) {
          // make sure top categories doesn't have parent
          return Promise.all(services.map(function (service) {
            service.set('parent', null);
            return service.save();
          }));
        });
      },
      makeParents: function makeParents() {
        var _this = this;
        var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
          id = _ref.id,
          params = _ref.params;
        var store = this.store;
        return Ember.RSVP.hash({
          parent: store.findRecord('service', id),
          children: store.query('service', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, params), {}, {
            per_page: 1000
          }))
        }).then(function (_ref2) {
          var parent = _ref2.parent,
            children = _ref2.children;
          /*results.forEach(({ parent, children }) =>
              console.table(
                [parent, ...children.toArray()].map((service) => service.getProperties('id', 'title'))
              )
            );*/
          return Promise.all(children.filter(function (child) {
            return !_this.get('parentIds').includes(child.get('id'));
          }).map(function (child) {
            child.set('parent', parent);
            return child.save();
          }));
        });
      },
      fixTop: function fixTop() {
        // make sure top categories doesn't have parent
        var store = this.store,
          model = this.model;
        return Promise.all(model.parents.map(function (_ref3) {
          var id = _ref3.id;
          return store.findRecord('service', id);
        })).then(function (services) {
          return Promise.all(services.map(function (service) {
            service.set('parent', null);
            return service.save();
          }));
        });
      }
    }
  });
  _exports.default = _default;
});