define("livarava-web/templates/components/offer/offer-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Y4rcjmju",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"i\"],[14,0,\"fa fa-check text-success\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" обрано\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"created\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle text-success\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" нова\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"published\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle text-success\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" опубіковано\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"rejected\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-circle text-danger\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" відхилено\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[30,[36,1],[[35,0],\"canceled\"],null]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-ban text-muted\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" скасовано\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[34,0]],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[18,1,null]],\"hasEval\":false,\"upvars\":[\"status\",\"eq\",\"if\",\"isSelected\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/offer/offer-status.hbs"
    }
  });
  _exports.default = _default;
});