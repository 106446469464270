define("livarava-web/routes/investments/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      project: {
        refreshModel: true
      },
      product: {
        refreshModel: true
      },
      user: {
        refreshModel: true
      },
      amount: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var copyId = params.copy ? parseInt(params.copy) : undefined;
      var title = params.title || null;
      var created = params.created || (0, _moment.default)();
      var projectId = params.project ? parseInt(params.project) : undefined;
      var productId = params.product ? parseInt(params.product) : undefined;
      var userId = params.user ? parseInt(params.user) : undefined;
      var amount = params.amount ? parseFloat(params.amount) : undefined;
      var currency = params.currency || 'USD';
      var status = params.status || null;
      var item = store.createRecord('investment', {
        title: title,
        created: created,
        amount: amount,
        currency: currency,
        status: status
      });
      if (copyId !== undefined) {
        store.findRecord('investment', copyId).then(function (itemToCopy) {
          item.set('title', itemToCopy.get('title'));
          item.set('created', itemToCopy.get('created'));
          item.set('project', itemToCopy.get('project'));
          item.set('product', itemToCopy.get('product'));
          item.set('user', itemToCopy.get('user'));
          item.set('amount', itemToCopy.get('amount'));
          item.set('currency', itemToCopy.get('currency'));
          item.set('status', itemToCopy.get('status'));
        });
      }
      if (projectId !== undefined) {
        store.findRecord('neuron', projectId).then(function (project) {
          Ember.set(item, 'project', project);
        });
      }
      if (productId !== undefined) {
        store.findRecord('neuron', productId).then(function (product) {
          Ember.set(item, 'product', product);
        });
      }
      if (userId !== undefined) {
        store.findRecord('user', userId).then(function (user) {
          Ember.set(item, 'user', user);
        });
      }
      if (amount !== undefined) {
        Ember.set(item, 'amount', amount);
      }
      return item;
    },
    // Actions
    actions: {
      onSave: function onSave(item) {
        var _this = this;
        item.save().then(function () {
          return _this.transitionTo('investments.created', {
            queryParams: {
              id: item.id
            }
          });
        });
      }
    }
  });
  _exports.default = _default;
});