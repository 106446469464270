define("livarava-web/components/input-upload", ["exports", "ember-uploader/components/file-field", "livarava-web/utils/upload-file"], function (_exports, _fileField, _uploadFile) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _fileField.default.extend({
    // Events
    onUpload: null,
    // Flags
    isFileUploading: false,
    // Functions
    filesDidChange: function filesDidChange(files) {
      var _this = this;
      if (!Ember.isEmpty(files)) {
        Ember.set(this, 'isFileUploading', true);
        (0, _uploadFile.default)(files[0]).then(function (data) {
          Ember.set(_this, 'isFileUploading', false);
          console.log(data);
          _this.onUpload(data);
        });
      }
    }
  });
  _exports.default = _default;
});