define("livarava-web/components/neuroner/customer-item", ["exports", "fetch", "livarava-web/utils/get-api-url", "moment"], function (_exports, _fetch, _getApiUrl, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Parameters
    data: null,
    // Computed
    due: Ember.computed('data.neuroner_due', function () {
      var dueRaw = Ember.get(this, 'data.neuroner_due');
      if (!dueRaw) return null;
      return (0, _moment.default)(dueRaw);
    }),
    // Setup
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var application = 'neuroner';
      var user = Ember.get(this, 'item.id');
      var url = (0, _getApiUrl.default)('store-application-status', {
        application: application,
        user: user
      });
      var token = Ember.get(this, 'me.token');
      (0, _fetch.default)(url, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        Ember.set(_this, 'data', data);
      }).catch(function () {
        alert('Error! Please, contact admin.');
      });
    }
  });
  _exports.default = _default;
});