define("livarava-web/routes/tasks", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Do something
    model: function model() {
      if (!this.get('me.rights.tasks')) {
        return this.transitionTo('error403');
      }
    }
  });
  _exports.default = _default;
});