define("livarava-web/components/service/service-list-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    displayOptions: _environment.default.displayOptions,
    // Actions
    actions: {
      delete: function _delete() {
        this.onDelete(this.item);
      },
      setDisplay: function setDisplay(value) {
        var _this = this;
        var item = this.item;
        item.set('display', value);
        item.save().then(function (item) {
          _this.set('item', item);
        });
      },
      setFeatured: function setFeatured(value) {
        var _this2 = this;
        var item = this.item;
        item.set('featured', value);
        item.save().then(function (item) {
          _this2.set('item', item);
        });
      }
    }
  });
  _exports.default = _default;
});