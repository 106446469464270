define("livarava-web/components/site-navigations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    items: null,
    site_id: null,
    // States
    isDirty: false,
    isSaving: false,
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      var order = 10;
      var items = this.items;
      items.forEach(function (item) {
        item.set('order', order);
        order = order + 10;
      });
      var itemsSorted = items.sortBy('order');
      this.set('items', itemsSorted);
    },
    // Actions
    actions: {
      reorder: function reorder() {
        var items = this.items;
        var itemsSorted = items.sortBy('order');
        var order = 10;
        itemsSorted.forEach(function (item) {
          item.set('order', order);
          order = order + 10;
        });
        this.set('items', itemsSorted);
        this.set('isDirty', true);
      }
    }
  });
  _exports.default = _default;
});