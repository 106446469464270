define("livarava-web/utils/get-neuron-type-choices", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getNeuronTypeChoices;
  function getNeuronTypeChoices() {
    return Ember.A([{
      value: 'text',
      label: 'Text'
    }, {
      value: 'phone',
      label: 'Phone'
    }, {
      value: 'email',
      label: 'Email'
    }, {
      value: 'link',
      label: 'Link'
    }, {
      value: 'image',
      label: 'Image'
    }, {
      value: 'video',
      label: 'Video'
    }, {
      value: 'page',
      label: 'Page'
    }, {
      value: 'post',
      label: 'Post'
    }, {
      value: 'project',
      label: 'Project'
    }, {
      value: 'product',
      label: 'Product'
    }, {
      value: 'event',
      label: 'Event'
    }, {
      value: 'task',
      label: 'Task'
    }]);
  }
});