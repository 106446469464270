define("livarava-web/templates/components/input-model", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "N91ITD84",
    "block": "{\"symbols\":[\"prediction\",\"&default\"],\"statements\":[[6,[37,11],null,[[\"class\",\"triggerClass\",\"dropdownClass\",\"tagName\",\"searchEnabled\",\"options\",\"onChange\",\"selected\",\"search\",\"allowClear\",\"matchTriggerWidth\",\"placeholder\",\"dropdownClass\"],[[35,10],[35,9],[35,8],[35,7],true,[35,6],[30,[36,4],[[32,0],\"select\"],null],[35,5],[30,[36,4],[[32,0],\"handleSearch\"],null],[35,3],false,[35,2],\"input-city-dropdown\"]],[[\"default\"],[{\"statements\":[[6,[37,1],[[27,[32,2]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[1,[30,[36,0],[[32,1,[\"title\"]],[32,1,[\"fullname\"]]],null]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"or\",\"if\",\"placeholder\",\"allowClear\",\"action\",\"value\",\"predictions\",\"tagName\",\"dropdownClass\",\"triggerClass\",\"klass\",\"power-select\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-model.hbs"
    }
  });
  _exports.default = _default;
});