define("livarava-web/components/common/filters/filter-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    controller: null,
    filter: null,
    value: null,
    label: Ember.computed('value', function () {
      var value = this.value;
      if (!value) return null;
      var options = this.get('filter.options');
      var label = null;
      for (var i = 0, len = options.length; i < len; i++) {
        var option = options[i];
        if (option.value === value) {
          label = option.label;
        }
      }
      return label;
    }),
    // States
    isExpanded: false,
    didReceiveAttrs: function didReceiveAttrs() {
      var controller = this.controller;
      var filter = this.filter;
      var name = filter.query;
      this.set('value', controller.get(name));
    },
    // Actions
    actions: {
      setFilter: function setFilter(value) {
        var name = this.get('filter.query');
        this.set('value', value);
        this.controller.set('page', 1);
        this.controller.set(name, value);
        this.set('isExpanded', false);
      },
      collapseFilter: function collapseFilter() {
        this.set('isExpanded', false);
      }
    }
  });
  _exports.default = _default;
});