define("livarava-web/routes/sites/item/feedbacks/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var site_id = parseInt(this.paramsFor('sites.item').site_id);
      params['site'] = site_id;
      return Ember.RSVP.hash({
        site_id: site_id,
        site: this.store.peekRecord('site', site_id),
        items: this.store.query('feedback', (0, _getFiltersFromParams.default)(params))
      });
    }
  });
  _exports.default = _default;
});