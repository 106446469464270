define("livarava-web/templates/components/site-navigations-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8/FInvAv",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,9],[[35,8]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"Saving...\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,7],[[35,6]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-success\"],[12],[2,\"Saved!\"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"alert alert-warning\"],[12],[2,\"Changes not saved\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,9],[[35,3,[\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"container navigation-container\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[1,[30,[36,2],null,[[\"site_id\",\"item\",\"onChange\"],[[35,1],[32,1],[30,[36,0],[[32,0],\"reorder\"],null]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"No navigations was found\"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"site_id\",\"navigation/navigation-list-item\",\"items\",\"-track-array\",\"each\",\"isDirty\",\"unless\",\"isSaving\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/site-navigations-list.hbs"
    }
  });
  _exports.default = _default;
});