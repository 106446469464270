define("livarava-web/templates/components/user/user-small", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OkcujFxT",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"span\"],[14,0,\"user user-small\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"imageStyle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"span\"],[15,5,[34,0,[\"imageStyle\"]]],[14,0,\"icon-24\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]},{\"statements\":[[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-user\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,1],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,0,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[14,0,\"header\"],[12],[1,[35,0,[\"firstname\"]]],[2,\" \"],[1,[35,0,[\"lastname\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[18,1,null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"link-to\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/user/user-small.hbs"
    }
  });
  _exports.default = _default;
});