define("livarava-web/templates/components/neuroner/filter-form-group-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2I8KT+dO",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[10,\"fieldset\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"btn-group\"],[14,\"role\",\"group\"],[14,\"aria-label\",\"Basic example\"],[12],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,2],[[35,1,[\"all\"]],\"btn-success\",\" btn-secondary-outline\"],null]]]],[24,4,\"button\"],[4,[38,3],[[32,0],\"setAll\",true],null],[12],[2,\"\\n      All\\n    \"],[13],[2,\"\\n    \"],[11,\"button\"],[16,0,[31,[\"btn \",[30,[36,4],[[35,1,[\"all\"]],\"btn-success\",\" btn-secondary-outline\"],null]]]],[24,4,\"button\"],[4,[38,3],[[32,0],\"setAll\",false],null],[12],[2,\"Any\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n  Article has to pass\\n  \"],[10,\"strong\"],[12],[6,[37,2],[[35,1,[\"all\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\" all \"]],\"parameters\":[]},{\"statements\":[[2,\" any of the \"]],\"parameters\":[]}]]],[13],[2,\" following rules:\\n\"],[13],[2,\"\\n\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,1,[\"rules\"]]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,0],null,[[\"deleteRule\",\"rule\"],[\"deleteRule\",[32,1]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n\"],[10,\"div\"],[14,0,\"form-group\"],[12],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-primary-outline\"],[4,[38,3],[[32,0],\"addRule\"],null],[12],[10,\"i\"],[14,0,\"fa fa-plus\"],[12],[13],[2,\" Add\"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"neuroner/filter-list-item\",\"group\",\"if\",\"action\",\"unless\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/filter-form-group-item.hbs"
    }
  });
  _exports.default = _default;
});