define("livarava-web/templates/components/post-category-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "0Qu+WNOK",
    "block": "{\"symbols\":[\"category\"],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"autocomplete-abstract\"],[12],[2,\"\\n\"],[6,[37,4],null,[[\"options\",\"selected\",\"searchField\",\"onChange\",\"extra\"],[[35,3],[35,2],\"header\",[30,[36,1],[[32,0],\"add\"],null],[30,[36,0],null,[[\"labelPath\"],[\"header\"]]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"autocomplete-item\"],[12],[2,\"\\n          \"],[10,\"div\"],[14,0,\"autocomplete-image\"],[15,5,[31,[\"background: url('\",[32,1,[\"icon_url_safe\"]],\"') no-repeat center center; background-size: cover;\"]]],[12],[13],[2,\"\\n          \"],[10,\"div\"],[14,0,\"autocomplete-header\"],[12],[1,[32,1,[\"header\"]]],[13],[2,\"\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,7],null,[[\"items\",\"onRemoveCategory\"],[[35,6],[35,5]]]]]],\"hasEval\":false,\"upvars\":[\"hash\",\"action\",\"category\",\"categories\",\"power-select\",\"onRemoveCategory\",\"selectedCategories\",\"post-categories-list\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/post-category-picker.hbs"
    }
  });
  _exports.default = _default;
});