define("livarava-web/controllers/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    moment: Ember.inject.service(),
    // Greeting
    greeting: Ember.computed('moment', function () {
      var now = new Date();
      var hours = now.getHours();
      if (hours > 0 && hours < 5) {
        return 'Good evening';
      } else if (hours >= 5 && hours < 12) {
        return 'Good morning';
      } else if (hours >= 12 && hours < 17) {
        return 'Good day';
      } else if (hours >= 17 && hours < 21) {
        return 'Good evening';
      } else if (hours >= 21 && hours < 24) {
        return 'Good evening';
      } else {
        return 'Hi';
      }
    })
  });
  _exports.default = _default;
});