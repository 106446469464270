define("livarava-web/components/neuron-person", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    getStatus: Ember.computed('model.online', function () {
      return this.get('model.online') === true ? 'online' : 'offline';
    }),
    getLanguage: Ember.computed('model.language', function () {
      var language = this.get('model.language');
      if (language === 'uk') {
        return language = 'Українська';
      } else if (language === 'ru') {
        return language = 'Русский';
      } else {
        return language = 'English';
      }
    })
  });
  _exports.default = _default;
});