define("livarava-web/templates/sites/item/posts/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "bTD+xMSc",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"model\",\"save\"],[[35,1],[30,[36,0],[\"save\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"model\",\"site/site-post-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/posts/create.hbs"
    }
  });
  _exports.default = _default;
});