define("livarava-web/templates/components/common/filters/filter-search", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "p1dm0XZR",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"btn-group\"],[14,\"role\",\"group\"],[12],[2,\"\\n  \"],[1,[30,[36,4],null,[[\"id\",\"name\",\"class\",\"placeholder\",\"value\",\"key-up\"],[[30,[36,3],[\"filter-\",[35,2,[\"name\"]]],null],[35,2,[\"name\"]],\"form-control form-control-sm\",[35,2,[\"placeholder\"]],[35,1],[30,[36,0],[[32,0],\"onSearchKeyUp\"],null]]]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"filter\",\"concat\",\"input\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/filters/filter-search.hbs"
    }
  });
  _exports.default = _default;
});