define("livarava-web/templates/transactions/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kMogG70F",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"\\n      Home\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[2,\"\\n\"],[6,[37,0],null,[[\"route\"],[\"transactions\"]],[[\"default\"],[{\"statements\":[[2,\"      Transactions\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"\\n    #\"],[1,[35,1,[\"id\"]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"transactions\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n    \"],[10,\"h2\"],[12],[2,\"\\n      Edit Transaction\\n    \"],[13],[2,\"\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n    \"],[1,[30,[36,5],null,[[\"model\",\"save\",\"delete\",\"cancel\"],[[30,[36,4],[[35,1],[35,3]],null],[30,[36,2],[[32,0],\"save\"],null],[30,[36,2],[[32,0],\"delete\"],null],[30,[36,2],[[32,0],\"cancel\"],null]]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"action\",\"transactionValidations\",\"changeset\",\"transaction/transaction-form\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/transactions/edit.hbs"
    }
  });
  _exports.default = _default;
});