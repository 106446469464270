define("livarava-web/components/profile-feed-item", ["exports", "livarava-web/utils/check-image-url"], function (_exports, _checkImageUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    getImageFor: function getImageFor() {
      var node = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'liva';
      if ((0, _checkImageUrl.default)(this.get("item.".concat(node, ".image_url")))) {
        return this.get("item.".concat(node, ".image_url"));
      } else if ((0, _checkImageUrl.default)(this.get("item.".concat(node, ".image")))) {
        return this.get("item.".concat(node, ".image"));
      } else {
        return 'https://www.livarava.com/static/livarava/img/neurons/' + this.get("item.".concat(node, ".type")) + '.png';
      }
    },
    getLivaImage: Ember.computed('item.liva.image_url', 'item.liva.image', function () {
      return this.getImageFor('liva');
    }),
    getRavaImage: Ember.computed('item.rava.image_url', 'item.rava.image', function () {
      return this.getImageFor('rava');
    })
  });
  _exports.default = _default;
});