define("livarava-web/components/display-dropdown", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    value: null,
    label: null,
    icon: null,
    options: null,
    // Flags
    showDropdown: false,
    // Handlers
    onChange: null,
    // Init
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var options = this.options;
      var value = this.value;
      var label, icon;
      if (!value) return;
      options.forEach(function (option) {
        if (option.value === value) {
          label = option.label ? option.label : null;
          icon = option.icon ? option.icon : null;
        }
      });
      Ember.set(this, 'label', label);
      Ember.set(this, 'icon', icon);
      Ember.set(this, 'showDropdown', false);
    },
    // Actions
    actions: {
      change: function change(option) {
        var handler = this.onChange;
        Ember.set(this, 'value', option.value);
        Ember.set(this, 'label', option.label);
        if (option.icon) Ember.set(this, 'icon', option.icon);
        Ember.set(this, 'showDropdown', false);
        if (handler) handler(option);
      }
    }
  });
  _exports.default = _default;
});