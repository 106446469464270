define("livarava-web/components/funnel/funnel-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    isCollapsed: true,
    amount: Ember.computed('items', function () {
      var items = this.items || [];
      return items.reduce(function (sum, item) {
        return sum + Ember.get(item, 'amount');
      }, 0);
    })
  });
  _exports.default = _default;
});