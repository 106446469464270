define("livarava-web/components/site-members-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    selectedUser: null,
    selectedRole: null,
    actions: {
      selectUser: function selectUser(item) {
        this.set('selectedUser', item);
      },
      removeSelectedUser: function removeSelectedUser() {
        this.set('selectedUser', null);
      }
    }
  });
  _exports.default = _default;
});