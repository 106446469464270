define("livarava-web/routes/sites/item/general", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'General Info',
    // Actions
    actions: {
      save: function save(model) {
        model.save();
      },
      delete: function _delete(model) {
        var _this = this;
        if (confirm("Are you sure?")) {
          model.destroyRecord().then(function () {
            return _this.transitionTo('sites');
          });
        }
      },
      refresh: function refresh(model) {
        model.reload();
      }
    }
  });
  _exports.default = _default;
});