define("livarava-web/templates/components/navigation/navigation-item-after", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "8+xsqIev",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,1],null,[[\"action\",\"dragOverAction\",\"dragOutAction\"],[\"onDragDrop\",\"onDragOver\",\"onDragOut\"]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"navigation-draggable-target navigation-after\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[6,[37,3],[[35,2]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"navigation-after navigation-after-dragover navigation-level navigation-level\",[34,0,[\"level\"]]]]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"draggable-object-target\",\"isDragOver\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/navigation/navigation-item-after.hbs"
    }
  });
  _exports.default = _default;
});