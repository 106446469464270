define("livarava-web/components/post-categories-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Functions
    onRemoveCategory: null,
    actions: {
      remove: function remove(item) {
        this.onRemoveCategory(item);
      }
    }
  });
  _exports.default = _default;
});