define("livarava-web/controllers/notifications", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      markAllAsRead: function markAllAsRead() {
        var url = (0, _getApiUrl.default)('notifications-mark-all-as-read');
        var token = Ember.get(this, 'me.token');
        var params = {
          headers: {
            'X-LivaRava-Token': token
          }
        };
        Ember.set(this, 'markAllAsRead', true);
        (0, _fetch.default)(url, params).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        });
      }
    }
  });
  _exports.default = _default;
});