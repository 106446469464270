define("livarava-web/templates/components/order/order-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qjLn38PD",
    "block": "{\"symbols\":[\"statusOptionsItem\"],\"statements\":[[6,[37,5],[[30,[36,4],[\"value\",[35,3],[35,2]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,1],[[32,1]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,\"title\",[32,1,[\"label\"]]],[12],[2,\"\\n      \"],[10,\"i\"],[15,0,[32,1,[\"icon\"]]],[12],[13],[2,\"\\n      \"],[1,[30,[36,1],[[35,0],[32,1,[\"label\"]]],null]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"showText\",\"if\",\"statusOptions\",\"status\",\"find-by\",\"with\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/order/order-status.hbs"
    }
  });
  _exports.default = _default;
});