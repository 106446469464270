define("livarava-web/controllers/funnels/entities", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ["manager"],
    manager: null,
    actions: {
      setManager: function setManager(value) {
        Ember.set(this, "manager", value);
      }
    }
  });
  _exports.default = _default;
});