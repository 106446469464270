define("livarava-web/controllers/products/item/tasks", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'lira', 'project', 'product', 'author', 'executor', 'status', {
      'task_type': 'type'
    }, 'currency'],
    page: 1,
    per_page: 10,
    q: null,
    project: null,
    product: null,
    author: null,
    executor: null,
    task_type: null,
    status: null,
    currency: null,
    date_start: null,
    date_end: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'author',
      query: 'author',
      type: 'user',
      placeholder: 'Author'
    }, {
      name: 'executor',
      query: 'executor',
      type: 'user',
      placeholder: 'Executor'
    }, {
      name: 'type',
      query: 'task_type',
      type: 'select',
      placeholder: 'Type',
      options: [{
        value: 'research',
        label: 'Research',
        icon: 'fa fa-area-chart text-warning'
      }, {
        value: 'development',
        label: 'Development',
        icon: 'fa fa-wrench text-primary'
      }]
    }, {
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: 'opened',
        label: 'Opened',
        icon: 'fa fa-square-o text-primary'
      }, {
        value: 'assigned',
        label: 'Assigned',
        icon: 'fa fa-wrench text-primary'
      }, {
        value: 'evaluated',
        label: 'Evaluated',
        icon: 'fa fa-calculator text-warning'
      }, {
        value: 'approved',
        label: 'Approved',
        icon: 'fa fa-check text-success'
      }, {
        value: 'in_progress',
        label: 'In progress',
        icon: 'fa fa-clock-o text-warning'
      }, {
        value: 'finished',
        label: 'Finished',
        icon: 'fa fa-flag-checkered text-primary'
      }, {
        value: 'closed',
        label: 'Closed',
        icon: 'fa fa-check-square-o text-success'
      }]
    }]),
    // States
    isFiltersExpanded: false,
    isFiltersSetted: Ember.computed('q', 'project', 'product', 'author', 'executor', 'task_type', 'status', 'currency', function () {
      var _this = this;
      var filters = this.filters;
      var filtersSetted = 0;
      filters.forEach(function (filter) {
        var value = _this.get(filter.query);
        if (value !== null) {
          filtersSetted += 1;
        }
      });
      if (filtersSetted > 0) this.set('isFiltersExpanded', true);
      return filtersSetted > 0;
    }),
    // Icons
    icons: {
      types: {
        research: 'fa fa-area-chart text-warning',
        development: 'fa fa-wrench text-primary'
      },
      statuses: {
        opened: 'fa fa-square-o text-primary',
        assigned: 'fa fa-user text-success',
        evaluated: 'fa fa-calculator text-warning',
        approved: 'fa fa-check text-success',
        in_progress: 'fa fa-clock-o text-warning',
        finished: 'fa fa-flag-checkered text-primary',
        closed: 'fa fa-check-square-o text-success'
      },
      currencyOptions: {
        USD: '<i class="fa fa-dollar"></i>',
        EUR: '<i class="fa fa-eur"></i>',
        UAH: '&#8372;',
        RUB: '<i class="fa fa-rub"></i>'
      }
    },
    // Actions
    actions: {
      setFilter: function setFilter(name, value) {
        this.transitionToRoute('tasks', {
          queryParams: (0, _defineProperty2.default)({
            page: this.page,
            q: this.q
          }, "filter[".concat(name, "]"), value)
        });
      },
      resetFilters: function resetFilters() {
        var _this2 = this;
        var queryParams = this.queryParams;
        queryParams.forEach(function (param) {
          var paramName;
          if (param.toString() === '[object Object]') {
            paramName = Object.keys(param)[0];
          } else {
            paramName = param;
          }
          var value = paramName === 'page' ? 1 : null;
          _this2.set(paramName, value);
        });
      },
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      search: function search(q) {
        this.transitionToRoute('tasks', {
          queryParams: {
            q: q,
            page: 1
          }
        });
      },
      copy: function copy(id) {
        this.transitionToRoute('tasks.create', {
          queryParams: {
            copy: id
          }
        });
      },
      delete: function _delete(item) {
        var _this3 = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this3.send('reload');
          });
        }
      }
    }
  });
  _exports.default = _default;
});