define("livarava-web/routes/transactions/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: null,
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var id = parseInt(params.id);
      return store.findRecord('transaction', id).then(function (item) {
        var id = Ember.get(item, 'id');
        var title = "Transaction #".concat(id);
        Ember.set(_this, 'titleToken', title);
        return item;
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});