define("livarava-web/templates/sites/item/members", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "o/hZNolH",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[14,0,\"mb-3\"],[12],[2,\"\\n  Members\\n\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[14,0,\"mb-3\"],[12],[13],[2,\"\\n\\n\"],[3,\"TODO: Refactor to site/site-members \"],[2,\"\\n\"],[1,[30,[36,2],null,[[\"model\",\"onAddUser\",\"onSaveUser\",\"onRemoveUser\"],[[35,1],[30,[36,0],[[32,0],\"setSiteRole\"],null],[30,[36,0],[[32,0],\"setSiteRole\"],null],[30,[36,0],[[32,0],\"removeSiteRole\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"site-members\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/members.hbs"
    }
  });
  _exports.default = _default;
});