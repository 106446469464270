define("livarava-web/routes/tasks/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var taskId = parseInt(params.id);
      return Ember.RSVP.hash({
        item: store.findRecord('task', taskId),
        comments: store.query('comment', {
          neuron: taskId,
          per_page: 1000
        })
      });
    },
    // After Model
    afterModel: function afterModel(model) {
      Ember.set(this, 'title', Ember.get(model, 'item.title'));
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});