define("livarava-web/templates/page", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "cjMs80dQ",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"nomenu\"],[true]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],[[30,[36,0],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"-outlet\",\"component\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/page.hbs"
    }
  });
  _exports.default = _default;
});