define("livarava-web/models/feedback", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    subject: _emberData.default.attr('string'),
    text: _emberData.default.attr('string'),
    read: _emberData.default.attr('boolean'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Status
    status: _emberData.default.attr('string', {
      defaultValue: 'opened'
    }),
    statusLabel: Ember.computed.reads('status'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('neuron')
  });
  _exports.default = _default;
});