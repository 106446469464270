define("livarava-web/templates/signup", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ldzYOOJj",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],null,[[\"model\"],[[35,0]]]]],[2,\"\\n\\n  \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"signup-form\",\"-outlet\",\"component\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/signup.hbs"
    }
  });
  _exports.default = _default;
});