define("livarava-web/routes/neuroner/settings/customers/index", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Properties
    product_id: null,
    // Params
    queryParams: {
      page: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var product_id = 119036;
      var customerParams = _jquery.default.extend({}, params);
      customerParams['filter[product_customer]'] = product_id;
      return Ember.RSVP.hash({
        product_id: product_id,
        customers: store.query('user', customerParams)
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});