define("livarava-web/routes/products/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var product_id = parseInt(params.product_id);
      return store.findRecord('product', product_id);
    }
  });
  _exports.default = _default;
});