define("livarava-web/templates/events/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Zat7kOug",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,0],null,[[\"route\"],[\"events\"]],[[\"default\"],[{\"statements\":[[2,\"Events\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,4],null,[[\"model\",\"delete\",\"save\",\"saveAndGo\",\"reload\"],[[35,1],[30,[36,3],[[32,0],\"delete\"],null],[30,[36,3],[[32,0],\"save\"],null],[30,[36,3],[[32,0],\"saveAndGo\"],null],[30,[36,2],[\"reload\"],null]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"link-to\",\"model\",\"route-action\",\"action\",\"event/event-form\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/edit.hbs"
    }
  });
  _exports.default = _default;
});