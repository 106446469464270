define("livarava-web/services/html5-notifications", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    cookies: Ember.inject.service(),
    // Properties
    granted: false,
    // Init
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);

      // Let's check if the browser supports notifications
      if (!("Notification" in window)) {
        Ember.set(this, 'granted', false);
      }
      // Let's check whether notification permissions have already been granted
      else if (Notification.permission === "granted") {
        // If it's okay we can create a notification
        Ember.set(this, 'granted', true);
      }

      // Otherwise, we need to ask the user for permission
      else if (Notification.permission !== 'denied') {
        Notification.requestPermission(function (permission) {
          return Ember.set(_this, 'granted', permission === 'granted');
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them any more.
    },
    notify: function notify(items) {
      if (!this.granted) {
        return;
      }
      items.forEach(function (item) {
        var title = Ember.get(item, 'title') || Ember.get(item, 'header') || Ember.get(item, 'name') || '',
          body = Ember.get(item, 'text') || Ember.get(item, 'description') || Ember.get(item, 'summary') || '';
        title = title.replace(/(<([^>]+)>)/ig, "").trim();
        body = body.replace(/(<([^>]+)>)/ig, "").trim();
        new Notification(title, {
          body: body,
          icon: 'https://www.livarava.com/static/livarava/img/neurons/link.png'
        });
      });
    },
    notifyNewComment: function notifyNewComment(comment, uid) {
      if (!this.granted) {
        return;
      }
      var cookieService = this.cookies;
      var notificationUid = "notification".concat(uid);
      var cookie = cookieService.read(notificationUid);
      if (cookie) {
        return;
      }
      var header = Ember.get(comment, 'author.fullname');
      var body = Ember.get(comment, 'text');
      var icon = Ember.get(comment, 'author.image_url');
      var rootURL = Ember.get(comment, 'rootURL');
      var notification = new Notification(header, {
        body: body,
        icon: icon
      });
      notification.onclick = function () {
        if (window) {
          window.open(rootURL, '_blank');
        }
      };
      var audio = new Audio('/snd/notification.wav');
      audio.volume = 0.5;
      audio.play();

      // Save Notification UID into Local Storage
      cookieService.write(notificationUid, true);
    },
    notifyNewMessage: function notifyNewMessage(item) {
      if (!this.granted) {
        return;
      }
      if (document && document.hasFocus()) {
        return;
      }
      var itemId = Ember.get(item, 'id');
      var cookieService = this.cookies;
      var notificationId = "notification".concat(itemId);
      var cookie = cookieService.read(notificationId);
      if (cookie) {
        return;
      }
      var threadId = Ember.get(item, 'thread.id');
      var authorFullname = Ember.get(item, 'author.fullname');
      var authorImageUrl = Ember.get(item, 'author.image_url');
      var messageText = Ember.get(item, 'message');
      var header = authorFullname;
      var body = messageText;
      var icon = authorImageUrl;
      var link = "".concat(_environment.default.api.host, "/messages/").concat(threadId, "#").concat(itemId);
      var notification = new Notification(header, {
        body: body,
        icon: icon
      });
      notification.onclick = function () {
        if (window) {
          window.open(link, "thread".concat(threadId));
        }
      };
      var audio = new Audio('/snd/notification.wav');
      audio.volume = 0.5;
      audio.play();

      // Save Notification UID into Local Storage
      cookieService.write(notificationId, true);
    }
  });
  _exports.default = _default;
});