define("livarava-web/templates/components/neuron/neuron-buttons", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "RxPVIYSV",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"li\"],[14,0,\"list-inline-item pull-right neuron-info-buttons\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[35,1,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[1,[30,[36,2],null,[[\"item\",\"tagName\"],[[35,1],\"span\"]]]],[2,\"\\n\\n    \"],[1,[30,[36,3],null,[[\"liva\",\"tagName\"],[[35,1],\"span\"]]]],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n    \"],[11,\"button\"],[24,0,\"btn btn-primary\"],[4,[38,0],[[32,0],\"create\"],null],[12],[2,\"\\n      Create Neuron\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"action\",\"item\",\"neuron/neuron-subscription-button\",\"neuron/neuron-axon-button\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron/neuron-buttons.hbs"
    }
  });
  _exports.default = _default;
});