define("livarava-web/templates/components/common/common-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "D69MBwWu",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[\"common-image \",[34,1],\" \",[34,0]]]],[15,5,[34,2]],[15,\"title\",[34,3]],[12],[2,\"\\n   \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"classImage\",\"classDefault\",\"style\",\"title\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-image.hbs"
    }
  });
  _exports.default = _default;
});