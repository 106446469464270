define("livarava-web/models/task", ["exports", "ember-data", "livarava-web/utils/get-currency-sign", "livarava-web/utils/get-task-type-label", "livarava-web/utils/get-task-type-icon-class", "livarava-web/utils/get-display-label", "livarava-web/utils/get-display-icon-class"], function (_exports, _emberData, _getCurrencySign, _getTaskTypeLabel, _getTaskTypeIconClass, _getDisplayLabel, _getDisplayIconClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Service
    intl: Ember.inject.service(),
    me: Ember.inject.service(),
    // Attributes
    title: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string', {
      defaultValue: ''
    }),
    // Stats
    views: _emberData.default.attr('number'),
    subscriber_count: _emberData.default.attr('number'),
    comment_count: _emberData.default.attr('number'),
    axon_count: _emberData.default.attr('number'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    due: _emberData.default.attr('string'),
    due_hours: _emberData.default.attr('number'),
    hours: _emberData.default.attr('number'),
    // Type
    type: 'task',
    typeLabel: Ember.computed('type', function () {
      var type = this.type;
      return type[0].toUpperCase() + type.slice(1, type.length);
    }),
    // Display
    display: _emberData.default.attr('string'),
    displayLabel: Ember.computed('display', function () {
      return (0, _getDisplayLabel.default)(this.display);
    }),
    displayIconClass: Ember.computed('display', function () {
      return (0, _getDisplayIconClass.default)(this.display);
    }),
    // Task Type
    task_type: _emberData.default.attr('string'),
    taskTypeLabel: Ember.computed('task_type', function () {
      return (0, _getTaskTypeLabel.default)(this.task_type);
    }),
    taskTypeIconClass: Ember.computed('task_type', function () {
      return (0, _getTaskTypeIconClass.default)(this.task_type);
    }),
    // Rights
    rights: _emberData.default.attr(),
    // Status
    status: _emberData.default.attr('string'),
    statusLabel: Ember.computed('status', function () {
      var variable = 'task.status.' + this.status;
      return this.intl.t(variable);
    }),
    statusClass: Ember.computed('status', function () {
      return 'task-status-' + this.status;
    }),
    // Payment
    amount: _emberData.default.attr('number'),
    currency: _emberData.default.attr('string'),
    currencySign: Ember.computed('currency', function () {
      return (0, _getCurrencySign.default)(this.currency);
    }),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    project: _emberData.default.belongsTo('neuron'),
    product: _emberData.default.belongsTo('neuron'),
    executor: _emberData.default.belongsTo('user'),
    // Rights
    canAssign: true,
    canApply: true,
    canApprove: true,
    canStart: true,
    canFinish: true,
    canClose: true,
    canEdit: true,
    canDelete: true,
    canArchive: true
  });
  _exports.default = _default;
});