define("livarava-web/components/neuroner/feed-links", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    url: null,
    links: null,
    error: null,
    // Recieve Attributes
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      this._super.apply(this, arguments);
      var url = this.url;
      var neuroner_url = _environment.default.api.neuroner;
      var apiUrl = "".concat(neuroner_url, "/api/get-links/?url=").concat(url);
      var token = Ember.get(this, 'me.token');
      (0, _fetch.default)(apiUrl, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        // console.log(data);
        Ember.set(_this, 'links', data);
      }).catch(function () {
        Ember.set(_this, 'error', 'Error! Please, ask admin to help.');
      });
    }
  });
  _exports.default = _default;
});