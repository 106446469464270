define("livarava-web/components/notification/notification-neuron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    targetId: null,
    tagName: 'span',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var store = this.store;
      var targetId = this.targetId;
      if (!targetId) {
        return;
      }
      var item = store.peekRecord('neuron', targetId);
      if (item) {
        Ember.set(this, 'neuron', item);
      } else {
        item = store.findRecord('neuron', targetId);
        Ember.set(this, 'neuron', item);
      }
    }
  });
  _exports.default = _default;
});