define("livarava-web/templates/entities/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "5QIYwPPU",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,5],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,5],null,[[\"route\"],[\"entities\"]],[[\"default\"],[{\"statements\":[[2,\"Entities\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,1,[\"item\",\"title\"]]],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[6,[37,7],null,[[\"onChange\",\"model\"],[[30,[36,6],[\"reload\"],null],[35,1]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"comments mb-3\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-3\"],[12],[10,\"b\"],[12],[2,\"Comments\"],[13],[13],[2,\"\\n\\n    \"],[1,[30,[36,2],null,[[\"item\",\"onNewComment\"],[[35,1,[\"item\"]],[30,[36,0],[[32,0],\"addComment\"],null]]]]],[2,\"\\n\\n    \"],[1,[30,[36,4],null,[[\"item\",\"items\",\"page\",\"onSaveComment\",\"onDeleteComment\"],[[35,1,[\"item\"]],[35,1,[\"comments\"]],[35,3],[30,[36,0],[[32,0],\"saveComment\"],null],[30,[36,0],[[32,0],\"deleteComment\"],null]]]]],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"comment/comment-form\",\"page\",\"comment/comment-list\",\"link-to\",\"route-action\",\"entity/entity-item\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/entities/item.hbs"
    }
  });
  _exports.default = _default;
});