define("livarava-web/components/motivation/livarava-echarts", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    /* global echarts */
    options: null,
    chart: null,
    minHeight: '270px',
    onClick: function onClick() {},
    init: function init() {
      var _this = this;
      this._super.apply(this, arguments);
      this.handleResize = function () {
        Ember.run.next(_this, function () {
          var chart = _this.chart;
          if (chart && !chart.isDisposed()) {
            _this.chart.resize();
          }
        });
      };
      window.addEventListener('resize', this.handleResize, {
        passive: true
      });
    },
    didInsertElement: function didInsertElement() {
      var _this2 = this;
      this._super.apply(this, arguments);
      this.element.style.minHeight = this.get('minHeight');
      var chart = echarts.init(this.element);
      // use configuration item and data specified to show chart
      chart.setOption(this.get('options'));
      chart.on('click', function (params) {
        _this2.onClick(params);
      });
      this.set('chart', chart);
    },
    willDestroyElement: function willDestroyElement() {
      var chart = this.chart;
      if (chart && !chart.isDisposed()) {
        // https://echarts.apache.org/en/api.html#echartsInstance.dispose
        chart.dispose();
      }
      window.removeEventListener('resize', this.handleResize);
      this._super.apply(this, arguments);
    },
    didUpdateAttrs: function didUpdateAttrs() {
      this._super.apply(this, arguments);
      var chart = this.chart;
      if (chart) {
        chart.setOption(this.get('options'));
      }
    }
  });
  _exports.default = _default;
});