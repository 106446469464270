define("livarava-web/components/common/common-editable", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    item: null,
    value: null,
    // Flags
    showInput: false,
    // Actions
    actions: {
      edit: function edit() {
        Ember.set(this, 'showInput', true);
      },
      save: function save() {
        var item = this.item;
        Ember.set(this, 'showInput', false);
        item.save();
      }
    }
  });
  _exports.default = _default;
});