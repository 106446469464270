define("livarava-web/components/service/service-type", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var allServiceTypes = Array.from(_environment.default.service.types);
  var _default = Ember.Component.extend({
    type: null,
    tagName: 'span',
    showText: true,
    serviceType: Ember.computed('type', function () {
      var _this = this;
      if (!this.type) return null;
      return allServiceTypes.find(function (item) {
        return item.value == _this.type;
      });
    })
  });
  _exports.default = _default;
});