define("livarava-web/templates/sites/item/events/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yZcuvfeG",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,2],null,[[\"controller\",\"model\",\"page\"],[[32,0],[35,1],[35,0]]]]]],\"hasEval\":false,\"upvars\":[\"page\",\"model\",\"site/site-events\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/events/index.hbs"
    }
  });
  _exports.default = _default;
});