define("livarava-web/routes/feedbacks/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var me = this.me;
      return me.load().then(function () {
        return Ember.RSVP.hash({
          items: store.query('feedback', (0, _getFiltersFromParams.default)(params)).catch(function () {
            _this.transitionTo('error403');
          })
        });
      }).catch(function () {
        var next = window && window.location ? window.location.href : '/';
        _this.transitionTo('signin', {
          queryParams: {
            next: next
          }
        });
      });
    }
  });
  _exports.default = _default;
});