define("livarava-web/services/languages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    get: function get() {
      return ['en', 'ru', 'uk'];
    }
  });
  _exports.default = _default;
});