define("livarava-web/templates/homepage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "2ywRCHoV",
    "block": "{\"symbols\":[],\"statements\":[[1,[34,0]],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"homepage\"],[12],[2,\"\\n  \"],[1,[30,[36,3],null,[[\"images\",\"posts\"],[[35,2],[35,1,[\"posts\"]]]]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[34,4]]],\"hasEval\":false,\"upvars\":[\"common/common-header\",\"model\",\"images\",\"main-homepage\",\"common/common-footer\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/homepage.hbs"
    }
  });
  _exports.default = _default;
});