define("livarava-web/routes/lang/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    intl: Ember.inject.service(),
    // Computed
    locale: Ember.computed.reads('intl.primaryLocale'),
    // Model
    model: function model() {
      this.transitionTo('lang', this.locale);
    }
  });
  _exports.default = _default;
});