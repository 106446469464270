define("livarava-web/templates/components/input-property-select", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "UYB1VSOk",
    "block": "{\"symbols\":[\"value\"],\"statements\":[[6,[37,3],null,[[\"options\",\"selected\",\"onChange\"],[[35,2],[35,1],[30,[36,0],[[32,0],\"optionChanged\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\t\"],[1,[32,1,[\"property\",\"title\"]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"items\",\"power-select\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-property-select.hbs"
    }
  });
  _exports.default = _default;
});