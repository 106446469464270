define("livarava-web/controllers/neuroner/feeds/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: ['page'],
    page: 1,
    // Computed
    canCreate: Ember.computed('me.data.neuroner_overdue', 'me.data.neuroner_feeds_limit', 'model.meta.total_results', function () {
      var overdue = this.get('me.data.neuroner_overdue');
      var limit = this.get('me.data.neuroner_feeds_limit');
      var total = this.get('model.meta.total_results');
      if (overdue) return false;
      return total < limit;
    }),
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this.send('reload');
          });
        }
      }
    }
  });
  _exports.default = _default;
});