define("livarava-web/templates/components/feedback/feedback-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "qUn3UITi",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"text mt-2\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"row mb-3\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 text-left\"],[12],[2,\"\\n\"],[6,[37,2],[[35,0,[\"author\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"item\"],[[35,0,[\"author\"]]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"        \"],[10,\"b\"],[12],[2,\"\\n          Feedback Form\\n        \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-6 text-right text-muted small\"],[12],[2,\"\\n      \"],[1,[30,[36,3],[[35,0,[\"created\"]],\"MMMM DD, YYYY HH:mm\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[2,[35,0,[\"text\"]]],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"item\",\"user/user-medium\",\"if\",\"moment-format\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/feedback/feedback-item.hbs"
    }
  });
  _exports.default = _default;
});