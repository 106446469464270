define("livarava-web/components/lead/lead-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    propertyusers: null,
    hideAdvanced: true,
    userProperty: Ember.computed('model.property.id', 'propertyusers', function () {
      var propertyusers = this.propertyusers;
      var propertyId = this.get('model.property.id');
      if (!propertyusers || !propertyId) return null;
      var userProperty = propertyusers.find(function (pu) {
        return pu.get('property.id') == propertyId;
      });
      return userProperty;
    }),
    project: Ember.computed('model.project', {
      get: function get() {
        return this.get('model.project');
      },
      set: function set(key, value) {
        var _this = this;
        if (!value) {
          Ember.set(this, 'model.project', null);
        } else {
          this.store.findRecord('project', value.id).then(function (project) {
            return Ember.set(_this, 'model.project', project);
          });
        }
      }
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchProperties();
    },
    fetchProperties: function fetchProperties() {
      var _this2 = this;
      var userId = parseInt(this.get('model.author.id') || this.get('me.id'));
      this.store.query('propertyuser', {
        'filter[user]': userId,
        per_page: 100
      }).then(function (propertyusers) {
        _this2.set('propertyusers', propertyusers);
      });
    },
    // Actions
    actions: {
      setAuthor: function setAuthor(user) {
        var model = this.model;
        if (!user) {
          model.set('author', null);
          return;
        }
        model.set('author', user);
        model.set('firstname', user.get('firstname'));
        model.set('lastname', user.get('lastname'));
        model.set('email', user.get('email'));
        model.set('phone', user.get('phone'));
        return this.fetchProperties();
      },
      save: function save() {
        this.save(this.model);
      },
      delete: function _delete() {
        this['delete'](this.model);
      }
    }
  });
  _exports.default = _default;
});