define("livarava-web/templates/sites/item/pages/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "BSkjjhyd",
    "block": "{\"symbols\":[],\"statements\":[[1,[30,[36,3],null,[[\"controller\",\"model\",\"page\",\"onRemove\"],[[32,0],[35,2],[35,1],[30,[36,0],[\"delete\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"route-action\",\"page\",\"model\",\"site/site-pages\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/pages/index.hbs"
    }
  });
  _exports.default = _default;
});