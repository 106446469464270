define("livarava-web/routes/sites/item/categories/index", ["exports", "lodash/omitBy", "lodash/isNil", "livarava-web/utils/get-filters-from-params"], function (_exports, _omitBy2, _isNil2, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      language: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var site_id = parseInt(this.paramsFor('sites.item').site_id);
      params['filter[site]'] = site_id;
      return Ember.RSVP.hash({
        site_id: site_id,
        site: this.store.peekRecord('site', site_id),
        items: this.store.query('category', (0, _omitBy2.default)((0, _getFiltersFromParams.default)(params), _isNil2.default))
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});