define("livarava-web/templates/components/user/user-medium", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kOl3gsoS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"user-medium text-vcenter\"],[12],[2,\"\\n\"],[6,[37,1],[[35,0,[\"imageStyle\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,5,[34,0,[\"imageStyle\"]]],[14,0,\"image icon-36\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0,[\"imageRandomStyle\"]]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"span\"],[15,5,[34,0,[\"imageRandomStyle\"]]],[14,0,\"image icon-36 text-vcenter text-center\"],[12],[1,[35,0,[\"firstletter\"]]],[13],[2,\"\\n  \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"  \"],[10,\"span\"],[14,0,\"header\"],[12],[1,[35,0,[\"firstname\"]]],[2,\" \"],[1,[35,0,[\"lastname\"]]],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"item\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/user/user-medium.hbs"
    }
  });
  _exports.default = _default;
});