define("livarava-web/templates/components/input-image-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "sWfOk9KP",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,5,[34,0]],[14,0,\"input-image-item mb-3\"],[15,\"title\",[34,1,[\"header\"]]],[12],[2,\"\\n   \\n\"],[13]],\"hasEval\":false,\"upvars\":[\"imageStyle\",\"item\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-image-item.hbs"
    }
  });
  _exports.default = _default;
});