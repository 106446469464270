define("livarava-web/controllers/investments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['user'],
    user: null,
    projectsShowAll: false,
    projects: Ember.computed('model.dashboard.projects', 'projectsShowAll', function () {
      var projectsShowAll = Ember.get(this, 'projectsShowAll');
      var projects = Ember.get(this, 'model.dashboard.projects');
      return projectsShowAll ? projects : projects.slice(0, 5);
    }),
    productsShowAll: false,
    products: Ember.computed('model.dashboard.products', 'productsShowAll', function () {
      var productsShowAll = Ember.get(this, 'productsShowAll');
      var products = Ember.get(this, 'model.dashboard.products');
      return productsShowAll ? products : products.slice(0, 5);
    })
  });
  _exports.default = _default;
});