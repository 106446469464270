define("livarava-web/templates/components/thread/thread-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mhF48ICK",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,[[\"route\",\"model\"],[\"messages.thread\",[35,3,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[15,0,[31,[\"thread \",[30,[36,1],[[35,0],\"thread-unread\"],null]]]],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"avatar\"],[15,5,[34,2,[\"imageStyle\"]]],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"info\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"name\"],[12],[1,[35,2,[\"fullname\"]]],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"last\"],[15,\"title\",[30,[36,4],[[35,3,[\"last_message\",\"created\"]],\"YYYY-MM-DD HH:mm:ss\"],null]],[12],[2,\"\\n        \"],[1,[35,3,[\"last_message\",\"message\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"unread\",\"if\",\"recipient\",\"item\",\"moment-format\",\"link-to\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/thread/thread-list-item.hbs"
    }
  });
  _exports.default = _default;
});