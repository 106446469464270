define("livarava-web/components/user-role-picker", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    roles: null
  });
  _exports.default = _default;
});