define("livarava-web/templates/components/input-relationship", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wR0trn6u",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,6],[[35,3]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"row no-gutters align-items-center\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col\"],[12],[2,\"\\n      \"],[1,[34,2]],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col col-auto\"],[12],[2,\"\\n      \"],[11,\"button\"],[24,0,\"btn btn-link btn-sm\"],[24,\"title\",\"Edit\"],[4,[38,1],[[32,0],[30,[36,5],[[35,3]],null],[30,[36,4],[[35,3]],null]],null],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-close\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[18,1,null],[2,\"\\n  \"],[11,\"button\"],[24,0,\"btn btn-link btn-sm\"],[24,\"title\",\"Редагувати\"],[4,[38,1],[[32,0],[30,[36,0],[\"isEdit\",[32,0]],null]],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"toggle\",\"action\",\"inputComponent\",\"isEdit\",\"not\",\"mut\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-relationship.hbs"
    }
  });
  _exports.default = _default;
});