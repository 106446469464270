define("livarava-web/components/members-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    isEditing: false,
    selectedRole: null,
    roles: Object.freeze(["Admin", "Owner", "Manager", "Employee"]),
    actions: {
      edit: function edit() {
        this.setProperties({
          selectedRole: this.get("item.role"),
          isEditing: true
        });
      },
      save: function save(item, role) {
        var result = confirm(this.intl.t("modal.confirm"));
        if (!result) return;
        this.onSaveUser(item, role);
        this.set("isEditing", false);
      },
      remove: function remove(item) {
        var result = confirm(this.intl.t("modal.confirm"));
        if (!result) return;
        this.onRemoveUser(item);
        this.set("isEditing", false);
      }
    }
  });
  _exports.default = _default;
});