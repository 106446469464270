define("livarava-web/templates/components/review/review-table", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ktFiNNAY",
    "block": "{\"symbols\":[\"review\"],\"statements\":[[6,[37,6],[[35,5]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center\"],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-2x fa-circle-o-notch fa-spin\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"pb-3\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"mb-1 review\"],[12],[2,\"\\n        \"],[10,\"i\"],[14,0,\"fa fa-comment-o\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" \"],[1,[32,1,[\"author\",\"fullname\"]]],[2,\"\\n      \"],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"d-flex align-items-center mb-1\"],[12],[2,\"\\n        \"],[8,\"review/rate\",[[24,0,\"pr-3\"]],[[\"@rating\"],[[32,1,[\"rating\"]]]],null],[2,\"\\n        \"],[10,\"span\"],[14,0,\"text-muted small\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[[32,1,[\"created\"]],\"DD-MM-YYYY HH:mm\"],null]],[2,\"\\n        \"],[13],[2,\"\\n\"],[6,[37,1],null,[[\"class\",\"title\",\"route\",\"model\"],[\"ml-3\",\"Редагувати\",\"reviews.item\",[32,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n          \"],[8,\"details-popover\",[],[[\"@modelName\",\"@modelId\"],[\"review\",[32,1,[\"id\"]]]],null],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n      \"],[10,\"div\"],[12],[2,\"\\n        \"],[1,[32,1,[\"comment\"]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"moment-format\",\"link-to\",\"reviews\",\"-track-array\",\"each\",\"loadingReviews\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/review/review-table.hbs"
    }
  });
  _exports.default = _default;
});