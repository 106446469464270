define("livarava-web/routes/neuron/create", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var _this = this;
      var title = params.title;
      var url = (0, _getApiUrl.default)('parse-neuron', {
        title: title
      });
      var token = Ember.get(this, 'me.token');
      var neuron = this.store.createRecord('neuron', {
        title: title,
        header: title,
        type: 'text',
        display: 'public',
        summary: null,
        // Stats
        views: 0,
        comment_count: 0,
        axon_count: 0,
        subscriber_count: 0
      });
      (0, _fetch.default)(url, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        if (data.hasOwnProperty('neuron_id')) {
          _this.transitionTo('neuron.item', data.neuron_id);
        }
        for (var key in data) {
          if (data.hasOwnProperty(key)) {
            neuron.set(key, data[key]);
          }
        }
      });
      return neuron;
    },
    // Actions
    actions: {
      save: function save() {
        var _this2 = this;
        this.controller.model.save().then(function () {
          return _this2.transitionTo('neuron.item', _this2.controller.model.id);
        });
      }
    }
  });
  _exports.default = _default;
});