define("livarava-web/services/notifications", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Service.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Counters
    messageCount: Ember.computed.reads('me.data.message_count'),
    notificationCount: Ember.computed.reads('me.data.notification_count')
  });
  _exports.default = _default;
});