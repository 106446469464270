define("livarava-web/templates/components/input-image", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xXNGrDEL",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[15,0,[31,[[34,5],\" \",[30,[36,4],[[35,1],[35,3]],null]]]],[15,5,[34,6]],[15,\"title\",[34,1,[\"header\"]]],[12],[2,\"\\n\"],[6,[37,7],[[35,1,[\"id\"]]],null,[[\"default\"],[{\"statements\":[[6,[37,2],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,1,[\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"div\"],[14,5,\"position: absolute; width: 100%; height: 100%;\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[6,[37,7],[[35,8]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"div\"],[24,0,\"input-image-mask text-center\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"showModal\"],null],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-camera\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\" Update\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[1,[30,[36,12],null,[[\"item\",\"model\",\"show\",\"onSelect\"],[[35,11],[35,10],[35,9],[30,[36,0],[[32,0],\"onSelect\"],null]]]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"value\",\"link-to\",\"classDefault\",\"unless\",\"classImage\",\"imageStyle\",\"if\",\"showButton\",\"showModal\",\"model\",\"item\",\"input-image-modal\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-image.hbs"
    }
  });
  _exports.default = _default;
});