define("livarava-web/controllers/feedbacks/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'status'],
    page: 1,
    status: null,
    // Filters
    filters: [{
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: 'opened',
        label: 'Opened',
        icon: 'fa fa-square-o text-primary'
      }, {
        value: 'closed',
        label: 'Closed',
        icon: 'fa fa-check-square-o text-success'
      }]
    }],
    // Actions
    actions: {
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this.send('reload');
          });
        }
      }
    }
  });
  _exports.default = _default;
});