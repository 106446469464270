define("livarava-web/routes/investments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: null,
    // Model
    model: function model() {
      var store = this.store;
      return Ember.RSVP.hash({
        investment: store.createRecord('investment', {}),
        projects: store.query('project', {
          sort: '-views',
          'filter[investments_on]': true
        })
      });
    }
  });
  _exports.default = _default;
});