define("livarava-web/templates/components/property/property-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "T/2Ct2M3",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[10,\"i\"],[14,\"aria-hidden\",\"true\"],[15,0,[34,0,[\"property_icon\"]]],[15,\"title\",[34,0,[\"property_title\"]]],[12],[13],[2,\" \"],[1,[30,[36,2],[[35,1],[35,0,[\"property_title\"]]],null]],[2,\"\\n\"],[18,1,null]],\"hasEval\":false,\"upvars\":[\"property\",\"showText\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/property/property-status.hbs"
    }
  });
  _exports.default = _default;
});