define("livarava-web/components/main-homepage", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    intl: Ember.inject.service(),
    // Variables
    locale: Ember.computed.reads('intl.primaryLocale'),
    images: ['https://www.w3schools.com/css/trolltunga.jpg', 'https://tse4.mm.bing.net/th?id=ORT.TH_470633631&pid=1.12&eid=G.470633631', 'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRTM69nl2FdEpkpEIklz1Y5l0BKuDhB0UAuonLAO_DGR8gatmnt'],
    breakpoints: [{
      'breakpoint': 2400,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': true,
        'adaptiveHeight': true
      }
    }, {
      'breakpoint': 1800,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': true,
        'adaptiveHeight': true
      }
    }, {
      'breakpoint': 1024,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': true,
        'adaptiveHeight': true
      }
    }, {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': true,
        'adaptiveHeight': true
      }
    }, {
      'breakpoint': 480,
      'settings': {
        'slidesToShow': 1,
        'slidesToScroll': 1,
        'dots': true,
        'adaptiveHeight': true
      }
    }],
    customer_breakpoints: [{
      'breakpoint': 2400,
      'settings': {
        'slidesToShow': 6,
        'slidesToScroll': 1
      }
    }, {
      'breakpoint': 1800,
      'settings': {
        'slidesToShow': 6,
        'slidesToScroll': 1
      }
    }, {
      'breakpoint': 1024,
      'settings': {
        'slidesToShow': 6,
        'slidesToScroll': 1
      }
    }, {
      'breakpoint': 600,
      'settings': {
        'slidesToShow': 6,
        'slidesToScroll': 1
      }
    }, {
      'breakpoint': 480,
      'settings': {
        'slidesToShow': 6,
        'slidesToScroll': 1
      }
    }]
  });
  _exports.default = _default;
});