define("livarava-web/templates/settings/invoices/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "wowZ6glo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,4],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Settings\"],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,4],null,[[\"route\"],[\"settings.billing\"]],[[\"default\"],[{\"statements\":[[2,\"Billing\"]],\"parameters\":[]}]]],[13],[2,\"\\n  \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Invoices\"],[13],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[10,\"div\"],[14,0,\"billing\"],[12],[2,\"\\n\\n  \"],[10,\"h2\"],[12],[2,\"Invoices\"],[13],[2,\"\\n\\n  \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[6,[37,5],[[35,1,[\"invoices\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[1,[30,[36,2],null,[[\"items\"],[[35,1,[\"invoices\"]]]]]],[2,\"\\n\\n    \"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"count\",\"current\",\"pager\",\"class\"],[[35,1,[\"invoices\",\"meta\",\"total_pages\"]],[35,0],true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"        Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"invoices\",\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n      No invoices yet.\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,7],[[30,[36,6],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page\",\"model\",\"billing/billing-invoice-list\",\"pagination-pager\",\"link-to\",\"if\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/settings/invoices/index.hbs"
    }
  });
  _exports.default = _default;
});