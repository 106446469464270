define("livarava-web/templates/components/thread/thread-messages", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "6gYL9wJj",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,1]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[1,[30,[36,2],null,[[\"item\",\"items\",\"onMessageDelete\"],[[32,1],[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]]],\"hasEval\":false,\"upvars\":[\"onMessageDelete\",\"items\",\"thread/thread-message\",\"-track-array\",\"each\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/thread/thread-messages.hbs"
    }
  });
  _exports.default = _default;
});