define("livarava-web/components/event/event-tickets", ["exports", "fetch", "livarava-web/utils/get-api-url", "livarava-web/config/environment"], function (_exports, _fetch, _getApiUrl, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    subject: null,
    text: null,
    message: null,
    // Computed
    recipients: Ember.computed('tickets', function () {
      var recipients = [];
      var tickets = this.tickets;
      tickets.forEach(function (ticket) {
        var firstname = Ember.get(ticket, 'firstname');
        var lastname = Ember.get(ticket, 'lastname');
        var email = Ember.get(ticket, 'email');
        var phone = Ember.get(ticket, 'phone');
        recipients.push({
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone
        });
      });
      return recipients;
    }),
    // Properties
    froalaOptions: _environment.default.froalaOptions.max,
    // Flags
    showEmailForm: false,
    isEmailSending: false,
    showESmsForm: false,
    isSmsSending: false,
    // Actions
    actions: {
      sendEmail: function sendEmail() {
        var _this = this;
        var recipients = this.recipients;
        var subject = this.subject;
        var text = this.text;
        var params = {
          recipients: recipients,
          subject: subject,
          text: text
        };
        var url = (0, _getApiUrl.default)('email', params, 'POST');
        var token = Ember.get(this, 'me.token');
        Ember.set(this, 'isEmailSending', true);
        Ember.set(this, 'emailError', null);
        (0, _fetch.default)(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          Ember.set(_this, 'isEmailSending', false);
          Ember.set(_this, 'emailError', null);
          Ember.set(_this, 'showEmailSuccess', true);
        }).catch(function (response) {
          Ember.set(_this, 'isEmailSending', false);
          Ember.set(_this, 'emailError', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this, 'showEmailError', true);
        });
      },
      sendSms: function sendSms() {
        var _this2 = this;
        var recipients = this.recipients;
        var message = this.message;
        var params = {
          recipients: recipients,
          message: message
        };
        var url = (0, _getApiUrl.default)('sms', params, 'POST');
        var token = Ember.get(this, 'me.token');
        Ember.set(this, 'isSmsSending', true);
        Ember.set(this, 'smsError', null);
        (0, _fetch.default)(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-LivaRava-Token': token
          },
          body: JSON.stringify(params)
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          Ember.set(_this2, 'isSmsSending', false);
          Ember.set(_this2, 'smsError', null);
          Ember.set(_this2, 'showSmsSuccess', true);
        }).catch(function (response) {
          Ember.set(_this2, 'isSmsSending', false);
          Ember.set(_this2, 'smsError', response.error || 'Please, report to admin@livarava.com');
          Ember.set(_this2, 'showSmsError', true);
        });
      }
    }
  });
  _exports.default = _default;
});