define("livarava-web/routes/products/item/goals", ["exports", "fetch", "livarava-web/utils/get-api-url", "livarava-web/utils/clean-params"], function (_exports, _fetch, _getApiUrl, _cleanParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Goals',
    // Params
    queryParams: {
      member: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var product_id = parseInt(this.paramsFor('products.item').product_id);
      var product = store.peekRecord('product', product_id);
      params['product_id'] = product_id;
      var url = (0, _getApiUrl.default)('get-product-goal-stats', (0, _cleanParams.default)(params));
      var token = Ember.get(this, 'me.token');
      return (0, _fetch.default)(url, {
        headers: {
          'X-LivaRava-Token': token
        }
      }).then(function (response) {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error();
        }
      }).then(function (data) {
        var stats = data;
        stats.monthProgress = data.month_progress > 1 ? 1 : data.month_progress;
        stats.dayProgress = data.day_progress > 1 ? 1 : data.day_progress;
        return Ember.RSVP.hash({
          product: product,
          stats: stats
        });
      }).catch(function () {
        _this.transitionTo('products.item', product_id);
      });
    },
    // Actions
    actions: {
      refresh: function refresh() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});