define("livarava-web/routes/events/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    title: null,
    // Query Params
    queryParams: {
      ticket: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var event_id = parseInt(params.id);

      // const firstname = get(this, 'me.firstname') || 'John';
      // const lastname = get(this, 'me.lastname') || 'Doe';
      // const email = get(this, 'me.email') || 'john.doe@livarava.com';
      // const phone = get(this, 'me.phone') || '+380679374541';

      // const firstname = get(this, 'me.firstname') || "Artem";
      // const lastname = get(this, 'me.lastname') || "Kariavka";
      // const email = get(this, 'me.email') || "artem@kariavka.com";
      // const phone = get(this, 'me.phone') || "+380679374541";

      var firstname = Ember.get(this, 'me.firstname');
      var lastname = Ember.get(this, 'me.lastname');
      var email = Ember.get(this, 'me.email');
      var phone = Ember.get(this, 'me.phone');
      return store.findRecord('event', event_id).then(function (event) {
        var title = Ember.get(event, 'title');
        var tickets = Ember.get(event, 'tickets');
        var ticketSelected = tickets.filterBy('uid', params.ticket).objectAt(0);
        var ticket_uid = Ember.get(ticketSelected, 'uid');
        var price = Ember.get(ticketSelected, 'price');
        var currency = Ember.get(event, 'currency');

        // Set Title
        Ember.set(_this, 'title', title);
        var ticket = store.createRecord('ticket', {
          // Ticket
          ticket_uid: ticket_uid,
          price: price,
          currency: currency,
          status: null,
          // Datetime
          created: null,
          updated: null,
          // Participant
          firstname: firstname,
          lastname: lastname,
          email: email,
          phone: phone,
          company: null,
          industry: null,
          country: null
        });
        return Ember.RSVP.hash({
          event: event,
          ticket: ticket,
          order: ticketSelected
        });
      });
    },
    // Actions
    actions: {
      delete: function _delete() {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          this.controller.model.destroyRecord().then(function () {
            return _this2.transitionTo('posts');
          });
        }
      },
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});