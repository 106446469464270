define("livarava-web/components/input-neuron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Properties
    type: 'neuron',
    value: null,
    item: null,
    showItem: false,
    inputClass: 'form-control',
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var value = this.value;
      if (!value) {
        this.set('item', null);
        this.set('showItem', false);
        return;
      }
      if (typeof value === 'number') return;
      var id = value.get('id');
      if (id === undefined) return;
      if (id < 0) return;
      this.set('item', value);
      this.set('showItem', true);
    },
    // Actions
    actions: {
      onFocusIn: function onFocusIn() {
        this.set('showItem', false);
      },
      onFocusOut: function onFocusOut() {
        var item = this.item;
        if (item === null) {
          return;
        }
        this.set('showItem', true);
      },
      setItem: function setItem(id, item) {
        var _this = this;
        var store = this.store;
        var type = this.type;
        if (type !== 'user') type = 'neuron';
        if (type == 'person') type = 'user';
        if (item === null) {
          this.set('value', null);
          this.set('item', null);
          this.set('showItem', false);
          if (typeof this.onRemove == 'function') {
            return this.onRemove();
          }
          return;
        }
        store.findRecord(type, id).then(function (item) {
          _this.set('value', item);
          _this.set('item', item);
          _this.set('showItem', true);
          if (typeof _this.onSelect == 'function') {
            return _this.onSelect(item);
          }
        });
      },
      neuronClick: function neuronClick() {
        this.set('showItem', false);
        this.$('input').focus();
      }
    }
  });
  _exports.default = _default;
});