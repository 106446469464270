define("livarava-web/routes/entities/create", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "moment"], function (_exports, _objectSpread2, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Query Params
    queryParams: {
      copy: {
        reloadModel: true
      },
      lead: {
        reloadModel: true
      }
    },
    // Title
    titleToken: 'New Entity',
    // Model
    model: function model(params) {
      var store = this.store;
      var copyId = params.copy;
      var leadId = params.lead;
      var created = (0, _moment.default)();
      if (copyId) {
        return store.findRecord('entity', copyId).then(function (data) {
          return store.createRecord('entity', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, data.serialize().data.attributes), {}, {
            created: created
          }));
        });
      } else if (leadId) {
        return store.findRecord('lead', leadId).then(function (lead) {
          var entity = store.createRecord('entity', (0, _objectSpread2.default)((0, _objectSpread2.default)({}, lead.getProperties('title', 'summary', 'description', 'business_id', 'entity_type', 'business_id', 'keywords', 'city', 'address', 'address_area_level_1', 'address_area_level_2', 'address_postal_code', 'address_type', 'address_name', 'address_building', 'address_apartment', 'phone', 'email', 'website', 'utm_source', 'utm_medium', 'utm_campaign', 'utm_term', 'utm_content', 'manager', 'project')), {}, {
            // final_deadline: created,
            // apply_deadline: created,
            created: created,
            lead: lead
          }));
          return entity;
        });
      } else {
        return store.createRecord('entity', {
          title: '',
          summary: '',
          description: '',
          email: '',
          phone: '',
          website: '',
          keywords: []
        });
      }
    }
  });
  _exports.default = _default;
});