define("livarava-web/components/common/common-content-test", ["ember-qunit"], function (_emberQunit) {
  "use strict";

  (0, _emberQunit.moduleForComponent)('common/common-content', 'Integration | Component | common/common content', {
    integration: true
  });
  (0, _emberQunit.test)('it renders', function (assert) {
    // Set any properties with this.set('myProperty', 'value');
    // Handle any actions with this.on('myAction', function(val) { ... });

    this.render(Ember.HTMLBars.template(
    /*
      {{common/common-content}}
    */
    {
      "id": "PIAR9Km/",
      "block": "{\"symbols\":[],\"statements\":[[1,[34,0]]],\"hasEval\":false,\"upvars\":[\"common/common-content\"]}",
      "meta": {}
    }));
    assert.equal(this.$().text().trim(), '');

    // Template block usage:
    this.render(Ember.HTMLBars.template(
    /*
      
        {{#common/common-content}}
          template block text
        {{/common/common-content}}
      
    */
    {
      "id": "Hwvidzs5",
      "block": "{\"symbols\":[],\"statements\":[[2,\"\\n\"],[6,[37,0],null,null,[[\"default\"],[{\"statements\":[[2,\"      template block text\\n\"]],\"parameters\":[]}]]],[2,\"  \"]],\"hasEval\":false,\"upvars\":[\"common/common-content\"]}",
      "meta": {}
    }));
    assert.equal(this.$().text().trim(), 'template block text');
  });
});