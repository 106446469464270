define("livarava-web/routes/neuroner/settings/feeds/create", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var defaultURL = _environment.default.environment === 'development' ? 'http://itc.ua/feed/' : '';
  var defaultRuleURL = _environment.default.environment === 'development' ? 'http://itc.ua/' : '';
  var defaultRuleSelector = _environment.default.environment === 'development' ? '.post-txt' : '';
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model() {
      return this.store.createRecord('neuroner.feed', {
        url: defaultURL,
        rules: Ember.A([Ember.Object.create({
          url: defaultRuleURL,
          selector: defaultRuleSelector,
          without: ''
        })])
      });
    },
    // Actions
    actions: {
      save: function save(model) {
        var _this = this;
        model.save().then(function () {
          return _this.transitionTo('neuroner.settings.feeds.index');
        });
      }
    }
  });
  _exports.default = _default;
});