define("livarava-web/templates/products/item/payments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vWnXIFvf",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Payments\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"items\",\"page\"],[[35,1,[\"items\"]],[35,0]]]]],[2,\"\\n\\n\"],[10,\"nav\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"count\",\"current\",\"change\",\"pager\",\"class\"],[[35,1,[\"items\",\"meta\",\"total_pages\"]],[35,0],\"pageChanged\",true,\"pagination justify-content-center\"]],[[\"default\"],[{\"statements\":[[2,\"    Page \"],[1,[34,0]],[2,\" of \"],[1,[35,1,[\"items\",\"meta\",\"total_pages\"]]],[2,\"\\n\"]],\"parameters\":[]}]]],[13],[2,\"\\n\\n\"],[1,[30,[36,5],[[30,[36,4],null,null]],null]]],\"hasEval\":false,\"upvars\":[\"page\",\"model\",\"payment/payment-list\",\"pagination-pager\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/products/item/payments/index.hbs"
    }
  });
  _exports.default = _default;
});