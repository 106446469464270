define("livarava-web/utils/upload-file", ["exports", "ember-uploader/uploaders/uploader", "livarava-web/config/environment"], function (_exports, _uploader, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = uploadFile;
  function uploadFile(file) {
    if (!file) {
      console.log('UploadError: File is empty!');
      return;
    }
    var hostUrl = _environment.default.api.host;
    var apiPath = _environment.default.api.path;
    var url = "".concat(hostUrl).concat(apiPath, "/upload");
    var uploader = _uploader.default.create({
      url: url
    });
    return uploader.upload(file);
  }
});