define("livarava-web/templates/page/post", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yJ+2ZCdz",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[12],[2,\"\\n\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\" Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,1],null,[[\"route\",\"model\"],[\"page\",[35,0,[\"page_id\"]]]],[[\"default\"],[{\"statements\":[[1,[35,0,[\"neuron\",\"title\"]]]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[1,[35,0,[\"post\",\"title\"]]],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[1,[30,[36,2],null,[[\"model\"],[[35,0,[\"post\"]]]]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"blog/blog-post\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/page/post.hbs"
    }
  });
  _exports.default = _default;
});