define("livarava-web/instance-initializers/navigator", ["exports", "ember-place-autocomplete/instance-initializers/navigator"], function (_exports, _navigator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function get() {
      return _navigator.default;
    }
  });
  Object.defineProperty(_exports, "initialize", {
    enumerable: true,
    get: function get() {
      return _navigator.initialize;
    }
  });
});