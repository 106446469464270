define("livarava-web/transforms/plain-object", ["exports", "ember-data/transform"], function (_exports, _transform) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _transform.default.extend({
    deserialize: function deserialize(serialized) {
      return Ember.Object.create(serialized);
    },
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
  _exports.default = _default;
});