define("livarava-web/components/neuron-job", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    expanded: false,
    actions: {
      toggleText: function toggleText() {
        this.toggleProperty('expanded');
      }
    }
  });
  _exports.default = _default;
});