define("livarava-web/components/order/order-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    item: Ember.computed.reads('model.item'),
    propertyusers: null,
    entities: null,
    // States
    serviceModal: false,
    authorModal: false,
    propertyModal: false,
    loadingEntites: false,
    activeTab: 'tab-actions',
    statusOptions: _environment.default.options.order.status,
    offerStatusOptions: _environment.default.options.offer.status,
    // city filter for entities table
    entitiesCity: null,
    entitiesOffers: Ember.computed('entities.@each.id', 'model.offers.@each.entity', function () {
      var entities = this.entities;
      var offers = this.get('model.offers');
      if (!entities || !offers) return {};
      // debugger;
      return entities.reduce(function (result, entity) {
        var entityId = entity.get('id');
        result[entityId] = offers.find(function (offer) {
          return offer.get('entity.id') == entityId;
        });
        return result;
      }, {});
    }),
    memberUsers: Ember.computed('item.author', 'actionItems.@each.customer', function () {
      var author = this.get('item.author');
      var actionItems = this.actionItems;
      var memberUsers = [];
      if (actionItems) {
        memberUsers = this.actionItems.mapBy('customer');
      }
      if (author) {
        memberUsers.push(author);
      }
      return memberUsers.uniqBy('id');
    }),
    googleLink: Ember.computed('item.{city,service.title}', function () {
      return "".concat(this.get('item.city'), "+").concat(this.get('item.service.title')).replace(/\s/g, '+');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      if (!this.get('entitiesCity')) {
        this.set('entitiesCity', this.get('item.city'));
      }
      this.fetchProperties();
      this.fetchActions();
      this.fetchTransactions();
    },
    fetchProperties: function fetchProperties() {
      var _this = this;
      var userId = parseInt(this.get('item.author.id') || this.get('me.id'));
      this.store.query('propertyuser', {
        'filter[user]': userId
      }).then(function (propertyusers) {
        _this.set('propertyusers', propertyusers);
      });
    },
    fetchEntities: function fetchEntities() {
      var _this2 = this;
      this.set('loadingEntites', true);
      var params = {
        'filter[service]': this.get('item.service.id'),
        per_page: 100,
        page: 1
      };
      if (this.entitiesCity) {
        params['filter[city]'] = this.entitiesCity;
      }
      this.store.query('entityservice', params).then(function (entityservice) {
        return Ember.RSVP.all(entityservice.map(function (es) {
          return es.get('entity.id');
        }).uniq().map(function (entityId) {
          return _this2.store.find('entity', entityId);
        }));
      }).then(function (entities) {
        _this2.set('entities', entities);
        _this2.set('loadingEntites', false);
      }).catch(function () {
        _this2.set('loadingEntites', false);
      });
    },
    fetchActions: function fetchActions() {
      var _this3 = this;
      this.set('loadingActionItems', true);
      return this.store.query('action', {
        'filter[order]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (actionItems) {
        _this3.set('actionItems', actionItems);
        _this3.set('loadingActionItems', false);
      }).catch(function () {
        _this3.set('loadingActionItems', false);
      });
    },
    fetchTransactions: function fetchTransactions() {
      var _this4 = this;
      this.set('loadingTransactions', true);
      return this.store.query('transaction', {
        'filter[order]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (transactions) {
        _this4.set('transactions', transactions);
        _this4.set('loadingTransactions', false);
      }).catch(function () {
        _this4.set('loadingTransactions', false);
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      tabChanged: function tabChanged(newId) {
        this.set('activeTab', newId);
        if (!this.get('entities.length')) this.fetchEntities();
      },
      setProperty: function setProperty(property) {
        var item = this.item;
        if (!property) {
          return;
        }
        this.set('propertyModal', false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set('property', property);
        return item.save();
      },
      setService: function setService(service) {
        var _this5 = this;
        var item = this.item;
        if (!service) {
          return;
        }
        this.set('serviceModal', false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set('service', service);
        return item.save().then(function (item) {
          _this5.fetchEntities();
          return item;
        });
      },
      setStatus: function setStatus(status) {
        var item = this.item;
        if (!status || status == item.get('status')) {
          return;
        }
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set('status', status);
        return item.save();
      },
      setUserRelationship: function setUserRelationship(relationship, user) {
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!user) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set(relationship, user);
        return item.save();
      },
      setOfferStatus: function setOfferStatus(offer, status) {
        var _this6 = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        offer.set('status', status);
        var orderPromise = Promise.resolve();
        if (status == 'accepted') {
          this.item.set('status', 'accepted');
          orderPromise = this.item.save();
        }
        return orderPromise.then(function () {
          return offer.save();
        }).catch(function () {
          alert(_this6.intl.t('error.occured'));
        });
      },
      addOffer: function addOffer(entity) {
        var _this7 = this;
        var order = this.item;
        var amount = prompt("\u0412\u0432\u0435\u0434\u0438\u0442\u0435 \u0441\u0443\u043C\u043C\u0443 \u043E\u0444\u0444\u0435\u0440\u0430 \u043E\u0442 ".concat(entity.get('title'), "."));
        if (!amount) return;
        var offer = this.store.createRecord('offer', {
          amount: amount,
          order: order,
          entity: entity,
          status: 'created'
        });
        return offer.save().then(function () {
          _this7.set('activeTab', 'tab-offers');
          _this7.reload();
        }).catch(function () {
          alert(_this7.intl.t('error.occured'));
        });
      },
      deleteOffer: function deleteOffer(offer) {
        var _this8 = this;
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        return offer.destroyRecord().catch(function () {
          alert(_this8.intl.t('error.occured'));
        });
      },
      isViewedByEntity: function isViewedByEntity(entityId) {
        var actionItems = this.actionItems;
        if (!actionItems) {
          return false;
        }
        var actionItem = actionItems.find(function (item) {
          return item.get('text').includes("\u0432\u0456\u0434 \u0431\u0456\u0437\u043D\u0435\u0441\u0443 #".concat(entityId));
        });
        return !!actionItem;
      },
      handleActionAdded: function handleActionAdded() {
        this.fetchActions();
      },
      handleTransactionAdded: function handleTransactionAdded() {
        this.fetchTransactions();
      },
      handleEntitiesCityChange: function handleEntitiesCityChange() {
        this.fetchEntities();
      }
    }
  });
  _exports.default = _default;
});