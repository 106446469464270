define("livarava-web/templates/components/form-in-iframe", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Gu3DtS5W",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[18,1,null],[2,\"\\n\"],[10,\"div\"],[14,0,\"embed-responsive embed-responsive-1by1\"],[12],[2,\"\\n  \"],[10,\"iframe\"],[15,\"src\",[31,[[34,0]]]],[14,0,\"embed-responsive-item\"],[12],[13],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"src\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/form-in-iframe.hbs"
    }
  });
  _exports.default = _default;
});