define("livarava-web/routes/sites/item/navigations/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Attributes
    site_id: null,
    // Title
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    // Model
    model: function model() {
      var site_id = this.paramsFor('sites.item').site_id;
      this.set('site_id', site_id);
      var newNavigation = this.store.createRecord('navigation', {
        title: '',
        path: '',
        language: this.get('me.locale'),
        author: this.me,
        created: (0, _moment.default)()
      });
      return this.store.findRecord('neuron', site_id).then(function (site) {
        newNavigation.set('site', site);
        return newNavigation;
      });
    },
    // Actions
    actions: {
      save: function save() {
        var _this = this;
        var site_id = this.site_id;
        this.controller.model.save().then(function () {
          return _this.transitionTo('sites.item.navigations', site_id);
        });
      }
    }
  });
  _exports.default = _default;
});