define("livarava-web/components/service/input-icon", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    icons: _environment.default.icons,
    loading: false,
    search: '',
    visibleIcons: Ember.computed('search', function () {
      var search = this.search,
        icons = this.icons;
      if (!search) return icons;
      return icons.filter(function (i) {
        return i.includes(search);
      });
    }),
    onChange: function onChange() {},
    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    actions: {
      select: function select(icon) {
        this.onChange(icon);
      },
      handleIconChange: function handleIconChange(icon) {
        this.setProperties({
          modalOpened: false
        });
        this.onChange('ho ho-' + icon);
      }
    }
  });
  _exports.default = _default;
});