define("livarava-web/templates/components/speech/speech-mic", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Om+hgbrl",
    "block": "{\"symbols\":[],\"statements\":[[11,\"span\"],[16,0,[31,[[30,[36,1],[[35,0,[\"isRecording\"]],\"text-danger\",\"\"],null]]]],[4,[38,2],[[32,0],\"micStart\"],null],[12],[10,\"i\"],[14,0,\"fa fa-microphone\"],[12],[13],[13]],\"hasEval\":false,\"upvars\":[\"speech\",\"if\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/speech/speech-mic.hbs"
    }
  });
  _exports.default = _default;
});