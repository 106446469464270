define("livarava-web/templates/sites/item/sitemap", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "FRGknUsZ",
    "block": "{\"symbols\":[],\"statements\":[[10,\"h2\"],[12],[2,\"Sitemap\"],[13],[2,\"\\n\\n\"],[10,\"hr\"],[12],[13],[2,\"\\n\\n\"],[1,[30,[36,2],null,[[\"class\",\"autoresize\",\"value\",\"focus-in\"],[\"form-control sitemap\",true,[35,1,[\"sitemap\"]],[30,[36,0],[[32,0],\"onFocusIn\"],null]]]]]],\"hasEval\":false,\"upvars\":[\"action\",\"model\",\"textarea\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/sites/item/sitemap.hbs"
    }
  });
  _exports.default = _default;
});