define("livarava-web/routes/investments/index", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'Investments',
    // Params
    queryParams: {
      user: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var _config$api = _environment.default.api,
        host = _config$api.host,
        path = _config$api.path;
      var userId = parseInt(params.user || Ember.get(this, 'me.id'));
      var queryParams = {
        'filter[user]': userId,
        per_page: 5,
        page: 1,
        sort: '-created'
      };
      return Ember.RSVP.hash({
        dashboard: (0, _fetch.default)("".concat(host).concat(path, "/analytics/investments_dashboard?user=").concat(userId)).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }),
        investments: store.query('investment', queryParams),
        payouts: store.query('payout', queryParams)
      });
    }
  });
  _exports.default = _default;
});