define("livarava-web/routes/sites/index", ["exports", "livarava-web/utils/get-filters-from-params", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _getFiltersFromParams, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      sort: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      return Ember.RSVP.hash({
        items: this.store.query('site', (0, _getFiltersFromParams.default)(params))
      });
    }
  });
  _exports.default = _default;
});