define("livarava-web/templates/components/user-autocomplete-v1", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "/qJvZRng",
    "block": "{\"symbols\":[\"item\"],\"statements\":[[1,[30,[36,9],null,[[\"type\",\"class\",\"placeholder\",\"value\",\"input\",\"key-down\",\"focusIn\",\"autofocus\"],[\"text\",\"form-control\",[35,8],[35,7],[30,[36,1],[[32,0],\"onInput\"],null],[30,[36,1],[[32,0],\"onKeyUp\"],null],[30,[36,1],[[32,0],\"onFocusIn\"],null],[35,6]]]]],[2,\"\\n\\n\"],[6,[37,0],[[35,3,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"user-autocomplete-v1-dropdown\"],[12],[2,\"\\n\"],[6,[37,5],[[30,[36,4],[[30,[36,4],[[35,3]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[11,\"div\"],[16,0,[31,[\"clearfix user-autocomplete user-autocomplete-v1-item \",[30,[36,0],[[32,1,[\"selected\"]],\"user-autocomplete-v1-item-selected\"],null]]]],[4,[38,1],[[32,0],\"selectItem\",[32,1]],null],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"user-autocomplete-image\"],[15,5,[30,[36,2],[\"background-image: url(\",[32,1,[\"image_url\"]],\")\"],null]],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"user-autocomplete-header\"],[12],[1,[32,1,[\"header\"]]],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"if\",\"action\",\"html-safe\",\"items\",\"-track-array\",\"each\",\"autofocus\",\"value\",\"placeholder\",\"input\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/user-autocomplete-v1.hbs"
    }
  });
  _exports.default = _default;
});