define("livarava-web/controllers/contacts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Properties
    title: Ember.computed('model.firstname', 'model.lastname', function () {
      var firstname = this.get('model.firstname');
      var lastname = this.get('model.lastname');
      return firstname + " " + lastname;
    }),
    // Actions
    actions: {
      save: function save(item) {
        var _this = this;
        item.save().then(function () {
          return _this.transitionToRoute('contacts.item', item.id);
        });
      },
      delete: function _delete(item) {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this2.transitionToRoute('contacts');
          });
        }
      }
    }
  });
  _exports.default = _default;
});