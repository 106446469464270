define("livarava-web/controllers/invoices/index", ["exports", "@babel/runtime/helpers/esm/defineProperty"], function (_exports, _defineProperty2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'project', 'product', 'status', 'currency', 'date_start', 'date_end', 'sort'],
    page: 1,
    per_page: 10,
    q: null,
    project: null,
    product: null,
    status: null,
    currency: null,
    date_start: null,
    date_end: null,
    sort: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'project',
      query: 'project',
      type: 'neuron',
      abstract: 'project',
      placeholder: 'Project'
    }, {
      name: 'product',
      query: 'product',
      type: 'neuron',
      abstract: 'product',
      placeholder: 'Product'
    }, {
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: 'opened',
        label: 'Opened',
        icon: 'fa fa-square-o text-primary'
      }, {
        value: 'payed',
        label: 'Payed',
        icon: 'fa fa-check-square-o text-success'
      }]
    }, {
      name: 'currency',
      query: 'currency',
      type: 'select',
      placeholder: 'Currency',
      options: [{
        value: 'USD',
        label: 'USD',
        icon: 'fa fa-usd'
      }, {
        value: 'EUR',
        label: 'EUR',
        icon: 'fa fa-eur'
      }, {
        value: 'UAH',
        label: 'UAH',
        sign: '₴'
      }, {
        value: 'RUB',
        label: 'RUB',
        icon: 'fa fa-rub'
      }]
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }]),
    // States
    isFiltersExpanded: false,
    isFiltersSetted: Ember.computed('q', 'project', 'product', 'author', 'executor', 'task_type', 'status', 'currency', function () {
      var _this = this;
      var filters = this.filters;
      var filtersSetted = 0;
      filters.forEach(function (filter) {
        var value = _this.get(filter.query);
        if (value !== null) {
          filtersSetted += 1;
        }
      });
      return filtersSetted > 0;
    }),
    // Variables
    icons: {
      statuses: {
        opened: 'fa fa-square-o text-primary',
        payed: 'fa fa-check-square-o text-success'
      },
      currencyOptions: {
        USD: '<i class="fa fa-dollar"></i>',
        EUR: '<i class="fa fa-eur"></i>',
        UAH: '&#8372;',
        RUB: '<i class="fa fa-rub"></i>'
      }
    },
    // Actions
    actions: {
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      setFilter: function setFilter(name, value) {
        this.transitionToRoute('invoices', {
          queryParams: (0, _defineProperty2.default)({
            page: this.page,
            q: this.q
          }, "filter[".concat(name, "]"), value)
        });
      },
      setDateRange: function setDateRange() {
        var dateStart = this.get('model.date_start');
        var dateEnd = this.get('model.date_end');
        this.transitionToRoute('invoices', {
          queryParams: {
            date_start: dateStart,
            date_end: dateEnd
          }
        });
      },
      resetFilters: function resetFilters() {
        var _this2 = this;
        var queryParams = this.queryParams;
        queryParams.forEach(function (param) {
          var paramName;
          if (param.toString() === '[object Object]') {
            paramName = Object.keys(param)[0];
          } else {
            paramName = param;
          }
          var value = paramName === 'page' ? 1 : null;
          _this2.set(paramName, value);
        });
      }
    }
  });
  _exports.default = _default;
});