define("livarava-web/utils/get-neuron-types", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getNeuronTypes;
  function getNeuronTypes() {
    return Ember.A(['text', 'phone', 'email', 'link', 'image', 'video', 'page', 'post', 'project', 'product', 'rssfeed', 'event', 'task']);
  }
});