define("livarava-web/routes/sites/item/feedbacks/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Model
    model: function model(params) {
      var site_id = parseInt(this.paramsFor('sites.item').site_id);
      var store = this.store;
      var id = parseInt(params.feedback_id);
      params['site'] = site_id;
      params['neuron'] = id;
      return Ember.RSVP.hash({
        site_id: site_id,
        item: store.findRecord('feedback', id),
        comments: store.query('comment', params),
        comment: store.createRecord('comment', {
          site: store.findRecord('site', 4964),
          root: id
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});