define("livarava-web/controllers/products/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'status'],
    page: 1,
    per_page: 6,
    q: null,
    status: 'created',
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'status',
      query: 'status',
      type: 'select',
      placeholder: 'Status',
      options: [{
        value: 'created',
        label: 'Created',
        icon: 'fa fa-square-o text-primary'
      }, {
        value: 'archived',
        label: 'Archived',
        icon: 'fa fa-archive text-muted'
      }]
    }])
  });
  _exports.default = _default;
});