define("livarava-web/controllers/restore", ["exports", "fetch", "livarava-web/config/environment"], function (_exports, _fetch, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Query Params
    queryParams: ['email'],
    // Attributes
    email: null,
    code: null,
    error: null,
    // States
    isVerifying: false,
    isSending: false,
    isDisabled: Ember.computed('code', 'isVerifying', function () {
      var isVeryfying = this.isVerifying;
      var code = this.code;
      return isVeryfying || !(code && code.length === 6);
    }),
    // Actions
    actions: {
      onResend: function onResend() {
        var _this = this;
        var host = _environment.default.api.host;
        var path = _environment.default.api.path;
        var time = new Date().getTime();
        var email = this.email;
        var site = _environment.default.neuronet.uid;
        var url = "".concat(host).concat(path, "/password-forgot/") + "?__t=".concat(time) + "&email=".concat(email) + "&site=".concat(site);
        this.set('isSending', true);
        this.set('error', null);
        (0, _fetch.default)(url).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', null);
        }).catch(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', 'Unexpected error. Please, report to admin@livarava.com');
        });
      },
      onVerify: function onVerify() {
        var _this2 = this;
        var host = _environment.default.api.host;
        var path = _environment.default.api.path;
        var time = new Date().getTime();
        var email = this.email;
        var code = this.code;
        var site = _environment.default.neuronet.uid;
        var url = "".concat(host).concat(path, "/password-restore/") + "?__t=".concat(time) + "&email=".concat(email) + "&code=".concat(code) + "&site=".concat(site);
        this.set('isVerifying', true);
        this.set('error', null);
        (0, _fetch.default)(url).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          // console.log(response);
          _this2.set('isVerifying', false);
          if (response.error) {
            _this2.set('error', "Error: ".concat(response.error) || 'Unexpected error. Please, report to admin@livarava.com');
          } else {
            _this2.set('error', null);
            _this2.send('toPasswordChange', {
              email: email,
              code: code
            });
          }
        }).catch(function (response) {
          // console.log(response);
          _this2.set('isVerifying', false);
          _this2.set('error', 'Unexpected error. Please, report to admin@livarava.com');
        });
      }
    }
  });
  _exports.default = _default;
});