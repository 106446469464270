define("livarava-web/controllers/tasks/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      saveAndGo: function saveAndGo(item) {
        var _this = this;
        item.save().then(function () {
          return _this.transitionToRoute('tasks.item', item.id);
        });
      },
      delete: function _delete(item) {
        var _this2 = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function () {
            return _this2.transitionToRoute('tasks');
          });
        }
      }
    }
  });
  _exports.default = _default;
});