define("livarava-web/components/invoice/invoice-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Settings
    tagName: 'span',
    // Attributes
    status: Ember.computed.reads('item.status'),
    // Options
    options: [{
      value: 'opened',
      label: 'Opened',
      icon: 'fa fa-clock-o text-muted'
    }, {
      value: 'process',
      label: 'Process',
      icon: 'fa fa-cogs text-info'
    }, {
      value: 'payed',
      label: 'Payed',
      icon: 'fa fa-check text-success'
    }, {
      value: 'canceled',
      label: 'Canceled',
      icon: 'fa fa-ban text-danger'
    }],
    // Computed
    icon: Ember.computed('status', function () {
      var status = this.status;
      var options = this.options;
      var icon;
      options.forEach(function (option) {
        if (option.value === status) {
          icon = option.icon;
        }
      });
      return icon;
    }),
    // Actions
    actions: {
      setStatus: function setStatus(status) {
        var item = this.item;
        Ember.set(item, 'status', status);
        Ember.set(item, 'showDropDown', false);
        item.save().then(function () {
          // Do nothing
        }).catch(function () {
          // Do nothing
        });
      }
    }
  });
  _exports.default = _default;
});