define("livarava-web/controllers/users/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Flags
    isShowingUserDeleteModal: false,
    // Actions
    actions: {
      delete: function _delete() {
        var _this = this;
        var item = this.model;
        item.destroyRecord().then(function () {
          _this.transitionToRoute('users.index');
        });
      },
      save: function save(item) {
        item.save();
      }
    }
  });
  _exports.default = _default;
});