define("livarava-web/templates/neuron/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "Os//rIHb",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuron\"],[12],[2,\"\\n    \"],[1,[30,[36,2],null,[[\"item\",\"isLoading\"],[[35,1],[35,0]]]]],[2,\"\\n\\n    \"],[1,[30,[36,4],[[30,[36,3],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"isLoading\",\"model\",\"neuron-article\",\"-outlet\",\"component\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuron/item.hbs"
    }
  });
  _exports.default = _default;
});