define("livarava-web/templates/components/neuron-normal-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "WMkWpu1Z",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"clearfix neuron-normal\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"neuron-image\"],[15,5,[34,3]],[12],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"neuron-data\"],[12],[2,\"\\n\\n\"],[6,[37,2],[[35,0,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"neuron-header\"],[12],[1,[35,0,[\"header\"]]],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,2],[[35,0,[\"header\"]]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"value\",\"tagName\",\"placeholder\",\"type\",\"allowNewlines\",\"focus-out\",\"class\"],[[35,0,[\"header\"]],\"div\",\"Input title here...\",\"text\",false,\"save\",\"neuron-header\"]]]],[2,\"\\n      \"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n      \"],[10,\"div\"],[14,0,\"neuron-info\"],[12],[2,\"\\n\\n        \"],[1,[30,[36,4],null,[[\"item\"],[[35,0]]]]],[2,\"\\n\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"neuron-summary\"],[12],[2,\"\\n        \"],[1,[35,0,[\"summary\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"neuron-title\"],[12],[2,\"\\n        \"],[1,[35,0,[\"title\"]]],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"item\",\"content-editable\",\"if\",\"imageStyle\",\"neuron-short-info\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-normal-form.hbs"
    }
  });
  _exports.default = _default;
});