define("livarava-web/routes/neuron/item", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      var neuronId = parseInt(params.id);
      return store.findRecord('neuron', neuronId).then(function (data) {
        var title = Ember.get(data, 'header');
        Ember.set(_this, 'titleToken', title);
        return data;
      }).catch(function (response) {
        if (response.errors[0].status === '404') {
          _this.transitionTo('notfound');
        }
      });
    },
    afterModel: function afterModel(model, transition) {
      var isSigned = Ember.get(this, 'session.signed');
      if (!isSigned) return;
      if (transition.targetName === 'neuron.item') {
        this.transitionTo('neuron.item.overview', model.id);
      }
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});