define("livarava-web/routes/projects/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: function titleToken(model) {
      return model.get('title');
    },
    // Model
    model: function model(param) {
      return this.store.findRecord('project', param.id);
    }
  });
  _exports.default = _default;
});