define("livarava-web/components/common/filters/filter-neuron", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    controller: null,
    filter: null,
    value: null,
    neuron: null,
    // States
    isExpanded: false,
    setValue: function setValue(newValue) {
      var _this = this;
      var controller = this.controller;
      var name = Ember.get(this, 'filter.query');
      var currentValue = this.value;
      if (currentValue === newValue) {
        return;
      }
      if (newValue === null) {
        Ember.set(this, 'value', null);
        Ember.set(this, 'neuron', null);
        controller.set(name, null);
        return;
      }
      this.store.findRecord('neuron', newValue).then(function (neuron) {
        Ember.set(_this, 'value', newValue);
        Ember.set(_this, 'neuron', neuron);
        controller.set(name, newValue);
      });
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var controller = this.controller;
      var name = Ember.get(this, 'filter.query');
      var value = controller.get(name);
      var type = Ember.get(this, 'filter.abstract') || Ember.get(this, 'filter.type');
      Ember.set(this, 'type', type);
      this.setValue(value);
    },
    // Actions
    actions: {
      setFilter: function setFilter(value) {
        this.setValue(value);
        Ember.set(this, 'isExpanded', false);
      },
      collapseFilter: function collapseFilter() {
        Ember.set(this, 'isExpanded', false);
      }
    }
  });
  _exports.default = _default;
});