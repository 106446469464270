define("livarava-web/controllers/support/posts/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Properties
    currentUrl: Ember.computed(function () {
      return window && window.location ? window.location.href : '/';
    }),
    // Params
    queryParams: ['page'],
    page: 1,
    // Actions
    actions: {
      onComment: function onComment(item) {
        var _this = this;
        item.save().then(function (item) {
          return _this.send('reload');
        });
      },
      onSaveComment: function onSaveComment(item) {
        var _this2 = this;
        item.save().then(function (item) {
          return _this2.send('reload');
        });
      },
      onDeleteComment: function onDeleteComment(item) {
        var _this3 = this;
        if (confirm("Are you sure?")) {
          item.destroyRecord().then(function (item) {
            return _this3.send('reload');
          });
        }
      }
    }
  });
  _exports.default = _default;
});