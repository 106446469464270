define("livarava-web/utils/check-image-url", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = checkImageURL;
  function checkImageURL() {
    var url = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
    return url.match(/\.(gif|jpg|jpeg|tiff|png)$/i);
  }
});