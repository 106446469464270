define("livarava-web/templates/components/thread/thread-message", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "OKw3mTQg",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,4],[[35,3]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-center text-muted\"],[12],[2,\"\\n    \"],[10,\"small\"],[12],[1,[30,[36,2],[[35,1,[\"created\"]],\"YYYY-MM-DD hh:mm\"],null]],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[10,\"div\"],[15,1,[31,[[34,5]]]],[15,0,[31,[\"message \",[30,[36,4],[[35,7],\"message-my\"],null],\" \",[30,[36,4],[[35,6],\"message-same-author\"],null]]]],[12],[2,\"\\n\"],[6,[37,4],[[30,[36,9],[[35,7],[35,8]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[11,\"i\"],[24,0,\"fa fa-remove text-muted\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"delete\"],null],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[6,[37,4],[[30,[36,9],[[35,7],[35,1,[\"edited\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-pencil text-muted\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[10,\"div\"],[14,0,\"message-content dont-break-out\"],[15,\"title\",[31,[[30,[36,2],[[35,1,[\"created\"]],\"YYYY-MM-DD hh:mm\"],null]]]],[12],[2,\"\\n    \"],[1,[30,[36,10],null,[[\"markdown\",\"strikethrough\",\"literalMidWordUnderscores\",\"simplifiedAutoLink\"],[[35,1,[\"message\"]],true,true,true]]]],[2,\"\\n  \"],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,9],[[30,[36,11],[[35,7]],null],[35,1,[\"edited\"]]],null]],null,[[\"default\"],[{\"statements\":[[2,\"    \"],[10,\"i\"],[14,0,\"fa fa-pencil text-muted\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[13]],\"hasEval\":false,\"upvars\":[\"action\",\"item\",\"moment-format\",\"showDate\",\"if\",\"itemId\",\"isNextSameAuthor\",\"isMy\",\"isMouseOver\",\"and\",\"markdown-to-html\",\"not\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/thread/thread-message.hbs"
    }
  });
  _exports.default = _default;
});