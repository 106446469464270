define("livarava-web/controllers/sites/item/navigations/index", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: ['page', 'per_page', 'q', 'language'],
    page: 1,
    per_page: 100,
    language: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'language',
      query: 'language',
      type: 'language',
      placeholder: 'Language',
      options: _environment.default.intl.localeLabels
    }])
  });
  _exports.default = _default;
});