define("livarava-web/controllers/investments/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Options
    progressOptions: {
      trailColor: '#eee',
      trailWidth: 3
    },
    amountOptions: [100, 200, 500, 1000, 2000, 5000, 10000, 20000, 50000],
    // Actions
    actions: {
      setProject: function setProject(project) {
        var investment = this.get('model.investment');
        var me = this.me;
        investment.set('project', project);
        investment.set('user', me.user);
        investment.set('title', "".concat(project.header));
        investment.set('currency', project.get('investment_currency'));
      },
      setAmount: function setAmount(amount) {
        var investment = this.get('model.investment');
        investment.set('amount', amount);
      },
      createInvestment: function createInvestment() {
        var _this = this;
        var investment = this.get('model.investment');
        investment.save().then(function () {
          _this.transitionToRoute('investments.success');
        });
      }
    }
  });
  _exports.default = _default;
});