define("livarava-web/components/action/action-list-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    actionTypeOptions: _environment.default.options.action.type,
    actionDirectionOptions: _environment.default.options.action.direction,
    // Actions
    actions: {
      delete: function _delete() {
        this.onDelete(this.item);
      }
    }
  });
  _exports.default = _default;
});