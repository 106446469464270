define("livarava-web/templates/projects/item/about", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "JQgF8Zj9",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-8\"],[12],[2,\"\\n    \"],[2,[35,0,[\"description\"]]],[2,\"\\n  \"],[13],[2,\"\\n\\n\"],[13],[2,\"\\n\\n\"],[1,[30,[36,2],[[30,[36,1],null,null]],null]],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"model\",\"-outlet\",\"component\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/projects/item/about.hbs"
    }
  });
  _exports.default = _default;
});