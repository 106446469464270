define("livarava-web/templates/components/input-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "H6gVenSS",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"input-date\"],[12],[2,\"\\n  \"],[8,\"ember-flatpickr\",[[24,\"placeholder\",\"Choose a Date\"],[24,0,\"form-control\"]],[[\"@allowInput\",\"@date\",\"@dateFormat\",\"@locale\",\"@mode\",\"@enableTime\",\"@onChange\"],[true,[34,0],\"Y-m-d\",\"en\",\"single\",false,[30,[36,1],[[32,0],\"setDate\"],null]]],null],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"date\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/input-date.hbs"
    }
  });
  _exports.default = _default;
});