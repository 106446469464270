define("livarava-web/controllers/password", ["exports", "fetch", "livarava-web/config/environment", "md5"], function (_exports, _fetch, _environment, _md) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Query Params
    queryParams: ['email', 'code'],
    // Attributes
    email: null,
    code: null,
    password: null,
    confirm: null,
    error: null,
    // States
    isSending: false,
    isDisabled: Ember.computed('password', 'confirm', 'isSending', function () {
      var isSending = this.isSending;
      var password = this.password;
      var confirm = this.confirm;
      return isSending || !password || !confirm || password.length < 8 || password !== confirm;
    }),
    // Actions
    actions: {
      onSend: function onSend() {
        var _this = this;
        var me = this.me;
        var host = _environment.default.api.host;
        var path = _environment.default.api.path;
        var time = new Date().getTime();
        var email = this.email;
        var code = this.code;
        var password = (0, _md.default)(this.password);
        var confirm = (0, _md.default)(this.confirm);
        var site = _environment.default.neuronet.uid;
        var url = "".concat(host).concat(path, "/password-change/") + "?__t=".concat(time) + "&email=".concat(email) + "&code=".concat(code) + "&password=".concat(password) + "&confirm=".concat(confirm) + "&site=".concat(site);
        this.set('isSending', true);
        this.set('error', null);
        (0, _fetch.default)(url).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', null);
          if (response.error) {
            _this.set('error', "Error: ".concat(response.error) || 'Unexpected error. Please, report to admin@livarava.com');
          } else {
            _this.set('isSending', true);
            me.signIn(email, password).then(function () {
              _this.set('isSending', false);
              _this.send('toIndex');
            }).catch(function () {
              _this.set('error', response.error || 'Login error. Please, report to admin@livarava.com');
            });
          }
        }).catch(function (response) {
          // console.log(response);
          _this.set('isSending', false);
          _this.set('error', 'Unexpected error. Please, report to admin@livarava.com');
        });
      }
    }
  });
  _exports.default = _default;
});