define("livarava-web/templates/components/neuron-input-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vQ6vdVOo",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"neuron-modal-autocomplete\"],[12],[2,\"\\n\\n  \"],[1,[30,[36,9],null,[[\"type\",\"value\",\"placeholder\",\"autofocus\",\"onInput\",\"onSelect\",\"onChange\",\"onEscape\"],[\"neuron\",[35,8],\"Text or link to image or video, then press Enter\",[35,7],[30,[36,6],[[32,0],\"onInput\"],null],[30,[36,6],[[32,0],\"onSelect\"],null],[30,[36,6],[[32,0],\"onChange\"],null],[35,5]]]]],[2,\"\\n\\n\"],[6,[37,4],[[35,10]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-center text-primary align-middle\"],[14,5,\"line-height: 100px;\"],[12],[2,\"\\n      \"],[10,\"i\"],[14,0,\"fa fa-spin fa-spinner fa-3x fa-fw\"],[12],[13],[2,\"\\n      \"],[10,\"span\"],[14,0,\"sr-only\"],[12],[2,\"Loading...\"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"\\n\"],[6,[37,4],[[35,0]],null,[[\"default\"],[{\"statements\":[[2,\"\\n      \"],[10,\"div\"],[14,0,\"neuron-modal\"],[12],[2,\"\\n\"],[6,[37,4],[[35,0,[\"id\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"          \"],[1,[30,[36,3],null,[[\"item\"],[[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"          \"],[1,[30,[36,2],null,[[\"display\",\"item\"],[[35,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"rava\",\"display\",\"neuron-normal-form\",\"neuron-normal\",\"if\",\"onEscape\",\"action\",\"autofocus\",\"value\",\"neuron-autocomplete-v1\",\"isLoading\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-input-form.hbs"
    }
  });
  _exports.default = _default;
});