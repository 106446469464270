define("livarava-web/controllers/sites/item/sitemap", ["exports", "jquery"], function (_exports, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      onFocusIn: function onFocusIn() {
        (0, _jquery.default)(".sitemap").select();
      }
    }
  });
  _exports.default = _default;
});