define("livarava-web/transforms/datetime", ["exports", "ember-data", "moment-timezone"], function (_exports, _emberData, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Transform.extend({
    serialize: function serialize(value) {
      return value ? value.utc().toJSON() : null;
    },
    deserialize: function deserialize(value) {
      return _momentTimezone.default.tz(value, 'UTC');
    }
  });
  _exports.default = _default;
});