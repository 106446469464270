define("livarava-web/routes/sites/item/posts/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var post_id = parseInt(params.post_id);
      return store.findRecord('post', post_id);
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'titleToken', Ember.get(model, 'title'));
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      // this.startWatchingTime(controller, model);
    },

    startWatchingTime: function startWatchingTime(controller, model) {
      var self = this;
      var post = Ember.get(model, 'post');
      Ember.run.later(function () {
        post.save().then(function () {
          return self.startWatchingTime(controller, model);
        });
      }, 60000);
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          var site_id = Ember.get(item, 'site.id');
          item.destroyRecord().then(function () {
            return _this.transitionTo('sites.item.posts', site_id);
          });
        }
      }
    }
  });
  _exports.default = _default;
});