define("livarava-web/components/property/input-property", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    klass: null,
    value: null,
    predictions: null,
    item: null,
    tagName: '',
    inputClass: null,
    placeholder: 'Select...',
    allowClear: true,
    property_type: 'condominium',
    requestPredictions: function requestPredictions(q) {
      var _this = this;
      if (Ember.isBlank(q)) {
        this.resetPredictions();
      }
      var store = this.store;
      store.query('property', {
        q: q,
        'filter[property_type]': this.property_type,
        sort: 'title'
      }).then(function (data) {
        Ember.set(_this, 'predictions', data);
      });
    },
    resetPredictions: function resetPredictions() {
      this.set('predictions', []);
    },
    actions: {
      select: function select(selectedItem) {
        var _this2 = this;
        if (Ember.isBlank(selectedItem)) {
          this.trigger('onChange', null);
          Ember.run.next(function () {
            _this2.resetPredictions();
          });
          return;
        } else {
          this.trigger('onChange', selectedItem);
        }
      },
      handleSearch: function handleSearch(q) {
        Ember.run.debounce(this, this.requestPredictions, q, 300);
      }
    }
  });
  _exports.default = _default;
});