define("livarava-web/models/post", ["exports", "ember-data", "livarava-web/utils/get-language-label", "livarava-web/utils/is-url"], function (_exports, _emberData, _getLanguageLabel, _isUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    title: _emberData.default.attr('string'),
    type: 'post',
    display: _emberData.default.attr('string'),
    featured: _emberData.default.attr('boolean'),
    language: _emberData.default.attr('string'),
    summary: _emberData.default.attr('string'),
    description: _emberData.default.attr('string'),
    // Stats
    weight: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    views: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    subscriber_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Subscribers count
    comment_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Comments count
    axon_count: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Axons count

    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.belongsTo('neuron'),
    icon_url: _emberData.default.attr('string'),
    iconUrl: Ember.computed('icon', 'icon_url', function () {
      var icon_url = this.icon_url;
      if (icon_url && (0, _isUrl.default)(icon_url)) {
        return icon_url;
      }
      return null;
    }),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    main_image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed('image', 'image_url', 'main_image_url', function () {
      var image = this.image;
      var image_url = this.image_url;
      var main_image_url = this.main_image_url;
      if (image) {
        return Ember.get(image, 'image_url');
      }
      if (main_image_url && (0, _isUrl.default)(main_image_url)) {
        return main_image_url;
      }
      if (image_url && (0, _isUrl.default)(image_url)) {
        return image_url;
      }
      return null;
    }),
    // Computed
    imageStyle: Ember.computed('image_url', function () {
      var url = this.image_url;
      return Ember.String.htmlSafe("background-image: url(".concat(url, ");"));
    }),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('site'),
    categories: _emberData.default.hasMany('category'),
    // Meta
    meta_title: _emberData.default.attr('string'),
    meta_description: _emberData.default.attr('string'),
    meta_keywords: _emberData.default.attr('string'),
    meta_image: _emberData.default.belongsTo('neuron'),
    meta_image_url: _emberData.default.attr('string'),
    // Computed
    languageLabel: Ember.computed('language', function () {
      return (0, _getLanguageLabel.default)(this.language);
    }),
    // Validators;
    validations: {
      title: {
        presence: true,
        length: {
          minimum: 3
        }
      }
    }
  });
  _exports.default = _default;
});