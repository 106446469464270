define("livarava-web/components/product-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    localSettings: Ember.inject.service('local-settings'),
    // Attributes
    froalaOptions: _environment.default.froalaOptions.min,
    // Events
    onSave: null,
    // Actions
    actions: {
      save: function save() {
        this.onSave(this.model);
      },
      setProject: function setProject(item) {
        var _this = this;
        if (item === null) {
          this.set('model.project', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          _this.set('model.project', item);

          // Save Project to Settings
          var localSettings = _this.localSettings;
          localSettings.set('settings.project_id', item.id);
        });
      },
      setImage: function setImage(item) {
        var _this2 = this;
        if (item === null) {
          this.set('model.image', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (image) {
          _this2.set('model.image', image);
        });
      }
    }
  });
  _exports.default = _default;
});