define("livarava-web/models/entityservice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var Model = _emberData.default.Model;
  var _default = Model.extend({
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // географія надання послуги 
    city: _emberData.default.attr('string'),
    // місто
    address_area_level_1: _emberData.default.attr('string'),
    // область
    address_area_level_2: _emberData.default.attr('string'),
    // район
    address_postal_code: _emberData.default.attr('string'),
    // поштовий індекс

    // Service
    service_type: _emberData.default.attr('string'),
    amount: _emberData.default.attr('number'),
    cashback: _emberData.default.attr('number'),
    customer_max: _emberData.default.attr('number'),
    // Author
    author: _emberData.default.belongsTo('user'),
    entity: _emberData.default.belongsTo('entity'),
    service: _emberData.default.belongsTo('service'),
    // Computed
    title: Ember.computed.reads('service.title')
  });
  _exports.default = _default;
});