define("livarava-web/models/contact", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    email: _emberData.default.attr('string'),
    phone: _emberData.default.attr('string'),
    firstname: _emberData.default.attr('string'),
    lastname: _emberData.default.attr('string'),
    fullname: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    // Type
    type: 'contact',
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Icon
    icon: _emberData.default.attr('string'),
    icon_url: _emberData.default.attr('string'),
    // Image
    image: _emberData.default.belongsTo('neuron'),
    image_url: _emberData.default.attr('string'),
    imageUrl: Ember.computed.reads('image_url'),
    imageStyle: Ember.computed('image_url', function () {
      var imageUrl = this.image_url;
      if (imageUrl === 'None') {
        return;
      }
      return Ember.String.htmlSafe("background-image: url(".concat(imageUrl, ");"));
    }),
    // Computed
    firstletter: Ember.computed('firstname', function () {
      var firstname = this.firstname;
      return firstname.charAt(0);
    }),
    imageRandomStyle: Ember.computed('firstletter', function () {
      var colors = ['#777', '#770', '#070', '#077', '#007', '#707', '#700'];
      var randomColor = colors[Math.floor(Math.random() * colors.length)];
      return Ember.String.htmlSafe("background-color: ".concat(randomColor, ";"));
    }),
    // User
    user: _emberData.default.belongsTo('user')
  });
  _exports.default = _default;
});