define("livarava-web/routes/projects/item/payments", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Title
    titleToken: 'Payments',
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      },
      product: {
        refreshModel: true
      },
      type: {
        refreshModel: true
      },
      status: {
        refreshModel: true
      },
      currency: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var store = this.store;
      var projectId = parseInt(this.paramsFor('projects.item').id);
      params['project'] = projectId;
      return Ember.RSVP.hash({
        items: store.query('payment', (0, _getFiltersFromParams.default)(params))
      });
    },
    setupController: function setupController(controller, model) {
      this._super(controller, model);
      Ember.set(controller, 'isRefreshing', false);
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});