define("livarava-web/controllers/products/item/payments/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Services
    me: Ember.inject.service(),
    // Params
    queryParams: ['page', 'per_page'],
    page: 1,
    per_page: 10,
    // Actions
    actions: {}
  });
  _exports.default = _default;
});