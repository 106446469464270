define("livarava-web/locales/en/translations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    'buttons': {
      'comment': 'Comment',
      'delete': 'Delete',
      'edit': 'Edit',
      'subscribe': 'Subscribe',
      'archive': 'Archive'
    },
    'invoice': {
      'status': {
        'opened': 'Opened',
        'payed': 'Payed'
      }
    },
    'nav': {
      'sites': 'Sites'
    },
    'portfolio': {
      'title': 'Portfolio',
      'buttons': {
        'view': 'View'
      },
      'header': {
        'content1': 'We Are A Team Of Developers',
        'content2': 'We Create Websites',
        'content3': 'and mobile apps',
        'share': 'share with friends'
      }
    },
    'task': {
      'buttons': {
        'apply': 'Apply',
        'assign': 'Assign',
        'approve': 'Approve',
        'finish': 'Finish',
        'start': 'Start',
        "close": 'Close'
      },
      'modals': {
        'apply': {
          'header': 'Apply to task',
          'description': 'Please, input data to apply for task...'
        },
        'assign': {
          'header': 'Assign member',
          'description': 'Please, select the member, that you want to assign for this task...'
        },
        'close': {
          'header': 'Close the task',
          'description': 'Please, input data to close the task...',
          'investment': 'Make investment'
        }
      },
      'status': {
        'opened': 'Opened',
        'assigned': 'Assigned',
        'evaluated': 'Evaluated',
        'approved': 'Approved',
        'in_progress': 'In progress',
        'finished': 'Finished',
        'closed': 'Closed',
        'deleted': 'Deleted',
        'archived': 'Archived'
      }
    }
  };
  _exports.default = _default;
});