define("livarava-web/controllers/sites/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      save: function save(model) {
        var _this = this;
        model.save().then(function () {
          _this.transitionToRoute('sites.item.about', model.id);
        });
      }
    }
  });
  _exports.default = _default;
});