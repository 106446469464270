define("livarava-web/components/common/filters/filter-user", ["exports", "@babel/runtime/helpers/esm/typeof"], function (_exports, _typeof2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    // Attributes
    controller: null,
    filter: null,
    value: null,
    type: 'user',
    // States
    isExpanded: false,
    setValue: function setValue(newValue) {
      var _this = this;
      var oldValue = this.value;
      var controller = this.controller;
      var type = this.type;
      var name = Ember.get(this, 'filter.query');
      var options = Ember.get(this, 'filter.options');
      if (typeof newValue === 'string' && !parseInt(newValue) > 0) {
        if (options) {
          options.forEach(function (option) {
            if (option.value === newValue) {
              Ember.set(_this, 'value', option.value);
              Ember.set(_this, 'label', option.label);
              controller.set(name, newValue);
            }
          });
          return;
        }
      }
      if (newValue === null) {
        Ember.set(this, 'value', null);
        Ember.set(this, 'label', null);
        Ember.set(this, 'inputValue', null);
        controller.set(name, null);
        return;
      }
      if (newValue === oldValue) return;
      if ((0, _typeof2.default)(newValue) === 'object') {
        Ember.set(this, 'value', newValue.value);
        Ember.set(this, 'label', newValue.label);
      } else if (parseInt(newValue) > 0) {
        this.store.findRecord(type, parseInt(newValue)).then(function (item) {
          Ember.set(_this, 'value', newValue);
          Ember.set(_this, 'label', item.get('header'));
          Ember.set(_this, 'inputValue', null);
          controller.set(name, newValue);
        });
      }
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var controller = this.controller;
      var name = Ember.get(this, 'filter.query');
      var value = controller.get(name);
      var type = Ember.get(this, 'filter.abstract') || Ember.get(this, 'filter.type');
      Ember.set(this, 'type', type);
      this.setValue(value);
    },
    // Actions
    actions: {
      setFilter: function setFilter(value) {
        this.setValue(value);
        Ember.set(this, 'isExpanded', false);
      },
      collapseFilter: function collapseFilter() {
        Ember.set(this, 'isExpanded', false);
      }
    }
  });
  _exports.default = _default;
});