define("livarava-web/components/input-datetime", ["exports", "moment-timezone"], function (_exports, _momentTimezone) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    me: Ember.inject.service(),
    // Special
    value: null,
    _value: null,
    _mask: null,
    format: 'YYYY-MM-DD HH:mm:ss',
    didReceiveAttrs: function didReceiveAttrs() {
      var format = this.format;
      var value = this.value;
      if (!value) return;
      if (!format) return;
      this.set('_value', _momentTimezone.default.tz(value, 'UTC').format(format));
      this.set('_mask', format.replace(/[a-zA-Z]/g, '9'));
    },
    didChanged: Ember.observer('_value', function () {
      var value = this._value;
      var _moment = _momentTimezone.default.tz(value, 'UTC');
      if (_moment._isValid) {
        this.set('value', _moment);
      }
    }),
    // Actions
    actions: {
      setNow: function setNow() {
        var format = this.format;
        var now = _momentTimezone.default.utc();
        this.set('_value', now.format(format));
        this.set('value', now);
      }
    }
  });
  _exports.default = _default;
});