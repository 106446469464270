define("livarava-web/helpers/full-address", ["exports", "@babel/runtime/helpers/esm/slicedToArray"], function (_exports, _slicedToArray2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Helper.helper(function fullAddress(_ref, _ref2) {
    var _ref3 = (0, _slicedToArray2.default)(_ref, 1),
      item /*, ...rest*/ = _ref3[0];
    var _ref2$showCity = _ref2.showCity,
      showCity = _ref2$showCity === void 0 ? false : _ref2$showCity,
      _ref2$showArea = _ref2.showArea,
      showArea = _ref2$showArea === void 0 ? true : _ref2$showArea,
      _ref2$fallbackToAddre = _ref2.fallbackToAddress,
      fallbackToAddress = _ref2$fallbackToAddre === void 0 ? true : _ref2$fallbackToAddre,
      _ref2$emptyValue = _ref2.emptyValue,
      emptyValue = _ref2$emptyValue === void 0 ? "" : _ref2$emptyValue;
    // check arguments
    if (Ember.isBlank(item)) {
      return emptyValue;
    }
    var parts = [];
    var address_area_level_1 = item.address_area_level_1,
      address_area_level_2 = item.address_area_level_2,
      address_postal_code = item.address_postal_code,
      address = item.address,
      city = item.city,
      address_type = item.address_type,
      address_name = item.address_name,
      address_building = item.address_building,
      address_apartment = item.address_apartment;
    // fallback to deprecated address field
    if (fallbackToAddress && !(address_name || address_building || address_apartment)) {
      return address;
    }
    if (showArea) {
      parts.push(address_area_level_1);
      parts.push(address_area_level_2);
      parts.push(address_postal_code);
    }
    if (showCity && city) {
      parts.push(city);
    }
    if (address_type || address_name) {
      parts.push(address_type + " " + address_name);
    }
    if (address_building) {
      parts.push("буд. " + address_building);
    }
    if (address_apartment) {
      parts.push("кв. " + address_apartment);
    }
    return parts.filter(function (s) {
      return !!s;
    }).join(", ");
  });
  _exports.default = _default;
});