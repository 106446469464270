define("livarava-web/routes/neuronet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Title
    titleToken: 'Neuronet',
    // Model
    model: function model() {
      var store = this.store;
      return store.findRecord('neuron', 4964);
    }
  });
  _exports.default = _default;
});