define("livarava-web/routes/sites/create", ["exports", "moment"], function (_exports, _moment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model() {
      var store = this.store;
      return store.createRecord('site', {
        title: '',
        url: '',
        created: (0, _moment.default)()
      });
    },
    // Actions
    actions: {
      save: function save() {
        var _this = this;
        this.controller.model.save().then(function () {
          return _this.transitionTo('sites.item.about', _this.controller.model.get('id'));
        });
      }
    }
  });
  _exports.default = _default;
});