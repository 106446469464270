define("livarava-web/models/navigation", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    path: _emberData.default.attr('string'),
    language: _emberData.default.attr('string'),
    level: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    order: _emberData.default.attr('number', {
      defaultValue: 0
    }),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    site: _emberData.default.belongsTo('neuron'),
    parent: _emberData.default.belongsTo('neuron'),
    // Computed
    nextLevel: Ember.computed('level', function () {
      return parseInt(this.level) + 1;
    })
  });
  _exports.default = _default;
});