define("livarava-web/templates/components/post-categories-list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "O+TVMMLq",
    "block": "{\"symbols\":[\"item\",\"&default\"],\"statements\":[[6,[37,5],[[35,2,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"row post-categories\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"col-sm-12\"],[12],[2,\"\\n\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"        \"],[1,[30,[36,1],null,[[\"item\",\"onRemoveCategory\"],[[32,1],[35,0]]]]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[\"onRemoveCategory\",\"post-categories-item\",\"items\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/post-categories-list.hbs"
    }
  });
  _exports.default = _default;
});