define("livarava-web/components/main-header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    router: Ember.inject.service(),
    notifications: Ember.inject.service(),
    intl: Ember.inject.service(),
    // Computed
    currentUrl: Ember.computed('', function () {
      return window && window.location ? window.location.href : '/';
    }),
    locale: Ember.computed.reads('intl.primaryLocale'),
    // Actions
    actions: {
      resetMessages: function resetMessages() {
        Ember.set(this, 'notifications.messageCount', null);
      },
      resetNotifications: function resetNotifications() {
        Ember.set(this, 'notifications.notificationCount', null);
      }
    }
  });
  _exports.default = _default;
});