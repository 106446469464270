define("livarava-web/components/input-display-picker", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    value: null,
    label: null,
    icon: null,
    options: _environment.default.displayOptions,
    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;
      var value = this.value;
      var options = this.options;
      options.forEach(function (option) {
        if (option.value === value) {
          Ember.set(_this, 'label', option.label);
          Ember.set(_this, 'icon', option.icon);
        }
      });
    },
    // Actions
    actions: {
      setValue: function setValue(option) {
        Ember.set(this, 'value', option.value);
        Ember.set(this, 'label', option.label);
        Ember.set(this, 'icon', option.icon);
        Ember.set(this, 'showDropdown', false);
      }
    }
  });
  _exports.default = _default;
});