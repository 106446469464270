define("livarava-web/components/property/property-item", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    item: Ember.computed.reads('model.item'),
    orders: null,
    actionItems: null,
    loadingOrders: false,
    loadingActionItems: false,
    // States
    authorModal: false,
    typeOptions: _environment.default.options.property.type,
    memberUsers: Ember.computed('model.propertyusers.@each.user', 'item.author', 'actionItems.@each.customer', function () {
      var author = this.get('item.author');
      var actionItems = this.actionItems;
      var propertyusers = this.get('model.propertyusers');
      var memberUsers = [];
      if (actionItems) {
        memberUsers.pushObjects(actionItems.mapBy('customer'));
      }
      if (author) {
        memberUsers.pushObject(author);
      }
      if (propertyusers) {
        memberUsers.pushObjects(propertyusers.mapBy('user'));
      }
      return memberUsers.uniqBy('id');
    }),
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchOrders();
      this.fetchActions();
      this.fetchTransactions();
      this.fetchPropertyservices();
      this.fetchChildren();
    },
    fetchOrders: function fetchOrders() {
      var _this = this;
      this.set('loadingOrders', true);
      return this.store.query('order', {
        'filter[property]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (orders) {
        _this.set('orders', orders);
        _this.set('loadingOrders', false);
      }).catch(function () {
        _this.set('loadingOrders', false);
      });
    },
    fetchActions: function fetchActions() {
      var _this2 = this;
      this.set('loadingActionItems', true);
      return this.store.query('action', {
        'filter[property]': this.get('item.id'),
        per_page: 100,
        page: 1,
        sort: '-updated'
      }).then(function (actionItems) {
        _this2.set('actionItems', actionItems);
        _this2.set('loadingActionItems', false);
      }).catch(function () {
        _this2.set('loadingActionItems', false);
      });
    },
    fetchTransactions: function fetchTransactions() {
      var _this3 = this;
      this.set('loadingTransactions', true);
      return this.store.query('transaction', {
        'filter[customer]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (transactions) {
        _this3.set('transactions', transactions);
        _this3.set('loadingTransactions', false);
      }).catch(function () {
        _this3.set('loadingTransactions', false);
      });
    },
    fetchChildren: function fetchChildren() {
      var _this4 = this;
      this.set('loadingChildren', true);
      return this.store.query('property', {
        'filter[parent]': this.get('item.id'),
        per_page: 100,
        page: 1
      }).then(function (children) {
        _this4.set('children', children);
        _this4.set('loadingChildren', false);
      }).catch(function () {
        _this4.set('loadingChildren', false);
      });
    },
    fetchPropertyservices: function fetchPropertyservices() {
      var _this5 = this;
      this.set('loadingPropertyservices', true);
      return this.store.query('propertyservice', {
        page: 1,
        per_page: 100,
        'filter[property]': this.get('item.id'),
        sort: '-title'
      }).then(function (propertyservices) {
        return Promise.all(propertyservices.mapBy('service')).then(function () {
          return propertyservices;
        });
      }).then(function (propertyservices) {
        _this5.set('propertyservices', propertyservices);
        _this5.set('loadingPropertyservices', false);
      }).catch(function () {
        _this5.set('loadingPropertyservices', false);
      });
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      setRelationship: function setRelationship(relationship, model) {
        var item = this.item;
        if (!relationship) {
          return;
        }
        if (!model) {
          item.set(relationship, null);
          return;
        }
        this.set("".concat(relationship, "Modal"), false);
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set(relationship, model);
        return item.save();
      },
      setPropertyType: function setPropertyType(property_type) {
        var item = this.item;
        if (!property_type || property_type == item.get('property_type')) {
          return;
        }
        var result = confirm(this.intl.t('modal.confirm'));
        if (!result) return;
        item.set('property_type', property_type);
        return item.save();
      },
      onAddPropertyUser: function onAddPropertyUser(user, role) {
        var _this6 = this;
        var propertyuser = this.store.createRecord('propertyuser', {
          role: role,
          property: this.item,
          user: user
        });
        return propertyuser.save().then(function () {
          _this6.onChange();
        });
      },
      onSavePropertyUser: function onSavePropertyUser(propertyuser, role) {
        propertyuser.set('role', role);
        return propertyuser.save();
      },
      onRemovePropertyUser: function onRemovePropertyUser(propertyuser) {
        return propertyuser.destroyRecord();
      },
      handleActionAdded: function handleActionAdded() {
        this.fetchActions();
      },
      handleTransactionAdded: function handleTransactionAdded() {
        this.fetchTransactions();
      },
      handleServiceAdded: function handleServiceAdded() {
        this.fetchPropertyservices();
      }
    }
  });
  _exports.default = _default;
});