define("livarava-web/components/site/site-posts", ["exports", "livarava-web/config/environment", "livarava-web/utils/get-language-label"], function (_exports, _environment, _getLanguageLabel) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Variables
    languages: _environment.default.intl.localeLabels,
    language: null,
    languageLabel: null,
    // Functions
    onSetFilter: null,
    // Init
    init: function init() {
      this._super.apply(this, arguments);
      var language = this.selectedLanguage;
      var label = (0, _getLanguageLabel.default)(language);
      this.set('selectedLanguageLabel', label);
    },
    // Actions
    actions: {
      setFilter: function setFilter(name, value) {
        this.onSetFilter(name, value);
        if (name === 'language') {
          var label = (0, _getLanguageLabel.default)(value);
          this.set('selectedLanguage', value);
          this.set('selectedLanguageLabel', label);
        }
      }
    }
  });
  _exports.default = _default;
});