define("livarava-web/controllers/sites/item/categories/create", ["exports", "fetch", "livarava-web/utils/get-api-url"], function (_exports, _fetch, _getApiUrl) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Actions
    actions: {
      add: function add(category, language) {
        var _this = this;
        var site_id = parseInt(Ember.get(this, 'model.id'));
        var category_id = category.id;
        var url = (0, _getApiUrl.default)('add-site-category', {
          site_id: site_id,
          category_id: category_id,
          language: language
        });
        var token = Ember.get(this, 'me.token');
        (0, _fetch.default)(url, {
          headers: {
            'X-LivaRava-Token': token
          }
        }).then(function (response) {
          if (response.ok) {
            return response.json();
          } else {
            throw new Error();
          }
        }).then(function () {
          _this.transitionToRoute('sites.item.categories', site_id);
        });
      }
    }
  });
  _exports.default = _default;
});