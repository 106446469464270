define("livarava-web/locations/history-locations", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HistoryLocation.extend({
    formatURL: function formatURL() {
      var url = this._super.apply(this, arguments);
      if (url.includes('#')) {
        return url.replace(/([^/])#(.*)/, '$1/#$2');
      } else {
        return url.replace(/\/?$/, '/');
      }
    }
  });
  _exports.default = _default;
});