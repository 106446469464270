define("livarava-web/components/files/gallery", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // args
    display: 'public',
    item: null,
    readonly: false,
    onFetch: function onFetch() {},
    // data
    axons: null,
    isLoading: false,
    // Permissions
    canEdit: Ember.computed('item.author.id', 'me.id', 'readonly', function () {
      return !this.get('readonly') && this.get('item.author.id') == this.get('me.id');
    }),
    fetchPhotos: function fetchPhotos() {
      var _this = this;
      this.setProperties({
        isLoading: true
      });
      return this.store.query('axon', {
        neuron: this.get('item.id'),
        author: 'all',
        'filter[type]': 'image',
        sort: 'created,-weight,header',
        page: 1,
        per_page: 100
      }).then(function (axons) {
        _this.setProperties({
          axons: axons
        });
        _this.onFetch(axons);
      }).finally(function () {
        return _this.setProperties({
          isLoading: false
        });
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.fetchPhotos();
    },
    actions: {
      handleChange: function handleChange() {
        return this.fetchPhotos();
      },
      remove: function remove(axon) {
        return axon.destroyRecord();
      }
    }
  });
  _exports.default = _default;
});