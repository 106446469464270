define("livarava-web/routes/axons/item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model(params) {
      var _this = this;
      var store = this.store;
      return store.findRecord('axon', params.id).then(function (item) {
        var title = Ember.get(item, 'title');
        Ember.set(_this, 'titleToken', title);
        return item;
      }).catch(function (response) {
        if (response.errors[0].status === '404') {
          _this.transitionTo('notfound');
        } else if (response.errors[0].status === '403') {
          _this.transitionTo('forbidden');
        }
      });
    }
  });
  _exports.default = _default;
});