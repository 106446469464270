define("livarava-web/routes/neuron/item/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Model
    model: function model(params) {
      var store = this.store;
      var neuronId = parseInt(this.paramsFor('neuron.item').id);
      return Ember.RSVP.hash({
        neuron: store.findRecord('neuron', neuronId),
        axons: store.query('axon', {
          display: 'public',
          neuron: neuronId,
          per_page: 1000,
          page: 1,
          sort: '-axon_count,-created,header'
        }),
        comments: store.query('comment', {
          neuron: neuronId,
          per_page: 1000,
          page: 1,
          sort: '-created'
        })
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});