define("livarava-web/utils/get-article", ["exports", "jquery", "livarava-web/config/environment"], function (_exports, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = getArticle;
  var forbidden = 'script, style, iframe, frame, meta, link, head';
  function parse(content) {
    var selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'body';
    var without = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    var $html, result;
    $html = (0, _jquery.default)(content).not(forbidden);
    if (without) {
      $html = $html.not(without);
      $html.filter(without).remove();
      $html.find(without).remove();
    }
    if (selector) {
      $html = $html.find(selector);
    }
    result = $html.html();
    return result;
  }
  function getArticle() {
    var data = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
    var selector = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
    var without = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    if (_environment.default.environment === 'development') {
      data.key = 'Q0eQWbEUJRerVFqEURGxz8pO8F2yc2sMO-Z1qUu0'; // Add an access token param.
    }

    return new Ember.RSVP.Promise(function (resolve, reject) {
      return _jquery.default.ajax({
        url: "".concat(_environment.default.api.host, "/api/v2/neuroner.article"),
        data: data,
        crossDomain: true
      }).done(function (data) {
        try {
          var result = parse(data, selector, without);
          return resolve(result);
        } catch (e) {
          reject(e);
        }
      }).fail(function (error) {
        return reject(error);
      });
    });
  }
});