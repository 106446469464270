define("livarava-web/routes/services/admin", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Title
    titleToken: 'Services Admin',
    // Model
    model: function model() {
      return Ember.RSVP.hash({
        parents: [{
          id: '147859',
          params: {
            q: 'водо',
            'filter[parent]': 'null'
          }
        }, {
          id: '147859',
          params: {
            q: 'сан',
            'filter[parent]': 'null'
          }
        }, {
          id: '148304',
          params: {
            q: 'електр',
            'filter[parent]': 'null'
          }
        }, {
          id: '156908',
          params: {
            q: 'газ',
            'filter[parent]': 'null'
          }
        }, {
          id: '148851',
          params: {
            q: 'ремонт',
            'filter[parent]': 'null'
          }
        }, {
          id: '161519',
          params: {
            q: 'прибир',
            'filter[parent]': 'null'
          }
        }, {
          id: '148038',
          params: {
            q: 'двер',
            'filter[parent]': 'null'
          }
        }, {
          id: '147880',
          params: {
            'filter[parent]': 'null'
          }
        }]
      });
    },
    // Actions
    actions: {
      reload: function reload() {
        this.refresh();
      }
    }
  });
  _exports.default = _default;
});