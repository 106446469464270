define("livarava-web/components/feed-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    tagName: 'fieldset',
    classNames: "form-group row",
    url: null,
    rule: null,
    selectors: null,
    selector: null,
    pathIndex: -1,
    // Flags
    showWithout: true,
    // Actions
    actions: {
      decreasePath: function decreasePath() {
        var url = this.url;
        var pathIndex = this.pathIndex;
        var newIndex = -1;
        var parser = document.createElement('a');
        parser.href = url;
        var parsedUrl = "".concat(parser.protocol, "//").concat(parser.hostname);
        var pathes = parser.pathname.split('/');
        if (pathIndex === -1) {
          pathIndex = pathes.length - 1;
        }
        if (pathIndex > 1) {
          newIndex = pathIndex - 1;
        } else {
          newIndex = 0;
        }
        for (var i = 0; i <= newIndex; i++) {
          parsedUrl += pathes[i] + "/";
        }
        this.set('pathIndex', newIndex);
        this.set('rule.url', parsedUrl);
      },
      increasePath: function increasePath() {
        var url = this.url;
        var pathIndex = this.pathIndex;
        var newIndex = -1;
        var parser = document.createElement('a');
        parser.href = url;
        var parsedUrl = "".concat(parser.protocol, "//").concat(parser.hostname);
        var pathes = parser.pathname.split('/');
        if (pathIndex === -1) {
          pathIndex = pathes.length - 1;
        }
        if (pathIndex < pathes.length) {
          newIndex = pathIndex + 1;
        } else {
          newIndex = pathes.length - 1;
        }
        for (var i = 0; i <= newIndex; i++) {
          parsedUrl += pathes[i] + "/";
        }
        this.set('pathIndex', newIndex);
        this.set('rule.url', parsedUrl);
      },
      prevSelector: function prevSelector() {
        var selectors = this.selectors;
        var selector = this.selector;
        var selectorIndex = -1;
        var newIndex = -1;
        for (var i = 0; i < selectors.length; i++) {
          if (selectors[i] === selector) {
            selectorIndex = i;
          }
        }
        if (selectorIndex > 0) {
          newIndex = selectorIndex - 1;
        } else {
          newIndex = selectors.length - 1;
        }
        var newSelector = selectors[newIndex];
        this.set('selector', newSelector);
        this.set('rule.selector', newSelector);
      },
      nextSelector: function nextSelector() {
        var selectors = this.selectors;
        var selector = this.selector;
        var selectorIndex = -1;
        var newIndex = -1;
        for (var i = 0; i < selectors.length; i++) {
          if (selectors[i] === selector) {
            selectorIndex = i;
          }
        }
        if (selectorIndex < selectors.length - 1) {
          newIndex = selectorIndex + 1;
        } else {
          newIndex = 0;
        }
        var newSelector = selectors[newIndex];
        this.set('selector', newSelector);
        this.set('rule.selector', newSelector);
      },
      setSelector: function setSelector(s) {
        var selectors = this.selectors;
        var selector = this.selector;
        var selectorIndex = -1;
        var newIndex = -1;
        for (var i = 0; i < selectors.length; i++) {
          if (selectors[i] === s) {
            newIndex = i;
          }
        }
        var newSelector = selectors[newIndex];
        this.set('selector', newSelector);
        this.set('rule.selector', newSelector);
      },
      remove: function remove(item) {
        this.sendAction('deleteRule', item);
      }
    }
  });
  _exports.default = _default;
});