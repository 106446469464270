define("livarava-web/components/offer/offer-status", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Computed
    isSelected: Ember.computed("selected", "offer", function () {
      var status = Ember.get(this, "offer.order.status");
      var selectedId = parseInt(Ember.get(this, "selected.id"));
      var offerId = parseInt(Ember.get(this, "offer.id"));
      var isStatusOk = ["accepted", "finished"].indexOf(status) > -1;
      var isMatchedIds = selectedId === offerId;
      return isStatusOk && isMatchedIds;
    })
  });
  _exports.default = _default;
});