define("livarava-web/templates/components/neuroner/feed-links", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "pnFBJxti",
    "block": "{\"symbols\":[\"link\",\"&default\"],\"statements\":[[6,[37,7],[[35,4]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"table\"],[14,0,\"table table-striped\"],[12],[2,\"\\n    \"],[10,\"tbody\"],[12],[2,\"\\n\"],[6,[37,6],[[30,[36,5],[[30,[36,5],[[35,4]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"tr\"],[12],[2,\"\\n        \"],[10,\"td\"],[12],[2,\"\\n          \"],[1,[30,[36,3],null,[[\"url\",\"onAddRule\"],[[32,1],[35,2]]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[6,[37,1],[[35,0]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-info\"],[12],[2,\"\\n      Loading...\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"    \"],[10,\"div\"],[14,0,\"alert alert-danger\"],[12],[2,\"\\n      \"],[1,[34,0]],[2,\"\\n    \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[]}]]],[2,\"\\n\"],[18,2,null]],\"hasEval\":false,\"upvars\":[\"error\",\"unless\",\"onAddRule\",\"neuroner/feed-item\",\"links\",\"-track-array\",\"each\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuroner/feed-links.hbs"
    }
  });
  _exports.default = _default;
});