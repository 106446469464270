define("livarava-web/routes/sites/item/pages/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Model
    model: function model(params) {
      var store = this.store;
      var page_id = parseInt(params.page_id);
      return store.findRecord('page', page_id);
    },
    afterModel: function afterModel(model) {
      Ember.set(this, 'titleToken', Ember.get(model, 'title'));
    },
    // Actions
    actions: {
      save: function save(item) {
        item.save();
      },
      delete: function _delete(item) {
        var _this = this;
        if (!item) return;
        if (confirm("Are you sure?")) {
          var id = Ember.get(item, 'site.id');
          item.destroyRecord().then(function () {
            return _this.transitionTo('sites.item.pages', id);
          });
        }
      }
    }
  });
  _exports.default = _default;
});