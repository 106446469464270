define("livarava-web/routes/sites/item/posts/index", ["exports", "livarava-web/utils/get-filters-from-params"], function (_exports, _getFiltersFromParams) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Params
    queryParams: {
      page: {
        refreshModel: true
      },
      q: {
        refreshModel: true
      },
      lira: {
        refreshModel: true
      },
      language: {
        refreshModel: true
      },
      date_start: {
        refreshModel: true
      },
      date_end: {
        refreshModel: true
      },
      display: {
        refreshModel: true
      },
      featured: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var site_id = this.paramsFor('sites.item').site_id;
      params['site'] = site_id;
      return Ember.RSVP.hash({
        site_id: site_id,
        site: this.store.peekRecord('site', site_id),
        items: this.store.query('post', (0, _getFiltersFromParams.default)(params))
      });
    },
    // Actions
    actions: {
      refresh: function refresh() {
        this.reload();
      },
      delete: function _delete(item) {
        var _this = this;
        if (confirm("Are you sure?")) {
          var site_id = item.get('site.id');
          item.destroyRecord().then(function () {
            return _this.refresh();
          });
        }
      }
    }
  });
  _exports.default = _default;
});