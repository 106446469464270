define("livarava-web/templates/components/neuron-list-comments", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "E4YzW27o",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,5],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"media neuron-comments\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"pull-left\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,1,[\"author\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"        \"],[10,\"div\"],[14,0,\"media-object comment-avatar\"],[15,5,[30,[36,2],[\"background-image: url(\",[35,1,[\"author\",\"image_url\"]],\")\"],null]],[12],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"    \"],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"block-comment\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[12],[2,\"\\n\"],[6,[37,3],null,[[\"route\",\"model\"],[\"neuron.item.overview\",[35,1,[\"author\",\"id\"]]]],[[\"default\"],[{\"statements\":[[2,\"          \"],[10,\"strong\"],[14,0,\"text-primary\"],[12],[1,[35,1,[\"author\",\"fullname\"]]],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"        \"],[10,\"span\"],[14,0,\"text-muted\"],[12],[2,\"Added:\"],[13],[2,\" \"],[1,[30,[36,4],[[35,1,[\"created\"]]],null]],[2,\"\\n\"],[6,[37,5],[[35,1,[\"canDelete\"]]],null,[[\"default\"],[{\"statements\":[[2,\"          \"],[11,\"span\"],[24,0,\"text-danger\"],[24,\"role\",\"button\"],[4,[38,0],[[32,0],\"deleteComment\"],null],[12],[2,\"Delete\"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[2,\"      \"],[13],[2,\"\\n\\n      \"],[10,\"blockquote\"],[14,0,\"blockquote\"],[12],[2,\"\\n        \"],[10,\"p\"],[14,0,\"m-b-0 text-muted\"],[12],[2,\"\\n          \"],[1,[30,[36,6],null,[[\"markdown\",\"strikethrough\",\"literalMidWordUnderscores\",\"simplifiedAutoLink\",\"openLinksInNewWindow\"],[[35,1,[\"text\"]],true,true,true,true]]]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"action\",\"comment\",\"html-safe\",\"link-to\",\"moment-from-now\",\"if\",\"markdown-to-html\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/neuron-list-comments.hbs"
    }
  });
  _exports.default = _default;
});