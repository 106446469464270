define("livarava-web/controllers/support/tickets/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['page', 'status'],
    page: 1,
    status: 'opened'
  });
  _exports.default = _default;
});