define("livarava-web/templates/components/common/common-loading", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "vwGttbhM",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"loading-page text-center\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"mt-5 mb-5\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,0,\"img img-fluid\"],[14,\"width\",\"150\"],[14,\"height\",\"129\"],[14,\"alt\",\"Loading image\"],[14,\"src\",\"/img/loaders/upgrading.gif\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"p\"],[14,0,\"block error-description\"],[12],[2,\"LivaRava is loading...\"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/common/common-loading.hbs"
    }
  });
  _exports.default = _default;
});