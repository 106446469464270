define("livarava-web/controllers/support/index", ["exports", "livarava-web/config/environment", "jquery"], function (_exports, _environment, _jquery) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Config
    sourceURL: "".concat(_environment.default.api.host, "/api/v2/autocomplete"),
    type: 'post',
    timer: null,
    items: null,
    // Params
    queryParams: ['page', 'q'],
    q: null,
    page: 1,
    per_page: 10,
    // Functions
    reset: function reset() {
      this.set('items', null);
    },
    search: function search(q) {
      var _this = this;
      var value = q;
      var limit = 10;
      var min = 3;
      var type = this.type;
      var timer = this.timer;
      clearTimeout(timer);
      timer = setTimeout(function () {
        _this.reset();
        if (value === null) {
          return;
        }
        if (value === "") {
          return;
        }
        if (value && value.length < min) {
          return;
        }

        // console.log(value);

        _jquery.default.get(_this.sourceURL, {
          q: value,
          type: type,
          limit: limit,
          lira: '0,4964'
        }).done(function (data) {
          return _this.set('items', data.data);
        }).fail(function () {
          return _this.set('items', []);
        });
      }, 500);
      this.set('timer', timer);
    },
    // Actions
    actions: {
      onInput: function onInput() {
        this.search(this.q);
      },
      onFocusIn: function onFocusIn() {
        this.search(this.q);
      }
    }
  });
  _exports.default = _default;
});