define("livarava-web/routes/signup", ["exports", "ember-local-storage"], function (_exports, _emberLocalStorage) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Storage
    session: (0, _emberLocalStorage.storageFor)('session'),
    // Params
    queryParams: {
      invitation: {
        refreshModel: true
      },
      site: {
        refreshModel: true
      },
      next: {
        refreshModel: true
      }
    },
    // Model
    model: function model(params) {
      var isSigned = Ember.get(this, 'session.signed');
      if (isSigned) {
        this.transitionTo('index');
      }
      var store = this.store;
      var invitationId = parseInt(params.invitation);
      var invitation = invitationId > 0 ? store.findRecord('invitation', invitationId) : null;
      var siteId = parseInt(params.site);
      var nextUrl = params.next;
      return Ember.RSVP.hash({
        invitation: invitation,
        site: siteId,
        next: nextUrl
      });
    }
  });
  _exports.default = _default;
});