define("livarava-web/components/input-image-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    imageStyle: Ember.computed('item.title', function () {
      var url = Ember.get(this, 'item.title');
      if (url === undefined) return '';
      return Ember.String.htmlSafe("background-image: url('".concat(url, "');"));
    })
  });
  _exports.default = _default;
});