define("livarava-web/components/entity/entity-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    intl: Ember.inject.service(),
    // Actions
    actions: {
      save: function save() {
        this.save(this.item);
      },
      delete: function _delete() {
        this["delete"](this.item);
      },
      addKeyword: function addKeyword(keyword) {
        if (!Array.isArray(this.get("item.keywords"))) {
          this.item.set('keywords', []);
        }
        this.get("item.keywords").pushObject(keyword);
      },
      removeKeywordAtIndex: function removeKeywordAtIndex(index) {
        this.get("item.keywords").removeAt(index);
      }
    }
  });
  _exports.default = _default;
});