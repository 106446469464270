define("livarava-web/templates/components/files/logo-uploader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "mjzDb28d",
    "block": "{\"symbols\":[\"error\",\"queue\"],\"statements\":[[6,[37,5],[[35,4,[\"logo_url\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"  \"],[10,\"img\"],[15,\"src\",[31,[[34,4,[\"logo_url\"]]]]],[14,\"alt\",\"Логотип\"],[14,0,\"img-thumbnail img-fluid\"],[12],[13],[2,\"\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"image mb-1\"],[15,\"title\",[31,[[34,3,[\"title\"]]]]],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"ho ho-portfolio fa-4x text-muted\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[8,\"file-upload\",[[24,0,\"mb-0\"]],[[\"@name\",\"@name\",\"@accept\",\"@multiple\",\"@onfileadd\"],[\"files\",\"photos\",[34,6],false,[30,[36,7],[[32,0],\"upload\"],null]]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"span\"],[14,0,\"btn btn-link btn-sm\"],[12],[2,\"\\n\"],[6,[37,5],[[32,2,[\"files\",\"length\"]]],null,[[\"default\",\"else\"],[{\"statements\":[[2,\"      Завантаження...\\n\"]],\"parameters\":[]},{\"statements\":[[2,\"      \"],[10,\"i\"],[14,0,\"fa fa-pencil\"],[14,\"aria-hidden\",\"true\"],[12],[13],[2,\"\\n      Змінити логотип\\n\"]],\"parameters\":[]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[2]}]]],[2,\"\\n\"],[6,[37,5],[[35,0,[\"length\"]]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"text-danger small\"],[14,\"role\",\"alert\"],[12],[2,\"\\n    Помилка при завантаженні файлy\\n\"],[6,[37,2],[[30,[36,1],[[30,[36,1],[[35,0]],null]],null]],null,[[\"default\"],[{\"statements\":[[2,\"      \"],[10,\"br\"],[12],[13],[1,[32,1]],[2,\"\\n\"]],\"parameters\":[1]}]]],[2,\"  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"errors\",\"-track-array\",\"each\",\"item\",\"liva\",\"if\",\"accept\",\"action\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/files/logo-uploader.hbs"
    }
  });
  _exports.default = _default;
});