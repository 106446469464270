define("livarava-web/components/motivation/services-of-activated-entities", ["exports", "@babel/runtime/helpers/esm/objectSpread2", "lodash/flatten", "lodash/groupBy", "lodash/uniqWith", "lodash/toPairs"], function (_exports, _objectSpread2, _flatten2, _groupBy2, _uniqWith2, _toPairs2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Services
    store: Ember.inject.service(),
    loading: Ember.inject.service(),
    // other
    items: null,
    totals: Ember.computed('items', function () {
      if (!this.get('items.length')) {
        return;
      }
      return this.items;
    }),
    fetchData: function fetchData() {
      var _this = this;
      var store = this.store;
      var requestParams = {
        per_page: 1000,
        page: 1
      };
      return store.query('entity', (0, _objectSpread2.default)({
        'filter[is_active]': true
      }, requestParams)).then(function (entities) {
        return Promise.all(entities.map(function (entity) {
          return store.query('entityservice', (0, _objectSpread2.default)({
            'filter[entity]': entity.get('id')
          }, requestParams));
        }));
      }).then(function (arrayOfArrays) {
        var flat = (0, _flatten2.default)(arrayOfArrays.map(function (a) {
          return a.toArray();
        }));
        var entityservices = (0, _uniqWith2.default)(flat, function (a, b) {
          return a.get('service.id') == b.get('service.id') && a.get('entity.id') == b.get('entity.id');
        });
        return entityservices;
      }).then(function (entityservices) {
        var grouped = (0, _groupBy2.default)(entityservices, function (es) {
          return es.get('service.id');
        });
        var pairs = (0, _toPairs2.default)(grouped);
        var mapped = pairs.map(function (pair) {
          return {
            service: pair[1][0].get('service'),
            entityservices: pair[1]
          };
        });
        return mapped;
      }).then(function (items) {
        return _this.set('items', items);
      });
    },
    init: function init() {
      this._super.apply(this, arguments);
      Ember.run.next(this, function () {
        var _this2 = this;
        this.loading.run(function () {
          return _this2.fetchData();
        });
      });
    },
    // Actions
    actions: {}
  });
  _exports.default = _default;
});