define("livarava-web/components/neuron/neuron-axon-random", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    xaxon: null,
    xneuron: null,
    // Flags
    isLoading: Ember.computed('xaxon', 'xneuron', function () {
      var xaxon = this.xaxon;
      var xneuron = this.xneuron;
      return !(xaxon && xneuron);
    }),
    // Computed
    imageUrl: Ember.computed('xneuron.image_url', 'xneuron.icon_url', function () {
      var imageUrl = this.get('xneuron.image_url');
      var outUrl = this.get('xneuron.icon_url');
      if (imageUrl) outUrl = imageUrl;
      return outUrl;
    })
  });
  _exports.default = _default;
});