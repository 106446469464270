define("livarava-web/templates/neuron/item/overview", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "yvpA2xGN",
    "block": "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-8 mb-5\"],[12],[2,\"\\n    \"],[10,\"h3\"],[12],[2,\"Overview\"],[13],[2,\"\\n\\n    \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n    \"],[1,[30,[36,1],null,[[\"item\"],[[35,0,[\"neuron\"]]]]]],[2,\"\\n\\n    \"],[1,[30,[36,3],[[30,[36,2],null,null]],null]],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"col-12 col-md-4 mb-5\"],[12],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Promoted\"],[13],[2,\"\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[10,\"div\"],[14,0,\"sticky-top neuron-adsense\"],[12],[2,\"\\n        \"],[1,[30,[36,4],null,[[\"name\"],[\"xxxxxx\"]]]],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"mb-5\"],[12],[2,\"\\n      \"],[10,\"h3\"],[12],[2,\"Learn more\"],[13],[2,\"\\n      \"],[10,\"hr\"],[12],[13],[2,\"\\n      \"],[1,[30,[36,5],null,[[\"neuron\"],[[35,0,[\"neuron\"]]]]]],[2,\"\\n    \"],[13],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"model\",\"neuron/neuron-overview\",\"-outlet\",\"component\",\"adsense/neuron-aside\",\"neuron/neuron-axons-random\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuron/item/overview.hbs"
    }
  });
  _exports.default = _default;
});