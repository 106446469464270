define("livarava-web/components/comment/comment-list-item", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Attributes
    item: null,
    // Functions
    onDeleteComment: null,
    onSaveComment: null,
    // States
    isEditMode: false,
    // Security
    canEdit: Ember.computed('item.author.id', 'me.id', function () {
      return parseInt(Ember.get(this, 'item.author.id')) === parseInt(Ember.get(this, 'me.id'));
    }),
    canDelete: Ember.computed('item.author.id', 'me.id', function () {
      return parseInt(Ember.get(this, 'item.author.id')) === parseInt(Ember.get(this, 'me.id'));
    }),
    // Functions
    save: function save() {
      var _this = this;
      var item = this.item;
      Ember.set(this, 'isSaving', true);
      item.save().then(function () {
        Ember.set(_this, 'isSaving', false);
        Ember.set(_this, 'isEditMode', false);
      });
    },
    // Actions
    actions: {
      closeDropdownMenu: function closeDropdownMenu() {
        Ember.set(this, 'showDropdownMenu', false);
      },
      editComment: function editComment() {
        Ember.set(this, 'showDropdownMenu', false);
        Ember.set(this, 'isEditMode', true);
      },
      deleteComment: function deleteComment(item) {
        Ember.set(this, 'showDropdownMenu', false);
        this.onDeleteComment(item);
      },
      saveComment: function saveComment() {
        this.save();
      },
      onKeyUp: function onKeyUp(text, event) {
        if (event.ctrlKey && event.key === 'Enter') {
          this.save();
        }
      }
    }
  });
  _exports.default = _default;
});