define("livarava-web/templates/components/object-bin", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "63XSjbvk",
    "block": "{\"symbols\":[\"obj\",\"&default\"],\"statements\":[[6,[37,6],null,[[\"action\"],[[30,[36,5],[[32,0],\"handleObjectDropped\"],null]]],[[\"default\"],[{\"statements\":[[2,\"  \"],[10,\"div\"],[14,0,\"object-bin-title\"],[12],[1,[34,1]],[13],[2,\"\\n  \"],[10,\"br\"],[12],[13],[2,\"\\n\"],[6,[37,4],[[30,[36,3],[[30,[36,3],[[35,2]],null]],null]],null,[[\"default\"],[{\"statements\":[[6,[37,0],null,[[\"action\",\"content\"],[\"handleObjectDragged\",[32,1]]],[[\"default\"],[{\"statements\":[[2,\"      \"],[18,2,[[32,1]]],[2,\"\\n\"]],\"parameters\":[]}]]]],\"parameters\":[1]}]]]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"draggable-object\",\"name\",\"model\",\"-track-array\",\"each\",\"action\",\"draggable-object-target\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/object-bin.hbs"
    }
  });
  _exports.default = _default;
});