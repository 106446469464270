define("livarava-web/controllers/payouts/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    // Params
    queryParams: ['page', 'per_page', 'q', 'currency', 'project', 'product', 'user', 'date_start', 'date_end'],
    // Params
    page: 1,
    q: null,
    currency: null,
    project: null,
    product: null,
    user: null,
    date_start: null,
    date_end: null,
    // Filters
    filters: Ember.A([{
      name: 'q',
      query: 'q',
      type: 'search',
      placeholder: 'Search...'
    }, {
      name: 'project',
      query: 'project',
      type: 'neuron',
      abstract: 'project',
      placeholder: 'Project'
    }, {
      name: 'product',
      query: 'product',
      type: 'neuron',
      abstract: 'product',
      placeholder: 'Product'
    }, {
      name: 'user',
      query: 'user',
      type: 'user',
      abstract: 'user',
      placeholder: 'User'
    }, {
      name: 'currency',
      query: 'currency',
      type: 'select',
      placeholder: 'Currency',
      options: [{
        value: 'USD',
        label: 'USD',
        icon: 'fa fa-usd'
      }, {
        value: 'EUR',
        label: 'EUR',
        icon: 'fa fa-eur'
      }, {
        value: 'UAH',
        label: 'UAH',
        sign: '₴'
      }, {
        value: 'RUB',
        label: 'RUB',
        icon: 'fa fa-rub'
      }]
    }, {
      name: 'created',
      query: 'created',
      type: 'daterange',
      placeholder: 'Created'
    }]),
    // States
    isFiltersExpanded: false,
    isFiltersSetted: Ember.computed('q', 'project', 'product', 'currency', function () {
      var _this = this;
      var filters = this.filters;
      var filtersSetted = 0;
      filters.forEach(function (filter) {
        var value = _this.get(filter);
        if (value !== null) {
          filtersSetted += 1;
        }
      });
      return filtersSetted > 0;
    }),
    // Actions
    actions: {
      resetFilters: function resetFilters() {
        var _this2 = this;
        var filters = this.filters;
        filters.forEach(function (filter) {
          _this2.set(filter, null);
        });
      },
      onSearchKeyUp: function onSearchKeyUp(v, e) {
        if (e.key === 'Enter') {
          this.send('search', v);
        }
      },
      search: function search(q) {
        this.transitionToRoute('payouts', {
          queryParams: {
            q: q,
            page: 1
          }
        });
      },
      open: function open(id) {
        if (parseInt(id) < 1) {
          return;
        }
        this.transitionToRoute('payouts.item', id);
      }
    }
  });
  _exports.default = _default;
});