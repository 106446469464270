define("livarava-web/routes/settings", ["exports", "livarava-web/mixins/authenticated-route-mixin"], function (_exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    // Services
    store: Ember.inject.service(),
    me: Ember.inject.service(),
    // Title
    titleToken: 'Settings',
    // Model
    model: function model() {
      var store = this.store;
      var meId = parseInt(Ember.get(this, 'me.id'));
      return store.findRecord('user', meId);
    }
  });
  _exports.default = _default;
});