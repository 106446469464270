define("livarava-web/components/payout/payout-form", ["exports", "livarava-web/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Component.extend({
    // Properties
    currencyOptions: _environment.default.currencyOptions,
    // Actions
    actions: {
      save: function save() {
        this.onSave(this.model);
      },
      delete: function _delete() {
        this.onDelete(this.model);
      },
      setProject: function setProject(item) {
        var _this = this;
        if (item === null) {
          this.set('model.project', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          return _this.model.set('project', item);
        });
      },
      setProduct: function setProduct(item) {
        var _this2 = this;
        if (item === null) {
          this.set('model.product', null);
          return;
        }
        this.store.findRecord('neuron', item.id).then(function (item) {
          return _this2.model.set('product', item);
        });
      },
      setUser: function setUser(item) {
        var _this3 = this;
        if (item === null) {
          this.set('model.user', null);
          return;
        }
        this.store.findRecord('user', item.id).then(function (item) {
          return _this3.model.set('user', item);
        });
      }
    }
  });
  _exports.default = _default;
});