define("livarava-web/templates/components/right-panel", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "xROjuQbr",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[6,[37,2],[[35,1]],null,[[\"default\"],[{\"statements\":[[2,\"  \"],[11,\"a\"],[24,0,\"btn btn-lg btn-link right-panel--toggle-button\"],[24,\"title\",\"Show menu\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"toggle\"],null],[12],[2,\"\\n    \"],[10,\"i\"],[14,0,\"fa fa-bars\"],[12],[13],[2,\"\\n  \"],[13],[2,\"\\n\"]],\"parameters\":[]}]]],[10,\"div\"],[15,0,[31,[\"modal-content right-panel m-a-0 \",[30,[36,3],[[35,1],\"right-panel--visible\"],null]]]],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-header\"],[12],[2,\"\\n    \"],[11,\"button\"],[24,0,\"close\"],[24,4,\"button\"],[4,[38,0],[[32,0],\"toggle\"],null],[12],[2,\"\\n      \"],[10,\"span\"],[14,\"aria-hidden\",\"true\"],[12],[2,\"×\"],[13],[2,\"\\n    \"],[13],[2,\"\\n    \"],[10,\"h4\"],[14,0,\"modal-title\"],[12],[10,\"i\"],[14,0,\"fa fa-bars\"],[12],[13],[2,\" Menu\"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-body\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"clearfix\"],[12],[2,\"\\n      \"],[18,1,null],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"modal-footer\"],[12],[2,\"\\n\\n  \"],[13],[2,\"\\n\\n\"],[13],[3,\" /.modal-content \"],[2,\"\\n\\n\\n\"]],\"hasEval\":false,\"upvars\":[\"action\",\"visible\",\"unless\",\"if\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/components/right-panel.hbs"
    }
  });
  _exports.default = _default;
});