define("livarava-web/controllers/sites/item/events/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Controller.extend({
    queryParams: ['copy'],
    copy: null
  });
  _exports.default = _default;
});