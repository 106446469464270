define("livarava-web/templates/neuronet", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "kiHShY4R",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,[[\"class\"],[\"common-dark\"]],[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[10,\"div\"],[14,0,\"neuronet\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"body\"],[12],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"text-center mb-4\"],[12],[2,\"\\n        \"],[10,\"img\"],[14,\"src\",\"/img/logo/logo-neuronet-400x170-hor.jpg\"],[14,0,\"img-fluid\"],[14,\"alt\",\"LivaRava Neuronet\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n      \"],[10,\"div\"],[14,0,\"row\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-10\"],[12],[2,\"\\n\\n          \"],[2,[35,0,[\"description\"]]],[2,\"\\n\\n          \"],[10,\"hr\"],[12],[13],[2,\"\\n\\n          \"],[10,\"p\"],[14,0,\"text-center\"],[12],[2,\"Виникли питання? \"],[6,[37,1],null,[[\"route\"],[\"contact\"]],[[\"default\"],[{\"statements\":[[2,\"Пишіть нам\"]],\"parameters\":[]}]]],[2,\".\"],[13],[2,\"\\n\\n        \"],[13],[2,\"\\n        \"],[10,\"div\"],[14,0,\"col-sm-1\"],[12],[13],[2,\"\\n      \"],[13],[2,\"\\n\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n  \"],[10,\"ol\"],[14,0,\"breadcrumb mt-3\"],[12],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item\"],[12],[6,[37,1],null,[[\"route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"i\"],[14,0,\"fa fa-home\"],[12],[13],[2,\"Home\"]],\"parameters\":[]}]]],[13],[2,\"\\n    \"],[10,\"li\"],[14,0,\"breadcrumb-item active\"],[12],[2,\"Neuronet\"],[13],[2,\"\\n  \"],[13],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"link-to\",\"common/common-article\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/neuronet.hbs"
    }
  });
  _exports.default = _default;
});