define("livarava-web/models/axon", ["exports", "ember-data", "livarava-web/utils/get-display-label", "livarava-web/utils/get-display-icon-class"], function (_exports, _emberData, _getDisplayLabel, _getDisplayIconClass) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _emberData.default.Model.extend({
    // Attributes
    title: _emberData.default.attr('string'),
    // Datetime
    created: _emberData.default.attr('datetime'),
    updated: _emberData.default.attr('datetime'),
    // Display
    display: _emberData.default.attr('string'),
    displayLabel: Ember.computed('display', function () {
      return (0, _getDisplayLabel.default)(this.display);
    }),
    displayIconClass: Ember.computed('display', function () {
      return (0, _getDisplayIconClass.default)(this.display);
    }),
    // Relationships
    author: _emberData.default.belongsTo('user'),
    liva: _emberData.default.belongsTo('neuron'),
    rava: _emberData.default.belongsTo('neuron'),
    lira: _emberData.default.belongsTo('neuron')
  });
  _exports.default = _default;
});