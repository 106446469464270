define("livarava-web/templates/events/register", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "t2wirf8I",
    "block": "{\"symbols\":[],\"statements\":[[6,[37,2],null,null,[[\"default\"],[{\"statements\":[[2,\"\\n  \"],[1,[30,[36,1],null,[[\"event\",\"ticket\",\"order\"],[[35,0,[\"event\"]],[35,0,[\"ticket\"]],[35,0,[\"order\"]]]]]],[2,\"\\n\\n\"]],\"parameters\":[]}]]]],\"hasEval\":false,\"upvars\":[\"model\",\"event/event-register-form\",\"common/common-article-min\"]}",
    "meta": {
      "moduleName": "livarava-web/templates/events/register.hbs"
    }
  });
  _exports.default = _default;
});