define("livarava-web/routes/feedbacks/create", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    store: Ember.inject.service(),
    // Model
    model: function model() {
      return this.store.createRecord('feedback');
    }
  });
  _exports.default = _default;
});