define("livarava-web/routes/events/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.Route.extend({
    // Services
    title: 'LivaRava Events',
    // Model
    model: function model(params) {
      var store = this.store;
      return Ember.RSVP.hash({
        items: store.query('event', {
          'filter[featured]': true,
          'filter[display]': 'public',
          per_page: 6,
          page: 1,
          sort: '-startdate'
        })
      });
    }
  });
  _exports.default = _default;
});